<script>
import Layout from "../layouts/main";
import appConfig from "@/app.config";

// import Chart from "@/components/widgets/chart";
import Stat from "@/components/widgets/widget-stat";
import Transaction from "@/components/widgets/transaction";
import TransactionNew from "@/components/widgets/transactionNew";
// import Activity from "@/components/widgets/activity";

import { sparklineChartData, salesDonutChart, radialBarChart } from "./data";
import {Money} from "v-money";
import {required} from "vuelidate/lib/validators";


export default {
  page: {
    title: "Dashboard",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
    // Chart,
    Stat,
    Transaction,
    // Activity,
    TransactionNew,
    Money
  },
  created(){
    // eslint-disable-next-line no-undef
    if(!User.loggedIn()){
      this.$router.push({name: 'admin-login'})
    }
    this.getTotalAdmin();
    this.getTransferList();

    this.getCurrencyData();
    setInterval(() => {
      this.getCurrencyData();
    },3600000);
  },
  data() {
    return {
      resultData: [],
      resultShow: false,
      form: {
        currency: null,
        firstCurrencySelect: null,
        secondCurrencySelect: null,
      },
      typeSubmit:false,
      errors:{},
      money: {
        decimal: ',',
        thousands: '.',
        // prefix: 'R$ ',
        // suffix: ' #',
        precision: 2,
        masked: true /* doesn't work with directive */
      },
      basicColumChartExchangeRate: {
        chartOptions: {
          chart: {
            toolbar: {
              show: false
            }
          },
          plotOptions: {
            bar: {
              horizontal: false,
              endingShape: 'rounded',
              columnWidth: '55%',
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          colors: ['#02a499'],
          xaxis: {
            categories: [],
          },
          legend: {
            offsetY: -5,
          },
          // yaxis: {
          //   title: {
          //     text: '$ (thousands)'
          //   }
          // },
          fill: {
            opacity: 1

          },
          grid: {
            row: {
              colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.2
            },
            borderColor: '#f1f3fa'
          },
          tooltip: {
            y: {
              formatter(val) {
                // return '$ ' + val + ' thousands';
                // return new Intl.NumberFormat('en-IN').format(val);
                // let value = parseFloat(val).toFixed(2);
                // return new Intl.NumberFormat('en-IN').format(val);
                return new Intl.NumberFormat('tr-TR').format(val);
              }
            }
          }
        },
        series: [{
          name: 'Currency',
          data: []
        }],

      },
      orderCreated:[],
      transferList:{
        order:[],
        stockTransfer:[],
      },
      totalAdmin: null,
      totalUser: null,
      totalCustomer: null,
      totalProduct: null,
      sparklineChartData: sparklineChartData,
      salesDonutChart: salesDonutChart,
      radialBarChart: radialBarChart,
      statData: [
        {
          title: "Orders",
          image: require("@/assets/images/services-icon/01.png"),
          value: "1,685",
          subText: "+ 12%",
          color: "success"
        },
        {
          title: "Revenue",
          image: require("@/assets/images/services-icon/02.png"),
          value: "52,368",
          subText: "- 28%",
          color: "danger"
        },
        {
          title: "Average Price",
          image: require("@/assets/images/services-icon/03.png"),
          value: "15.8",
          subText: "00%",
          color: "info"
        },
        {
          title: "Product Sold",
          image: require("@/assets/images/services-icon/04.png"),
          value: "2436",
          subText: "+ 84%",
          color: "warning"
        }
      ],
      transactions: [
        {
          id: "#14256",
          image: require("@/assets/images/users/user-2.jpg"),
          name: "Philip Smead",
          date: "15/1/2018",
          amount: "$94",
          status: "Delivered"
        },
        {
          id: "#14257",
          image: require("@/assets/images/users/user-3.jpg"),
          name: "Brent Shipley",
          date: "16/1/2019",
          amount: "$112",
          status: "Pending"
        },
        {
          id: "#14258",
          image: require("@/assets/images/users/user-4.jpg"),
          name: "Robert Sitton",
          date: "17/1/2019",
          amount: "$116",
          status: "Delivered"
        },
        {
          id: "#14259",
          image: require("@/assets/images/users/user-5.jpg"),
          name: "Alberto Jackson",
          date: "18/1/2019",
          amount: "$109",
          status: "Cancel"
        },
        {
          id: "#14260",
          image: require("@/assets/images/users/user-6.jpg"),
          name: "David Sanchez",
          date: "15/1/2018",
          amount: "$94",
          status: "Delivered"
        },
        {
          id: "#14261",
          image: require("@/assets/images/users/user-2.jpg"),
          name: "Philip Smead",
          date: "15/1/2018",
          amount: "$94",
          status: "Pending"
        }
      ],
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        maxFilesize: 0.5,
        headers: { "My-Awesome-Header": "header value" }
      },
      // activityData: [
      //   {
      //     date: "Jan 22",
      //     text: "Responded to need “Volunteer Activities”"
      //   },
      //   {
      //     date: "Jan 20",
      //     text:
      //       "At vero eos et accusamus et iusto odio dignissimos ducimus qui deleniti atque... "
      //   },
      //   {
      //     date: "Jan 19",
      //     text: "Joined the group “Boardsmanship Forum”"
      //   },
      //   {
      //     date: "Jan 17",
      //     text: "Responded to need “In-Kind Opportunity”"
      //   },
      //   {
      //     date: "Jan 16",
      //     text: "Sed ut perspiciatis unde omnis iste natus error sit rem."
      //   }
      // ]
    };
  },
  validations(){
    return{
      form:{
        currency: {required},
        firstCurrencySelect: {required},
        secondCurrencySelect: {required},
      },
    }
  },
  computed:{
    // eslint-disable-next-line vue/return-in-computed-property
    getResult(){
      return this.resultData;
    }
  },
  methods: {
    getCurrencyData:async function (){
      await this.$http.get('exchange-rate').then(({data})=>{
        for (let v = 0; v < data['value'].length; v++){
          // this.currencyData.push(data['value'][v]);
          this.basicColumChartExchangeRate.series[0].data.push(data['value'][v])
        }
        for (let l = 0; l < data['labels'].length; l++){
          // this.currencyLabel.push(data['labels'][l]);
          this.basicColumChartExchangeRate.chartOptions.xaxis.categories.push(data['labels'][l])
        }
      }).catch((error)=>{
        console.log(error);
      })
    },
    getTotalAdmin(){
      this.$http.get('panel-settings')
          .then(({data}) => (this.totalAdmin = data.dashboard))
          .catch()
    },
    getTransferList(){
      this.$http.get('transfer-list').then((response)=>{
        this.transferList.stockTransfer = response.data['stock_transfer']
        this.transferList.order = response.data['order']
        // console.log(this.transferList)


      }).catch((error)=>{
        console.log(error);
      })
    },
    converter(){
      this.typeSubmit = true;
      this.$v.$touch();
      if(this.$v.$invalid){
        return;
      }else{
        if (this.form.firstCurrencySelect === this.form.secondCurrencySelect){
          this.$toasted.global.warningCurrency()
          this.form.firstCurrencySelect = null;
          this.form.secondCurrencySelect = null;
        } else if (this.form.currency === "0,00"){
          this.$toasted.global.warningFee()
          this.form.currency = null;
        } else{
          this.$http.post('exchange-rate/converter',this.form)
              .then(({data}) => {
                this.resultData = [];
                // this.$router.push({ name: 'admin-service-list'})
                this.$toasted.global.success()
                this.resultData['currency'] = data['currency'];
                this.resultData['firstCurrencySelect'] = data['firstCurrencySelect'];
                this.resultData['secondCurrencySelect'] = data['secondCurrencySelect'];
                this.resultData['result'] = data['result'];
                // this.form.firstCurrencySelect = null;
                // this.form.secondCurrencySelect = null;
                // this.form.currency = null;
                this.resultShow = true;

              })
              .catch(error => this.errors = error.response.data.errors)
          }
        }
    }
  },
};
</script>

<template>
  <Layout>
    <!-- start page title -->

    <!-- end page title -->

    <div class="row mt-5">
      <div class="col-xl-3 col-md-6" v-for="(stat, key) of Object.keys(totalAdmin)" :key="key">
        <Stat
          :title="'Total'+' '+stat"
          :value="totalAdmin[stat]"
        />
      </div>
    </div>
    <!-- end row -->
    <div class="row">
      <div class="col-xl-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Currency Report</h4>
            <apexchart
                class="apex-charts"
                dir="ltr"
                height="380"
                type="bar"
                :series="basicColumChartExchangeRate.series"
                :options="basicColumChartExchangeRate.chartOptions"
            ></apexchart>
          </div>
        </div>
      </div>
      <div class="col-xl-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Currency Converter</h4>
            <form @submit.prevent="converter">
              <div class="row">
                <div class="col-md-4">
                  <label for="firstCurrencySelect">First Currency Select</label>
                  <select id="firstCurrencySelect" class="form-control" v-model="form.firstCurrencySelect"
                          :class="{'is-invalid': typeSubmit && $v.form.firstCurrencySelect.$error,}">
                    <option value="USD">USD</option>
                    <option value="EUR">EUR</option>
                    <option value="TRY">TRY</option>
                    <option value="QAR">QAR</option>
                    <option value="RUB">RUB</option>
                    <option value="KWD">KWD</option>
                  </select>
                  <div
                      v-if="typeSubmit && $v.form.firstCurrencySelect.$error"
                      class="invalid-feedback"
                  >
                      <span v-if="!$v.form.firstCurrencySelect.required"
                      >This value is required.</span
                      >
                  </div>
                </div>
                <div class="col-md-4">
                  <label for="fistCurrency">First Currency</label>
                  <money v-model.lazy="form.currency" v-bind="money" class="form-control" id="fistCurrency"
                         :class="{'is-invalid': typeSubmit && $v.form.currency.$error,}"></money>
                  <div
                      v-if="typeSubmit && $v.form.currency.$error"
                      class="invalid-feedback"
                  >
                      <span v-if="!$v.form.currency.required"
                      >This value is required.</span
                      >
                  </div>
                </div>
                <div class="col-md-4">
                  <label for="secondCurrencySelect">Second Currency Select</label>
                  <select id="secondCurrencySelect" class="form-control" v-model="form.secondCurrencySelect"
                          :class="{'is-invalid': typeSubmit && $v.form.secondCurrencySelect.$error,}">
                    <option value="USD">USD</option>
                    <option value="EUR">EUR</option>
                    <option value="TRY">TRY</option>
                    <option value="QAR">QAR</option>
                    <option value="RUB">RUB</option>
                    <option value="KWD">KWD</option>
                  </select>
                  <div
                      v-if="typeSubmit && $v.form.secondCurrencySelect.$error"
                      class="invalid-feedback"
                  >
                      <span v-if="!$v.form.secondCurrencySelect.required"
                      >This value is required.</span
                      >
                  </div>
                </div>
              </div>
              <br>
<!--              <div class="row">-->
<!--                <div class="col-md-6">-->
<!--                  <label for="firstCurrencySelect">First Currency Select</label>-->
<!--                  <select id="firstCurrencySelect" class="form-control" v-model="form.firstCurrencySelect"-->
<!--                          :class="{'is-invalid': typeSubmit && $v.form.firstCurrencySelect.$error,}">-->
<!--                    <option value="USD">USD</option>-->
<!--                    <option value="EUR">EUR</option>-->
<!--                    <option value="QAR">QAR</option>-->
<!--                    <option value="RUB">RUB</option>-->
<!--                    <option value="TRY">TRY</option>-->
<!--                  </select>-->
<!--                  <div-->
<!--                      v-if="typeSubmit && $v.form.firstCurrencySelect.$error"-->
<!--                      class="invalid-feedback"-->
<!--                  >-->
<!--                      <span v-if="!$v.form.firstCurrencySelect.required"-->
<!--                      >This value is required.</span-->
<!--                      >-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="col-md-6">-->
<!--                  <label for="secondCurrencySelect">Second Currency Select</label>-->
<!--                  <select id="secondCurrencySelect" class="form-control" v-model="form.secondCurrencySelect"-->
<!--                          :class="{'is-invalid': typeSubmit && $v.form.secondCurrencySelect.$error,}">-->
<!--                    <option value="USD">USD</option>-->
<!--                    <option value="EUR">EUR</option>-->
<!--                    <option value="QAR">QAR</option>-->
<!--                    <option value="RUB">RUB</option>-->
<!--                    <option value="TRY">TRY</option>-->
<!--                  </select>-->
<!--                  <div-->
<!--                      v-if="typeSubmit && $v.form.secondCurrencySelect.$error"-->
<!--                      class="invalid-feedback"-->
<!--                  >-->
<!--                      <span v-if="!$v.form.secondCurrencySelect.required"-->
<!--                      >This value is required.</span-->
<!--                      >-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
              <br>
              <button class="btn btn-success btn-sm col-xl-12">Convert</button>
            </form>
            <br>
            <div class="row mt-2" v-show="resultShow">
              <div class="card">
                <div class="card-body">
                  <p><b>Currency:</b> {{ getResult['currency'] }}</p>
                  <p><b>First Currency Select:</b> {{ getResult['firstCurrencySelect'] }}</p>
                  <p><b>Second Currency Select:</b> {{ getResult['secondCurrencySelect'] }}</p>
                  <p><b>Result Currency:</b> {{ getResult['result'] }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
<!--    <div class="row">-->
<!--      <div class="col-xl-12">-->
<!--        <div class="row mt-5">-->
<!--          <div class="col-xl-12 col-md-6">-->
<!--            -->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="col-xl-9">-->
<!--        <div class="card">-->
<!--          <div class="card-body">-->
<!--            <h4 class="card-title mb-4">Monthly Earning</h4>-->
<!--            <div class="row">-->
<!--              <div class="col-lg-7">-->
<!--                <div>-->
<!--                  <Chart />-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="col-lg-5">-->
<!--                <div class="row">-->
<!--                  <div class="col-md-6">-->
<!--                    <div class="text-center">-->
<!--                      <p class="text-muted mb-4">This month</p>-->
<!--                      <h3>$34,252</h3>-->
<!--                      <p-->
<!--                        class="text-muted mb-4"-->
<!--                      >It will be as simple as in fact it will be occidental.</p>-->
<!--                      <apexchart-->
<!--                        class="apex-charts"-->
<!--                        height="150"-->
<!--                        type="radialBar"-->
<!--                        :series="[80]"-->
<!--                        :options="radialBarChart.chartOptions"-->
<!--                      ></apexchart>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-md-6">-->
<!--                    <div class="text-center">-->
<!--                      <p class="text-muted mb-4">Last month</p>-->
<!--                      <h3>$36,253</h3>-->
<!--                      <p-->
<!--                        class="text-muted mb-4"-->
<!--                      >It will be as simple as in fact it will be occidental.</p>-->
<!--                      &lt;!&ndash; Radial bar chart  &ndash;&gt;-->
<!--                      <apexchart-->
<!--                        class="apex-charts"-->
<!--                        height="150"-->
<!--                        type="radialBar"-->
<!--                        :series="[55]"-->
<!--                        :options="radialBarChart.chartOptions"-->
<!--                      ></apexchart>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            &lt;!&ndash; end row &ndash;&gt;-->
<!--          </div>-->
<!--        </div>-->
<!--        &lt;!&ndash; end card &ndash;&gt;-->
<!--      </div>-->

<!--      <div class="col-xl-3">-->
<!--        <div class="card">-->
<!--          <div class="card-body">-->
<!--            <div>-->
<!--              <h4 class="card-title mb-4">Sales Analytics</h4>-->
<!--            </div>-->
<!--            <div class="wid-peity mb-4">-->
<!--              <div class="row">-->
<!--                <div class="col-md-6">-->
<!--                  <div>-->
<!--                    <p class="text-muted">Online</p>-->
<!--                    <h5 class="mb-4">1,542</h5>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="col-md-6">-->
<!--                  <div class="mb-4">-->
<!--                    &lt;!&ndash; sparkline chart &ndash;&gt;-->
<!--                    <sparkline height="65" width="122">-->
<!--                      <sparklineLine-->
<!--                        :data="sparklineChartData.sparkChart1"-->
<!--                        :limit="sparklineChartData.sparkChart1.length"-->
<!--                        :styles="sparklineChartData.style"-->
<!--                        :refLineStyles="sparklineChartData.reflineStyle"-->
<!--                      />-->
<!--                    </sparkline>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="wid-peity mb-4">-->
<!--              <div class="row">-->
<!--                <div class="col-md-6">-->
<!--                  <div>-->
<!--                    <p class="text-muted">Offline</p>-->
<!--                    <h5 class="mb-4">6,451</h5>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="col-md-6">-->
<!--                  <div class="mb-4">-->
<!--                    <sparkline height="65" width="122">-->
<!--                      <sparklineLine-->
<!--                        :data="sparklineChartData.sparkChart2"-->
<!--                        :limit="sparklineChartData.sparkChart2.length"-->
<!--                        :styles="sparklineChartData.style"-->
<!--                        :refLineStyles="sparklineChartData.reflineStyle"-->
<!--                      />-->
<!--                    </sparkline>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class>-->
<!--              <div class="row">-->
<!--                <div class="col-md-6">-->
<!--                  <div>-->
<!--                    <p class="text-muted">Marketing</p>-->
<!--                    <h5>84,574</h5>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="col-md-6">-->
<!--                  <div class>-->
<!--                    <sparkline height="65" width="122">-->
<!--                      <sparklineLine-->
<!--                        :data="sparklineChartData.sparkChart3"-->
<!--                        :limit="sparklineChartData.sparkChart3.length"-->
<!--                        :styles="sparklineChartData.style"-->
<!--                        :refLineStyles="sparklineChartData.reflineStyle"-->
<!--                      />-->
<!--                    </sparkline>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <!-- end row -->

<!--    <div class="row">-->
<!--      <div class="col-xl-6">-->
<!--        <div class="card">-->
<!--          <div class="card-body">-->
<!--            <h4 class="card-title mb-4">Sales Report</h4>-->

<!--            <div class="cleafix">-->
<!--              <p class="float-start">-->
<!--                <i class="mdi mdi-calendar me-1 text-primary"></i> Jan 01 - Jan 31-->
<!--              </p>-->
<!--              <h5 class="font-18 text-end">$4230</h5>-->
<!--            </div>-->
<!--            &lt;!&ndash; Sales donut chart &ndash;&gt;-->
<!--            <chartist-->
<!--              ratio="ct-chart wid"-->
<!--              :data="salesDonutChart.data"-->
<!--              :options="salesDonutChart.options"-->
<!--              type="Pie"-->
<!--            ></chartist>-->
<!--            <div class="mt-4">-->
<!--              <table class="table mb-0">-->
<!--                <tbody>-->
<!--                  <tr>-->
<!--                    <td>-->
<!--                      <span class="badge bg-primary">Desk</span>-->
<!--                    </td>-->
<!--                    <td>Desktop</td>-->
<!--                    <td class="text-end">54.5%</td>-->
<!--                  </tr>-->
<!--                  <tr>-->
<!--                    <td>-->
<!--                      <span class="badge bg-success">Mob</span>-->
<!--                    </td>-->
<!--                    <td>Mobile</td>-->
<!--                    <td class="text-end">28.0%</td>-->
<!--                  </tr>-->
<!--                  <tr>-->
<!--                    <td>-->
<!--                      <span class="badge bg-warning">Tab</span>-->
<!--                    </td>-->
<!--                    <td>Tablets</td>-->
<!--                    <td class="text-end">17.5%</td>-->
<!--                  </tr>-->
<!--                </tbody>-->
<!--              </table>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="col-xl-6">-->
<!--        <Activity :activityData="activityData" />-->
<!--      </div>-->
<!--    </div>-->
    <!-- end row -->

    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Order</h4>
            <Transaction :transactions="transferList.order" v-if="transferList.order" />
          </div>
        </div>
      </div>
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Stock Transfer</h4>
            <transaction-new :transactions="transferList.stockTransfer" v-if="transferList.stockTransfer" />
          </div>
        </div>
      </div>
<!--      <div class="col-xl-4">-->
<!--        <Chat :chat-messages="chatMessages" />-->
<!--      </div>-->
    </div>
    <!-- end row -->
  </Layout>
</template>