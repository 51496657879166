<template>
  <Layout>
   <div>selam</div>
  </Layout>


</template>


<script>

import Layout from "../../router/layouts/main.vue";
// import PageHeader from "@/components/page-header";


export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Layout
  },
  created() {
    // eslint-disable-next-line no-undef
    if (!User.loggedIn()) {
      this.$router.push({name: '/admin/login'})
    }
    this.selectedChatUser();
    this.chatUser();
  },
  data() {
    return {
      selectedChatUserRoom: [],
    }
  },


  mounted() {

  },
  computed: {},
  methods: {
    selectedChatUser() {
      let roomId = this.$route.params.id
      this.$http.post('chat/crm-user/' + roomId).then(response => {
        this.selectedChatUserRoom = response.data;
        console.log(response.data)
        // this.setChatRoom(response.data[0]);
      }).catch();
    },
    chatUser() {
      let roomId = this.$route.params.id
      // eslint-disable-next-line no-undef
      let id = User.id()
      this.$http.post('chat/user/' + roomId + '/' + id).then(() => {
      }).catch();
    }
  },


}
</script>

<style scoped>


</style>