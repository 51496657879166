var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('div',[_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"card card-statistics"},[_c('div',{staticClass:"card-body"},[_c('form',{staticClass:"row g-3",on:{"submit":function($event){$event.preventDefault();return _vm.imageResizeUpdate.apply(null, arguments)}}},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"sliderImageWidth"}},[_vm._v("Slider Image Width")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.slider_image_width),expression:"form.slider_image_width"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.typeSubmit && _vm.$v.form.slider_image_width.$error,
                },attrs:{"type":"text","min":"1","id":"sliderImageWidth"},domProps:{"value":(_vm.form.slider_image_width)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "slider_image_width", $event.target.value)}}}),(_vm.typeSubmit && _vm.$v.form.slider_image_width.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.slider_image_width.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"sliderImageHeight"}},[_vm._v("Slider Image Height")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.slider_image_height),expression:"form.slider_image_height"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.typeSubmit && _vm.$v.form.slider_image_height.$error,
                },attrs:{"type":"text","min":"1","id":"sliderImageHeight"},domProps:{"value":(_vm.form.slider_image_height)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "slider_image_height", $event.target.value)}}}),(_vm.typeSubmit && _vm.$v.form.slider_image_height.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.slider_image_height.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"blogImageWidth"}},[_vm._v("Blog Image Width")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.blog_image_width),expression:"form.blog_image_width"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.typeSubmit && _vm.$v.form.blog_image_width.$error,
                },attrs:{"type":"text","min":"1","id":"blogImageWidth"},domProps:{"value":(_vm.form.blog_image_width)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "blog_image_width", $event.target.value)}}}),(_vm.typeSubmit && _vm.$v.form.blog_image_width.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.blog_image_width.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"blogImageHeight"}},[_vm._v("Blog Image Height")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.blog_image_height),expression:"form.blog_image_height"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.typeSubmit && _vm.$v.form.blog_image_height.$error,
                },attrs:{"type":"text","min":"1","id":"blogImageHeight"},domProps:{"value":(_vm.form.blog_image_height)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "blog_image_height", $event.target.value)}}}),(_vm.typeSubmit && _vm.$v.form.blog_image_height.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.blog_image_height.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()])]),_c('br'),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"productImageWidth"}},[_vm._v("Product Image Width")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.product_image_width),expression:"form.product_image_width"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.typeSubmit && _vm.$v.form.product_image_width.$error,
                },attrs:{"type":"text","min":"1","id":"productImageWidth"},domProps:{"value":(_vm.form.product_image_width)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "product_image_width", $event.target.value)}}}),(_vm.typeSubmit && _vm.$v.form.product_image_width.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.product_image_width.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"productImageHeight"}},[_vm._v("Product Image Height")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.product_image_height),expression:"form.product_image_height"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.typeSubmit && _vm.$v.form.product_image_height.$error,
                },attrs:{"type":"text","min":"1","id":"productImageHeight"},domProps:{"value":(_vm.form.product_image_height)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "product_image_height", $event.target.value)}}}),(_vm.typeSubmit && _vm.$v.form.product_image_height.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.product_image_height.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"aboutUsImageWidth"}},[_vm._v("About Us Image Width")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.about_us_image_width),expression:"form.about_us_image_width"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.typeSubmit && _vm.$v.form.about_us_image_width.$error,
                },attrs:{"type":"text","min":"1","id":"aboutUsImageWidth"},domProps:{"value":(_vm.form.about_us_image_width)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "about_us_image_width", $event.target.value)}}}),(_vm.typeSubmit && _vm.$v.form.about_us_image_width.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.about_us_image_width.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"aboutUsImageHeight"}},[_vm._v("About Us Image Height")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.about_us_image_height),expression:"form.about_us_image_height"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.typeSubmit && _vm.$v.form.about_us_image_height.$error,
                },attrs:{"type":"text","min":"1","id":"aboutUsImageHeight"},domProps:{"value":(_vm.form.about_us_image_height)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "about_us_image_height", $event.target.value)}}}),(_vm.typeSubmit && _vm.$v.form.about_us_image_height.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.about_us_image_height.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()])]),_c('br'),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"serviceImageWidth"}},[_vm._v("Service Image Width")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.service_image_width),expression:"form.service_image_width"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.typeSubmit && _vm.$v.form.service_image_width.$error,
                },attrs:{"type":"text","min":"1","id":"serviceImageWidth"},domProps:{"value":(_vm.form.service_image_width)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "service_image_width", $event.target.value)}}}),(_vm.typeSubmit && _vm.$v.form.service_image_width.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.service_image_width.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"serviceImageHeight"}},[_vm._v("Service Image Height")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.service_image_height),expression:"form.service_image_height"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.typeSubmit && _vm.$v.form.service_image_height.$error,
                },attrs:{"type":"text","min":"1","id":"serviceImageHeight"},domProps:{"value":(_vm.form.service_image_height)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "service_image_height", $event.target.value)}}}),(_vm.typeSubmit && _vm.$v.form.service_image_height.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.service_image_height.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"teamImageWidth"}},[_vm._v("Team Image Width")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.team_image_width),expression:"form.team_image_width"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.typeSubmit && _vm.$v.form.team_image_width.$error,
                },attrs:{"type":"text","min":"1","id":"teamImageWidth"},domProps:{"value":(_vm.form.team_image_width)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "team_image_width", $event.target.value)}}}),(_vm.typeSubmit && _vm.$v.form.team_image_width.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.team_image_width.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"teamImageHeight"}},[_vm._v("Team Image Height")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.team_image_height),expression:"form.team_image_height"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.typeSubmit && _vm.$v.form.team_image_height.$error,
                },attrs:{"type":"text","min":"1","id":"teamImageHeight"},domProps:{"value":(_vm.form.team_image_height)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "team_image_height", $event.target.value)}}}),(_vm.typeSubmit && _vm.$v.form.team_image_height.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.team_image_height.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()])]),_c('br'),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"projectImageWidth"}},[_vm._v("Project Image Width")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.project_image_width),expression:"form.project_image_width"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.typeSubmit && _vm.$v.form.project_image_width.$error,
                },attrs:{"type":"text","min":"1","id":"projectImageWidth"},domProps:{"value":(_vm.form.project_image_width)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "project_image_width", $event.target.value)}}}),(_vm.typeSubmit && _vm.$v.form.project_image_width.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.project_image_width.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"projectImageHeight"}},[_vm._v("Project Image Height")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.project_image_height),expression:"form.project_image_height"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.typeSubmit && _vm.$v.form.project_image_height.$error,
                },attrs:{"type":"text","min":"1","id":"projectImageHeight"},domProps:{"value":(_vm.form.project_image_height)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "project_image_height", $event.target.value)}}}),(_vm.typeSubmit && _vm.$v.form.project_image_height.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.project_image_height.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"referanceImageWidth"}},[_vm._v("Referance Image Width")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.referance_image_width),expression:"form.referance_image_width"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.typeSubmit && _vm.$v.form.referance_image_width.$error,
                },attrs:{"type":"text","min":"1","id":"referanceImageWidth"},domProps:{"value":(_vm.form.referance_image_width)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "referance_image_width", $event.target.value)}}}),(_vm.typeSubmit && _vm.$v.form.referance_image_width.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.referance_image_width.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"referanceImageHeight"}},[_vm._v("Referance Image Height")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.referance_image_height),expression:"form.referance_image_height"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.typeSubmit && _vm.$v.form.referance_image_height.$error,
                },attrs:{"type":"text","min":"1","id":"referanceImageHeight"},domProps:{"value":(_vm.form.referance_image_height)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "referance_image_height", $event.target.value)}}}),(_vm.typeSubmit && _vm.$v.form.referance_image_height.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.referance_image_height.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()])]),_c('br'),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"adminImageWidth"}},[_vm._v("Admin Image Width")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.admin_image_width),expression:"form.admin_image_width"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.typeSubmit && _vm.$v.form.admin_image_width.$error,
                },attrs:{"type":"text","min":"1","id":"adminImageWidth"},domProps:{"value":(_vm.form.admin_image_width)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "admin_image_width", $event.target.value)}}}),(_vm.typeSubmit && _vm.$v.form.admin_image_width.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.admin_image_width.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"adminImageHeight"}},[_vm._v("Admin Image Height")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.admin_image_height),expression:"form.admin_image_height"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.typeSubmit && _vm.$v.form.admin_image_height.$error,
                },attrs:{"type":"text","min":"1","id":"adminImageHeight"},domProps:{"value":(_vm.form.admin_image_height)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "admin_image_height", $event.target.value)}}}),(_vm.typeSubmit && _vm.$v.form.admin_image_height.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.admin_image_height.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"noteImageWidth"}},[_vm._v("Note Image Width")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.note_image_width),expression:"form.note_image_width"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.typeSubmit && _vm.$v.form.note_image_width.$error,
                },attrs:{"type":"text","min":"1","id":"noteImageWidth"},domProps:{"value":(_vm.form.note_image_width)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "note_image_width", $event.target.value)}}}),(_vm.typeSubmit && _vm.$v.form.note_image_width.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.note_image_width.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"noteImageHeight"}},[_vm._v("Note Image Height")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.note_image_height),expression:"form.note_image_height"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.typeSubmit && _vm.$v.form.note_image_height.$error,
                },attrs:{"type":"text","min":"1","id":"noteImageHeight"},domProps:{"value":(_vm.form.note_image_height)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "note_image_height", $event.target.value)}}}),(_vm.typeSubmit && _vm.$v.form.note_image_height.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.note_image_height.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()])]),_c('br'),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"webSiteImageWidth"}},[_vm._v("Web Site Settings Image Width")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.website_settings_image_width),expression:"form.website_settings_image_width"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.typeSubmit && _vm.$v.form.website_settings_image_width.$error,
                },attrs:{"type":"text","min":"1","id":"webSiteImageWidth"},domProps:{"value":(_vm.form.website_settings_image_width)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "website_settings_image_width", $event.target.value)}}}),(_vm.typeSubmit && _vm.$v.form.website_settings_image_width.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.website_settings_image_width.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"webSiteImageHeight"}},[_vm._v("Web Site Settings Image Height")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.website_settings_image_height),expression:"form.website_settings_image_height"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.typeSubmit && _vm.$v.form.website_settings_image_height.$error,
                },attrs:{"type":"text","min":"1","id":"webSiteImageHeight"},domProps:{"value":(_vm.form.website_settings_image_height)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "website_settings_image_height", $event.target.value)}}}),(_vm.typeSubmit && _vm.$v.form.website_settings_image_height.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.website_settings_image_height.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"panelImageWidth"}},[_vm._v("Panel Settings Image Width")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.panel_settings_image_width),expression:"form.panel_settings_image_width"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.typeSubmit && _vm.$v.form.panel_settings_image_width.$error,
                },attrs:{"type":"text","min":"1","id":"panelImageWidth"},domProps:{"value":(_vm.form.panel_settings_image_width)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "panel_settings_image_width", $event.target.value)}}}),(_vm.typeSubmit && _vm.$v.form.panel_settings_image_width.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.panel_settings_image_width.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"panelImageHeight"}},[_vm._v("Panel Settings Image Height")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.panel_settings_image_height),expression:"form.panel_settings_image_height"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.typeSubmit && _vm.$v.form.panel_settings_image_height.$error,
                },attrs:{"type":"text","min":"1","id":"panelImageHeight"},domProps:{"value":(_vm.form.panel_settings_image_height)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "panel_settings_image_height", $event.target.value)}}}),(_vm.typeSubmit && _vm.$v.form.panel_settings_image_height.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.panel_settings_image_height.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()])]),_c('br'),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"orderTrackingImageWidth"}},[_vm._v("Order Tracking Status Image Width")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.order_tracking_status_image_width),expression:"form.order_tracking_status_image_width"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.typeSubmit && _vm.$v.form.order_tracking_status_image_width.$error,
                },attrs:{"type":"text","min":"1","id":"orderTrackingImageWidth"},domProps:{"value":(_vm.form.order_tracking_status_image_width)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "order_tracking_status_image_width", $event.target.value)}}}),(_vm.typeSubmit && _vm.$v.form.order_tracking_status_image_width.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.order_tracking_status_image_width.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"orderTrackingImageHeight"}},[_vm._v("Order Tracking Status Image Height")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.order_tracking_status_image_height),expression:"form.order_tracking_status_image_height"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.typeSubmit && _vm.$v.form.order_tracking_status_image_height.$error,
                },attrs:{"type":"text","min":"1","id":"orderTrackingImageHeight"},domProps:{"value":(_vm.form.order_tracking_status_image_height)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "order_tracking_status_image_height", $event.target.value)}}}),(_vm.typeSubmit && _vm.$v.form.order_tracking_status_image_height.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.order_tracking_status_image_height.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"employeeImageWidth"}},[_vm._v("Employee Image Width")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.employee_image_width),expression:"form.employee_image_width"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.typeSubmit && _vm.$v.form.employee_image_width.$error,
                },attrs:{"type":"text","min":"1","id":"employeeImageWidth"},domProps:{"value":(_vm.form.employee_image_width)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "employee_image_width", $event.target.value)}}}),(_vm.typeSubmit && _vm.$v.form.employee_image_width.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.employee_image_width.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"employeeImageHeight"}},[_vm._v("Employee Image Height")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.employee_image_height),expression:"form.employee_image_height"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.typeSubmit && _vm.$v.form.employee_image_height.$error,
                },attrs:{"type":"text","min":"1","id":"employeeImageHeight"},domProps:{"value":(_vm.form.employee_image_height)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "employee_image_height", $event.target.value)}}}),(_vm.typeSubmit && _vm.$v.form.employee_image_height.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.employee_image_height.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()])]),_c('br'),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"campaignImageWidth"}},[_vm._v("Campaign Image Width")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.campaign_image_width),expression:"form.campaign_image_width"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.typeSubmit && _vm.$v.form.campaign_image_width.$error,
                },attrs:{"type":"text","min":"1","id":"campaignImageWidth"},domProps:{"value":(_vm.form.campaign_image_width)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "campaign_image_width", $event.target.value)}}}),(_vm.typeSubmit && _vm.$v.form.campaign_image_width.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.campaign_image_width.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"campaignImageHeight"}},[_vm._v("Campaign Image Height")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.campaign_image_height),expression:"form.campaign_image_height"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.typeSubmit && _vm.$v.form.campaign_image_height.$error,
                },attrs:{"type":"text","min":"1","id":"campaignImageHeight"},domProps:{"value":(_vm.form.campaign_image_height)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "campaign_image_height", $event.target.value)}}}),(_vm.typeSubmit && _vm.$v.form.campaign_image_height.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.campaign_image_height.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"shippingCompanyImageWidth"}},[_vm._v("Shipping Company Image Width")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.shipping_company_image_width),expression:"form.shipping_company_image_width"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.typeSubmit && _vm.$v.form.shipping_company_image_width.$error,
                },attrs:{"type":"text","min":"1","id":"shippingCompanyImageWidth"},domProps:{"value":(_vm.form.shipping_company_image_width)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "shipping_company_image_width", $event.target.value)}}}),(_vm.typeSubmit && _vm.$v.form.shipping_company_image_width.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.shipping_company_image_width.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"shippingCompanyImageHeight"}},[_vm._v("Shipping Company Image Height")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.shipping_company_image_height),expression:"form.shipping_company_image_height"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.typeSubmit && _vm.$v.form.shipping_company_image_height.$error,
                },attrs:{"type":"text","min":"1","id":"shippingCompanyImageHeight"},domProps:{"value":(_vm.form.shipping_company_image_height)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "shipping_company_image_height", $event.target.value)}}}),(_vm.typeSubmit && _vm.$v.form.shipping_company_image_height.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.shipping_company_image_height.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()])]),_c('br'),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"variantImageWidth"}},[_vm._v("Variant Image Width")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.variant_image_width),expression:"form.variant_image_width"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.typeSubmit && _vm.$v.form.variant_image_width.$error,
                },attrs:{"type":"text","min":"1","id":"variantImageWidth"},domProps:{"value":(_vm.form.variant_image_width)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "variant_image_width", $event.target.value)}}}),(_vm.typeSubmit && _vm.$v.form.variant_image_width.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.variant_image_width.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"variantImageHeight"}},[_vm._v("Variant Image Height")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.variant_image_height),expression:"form.variant_image_height"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.typeSubmit && _vm.$v.form.variant_image_height.$error,
                },attrs:{"type":"text","min":"1","id":"variantImageHeight"},domProps:{"value":(_vm.form.variant_image_height)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "variant_image_height", $event.target.value)}}}),(_vm.typeSubmit && _vm.$v.form.variant_image_height.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.variant_image_height.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"commentImageWidth"}},[_vm._v("Comment Image Width")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.comment_image_width),expression:"form.comment_image_width"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.typeSubmit && _vm.$v.form.comment_image_width.$error,
                },attrs:{"type":"text","min":"1","id":"commentImageWidth"},domProps:{"value":(_vm.form.comment_image_width)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "comment_image_width", $event.target.value)}}}),(_vm.typeSubmit && _vm.$v.form.comment_image_width.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.comment_image_width.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"commentImageHeight"}},[_vm._v("Comment Image Height")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.comment_image_height),expression:"form.comment_image_height"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.typeSubmit && _vm.$v.form.comment_image_height.$error,
                },attrs:{"type":"text","min":"1","id":"commentImageHeight"},domProps:{"value":(_vm.form.comment_image_height)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "comment_image_height", $event.target.value)}}}),(_vm.typeSubmit && _vm.$v.form.comment_image_height.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.comment_image_height.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()])]),_c('br'),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"collectionImageWidth"}},[_vm._v("Collection Image Width")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.collection_image_width),expression:"form.collection_image_width"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.typeSubmit && _vm.$v.form.collection_image_width.$error,
                },attrs:{"type":"text","min":"1","id":"collectionImageWidth"},domProps:{"value":(_vm.form.collection_image_width)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "collection_image_width", $event.target.value)}}}),(_vm.typeSubmit && _vm.$v.form.collection_image_width.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.collection_image_width.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"collectionImageHeight"}},[_vm._v("Collection Image Height")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.collection_image_height),expression:"form.collection_image_height"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.typeSubmit && _vm.$v.form.collection_image_height.$error,
                },attrs:{"type":"text","min":"1","id":"collectionImageHeight"},domProps:{"value":(_vm.form.collection_image_height)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "collection_image_height", $event.target.value)}}}),(_vm.typeSubmit && _vm.$v.form.collection_image_height.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.collection_image_height.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"truckImageWidth"}},[_vm._v("Truck Image Width")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.truck_image_width),expression:"form.truck_image_width"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.typeSubmit && _vm.$v.form.truck_image_width.$error,
                },attrs:{"type":"text","min":"1","id":"truckImageWidth"},domProps:{"value":(_vm.form.truck_image_width)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "truck_image_width", $event.target.value)}}}),(_vm.typeSubmit && _vm.$v.form.truck_image_width.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.truck_image_width.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"truckImageHeight"}},[_vm._v("Truck Image Height")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.truck_image_height),expression:"form.truck_image_height"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.typeSubmit && _vm.$v.form.truck_image_height.$error,
                },attrs:{"type":"text","min":"1","id":"truckImageHeight"},domProps:{"value":(_vm.form.truck_image_height)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "truck_image_height", $event.target.value)}}}),(_vm.typeSubmit && _vm.$v.form.truck_image_height.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.truck_image_height.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()])]),_c('br'),_c('button',{staticClass:"btn btn-primary col-xl-12",attrs:{"type":"submit"}},[_vm._v("Save")])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }