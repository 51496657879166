<template>
  <Layout>
    <PageHeader :items="item" :title="title"></PageHeader>
    <div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body">
                <b-tabs justified pills class="navtab-bg" content-class="p-3">
                  <b-tab active>
                    <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                  </span>
                      <span class="d-none d-sm-inline-block">Export Excel</span>
                    </template>
                    <div>
                      <div class="card-body">
                        <div class="row">
                          <b-form-select id="category-select-add" v-model="form.table_name" class="form-control"  style="margin-bottom: 20px" value-field="id" text-field="text" :options="tables">Tables</b-form-select>
                          <b-button @click="getExport" type="submit" class="btn btn-success col-xl-12">Export</b-button>
                        </div>
                      </div>
                    </div>
                  </b-tab>
                  <b-tab>
                    <template v-slot:title>
                                  <span class="d-inline-block d-sm-none">
                                    <i class="fas fa-cog"></i>
                                  </span>
                      <span class="d-none d-sm-inline-block">Import Excel</span>
                    </template>
                    <div>
                      <div class="card-body">
                        <div class="row">
                          <form id="mainForm" enctype="multipart/form-data">
                            <input type="file" ref="file" @change="upload($event)" class="form-control" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" id="importExcel">
                            <b-button @click.prevent="imported" type="submit" class="btn btn-success col-xl-12 mt-4">Export</b-button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </b-tab>

                </b-tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../router/layouts/main.vue";
import PageHeader from "@/components/page-header";

export default {
  components:{
    // eslint-disable-next-line vue/no-unused-components
    Layout,PageHeader
  },
  created(){
    // eslint-disable-next-line no-undef
    if(!User.loggedIn()){
      this.$router.push({name: '/admin/login'})
    }
    this.getTableName();
  },
  data(){
    return{
      options:[],
      tables:[],
      importData:[],
      form:{
        table_name: ''
      },
      fileForm:{
        file_name: ''
      },
      excelData: [],
    }
  },
  methods:{
    getTableName(){
      this.$http.get('get/tables')
          .then(({data}) => (this.tables = data))
          .catch()
    },
    getExport(){
      this.$http.post('table/export',this.form)
          .then(() => {
            this.$toasted.global.success();
            // eslint-disable-next-line no-console
          }).catch((error)=> {
        console.log(error);
        this.$toasted.global.error();
      });
    },
    // onFileSelected(event){

      // console.log(event.target.files[0])
      // importedFile.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      // let file = event.target.files[0];
      // let reader = new FileReader();
      // reader.onload = event => {
      //   this.fileForm.file_name = event.target.result
      // };
      // reader.readAsDataURL(file);
      // let importedFile = event.target.files[0];
      // let splitFileName = importedFile.name.split('.');
      // let ext = splitFileName.at(-1);
      // const extList = ['xlsx','csv','xls'];
      // extList.map(e=>{
      //   if (e == ext){
      //     this.fileForm.file_name = importedFile.name;
      //   }
      // })
    // },
    upload(event){
      var file = event.target.files[0];
      var form_data = new FormData();
      form_data.append('file', file);
      // console.log(event.target.files[0])
      this.importData.push(event.target.files[0].name);
      // this.$http.post('table/import',form_data)
      //     .then(() => {
      //       this.$toasted.global.success();
      //       // eslint-disable-next-line no-console
      //     }).catch((error)=> {
      //   console.log(error);
      //   this.$toasted.global.error();
      // });

    },
    imported(){
      // console.log(this.excelData);
      // const $form = document.querySelector('#mainForm');
      // const formData = new FormData($form);
      // console.log(formData);
      this.$http.post('table/import',this.importData)
          .then(() => {
            this.$toasted.global.success();
            // eslint-disable-next-line no-console
          }).catch((error)=> {
        console.log(error);
        this.$toasted.global.error();
      });
    },
  },

}
</script>

<style scoped>

</style>