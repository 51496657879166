<template>
  <Layout>
    <PageHeader :items="item" :title="title"></PageHeader>
    <div>
      <div class="timeline">
        <div v-for="(item,i) in arr" :key="item.id" >
          <div :class="item.class" :style="`animation-delay: ${i}s;`">
            <div class="text-box">
              <ul style="list-style: none;">
                <li><h3>{{ item.item[0].log_type  }} {{item.item[0].log_code}}</h3></li>
                <li><b>User: </b>{{item.item[0].user.name }} {{item.item[0].user.surname}}</li>
                <li><b>Uri: </b>{{item.item[0].request_uri}}</li>
                <li><b>Method: </b>{{item.item[0].request_method}}</li>
                <li><b>Date: </b>{{ moment(item.item[0].created_at).format("YYYY-MM-DD HH:mm:ss")}}</li>
              </ul>
            </div>
            <span :class="item.spanClass"></span>
          </div>
        </div>
      </div>
    </div>

  </Layout>



</template>


<script>

import Layout from "../../router/layouts/main.vue";
import PageHeader from "@/components/page-header";
import moment from "moment";



export default {
  components:{
    // eslint-disable-next-line vue/no-unused-components
    Layout,PageHeader
  },
  created(){
    this.getLogsList();
    // eslint-disable-next-line no-undef
    if(!User.loggedIn()){
      this.$router.push({name: '/admin/login'})
    }
    // this.$http.get('test/email').then(response=>{
    //   console.log(response)
    // }).catch();
  },
  data(){
    return{
      moment:moment,
      arr:[] ,
      editModalDisplay:false,
      tablefields: [
        { key: 'category_name', label: 'Category Name', sortable: true, },
        { key: 'actions', label: 'Actions', sortable: true, },
      ],
      items: [

      ],
      logList:[],
      editForm:{
        category_name:null
      },
      displayModal:false,
      form:{
        category_name:null
      },
      id:null,
      title: "Form Elements",
      item: [
        {
          text: "Veltrix",
          href: "/"
        },
        {
          text: "Forms",
          href: "/"
        },
        {
          text: "Form Elements",
          active: true
        },


      ],
      categories:[],
      buttons: [
        {
          text: "New Category",
          href: "/",
          active: true
        },
      ],
      searchTerm:'',
      filter: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15],
      typesubmit:false,
    }
  },


  mounted(){
    this.totalRows = this.items.length;

  },
  computed:{
    sortOptions() {
      // Create an options list from our fields
      return this.tablefields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key };
          });
    },
    rows(){
      return this.items.length;
    },

  },
  methods:{
    getLogsList(){
      this.$http.get('logs-details').then(response=>{
        this.logList = response.data;
        this.cssManipule(this.logList)
      }).catch();
    },
    cssManipule(obj){
      const dataList  = [];
      const leftData = {
        0: 0,2:2,4:4,6:6,8:8,
      }
      const rightData = {1: 1,3:3,5:5,7:7,9:9 }

        obj.forEach((item,key)=>{
          dataList.push(key);
          if(key === leftData[key]){
        const data = { class:"container left-container",
          item:[],
          spanClass:"left-container-arrow",
        child:"container"}
        data.item.push(item);
        this.arr.push(data)
      }
      if(rightData[key] === key ){
        const data = {   class:"container right-container",
          item:[],
          spanClass:"right-container-arrow"}
        data.item.push(item)
        this.arr.push(data)
      }
        })



    },
  },


}
</script>

<style scoped>

.timeline{
  position: relative;
  max-width: 1200px;
  margin: 100px auto;


}

.container{
  padding: 10px 50px;
  position: relative;
  width: 50%;
  background: #333547;
  animation: movedown 1s linear forwards;
  opacity: 0;
}

@keyframes movedown {
  0%{
    opacity: 1;
    transform: translateY(-30px);
  }
  100%{
    opacity: 1;
    transform: translateY(0px);
  }

}

.text-box{
  padding: 20px 30px;
  background: #fff;
  position: relative;
  border-radius: 6px;
  font-size: 15px;
}
.left-container{
  right: 27%;
}

.right-container{
  left: 27%;
}
.timeline::after{
  content: '';
  position: absolute;
  width: 6px;
  height: 100%;
  background: #33e1e1;
  top: 0;
  left: 50%;
  margin-left: -3px;
  z-index: -1;
  animation: moveline 10.5s linear forwards;
}

@keyframes moveline {
  0%{
    height: 0;
  }

  100%{
    height: 100%;
  }
}

.text-box h2{
  font-weight: 600;
}
.text-box small{

}

.left-container-arrow{
  height: 0;
  width: 0;
  position: absolute;
  top: 28px;
  z-index: 1;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 15px solid #535Eb4;
  right: -15px;
}

.right-container-arrow{
  height: 0;
  width: 0;
  position: absolute;
  top: 28px;
  z-index: 1;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-right: 15px solid #535Eb4;
  left: -15px;
}

/*.container:nth-child(1){*/
/*  animation-delay: 0s;*/
/*}*/

/*.container:nth-child(2){*/
/*  animation-delay: 1s;*/
/*}*/
/*.container:nth-child(3){*/
/*  animation-delay: 2s;*/
/*}*/

/*.container:nth-child(4){*/
/*  animation-delay: 3s;*/
/*}*/


@media screen and (max-width: 600px){
    .timeline{
      margin: 50px auto;
    }

  .timeline::after{
    left: 31px;
  }

  .container{
    width: 100%;
    padding-left: 80px;
    padding-right: 25px;

  }

  .text-box{
    font-size: 13px;
  }
  .right-container{
    left:0;

  }
  .left-container-arrow,.right-container-arrow{
    border-right: 15px solid red;
    border-right: 0;
    left: 15px;
  }
}



</style>