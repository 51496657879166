<template>
  <Layout>
    <div>
      <div class="col-sm-6 col-md-3 mt-4">
<!--        <div class="text-center">-->
<!--          <b-modal id="edit-modal" v-model="displayModal" title="Edit Category" hide title-class="font-18">-->
<!--            <b-form-input v-model="editForm.category_name" placeholder="Edit Category"></b-form-input>-->
<!--            <template #modal-footer>-->
<!--              <button v-b-modal.modal-close_visit data-dismiss="modal" @click="closeModal" class="btn btn-danger btn-sm m-1">Close</button>-->
<!--              <button v-b-modal.modal-close_visit class="btn btn-success btn-sm m-1" @click="editCategory(id)" >Save</button>-->
<!--            </template>-->
<!--          </b-modal>-->
<!--        </div>-->

        <div class="text-center">
          <b-modal id="modal-standard"  v-model="editModalDisplay" title="Add New Folder" hide title-class="font-18">
            <b-form-input v-model="form.newFolderName" placeholder="Add New Folder"></b-form-input>
            <template  #modal-footer>
              <button  data-dismiss="modal" @click="closeModal" class="btn btn-danger btn-sm m-1">Close</button>
              <b-button  for="edit-modal" class="btn btn-success btn-sm m-1" @click="newDirectory" >Save</b-button>
            </template>
          </b-modal>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-3">
          <div class="card card-statistics h-100 mb-0 apexchart-tool-force-top">
            <div class="card-header d-flex justify-content-between">
              <div class="card-heading">

                <h4 class="card-title .customCardTitle">File List</h4>
              </div>
              <!--
                                  <a class="btn btn-sm btn-info"><font color="#ffffff">Add Customer</font></a>-->
            </div>
            <div class="card-body">
              <ul class="list-group">
                <li class="list-group-item d-flex align-items-center" v-for="folder in folders" :key="folder.id">
                  <div v-for="folderList in folder" :key="folderList.id">
                    <button style="color: black;" class="btn btn-sm" @click.prevent="mainFolderContent(folderList.name,folderList)">
                      <img v-if="folderList.type === null" :src="'/icon32/folder.png'" alt="FileType" class="img-responsive" />
                      <img v-else-if="folderList.type === 'png' || folderList.type === 'jpg' || folderList.type === 'jpeg' || folderList.type === 'gif' || folderList.type === 'svg'" :src="'/icon32/image.png'" alt="Product" class="img-responsive" />
                      <img v-else-if="folderList.type === 'css'" :src="'/icon32/css.png'" alt="FileType" class="img-responsive" />
                      <img v-else-if="folderList.type === 'js'" :src="'/icon32/js-file.png'" alt="FileType" class="img-responsive" />
                      <img v-else-if="folderList.type === 'txt'" :src="'/icon32/txt.png'" alt="FileType" class="img-responsive" />
                      <img v-else-if="folderList.type === 'json'" :src="'/icon32/json.png'" alt="FileType" class="img-responsive" />
                      <img v-else-if="folderList.type === 'xlsx'" :src="'/icon32/xls.png'" alt="FileType" class="img-responsive" />
                      <img v-else-if="folderList.type === 'zip'" :src="'/icon32/zip.png'" alt="FileType" class="img-responsive" />
                      <img v-else-if="folderList.type === 'php'" :src="'/icon32/php.png'" alt="FileType" class="img-responsive" />
                      <img v-else-if="folderList.type === 'ico'" :src="'/icon32/ico.png'" alt="FileType" class="img-responsive" />
                      <img v-else-if="folderList.type === 'docx'" :src="'/icon32/doc.png'" alt="FileType" class="img-responsive" />
                      <img v-else-if="folderList.type === 'pdf'" :src="'/icon32/pdf.png'" alt="FileType" class="img-responsive" />
                      <img v-else-if="folderList.type === 'mp4' || folderList.type === 'mov' || folderList.type === 'wmv' || folderList.type === 'avi' || folderList.type === 'mkv' || folderList.type === 'webm' || folderList.type === 'mpeg-2'" :src="'/icon32/video.png'" alt="FileType" class="img-responsive" />
                      <img v-else-if="folderList.type === 'mp3' || folderList.type === 'm4a'" :src="'/icon32/mp3.png'" alt="FileType" class="img-responsive" />
                      <img v-else-if="folderList.type === 'sql'" :src="'/icon128/sql.png'" alt="FileType" class="img-responsive" />
                      <img v-else-if="folderList.type === 'html'" :src="'/icon128/html.png'" alt="FileType" class="img-responsive" />
                      <img v-else-if="folderList.type === 'ttf'" :src="'/icon128/ttf.png'" alt="FileType" class="img-responsive" />
                      <img v-else-if="folderList.type === 'pptx'" :src="'/icon128/ppt.png'" alt="FileType" class="img-responsive" />
                      <img v-else-if="folderList.type === 'log'" :src="'/icon128/log-file.png'" alt="FileType" class="img-responsive" />
                      <img v-else="" :src="'/icon32/private.png'" alt="FileType" class="img-responsive" />
                      {{ folderList.name.substring(0,40) }}
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="col-xl-9">
          <div class="card">
            <div class="card-header d-flex justify-content-between">
              <div class="card-heading">

                <h4 class="card-title .customCardTitle">File Content</h4>
              </div>
            </div>
            <div class="card-body">
              <div class="input-group">
                <input type="text" v-model="searchTerm" class="form-control" placeholder="Search"><img :src="'/icon32/search.png'" alt="">
              </div>
              <br>
              <div>
                <i class="fa fa-arrow-right"> {{ getMainFolderName }}</i>
              </div>
              <hr>
              <div style="display: flex; justify-content: space-between;" class="mt-3">
                <button v-show="backShow" @click.prevent="backFolders()" class="btn btn-sm customBackgroundSettings"><img class="wcf-left" :src="'/icon32/undo.png'" alt=""></button>
                <div v-show="menuShow" class="btn-group" role="group" aria-label="Basic example">
                  <button @click.prevent="refresh()" class="btn btn-sm customBackgroundSettings"><img class="wcf-right" :src="'/icon32/refresh.png'" alt=""></button>
                  <button type="button" class="btn btn-sm customBackgroundSettings" v-b-modal.modal-standard><img class="wcf-right" :src="'/icon32/plus.png'" alt=""></button>
                  <button type="button" class="btn btn-sm customBackgroundSettings"><img class="wcf-right" :src="'/icon32/upload.png'" alt=""></button>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-md-12">
                  <MonacoEditor
                      width="1165"
                      height="600"
                      theme="vs-dark"
                      language="css"
                      :options="options"
                      @change="onChange"
                      :diffEditor="true"
                      :original="this.openFileForEditor"
                  ></MonacoEditor>
                </div>
                <div v-show="mainShow" class="col-lg-6 col-xl-3" v-for="folderContent in folderContents" :key="folderContent.id">
                  <!-- Simple card -->
                  <b-card v-for="content in folderContent" :key="content.id" class="text-center">
                    <b-dropdown size="sm" class="m-2" style="float: left">
                      <template v-slot:button-content>
                        <i class="mdi mdi-dots-grid"></i>
                      </template>
                      <b-dropdown-item-button v-if="content.type === null || content.type === 'sql'" type="button" class="btn btn-sm customBackgroundSettings" @click.prevent="createZip(content.name)">Create Zip</b-dropdown-item-button>
                      <b-dropdown-item-button v-if="content.type === null" type="button" class="btn btn-sm customBackgroundSettings" @click.prevent="deleteDirectory(content.name)">Delete This Folder</b-dropdown-item-button>
                    </b-dropdown>
                    <br>
                    <button class="btn btn-sm" @click.prevent="subFolderContent(content.name,content)">
                      <div class="flex-shrink-0 align-self-center text-center">
                        <img style="padding: 20px;" v-if="content.type === null" :src="'/icon128/folder.png'" alt="FileType" class="img-responsive" />
                        <img style="padding: 20px;" v-else-if="content.type === 'png' || content.type === 'jpg' || content.type === 'jpeg' || content.type === 'gif' || content.type === 'svg'" :src="'/icon128/image.png'" alt="Product" class="img-responsive" />
                        <img style="padding: 20px;" v-else-if="content.type === 'css'" :src="'/icon128/css.png'" alt="FileType" class="img-responsive" />
                        <img style="padding: 20px;" v-else-if="content.type === 'js'" :src="'/icon128/js-file.png'" alt="FileType" class="img-responsive" />
                        <img style="padding: 20px;" v-else-if="content.type === 'txt'" :src="'/icon128/txt.png'" alt="FileType" class="img-responsive" />
                        <img style="padding: 20px;" v-else-if="content.type === 'json'" :src="'/icon128/json.png'" alt="FileType" class="img-responsive" />
                        <img style="padding: 20px;" v-else-if="content.type === 'xlsx'" :src="'/icon128/xls.png'" alt="FileType" class="img-responsive" />
                        <img style="padding: 20px;" v-else-if="content.type === 'zip'" :src="'/icon128/zip.png'" alt="FileType" class="img-responsive" />
                        <img style="padding: 20px;" v-else-if="content.type === 'php'" :src="'/icon128/php.png'" alt="FileType" class="img-responsive" />
                        <img style="padding: 20px;" v-else-if="content.type === 'ico'" :src="'/icon128/ico.png'" alt="FileType" class="img-responsive" />
                        <img style="padding: 20px;" v-else-if="content.type === 'docx'" :src="'/icon128/doc.png'" alt="FileType" class="img-responsive" />
                        <img style="padding: 20px;" v-else-if="content.type === 'pdf'" :src="'/icon128/pdf.png'" alt="FileType" class="img-responsive" />
                        <img style="padding: 20px;" v-else-if="content.type === 'mp4' || content.type === 'mov' || content.type === 'wmv' || content.type === 'avi' || content.type === 'mkv' || content.type === 'webm' || content.type === 'mpeg-2'" :src="'/icon128/video.png'" alt="FileType" class="img-responsive" />
                        <img style="padding: 20px;" v-else-if="content.type === 'mp3' || content.type === 'm4a'" :src="'/icon128/mp3.png'" alt="FileType" class="img-responsive" />
                        <img style="padding: 20px;" v-else-if="content.type === 'sql'" :src="'/icon128/sql.png'" alt="FileType" class="img-responsive" />
                        <img style="padding: 20px;" v-else-if="content.type === 'html'" :src="'/icon128/html.png'" alt="FileType" class="img-responsive" />
                        <img style="padding: 20px;" v-else-if="content.type === 'ttf'" :src="'/icon128/ttf.png'" alt="FileType" class="img-responsive" />
                        <img style="padding: 20px;" v-else-if="content.type === 'pptx'" :src="'/icon128/ppt.png'" alt="FileType" class="img-responsive" />
                        <img style="padding: 20px;" v-else-if="content.type === 'log'" :src="'/icon128/log-file.png'" alt="FileType" class="img-responsive" />
                        <img style="padding: 20px;" v-else="" :src="'/icon128/private.png'" alt="FileType" class="img-responsive" />
                      </div>
                    </button>
                    <b-card-title>
                      <p class="text-center" style="font-size: 16px; font-family: Arial; font-weight: bold">{{ content.name.substring(0,20) }}</p>
                    </b-card-title>
<!--                    <b-card-text>-->
<!--                      Some quick example text to build on the card title and make-->
<!--                      up the bulk of the card's content.-->
<!--                    </b-card-text>-->
<!--                    <b-button href="javascript:void(0);" variant="primary">Button</b-button>-->
<!--                    <div class="btn-group" role="group" aria-label="Basic example">-->
<!--                      <button v-if="content.type === null || content.type === 'sql'" type="button" class="btn btn-sm customBackgroundSettings" @click.prevent="createZip(content.name)"><img class="wcf-right" :src="'/icon32/zip.png'" alt=""></button>-->
<!--                      <button v-if="content.type === null" type="button" class="btn btn-sm customBackgroundSettings" @click.prevent="deleteDirectory(content.name)"><img class="wcf-right" :src="'/icon32/txt.png'" alt=""></button>-->
<!--                    </div>-->
                  </b-card>
                </div>
                <div v-show="subShow" class="col-lg-6 col-xl-3" v-for="folderContent in subFolderContents" :key="folderContent.id">
                  <!-- Simple card -->
                  <b-card v-for="content in folderContent" :key="content.id" class="text-center">
                    <b-dropdown size="sm" class="m-2" style="float: left">
                      <template v-slot:button-content>
                        <i class="mdi mdi-dots-grid"></i>
                      </template>
                      <b-dropdown-item-button v-if="content.type === 'xlsx' || content.type === 'pdf'" @click.prevent="download(content.name)" class="btn btn-sm customBackgroundSettings">Download</b-dropdown-item-button>
                      <b-dropdown-item-button v-if="content.type === null || content.type === 'sql'" type="button" class="btn btn-sm customBackgroundSettings" @click.prevent="createZip(content.name)">Create Zip</b-dropdown-item-button>
                      <b-dropdown-item-button v-if="content.type === 'html' || content.type === 'css' || content.type === 'js' || content.type === 'php'" type="button" class="btn btn-sm customBackgroundSettings" @click.prevent="openFileInEditor(content.name)">Open File In Editor</b-dropdown-item-button>
                      <b-dropdown-item-button v-if="content.type === null" type="button" class="btn btn-sm customBackgroundSettings" @click.prevent="deleteDirectory(content.name)">Delete This Folder</b-dropdown-item-button>
                    </b-dropdown>
                    <br>
                    <button class="btn btn-sm" @click.prevent="subFolderContent(content.name,content)">
                      <div class="flex-shrink-0 align-self-center text-center">
                        <img v-if="content.type === null" :src="'/icon128/folder.png'" alt="FileType" class="img-responsive" />
                        <img v-else-if="content.type === 'png' || content.type === 'jpg' || content.type === 'jpeg' || content.type === 'gif' || content.type === 'svg'" :src="'/icon128/image.png'" alt="Product" class="img-responsive" />
                        <img v-else-if="content.type === 'css'" :src="'/icon128/css.png'" alt="FileType" class="img-responsive" />
                        <img v-else-if="content.type === 'js'" :src="'/icon128/js-file.png'" alt="FileType" class="img-responsive" />
                        <img v-else-if="content.type === 'txt'" :src="'/icon128/txt.png'" alt="FileType" class="img-responsive" />
                        <img v-else-if="content.type === 'json'" :src="'/icon128/json.png'" alt="FileType" class="img-responsive" />
                        <img v-else-if="content.type === 'xlsx'" :src="'/icon128/xls.png'" alt="FileType" class="img-responsive" />
                        <img v-else-if="content.type === 'zip'" :src="'/icon128/zip.png'" alt="FileType" class="img-responsive" />
                        <img v-else-if="content.type === 'php'" :src="'/icon128/php.png'" alt="FileType" class="img-responsive" />
                        <img v-else-if="content.type === 'ico'" :src="'/icon128/ico.png'" alt="FileType" class="img-responsive" />
                        <img v-else-if="content.type === 'docx'" :src="'/icon128/doc.png'" alt="FileType" class="img-responsive" />
                        <img v-else-if="content.type === 'pdf'" :src="'/icon128/pdf.png'" alt="FileType" class="img-responsive" />
                        <img v-else-if="content.type === 'mp4' || content.type === 'mov' || content.type === 'wmv' || content.type === 'avi' || content.type === 'mkv' || content.type === 'webm' || content.type === 'mpeg-2'" :src="'/icon128/video.png'" alt="FileType" class="img-responsive" />
                        <img v-else-if="content.type === 'mp3' || content.type === 'm4a'" :src="'/icon128/mp3.png'" alt="FileType" class="img-responsive" />
                        <img v-else-if="content.type === 'sql'" :src="'/icon128/sql.png'" alt="FileType" class="img-responsive" />
                        <img v-else-if="content.type === 'html'" :src="'/icon128/html.png'" alt="FileType" class="img-responsive" />
                        <img v-else-if="content.type === 'ttf'" :src="'/icon128/ttf.png'" alt="FileType" class="img-responsive" />
                        <img v-else-if="content.type === 'pptx'" :src="'/icon128/ppt.png'" alt="FileType" class="img-responsive" />
                        <img v-else-if="content.type === 'log'" :src="'/icon128/log-file.png'" alt="FileType" class="img-responsive" />
                        <img v-else="" :src="'/icon128/private.png'" alt="FileType" class="img-responsive" />
                      </div>
                    </button>
                    <b-card-title>
                      <p class="text-center" style="font-size: 16px; font-family: Arial; font-weight: bold">{{ content.name.substring(0,20) }}</p>
                    </b-card-title>
                    <!--                    <b-card-text>-->
                    <!--                      Some quick example text to build on the card title and make-->
                    <!--                      up the bulk of the card's content.-->
                    <!--                    </b-card-text>-->
                    <!--                    <b-button href="javascript:void(0);" variant="primary">Button</b-button>-->
                    <!--                    <div class="btn-group" role="group" aria-label="Basic example">-->
                    <!--                      <button v-if="content.type === null || content.type === 'sql'" type="button" class="btn btn-sm customBackgroundSettings" @click.prevent="createZip(content.name)"><img class="wcf-right" :src="'/icon32/zip.png'" alt=""></button>-->
                    <!--                      <button v-if="content.type === null" type="button" class="btn btn-sm customBackgroundSettings" @click.prevent="deleteDirectory(content.name)"><img class="wcf-right" :src="'/icon32/txt.png'" alt=""></button>-->
                    <!--                    </div>-->
                  </b-card>
                </div>
              </div>
            </div>
            <!-- end card-body -->
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>

import Layout from "../../router/layouts/main.vue";
import MonacoEditor from 'monaco-editor-vue';
export default {
  name:'admin-file-manager',
  components: { Layout,MonacoEditor },
  created(){
    // eslint-disable-next-line no-undef
    if(!User.loggedIn()){
      this.$router.push({name: 'admin-login'})
    }
    this.allMainFolder();

  },
  data(){
    return{
      options: {
        //Monaco Editor Options
        value: this.openFileForEditor,
        original: null,
        language: null,
      },
      editModalDisplay:false,
      form:{
        newFolderName: null,
      },
      editForm:{
        newFolderName:null
      },
      displayModal:false,
      folders:{},
      folderContents:{},
      subFolderContents:{},
      searchTerm: '',
      show: false,
      mainShow: false,
      subShow: false,
      backShow: false,
      menuShow: true,
      editorShow: false,
      mainFolderName: null,
      subFolderName: null,
      type:[
          'pdf','sql','excel'
      ],
      donwloadSuccess:{},
      newFolders:{},
      deleteFolders:{},
      zipFolders:{},
      openFileForEditor: null,
      code: 'const noop = () => {}'
    }
  },
  computed:{
    search(){
      return this.subFolderContents.filter(subFolder => {
        return subFolder.name.match(this.searchTerm)

      })
    },
    getMainFolderName(){
      return this.mainFolderName;
    },
    getFileContent(){
      return this.openFileForEditor;
    },
  },
  methods:{
    onChange(value) {
      console.log(value);
    },
    // Close Modal
    closeModal(){
      this.displayModal = false;
      this.editModalDisplay =false;
    },
    // Back
    backFolders(){
      if (this.mainFolderName.includes('>') === true){
        const result = this.mainFolderName.split('>');
        const split = ">"+result.slice(-1)[0];
        this.mainFolderName = this.mainFolderName.replace(split,"",this.mainFolderName);
        this.$http.get('sub/folder/content/'+this.mainFolderName)
            .then(({data}) => (this.subFolderContents = data))
            .catch()
        this.mainShow = false
        this.editorShow = false
        this.subShow = true
        this.backShow = true
      }
      // this.subShow = false
      // this.mainShow = true
      // this.backShow = false
      // this.editorShow = false
      // else{
      //   this.subShow = false
      //   this.mainShow = true
      //   this.backShow = false
      //   this.editorShow = false
      //   this.$http.get('main/folder/content/'+this.mainFolderName)
      //       .then(({data}) => (this.folderContents = data))
      //       .catch()
      // }
    },
    // Refresh
    refresh(){
      this.allMainFolder();
    },
    // Download
    download(folder){
      // eslint-disable-next-line no-undef
      Swal.fire({
        title: 'Do you want to download the file?',
        text: "Once approved, the download process will begin!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Download'
      }).then((result) => {
        if (result.value) {
          this.$http.get('download/file/'+this.mainFolderName+'/'+folder, { responseType: 'blob' })
              .then(response => {
                const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = folder
                link.click()
                URL.revokeObjectURL(link.href)
              }).catch(console.error)
          // eslint-disable-next-line no-undef
          Swal.fire(
              'Downloaded!',
              'Your file has been downloaded.',
              'success'
          )
        }
      })
      // this.$http.get('download/file/'+this.mainFolderName+'/'+folder, { responseType: 'blob' })
      //     .then(response => {
      //       const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      //       const link = document.createElement('a')
      //       link.href = URL.createObjectURL(blob)
      //       link.download = folder
      //       link.click()
      //       URL.revokeObjectURL(link.href)
      //     }).catch(console.error)
    },
    // New Directory
    newDirectory(){
      if (this.mainFolderName === null){
        this.mainFolderName = 'null'
        this.$http.get('new/folder/'+this.mainFolderName+'/'+this.form.newFolderName)
            .then(({data}) => (this.newFolders = data))
            .catch(error=>{
              console.log(error);
              this.$toasted.global.warning();
            })
        this.editModalDisplay = false;
        this.$toasted.global.success();
        this.form.newFolderName =null
        this.allMainFolder();
        this.mainShow = false
        this.editorShow = false
        this.subShow = true
        this.backShow = true
        this.mainFolderName = null
      }else{
        this.$http.get('new/folder/'+this.mainFolderName+'/'+this.form.newFolderName)
            .then((data) => {this.newFolders = data.data;
              if (this.newFolders === 'error'){
                this.$toasted.global.warning();
              }else{
                this.$toasted.global.success();
              }
            })
            .catch(error=>{
              console.log(error);
              this.$toasted.global.warning();
            })
        this.editModalDisplay = false;
        this.form.newFolderName =null
        this.$http.get('sub/folder/content/'+this.mainFolderName)
            .then(({data}) => (this.subFolderContents = data))
            .catch()
        this.mainShow = false
        this.editorShow = false
        this.subShow = true
        this.backShow = true
      }
    },
    // Delete Directory
    deleteDirectory(folder){
      if (this.mainFolderName === null){
        this.mainFolderName = 'null'
        this.$http.get('delete/folder/'+this.mainFolderName+'/'+folder)
            .then(({data}) => (this.deleteFolders = data))
            .catch(error=>{
              console.log(error);
              this.$toasted.global.warning();
            })
        this.editModalDisplay = false;
        this.$toasted.global.success();
        this.form.newFolderName =null
        this.allMainFolder();
        this.mainShow = false
        this.editorShow = false
        this.subShow = true
        this.backShow = true
        this.mainFolderName = null
      }else{
        this.$http.get('delete/folder/'+this.mainFolderName+'/'+folder)
            .then((data) => {this.deleteFolders = data.data;
              if (this.deleteFolders === 'error'){
                this.$toasted.global.warning();
              }else{
                this.$toasted.global.success();
              }
            })
            .catch(error=>{
              console.log(error);
              this.$toasted.global.warning();
            })
        this.editModalDisplay = false;
        this.form.newFolderName =null
        this.$http.get('sub/folder/content/'+this.mainFolderName)
            .then(({data}) => (this.subFolderContents = data))
            .catch()
        this.mainShow = false
        this.editorShow = false
        this.subShow = true
        this.backShow = true
      }
    },
    // Create Zip
    createZip(folder){

      if (this.mainFolderName === null){
        this.mainFolderName = 'null'
        this.$http.get('zip/file/'+this.mainFolderName+'/'+folder, { responseType: 'blob' })
            .then(response => {
              const blob = new Blob([response.data], { type: 'application/zip' })
              const link = document.createElement('a')
              link.href = URL.createObjectURL(blob)
              link.download = folder
              link.click()
              URL.revokeObjectURL(link.href)
              this.$toasted.global.success();
              this.allMainFolder();
              this.mainShow = false
              this.subShow = true
              this.backShow = true
              this.mainFolderName = null
            }).catch(error=>{
              console.log(error);
              this.$toasted.global.warning();
            })
        //
        // this.$http.get('zip/file/'+this.mainFolderName+'/'+file)
        //     .then(({data}) => (this.zipFolders = data))
        //     .catch(error=>{
        //       console.log(error);
        //       this.$toasted.global.warning();
        //     })
        // this.$toasted.global.success();
        // this.allMainFolder();
        // this.mainShow = false
        // this.subShow = true
        // this.backShow = true
        // this.mainFolderName = null
      }else{
        this.$http.get('zip/file/'+this.mainFolderName+'/'+folder, { responseType: 'blob' })
            .then(response => {
              console.log(response.data)
              const blob = new Blob([response.data], { type: 'application/zip' })
              const link = document.createElement('a')
              link.href = URL.createObjectURL(blob)
              link.download = folder
              link.click()
              URL.revokeObjectURL(link.href)
              if (this.zipFolders === 'error'){
                this.$toasted.global.warning();
              }else{
                this.$toasted.global.success();
              }
              this.$http.get('sub/folder/content/'+this.mainFolderName)
                  .then(({data}) => (this.subFolderContents = data))
                  .catch()
              this.mainShow = false
              this.subShow = true
              this.backShow = true
            }).catch(error=>{
          console.log(error);
          this.$toasted.global.warning();
        })
        // this.$http.get('sub/folder/content/'+this.mainFolderName)
        //     .then(({data}) => (this.subFolderContents = data))
        //     .catch()
        // this.mainShow = false
        // this.subShow = true
        // this.backShow = true




        // this.$http.get('zip/file/'+this.mainFolderName+'/'+file)
        //     .then((data) => {this.zipFolders = data.data;
        //       if (this.zipFolders === 'error'){
        //         this.$toasted.global.warning();
        //       }else{
        //         this.$toasted.global.success();
        //       }
        //     })
        //     .catch(error=>{
        //       console.log(error);
        //       this.$toasted.global.warning();
        //     })
        // this.$http.get('sub/folder/content/'+this.mainFolderName)
        //     .then(({data}) => (this.subFolderContents = data))
        //     .catch()
        // this.mainShow = false
        // this.subShow = true
        // this.backShow = true
      }
    },
    // Open File
    openFileInEditor(file){
      this.mainFolderName = this.mainFolderName + ">" + file
      this.$http.get('open/file/'+this.mainFolderName+'/'+file)
          .then(response => {
            this.openFileForEditor = response.data;
            this.editorShow = true
            this.options.original = this.openFileForEditor
            this.mainShow = false
            this.subShow = false
            this.backShow = true
            this.menuShow = false
            file.split(".")
            if (file[1] === "css"){
              this.options.language = "css"
            } else if(file[1] === "js"){
              this.options.language = "javascript"
            } else if(file[1] === "html"){
              this.options.language = "html"
            } else{
              this.options.language = "php"
            }
          })
    .catch()
    },

    // Main Folder List
    allMainFolder(){
      this.mainFolderName = null
      this.subFolderName = null
      this.mainShow = false
      this.subShow = false
      this.editorShow = false
      this.$http.get('main/folder')
          .then(({data}) => (this.folders = data))
          .catch()
    },
    // Base Folder List
    mainFolderContent(folder,item){
      // this.mainFolderName = null
      if (item.type === null){
        this.mainFolderName = folder
        this.$http.get('main/folder/content/'+this.mainFolderName)
            .then(({data}) => (this.folderContents = data))
            .catch()
        this.subShow = false
        this.mainShow = true
        this.backShow = true
        this.editorShow = false
      }else{
        if (item.type === 'html' || item.type === 'css' || item.type === 'php' || item.type === 'js'){
          // eslint-disable-next-line no-undef
          Swal.fire({
            title: 'Do you want to edit the file?',
            text: "Don't forget to save after editing!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Edit'
          }).then((result) => {
            if (result.value) {
              this.subShow = false
              this.mainShow = false
              this.backShow = true
              this.editorShow = true
              this.menuShow = false
              this.openFileInEditor(folder);
              // eslint-disable-next-line no-undef
              // Swal.fire(
              //     'Deleted!',
              //     'Your file has been deleted.',
              //     'success'
              // )
            }
          })
        }
        if (item.type === 'xlsx'){
          this.download(folder);
        }
      }

    },
    // Sub Folder List
    subFolderContent(folder,item){
      if (item.type === null){
        this.subFolderName = folder
        if (this.mainFolderName === null){
          this.mainFolderName = this.subFolderName
        }else{
          this.mainFolderName = this.mainFolderName + ">" + this.subFolderName
        }
        this.$http.get('sub/folder/content/'+this.mainFolderName)
            .then(({data}) => (this.subFolderContents = data))
            .catch()
        this.mainShow = false
        this.editorShow = false
        this.subShow = true
        this.backShow = true
      }else{
        if (item.type === 'html' || item.type === 'css' || item.type === 'php' || item.type === 'js'){
          // eslint-disable-next-line no-undef
          Swal.fire({
            title: 'Do you want to edit the file?',
            text: "Don't forget to save after editing!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Edit'
          }).then((result) => {
            if (result.value) {
              this.subShow = false
              this.mainShow = false
              this.backShow = true
              this.editorShow = true
              this.menuShow = false
              this.openFileInEditor(folder);
              // eslint-disable-next-line no-undef
              // Swal.fire(
              //     'Deleted!',
              //     'Your file has been deleted.',
              //     'success'
              // )
            }
          })
        }
        if (item.type === 'xlsx'){
          this.download(folder);
        }
      }
      // this.subFolderName = folder
      // if (this.mainFolderName === null){
      //   this.mainFolderName = this.subFolderName
      // }else{
      //   this.mainFolderName = this.mainFolderName + ">" + this.subFolderName
      // }
      // this.$http.get('sub/folder/content/'+this.mainFolderName)
      //     .then(({data}) => (this.subFolderContents = data))
      //     .catch()
      // this.mainShow = false
      // this.subShow = true
      // this.backShow = true
    },
  },
}






</script>

<style scoped>
.v-leave { opacity: 1; }
.v-leave-active { transition: opacity 5s }
.v-leave-to { opacity: 0; }
.v-enter { opacity: 0; }
.v-enter-active  { transition: opacity 5s }
.v-enter-to { opacity: 1; }
.customBackgroundSettings{
  background: transparent;
  border: none;
}
.customCard{
  width: 200px;
  height: 320px;
  margin-bottom:5px;
}
.shell{
  padding:80px 0;
}
.wsk-cp-product{
  background:#fff;
  padding:15px;
  border-radius:6px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  position:relative;
  margin:20px auto;
}
.wsk-cp-img{
  position:absolute;
  top:5px;
  left:50%;
  transform:translate(-50%);
  -webkit-transform:translate(-50%);
  -ms-transform:translate(-50%);
  -moz-transform:translate(-50%);
  -o-transform:translate(-50%);
  -khtml-transform:translate(-50%);
  width: 100%;
  padding: 15px;
  transition: all 0.2s ease-in-out;
}
.wsk-cp-img img{
  width:100%;
  transition: all 0.2s ease-in-out;
  border-radius:6px;
}
.wsk-cp-product:hover .wsk-cp-img{
  top:-40px;
}
.wsk-cp-product:hover .wsk-cp-img img{
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}
.wsk-cp-text{
  padding-top:150%;
}
.wsk-cp-text .category{
  text-align:center;
  font-size:12px;
  font-weight:bold;
  padding:5px;
  margin-bottom: -100px;
  position:relative;
  transition: all 0.2s ease-in-out;
}
.wsk-cp-text .category > *{
  position:absolute;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  -webkit-transform: translate(-50%,-50%);
  -moz-transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  -o-transform: translate(-50%,-50%);
  -khtml-transform: translate(-50%,-50%);

}
.wsk-cp-text .category > span{
  padding: 12px 30px;
  border: 1px solid #313131;
  background:#212121;
  color:#fff;
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
  border-radius:27px;
  transition: all 0.05s ease-in-out;

}
.wsk-cp-product:hover .wsk-cp-text .category > span{
  border-color:#ddd;
  box-shadow: none;
  padding: 11px 28px;
}
.wsk-cp-product:hover .wsk-cp-text .category{
  margin-top: 0px;
}
.wsk-cp-text .title-product{
  text-align:center;
}
.wsk-cp-text .title-product h3{
  font-size:20px;
  font-weight:bold;
  margin:15px auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width:100%;
}
.wsk-cp-text .description-prod p{
  margin:0;
}
/* Truncate */
.wsk-cp-text .description-prod {
  text-align:center;
  width: 100%;
  height:62px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom:15px;
}
.card-footer{
  padding: 25px 0 5px;
  border-top: 1px solid #ddd;
}
.card-footer:after, .card-footer:before{
  content:'';
  display:table;
}
.card-footer:after{
  clear:both;
}

.card-footer .wcf-left{
  float:left;

}

.card-footer .wcf-right{
  float:right;
}

.price{
  font-size:18px;
  font-weight:bold;
}

a.buy-btn{
  display:block;
  color:#212121;
  text-align:center;
  font-size: 18px;
  width:35px;
  height:35px;
  line-height:35px;
  border-radius:50%;
  border:1px solid #212121;
  transition: all 0.2s ease-in-out;
}
a.buy-btn:hover , a.buy-btn:active, a.buy-btn:focus{
  border-color: #FF9800;
  background: #FF9800;
  color: #fff;
  text-decoration:none;
}
.wsk-btn{
  display:inline-block;
  color:#212121;
  text-align:center;
  font-size: 18px;
  transition: all 0.2s ease-in-out;
  border-color: #FF9800;
  background: #FF9800;
  padding:12px 30px;
  border-radius:27px;
  margin: 0 5px;
}
.wsk-btn:hover, .wsk-btn:focus, .wsk-btn:active{
  text-decoration:none;
  color:#fff;
}
.red{
  color:#F44336;
  font-size:22px;
  display:inline-block;
  margin: 0 5px;
}
@media screen and (max-width: 991px) {
  .wsk-cp-product {
    margin: 40px auto;
  }

  .wsk-cp-product .wsk-cp-img {
    top: -40px;
  }

  .wsk-cp-product .wsk-cp-img img {
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);
  }

  .wsk-cp-product .wsk-cp-text .category > span {
    border-color: #ddd;
    box-shadow: none;
    padding: 11px 28px;
  }

  .wsk-cp-product .wsk-cp-text .category {
    margin-top: 0px;
  }

  a.buy-btn {
    border-color: #FF9800;
    background: #FF9800;
    color: #fff;
  }
}
</style>