<template>
  <Layout>
    <div>
      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-body">
              <b-tabs justified pills class="navtab-bg" content-class="p-3">
                <b-tab active>
                  <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                  </span>
                    <span class="d-none d-sm-inline-block">Add Group</span>
                  </template>
                  <div>
                    <div class="card-body">
                      <div class="row">
                        <form @submit.prevent="storeRouteGroup">
                          <div class="form-group">
                            <label for="routeGroup">Route Group</label>
                            <input type="text" id="routeGroup" v-model="form.route_group_name" class="form-control mb-4">
                          </div>
                          <br>
                          <div class="form-group">
                            <label for="selectRouteList">Select Route</label>
                            <multiselect id="selectRouteList" v-model="form.route_name" :options="routeLists" :multiple="true" style="height: 200px;"></multiselect>
                            <!--                <select id="inputFromWarehouse" class="form-control">-->
                            <!--                  <option value="">Select Warehouse</option>-->
                            <!--                  <option :key="routeList.id" :value="routeList" v-for="routeList in routeLists">{{ routeList }}</option>-->
                            <!--                </select>-->
                          </div>
                          <br>
                          <button type="submit" class="btn btn-success col-xl-12">Save</button>
                        </form>
                      </div>
                    </div>
                  </div>
                </b-tab>

                <b-tab>
                  <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-cog"></i>
                  </span>
                    <span class="d-none d-sm-inline-block">Group</span>
                  </template>
                  <div>
                    <div class="card-body">
                      <div class="row">
                        <div class="form-group">
                          <b-card no-body class="mb-1 faq" v-for="routeGroup in routeGroups" :key="routeGroup.id">
                            <b-card-header role="tab">
                              <h6 class="m-0 font-size-16">
                                <a
                                    v-b-toggle="'accordion-'+routeGroup.id"
                                    class="text-dark"
                                    href="javascript: void(0);"
                                >{{ routeGroup.route_group_name }}
                                </a>
                                <a v-role="['ROLE_ROOT']" @click="deleteGroup(routeGroup.id)" style="color: white; float: right" class="btn btn-danger"><i class="fa fa-trash"></i></a>
                              </h6>
                            </b-card-header>
                            <b-collapse :id="'accordion-'+routeGroup.id" visible accordion="my-accordion">
                              <b-card-body>
                                <div class="list-group" :key="group.id" v-for="group in routeGroup.route_list">
                                  <label class="list-group-item">
                                    {{ group }}
                                  </label>
                                </div>
                              </b-card-body>
                            </b-collapse>
                          </b-card>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-tab>
              </b-tabs>




            </div>
            <!-- end card-body -->
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>

import Layout from "../../router/layouts/main.vue";
import PageHeader from "@/components/page-header";
import Multiselect from "vue-multiselect";

export default {
  components:{
    // eslint-disable-next-line vue/no-unused-components
    Layout,PageHeader,Multiselect
  },
  created(){
    // eslint-disable-next-line no-undef
    if(!User.loggedIn()){
      this.$router.push({name: '/admin/login'})
    }
    this.allRouteList();
    this.allRouteGroupList();
    // eslint-disable-next-line no-undef
    Reload.$on('AfterAdd',() => {
    })
  },
  data(){
    return{
      routeLists:{},
      routeGroups:{},
      form: {
        route_group_name: '',
        route_name: []
      },
    }
  },
  methods:{
    allRouteGroupList(){
      this.$http.get('route/group')
          .then(({data}) => (this.routeGroups = data))
          .catch()
    },
    allRouteList(){
      this.$http.get('route/list')
          .then(({data}) => (this.routeLists = data))
          .catch()
    },
    storeRouteGroup(){
      this.$http.post('store/route/group',this.form)
          .then(() => {
            this.$router.push({ name: 'admin-route-group'})
            this.form.route_name = null
            this.form.route_group_name = ''
            this.$toasted.global.success()
            this.allRouteGroupList();
          })
          .catch(error => this.errors = error.response.data.errors)

    },
    deleteGroup(id){
      // eslint-disable-next-line no-undef
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          this.$http.delete('route/group/'+id)
              .then(() => {
                this.$toasted.global.success()
                this.allRouteGroupList();
                // this.listPermissionShow = false
                // this.selectRole = null
              })
              .catch(() => {
              })
          // eslint-disable-next-line no-undef
          Swal.fire(
              'Deleted!',
              'Your data has been deleted.',
              'success'
          )

        }
      })
    }
  },


}
</script>

<style scoped>

</style>
