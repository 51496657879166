<template>
  <Layout>
    <PageHeader :items="item" :title="title"></PageHeader>
    <div>
      <div class="col-sm-6 col-md-3 mt-4">
        <div class="text-center">
          <b-modal id="edit-modal" v-model="displayModal" title="Edit Revenue" hide title-class="font-18">
            <div class="form-group">
              <label for="revenueTitle">Title</label>
              <b-form-input v-model="editForm.revenue_title" id="revenueTitle"></b-form-input>
            </div>
            <br>
            <div class="form-group">
              <label for="revenueTax">Tax</label>
              <b-form-input v-model="editForm.revenue_tax" id="revenueTax"></b-form-input>
            </div>
            <br>
            <div class="form-group">
              <label for="revenueDate">Date</label>
              <div class="form-group mb-0">
                <input type="datetime-local" class="form-control" id="revenueDate" v-model="editForm.revenue_date">
              </div>
            </div>
            <br>
            <div class="form-group">
              <label for="revenueDescription">Description</label>
              <ckeditor :editor="editor" v-model="editForm.revenue_description" class="form-control" id="revenueDescription"></ckeditor>
            </div>
            <br>
            <div class="form-group">
              <label for="revenueStatus">Type</label>
              <select id="revenueStatus" class="form-control" v-model="editForm.accounting_status_id">
                <option :key="status.id" :value="status.id" v-for="status in accountingStatus">{{ status.accounting_status }}</option>
              </select>
            </div>
            <br>
            <template #modal-footer>
              <button v-b-modal.modal-close_visit data-dismiss="modal" @click="closeModal" class="btn btn-danger btn-sm m-1">Close</button>
              <button v-b-modal.modal-close_visit class="btn btn-success btn-sm m-1" @click="editRevenue(id)" >Save</button>
            </template>
          </b-modal>
        </div>

        <div class="text-center">
          <b-modal id="modal-standard"  v-model="editModalDisplay" title="Add Revenue" hide title-class="font-18">
            <div class="form-group">
              <label for="revenueTitle">Title</label>
              <b-form-input v-model="form.revenue_title" id="revenueTitle"></b-form-input>
            </div>
            <br>
            <div class="form-group">
              <label for="revenueTax">Tax</label>
              <b-form-input v-model="form.revenue_tax" id="revenueTax"></b-form-input>
            </div>
            <br>
            <div class="form-group">
              <label for="revenueDate">Date</label>
              <div class="form-group mb-0">
                <input type="datetime-local" class="form-control" id="revenueDate" v-model="form.revenue_date">
              </div>
            </div>
            <br>
            <div class="form-group">
              <label for="revenueDescription">Description</label>
              <ckeditor :editor="editor" v-model="form.revenue_description" class="form-control" id="revenueDescription"></ckeditor>
            </div>
            <br>
            <div class="form-group">
              <label for="revenueStatus">Type</label>
              <select id="revenueStatus" class="form-control" v-model="form.accounting_status_id">
                <option :key="status.id" :value="status.id" v-for="status in accountingStatus">{{ status.accounting_status }}</option>
              </select>
            </div>
            <br>
            <template  #modal-footer>
              <button  data-dismiss="modal" @click="closeModal" class="btn btn-danger btn-sm m-1">Close</button>
              <b-button  for="edit-modal" class="btn btn-success btn-sm m-1" @click="addRevenue" >Save</b-button>
            </template>
          </b-modal>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row">


          <div class="col-md-12">

            <div class="card">

              <div class="card-body">
                <div style="margin-left: 575px" class="col-sm-4">
                  <div class="float-end d-none d-md-block">
                    <b-button style="float: right"  v-b-modal.modal-standard variant="primary">New Revenue</b-button>
                  </div>
                </div>
                <b-row>

                  <b-col xl="6">

                    <b-input-group class="datatable-btn">
                      <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                      <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                      </b-input-group-append>

                    </b-input-group>

                  </b-col>

                  <b-col xl="6">
                    <b-form-group  label-cols="2" label="Per page" class="mb-4 datatable-select">
                      <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                    </b-form-group>

                  </b-col>
                </b-row>

                <div class="table-responsive datatable-vue">
                  <b-table
                      show-empty
                      stacked="md"
                      :items="items"
                      :fields="tablefields"
                      :filter="filter"
                      :current-page="currentPage"
                      :per-page="perPage"
                      @filtered="onFiltered"

                  >
                    <template v-slot:cell(description)="{item}">
                      {{ item.revenue_description.substring(0,30) }}
                    </template>
                    <template v-slot:cell(status)="{item}">
                      {{ item.accounting_status.accounting_status }}
                    </template>
                    <template v-slot:cell(actions)="{item}">
                      <b-button  v-b-modal.edit-modal @click="editRevenueModal(item)" class="btn btn-sm btn-dark">Edit</b-button>
                      <router-link :to="{ name: 'admin-revenue-detail',params:{id:item.id}}" class="btn btn-sm btn-primary">Detail</router-link>
                      <a  @click="deleteRevenue(item.id)" style="color: white;" class="btn btn-sm btn-danger">Delete</a>
                    </template>

                  </b-table>
                </div>

                <b-col md="6" class="my-1 p-0">
                  <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                      class="my-0"
                  ></b-pagination>
                </b-col>


              </div>

            </div>
          </div>

        </div>
      </div>


      <!-- begin row -->
      <!-- end row -->
      <!-- begin row -->

      <!-- end row -->
    </div>
  </Layout>



</template>


<script>

import Layout from "../../../router/layouts/main.vue";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import PageHeader from "@/components/page-header";
// import {Collapse as Noty} from "bootstrap";

export default {
  components:{
    // eslint-disable-next-line vue/no-unused-components
    ckeditor: CKEditor.component,
    Layout,
    PageHeader
  },
  created(){
    this.getRevenueList();
    this.allAccountingStatus();
    // eslint-disable-next-line no-undef
    if(!User.loggedIn()){
      this.$router.push({name: '/admin/login'})
    }
  },
  data(){
    return{
      editModalDisplay:false,
      tablefields: [
        { key: 'revenue_title', label: 'Title', sortable: true, },
        { key: 'revenue_tax', label: 'Tax', sortable: true, },
        { key: 'revenue_date', label: 'Date', sortable: true, },
        { key: 'description', label: 'Description', sortable: true, },
        { key: 'status', label: 'Type', sortable: true, },
        { key: 'actions', label: 'Actions', sortable: true, },
      ],
      items: [

      ],
      editForm:{
        revenue_title:null,
        revenue_tax:null,
        revenue_date:null,
        revenue_description:null,
        accounting_status_id:null,
      },
      displayModal:false,
      form:{
        revenue_title:null,
        revenue_tax:null,
        revenue_date:null,
        revenue_description:null,
        accounting_status_id:null,
      },
      id:null,
      title: "Form Elements",
      item: [
        {
          text: "Veltrix",
          href: "/"
        },
        {
          text: "Forms",
          href: "/"
        },
        {
          text: "Form Elements",
          active: true
        },


      ],
      revenues:[],
      accountingStatus:{},
      buttons: [
        {
          text: "New Revenue",
          href: "/",
          active: true
        },



      ],
      searchTerm:'',
      filter: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15],
      editor: ClassicEditor,
      errors:{},
    }
  },
  mounted(){
    this.totalRows = this.items.length;
  },
  computed:{
    sortOptions() {
      // Create an options list from our fields
      return this.tablefields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key };
          });
    },
    rows(){
      return this.items.length;
    },

  },
  methods:{
    allAccountingStatus(){
      this.$http.get('accounting-status')
          .then(({data}) => (this.accountingStatus = data))
          .catch()
    },
    editRevenueModal(item){
      this.id = item.id;
      this.editForm.revenue_title = item.revenue_title;
      this.editForm.revenue_tax = item.revenue_tax;
      this.editForm.revenue_date = item.revenue_date;
      this.editForm.revenue_description = item.revenue_description;
      this.editForm.accounting_status_id = item.accounting_status_id;

    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    closeModal(){
      this.displayModal = false;
      this.editModalDisplay =false;
    },
    addRevenue(){
      this.$http.post('revenue',this.form)
          .then((data) => {this.revenues = data;
            this.editModalDisplay = false;
            this.getRevenueList();
            this.form.revenue_title =null;
            this.form.revenue_tax =null;
            this.form.revenue_date =null;
            this.form.revenue_description =null;
            this.form.accounting_status_id =null;
          })
          .catch(error => this.errors = error.response.data.errors)

    },
    getRevenueList(){
      this.$http.get('revenue')
          .then((res) => {
            this.items = res.data;
          })
          .catch()
    },
    editRevenue(id){
      // eslint-disable-next-line no-console
      this.$http.patch('revenue/'+id,this.editForm)
          .then((res) => {
            this.items = res.data;
            this.getRevenueList();
            this.displayModal = false;
          })
          .catch()

    },
    deleteRevenue(id){
      // eslint-disable-next-line no-undef
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          this.$http.delete('revenue/'+id)
              .then(() => {
                // this.getRevenueList();
                this.items = this.items.filter(item => {
                  return item.id !== id
                })
              })
              .catch(() => {
              })
          // eslint-disable-next-line no-undef
          Swal.fire(
              'Deleted!',
              'Your data has been deleted.',
              'success'
          )
        }
      })

    },
  },

}
</script>

<style scoped>

</style>