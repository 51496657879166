<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="col-xl-12">
      <div class="card">
        <div class="card-header d-flex justify-content-between">
          <div class="card-heading">

            <h4 class="card-title .customCardTitle">Expense Details</h4>
          </div>
          <!--                        <a class="btn btn-sm btn-info"><font color="#ffffff">Add Customer</font></a>-->
        </div>
        <div class="card-body">
          <b-tabs justified pills class="navtab-bg" content-class="p-3">
            <b-tab active>
              <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                  </span>
                <span class="d-none d-sm-inline-block">Add</span>
              </template>
              <div>
                <div class="card-body">
                  <div class="row">
                    <div class="form-row">
                      <div class="form-group col-md-3">
                        <div class="checkbox checbox-switch switch-success">
                          <button type="button" class="btn btn-block btn-round btn-warning" @click="addNewExpenseRow">Add More</button>
                        </div>
                      </div>
                    </div>
                    <form class="row g-3" @submit.prevent="insertExpenseDetail">
                      <table class="display compact table table-striped table-bordered" width="100%" style="border-color: #ddd;">
                        <thead>
                        <th>Quantity / Day</th>
                        <th>Amount</th>
                        <th>Total Amount</th>
                        <th>Tax</th>
                        <th>Total Tax</th>
                        <th>Total</th>
                        <th>Invoice No</th>
                        <th>Invoice Date</th>
                        <th>Description</th>
                        <th>Action</th>
                        </thead>
                        <tbody id="addRow" class="addRow">

                        </tbody>
                        <tbody>
                        <tr v-for="(expense_detail, k) in expense_details" :key="k">
                          <td>
                            <input v-on:keypress="NumbersOnly" class="form-control" v-on:input="changeQuantity(k)" style="width: 100%" type="text" v-model="expense_detail.quantity">
                          </td>
                          <td>
                            <input v-on:keypress="NumbersOnly" class="form-control" v-on:input="totalAmount(k)" style="width: 100%" type="text" v-model="expense_detail.amount">
                          </td>
                          <td>
                            <input v-on:keypress="NumbersOnly" :readonly="true" class="form-control" style="width: 100%" type="text" v-model="expense_detail.total_amount">
                          </td>
                          <td>
                            <input v-on:keypress="NumbersOnly" class="form-control" @change="totalTax(k)" style="width: 100%" type="text" v-model="expense_detail.tax">
                          </td>
                          <td>
                            <input v-on:keypress="NumbersOnly" :readonly="true" class="form-control" style="width: 100%" type="text" v-model="expense_detail.total_tax">
                          </td>
                          <td>
                            <input v-on:keypress="NumbersOnly" :readonly="true" class="form-control" style="width: 100%" type="text" v-model="expense_detail.total">
                          </td>
                          <td>
                            <input class="form-control" style="width: 100%" type="text" v-model="expense_detail.invoice_no">
                          </td>
                          <td>
                            <input class="form-control" style="width: 100%" type="datetime-local" v-model="expense_detail.invoice_date">
                          </td>
                          <td>
                            <textarea id="description" v-model="expense_detail.description" class="form-control"></textarea>
                          </td>
                          <td>
                            <button type="button" class="btn btn-block btn-round btn-danger" @click="deleteExpenseRow(k,expense_detail)">X</button>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                      <button type="submit" class="btn btn-primary col-xl-12">Save</button>
                    </form>
                  </div>
                </div>
              </div>
            </b-tab>

            <b-tab>
              <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-cog"></i>
                  </span>
                <span class="d-none d-sm-inline-block">Show</span>
              </template>
              <div>
                <div class="card-body">
                  <div class="row">
                    <form class="row g-3" @submit.prevent="updateExpenseDetail">
                      <table class="display compact table table-striped table-bordered" width="100%" style="border-color: #ddd;">
                        <thead>
                        <th>Quantity / Day</th>
                        <th>Amount</th>
                        <th>Total Amount</th>
                        <th>Tax</th>
                        <th>Total Tax</th>
                        <th>Total</th>
                        <th>Invoice No</th>
                        <th>Invoice Date</th>
                        <th>Description</th>
                        <th>Action</th>
                        </thead>
                        <tbody id="addRow" class="addRow">

                        </tbody>
                        <tbody>
                        <tr v-for="(show_expense_detail, r) in show_expense_details" :key="r">
                          <input type="hidden" v-model="show_expense_detail.id">
                          <td>
                            <input class="form-control" style="width: 100%" type="text" v-model="show_expense_detail.quantity">
                          </td>
                          <td>
                            <input class="form-control" style="width: 100%" type="text" v-model="show_expense_detail.amount">
                          </td>
                          <td>
                            <input class="form-control" style="width: 100%" type="text" v-model="show_expense_detail.total_amount">
                          </td>
                          <td>
                            <input class="form-control" style="width: 100%" type="text" v-model="show_expense_detail.tax">
                          </td>
                          <td>
                            <input class="form-control" style="width: 100%" type="text" v-model="show_expense_detail.total_tax">
                          </td>
                          <td>
                            <input class="form-control" style="width: 100%" type="text" v-model="show_expense_detail.total">
                          </td>
                          <td>
                            <input class="form-control" style="width: 100%" type="text" v-model="show_expense_detail.invoice_no">
                          </td>
                          <td>
                            <input class="form-control" style="width: 100%" type="datetime-local" v-model="show_expense_detail.invoice_date">
                          </td>
                          <td>
                            <textarea id="description" v-model="show_expense_detail.description" class="form-control"></textarea>
                          </td>
                          <td>
                            <a @click="deleteExpenseDetail(show_expense_detail.id)" style="color: white;" class="btn btn-block btn-round btn-danger">X</a>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                      <button type="submit" class="btn btn-primary col-xl-12">Update</button>
                    </form>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
        <!-- end card-body -->
      </div>
    </div>
  </Layout>
</template>

<script>

import Layout from "../../../router/layouts/main.vue";
import PageHeader from "@/components/page-header";
import axios from "axios";

export default {
  components: { Layout, PageHeader },
  created(){
    // eslint-disable-next-line no-undef
    if(!User.loggedIn()){
      this.$router.push({name: 'admin-login'})
    }
    this.showExpenseDetails();

  },
  data() {
    return {
      expense_details: [{
        quantity: '',
        amount: '',
        total_amount: '',
        tax: '',
        total_tax: '',
        total: '',
        description: '',
        invoice_no: '',
        invoice_date: '',
      }],
      show_expense_details: [{
        id: '',
        quantity: '',
        amount: '',
        total_amount: '',
        tax: '',
        total_tax: '',
        total: '',
        description: '',
        invoice_no: '',
        invoice_date: '',
      }],
      errors:{},
      searchTerm:'',
      expenseDetails: [],
      title: "Form Repeater",
      items: [
        {
          text: "Veltrix",
          href: "/",
        },
        {
          text: "Forms",
          href: "/",
        },
        {
          text: "Form Repeater",
          active: true,
        },
      ],
    };
  },
  computed:{
    // filtersearch(){
    //   return this.show_revenue_details.filter(showRevenueDetail => {
    //     return showRevenueDetail.invoice_no.match(this.searchTerm)
    //
    //   })
    // },
    // eslint-disable-next-line vue/return-in-computed-property
  },
  methods: {
    NumbersOnly(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    changeQuantity(index){
      this.expense_details[index].quantity = event.target.value;
      this.totalAmount(index);
    },
    totalAmount(index){
      let totalAmount;
      if (this.expense_details[index].quantity !== null || this.expense_details[index].quantity !== ""){
        totalAmount = this.expense_details[index].quantity * this.expense_details[index].amount;
      }else{
        totalAmount = 0;
      }
      this.expense_details[index].total_amount = totalAmount;
      this.totalTax(index);
      this.total(index);
    },
    totalTax(index){
      let totalTax;
      if (this.expense_details[index].tax !== null || this.expense_details[index].tax !== ""){
        totalTax = (this.expense_details[index].total_amount / 100) * this.expense_details[index].tax;
      }else{
        totalTax = 0;
      }
      this.expense_details[index].total_tax = totalTax;
      this.total(index);
    },
    total(index){
      let total;
      total = this.expense_details[index].total_amount + this.expense_details[index].total_tax;
      this.expense_details[index].total = total;
    },
    showExpenseDetails(){
      let id = this.$route.params.id
      this.$http.get('expense/detail/'+id)
          .then(({data}) => (this.show_expense_details = data))
          .catch()
    },
    addNewExpenseRow() {
      this.expense_details.push({
        quantity: '',
        amount: '',
        total_amount: '',
        tax: '',
        total_tax: '',
        total: '',
        description: '',
        invoice_no: '',
        invoice_date: '',
      })
    },
    deleteExpenseRow(index,revenue_detail) {
      var idx = this.expense_details.indexOf(revenue_detail);
      if(idx > -1) {
        this.expense_details.splice(idx, 1);
      }
    },

    insertExpenseDetail(){
      let id = this.$route.params.id
      this.$http.post('expense-detail/'+id,this.expense_details)
          .then(() => {
            this.$router.push({ name: 'admin-expense-list'})
            this.$toasted.global.success()
          })
          .catch(error => this.errors = error.response.data.errors)
    },
    updateExpenseDetail(){
      let id = this.$route.params.id
      this.$http.post('expense-detail-update/'+id,this.show_expense_details)
          .then(() => {
            this.$router.push({ name: 'admin-expense-list'})
            this.$toasted.global.success()
          })
          .catch(error => this.errors = error.response.data.errors)
    },
    deleteExpenseDetail(id){
      // eslint-disable-next-line no-undef
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          axios.delete('expense-detail-delete/'+id)
              .then(() => {
                this.show_expense_details = this.show_expense_details.filter(showExpenseDetail => {
                  return showExpenseDetail.id !== id
                })
              })
              .catch(() => {
                this.$router.push({name: 'admin-expense-detail'})
              })
          // eslint-disable-next-line no-undef
          Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
          )
        }
      })

    },

  },
};
</script>

<style scoped>

</style>