import Vue from "vue";
import Toasted from "vue-toasted";

Vue.use(Toasted);

Vue.toasted.register('maxprocess_error','The maximum process cannot be greater than 20!',{
    position: "top-right",
    type:"warning",
    duration : 3000,
    theme: 'bubble',
    // ['toasted-primary', 'outline', 'bubble']
})

Vue.toasted.register('success','Successful.',{
    position: "top-right",
    type:"success",
    duration : 3000,
    theme: 'bubble',
    // ['toasted-primary', 'outline', 'bubble']
})


Vue.toasted.register('alert','Are You Sure?',{
    position: "top-right",
    type:"error",
    duration : 3000,
    theme: 'bubble',
    // ['toasted-primary', 'outline', 'bubble']
})


Vue.toasted.register('error','Something Went Wrong!',{
    position: "top-right",
    type:"error",
    duration : 3000,
    theme: 'bubble',
    // ['toasted-primary', 'outline', 'bubble']
})


Vue.toasted.register('warning','Opps Wrong!',{
    position: "top-right",
    type:"warning",
    duration : 3000,
    theme: 'bubble',
    // ['toasted-primary', 'outline', 'bubble']
})


Vue.toasted.register('newsletterWarning','This email has a record!',{
    position: "top-right",
    type:"warning",
    duration : 3000,
    theme: 'bubble',
    // ['toasted-primary', 'outline', 'bubble']
})


Vue.toasted.register('image_validation','Upload Image Less Then 10MB!',{
    position: "top-right",
    type:"error",
    duration : 3000,
    theme: 'bubble',
    // ['toasted-primary', 'outline', 'bubble']
})


Vue.toasted.register('cart_success','SSuccessfully Deleted!',{
    position: "top-right",
    type:"success",
    duration : 3000,
    theme: 'bubble',
    // ['toasted-primary', 'outline', 'bubble']
})


Vue.toasted.register('selectCategory','Please select category first',{
    position: "top-right",
    type:"warning",
    duration : 3000,
    theme: 'bubble',
    // ['toasted-primary', 'outline', 'bubble']
})

Vue.toasted.register('selectFromWarehouse','Please select from warehouse',{
    position: "top-right",
    type:"warning",
    duration : 3000,
    theme: 'bubble',
    // ['toasted-primary', 'outline', 'bubble']
})

Vue.toasted.register('selectToWarehouse','Please select to warehouse',{
    position: "top-right",
    type:"warning",
    duration : 3000,
    theme: 'bubble',
    // ['toasted-primary', 'outline', 'bubble']
})


Vue.toasted.register('selectToWarehouse','Successfully Added',{
    position: "top-right",
    type:"success",
    duration : 3000,
    theme: 'bubble',
    // ['toasted-primary', 'outline', 'bubble']
})

Vue.toasted.register('stocktransfercart_error','Insufficient Stock!',{
    position: "top-right",
    type:"error",
    duration : 3000,
    theme: 'bubble',
    // ['toasted-primary', 'outline', 'bubble']
})

Vue.toasted.register('stocktransfercart_success','Added To Transfer Cart',{
    position: "top-right",
    type:"success",
    duration : 3000,
    theme: 'bubble',
    // ['toasted-primary', 'outline', 'bubble']
})


Vue.toasted.register('stocktransfercart_delete','Successfully Deleted!',{
    position: "top-right",
    type:"success",
    duration : 3000,
    theme: 'bubble',
    // ['toasted-primary', 'outline', 'bubble']
})


Vue.toasted.register('warehouse_markers_warning','More Than 2 Markers Cannot Be Selected!',{
    position: "top-right",
    type:"warning",
    duration : 3000,
    theme: 'bubble',
    // ['toasted-primary', 'outline', 'bubble']
})

Vue.toasted.register('transport_warning','You have not selected a transport category!',{
    position: "top-right",
    type:"warning",
    duration : 3000,
    theme: 'bubble',
    // ['toasted-primary', 'outline', 'bubble']
})

Vue.toasted.register('choose_warehouse','Please choose warehouse!',{
    position: "top-right",
    type:"warning",
    duration : 3000,
    theme: 'bubble',
    // ['toasted-primary', 'outline', 'bubble']
})

Vue.toasted.register('something_went_wrong','something went wrong!',{
    position: "top-right",
    type:"warning",
    duration : 3000,
    theme: 'bubble',
    // ['toasted-primary', 'outline', 'bubble']
})

Vue.toasted.register('selectCountry','Please select country first',{
    position: "top-right",
    type:"warning",
    duration : 3000,
    theme: 'bubble',
    // ['toasted-primary', 'outline', 'bubble']
})

Vue.toasted.register('warningCurrency','Currencies cannot be the same',{
    position: "top-right",
    type:"warning",
    duration : 3000,
    theme: 'bubble',
    // ['toasted-primary', 'outline', 'bubble']
})

Vue.toasted.register('warningFee','Fee cannot be zero',{
    position: "top-right",
    type:"warning",
    duration : 3000,
    theme: 'bubble',
    // ['toasted-primary', 'outline', 'bubble']
})

