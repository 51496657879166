<template>
  <Layout>
    <div>
      <div class="col-sm-6 col-md-3 mt-4">
        <!--        <div class="text-center">-->
        <!--          <b-modal id="edit-modal" v-model="displayModal" title="Edit Salary" hide title-class="font-18">-->
        <!--            <b-form-input v-model="editForm.amount" placeholder="Edit Salary"></b-form-input>-->
        <!--            <template #modal-footer>-->
        <!--              <button v-b-modal.modal-close_visit data-dismiss="modal" @click="closeModal" class="btn btn-danger btn-sm m-1">Close</button>-->
        <!--              <button v-b-modal.modal-close_visit class="btn btn-success btn-sm m-1" @click="editSalary(this.employeeMonthSalaries.id)" >Save</button>-->
        <!--            </template>-->
        <!--          </b-modal>-->
        <!--        </div>-->

        <div class="text-center">
          <b-modal id="modal-standard" @close="closeModal"  v-model="editModalDisplay" title="Add Salary" hide title-class="font-18">
            <b-form-input v-model="form.amount" @keypress="onlyNumber" placeholder="Add Amount"></b-form-input>
            <template  #modal-footer>
              <button  data-dismiss="modal" @click="closeModal" class="btn btn-danger btn-sm m-1">Close</button>
              <b-button  for="edit-modal" class="btn btn-success btn-sm m-1" @click="addSalary" >Save</b-button>
            </template>
          </b-modal>
        </div>
        <div class="text-center">
          <b-modal id="edit-modal"  @close="closeModal" v-model="displayModal" title="Edit Salary" hide title-class="font-18">
            <b-form-input v-model="editform.amount" @keypress="onlyNumber" placeholder="Edit Amount"></b-form-input>
            <template  #modal-footer>
              <button  data-dismiss="modal" @click="closeModal" class="btn btn-danger btn-sm m-1">Close</button>
              <b-button  for="edit-modal" class="btn btn-success btn-sm m-1" @click="editSalary(salaryId)" >Save</b-button>
            </template>
          </b-modal>
        </div>

      </div>
      <div class="col-xl-12">
        <div class="card card-statistics">
          <div class="card-body">
            <b-button @click="getExport" type="submit" class="btn btn-success col-xl-12">Export</b-button>
            <div class="table-responsive">
              <table id="tableId" class="table table-bordered table-striped mb-0">
                <thead class="text-center">
                <tr>
                  <th scope="col">Employee</th>
                  <th v-for="month in months" :key="month.id" scope="col">{{ month.month }}<br /></th>
                  <!--                  <th scope="col">February<br /></th>-->
                  <!--                  <th scope="col">March<br /></th>-->
                  <!--                  <th scope="col">April<br /></th>-->
                  <!--                  <th scope="col">May<br /></th>-->
                  <!--                  <th scope="col">June<br /></th>-->
                  <!--                  <th scope="col">July<br /></th>-->
                  <!--                  <th scope="col">August<br /></th>-->
                  <!--                  <th scope="col">September<br /></th>-->
                  <!--                  <th scope="col">October<br /></th>-->
                  <!--                  <th scope="col">November<br /></th>-->
                  <!--                  <th scope="col">December<br /></th>-->
                </tr>
                </thead>
                <tbody>
                <tr  v-for="employee in employees" :key="employee.id">
                  <th style="text-align: center;" class="text-nowrap" scope="row">{{ employee.employee_name}} {{ employee.employee_surname}}</th>
                  <td class="salary" v-for="month in months" :key="month.id" style="text-align: center;" @click="editModalControl(month.id,employee.id)"  >
                    <div v-for="salary in employeeMonthSalaries" :key="salary.id" >
                      <i   v-if="month.id === salary.month_id && employee.id === salary.employee_id" class="fa fa-check" ></i>
                    </div>




                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>

<script>

import Layout from "../../router/layouts/main.vue";

export default {
  components:{
    // eslint-disable-next-line vue/no-unused-components
    Layout,
  },
  name:'admin-create-salary',
  created(){
    // eslint-disable-next-line no-undef
    if(!User.loggedIn()){
      this.$router.push({name: 'admin-login'})
    }
    this.allEmployee();
    this.allMonth();
    this.control()
    // eslint-disable-next-line no-undef

  },

  data(){
    return {
      form:{
        month_id: null,
        employee_id: null,
        amount: null,
      },
      editform:{
        amount: null,
      },
      excelForm:{
        table_name: 'salaries'
      },
      errors:{},
      employees:{},
      months:{},
      salaries:{},
      employeeMonthSalaries: [],
      editModalDisplay:false,
      displayModal:false,
      editModalControlCheck: [],
      salaryId : null,


    }
  },

  methods:{
    getExport(){
      this.$http.post('table/export',this.excelForm)
          .then(() => {
            this.$toasted.global.success();
            // eslint-disable-next-line no-console
          }).catch((error)=> {
        console.log(error);
        this.$toasted.global.error();
      });
    },
    onlyNumber ($event) {
      //console.log($event.keyCode); //keyCodes value
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) { // 46 is dot
        $event.preventDefault();
      }
    },


    // eslint-disable-next-line vue/no-dupe-keys
    editModalControl(month = null, employee = null){
      this.$http.get('salary/employee/month/control',{params:{month_id:month,employee_id:employee
        }})
          .then((res) => {this.editModalControlCheck = res.data;
            if(this.editModalControlCheck === 'no amount'){
              this.editModalDisplay = true;
              this.form.month_id = month;
              this.form.employee_id = employee;
            }else if (this.editModalControlCheck !== 'no amount'){
              this.salaryId = this.editModalControlCheck.id;
              this.editform.amount = this.editModalControlCheck.amount;
              this.displayModal = true;

              // }



            }

          })
          .catch()

    },
    control(){
      this.$http.get('salary/employee/month/control')
          .then((res) => {this.employeeMonthSalaries = res.data;
          })
          .catch()

    },
    closeModal(){
      this.displayModal = false;
      this.editModalDisplay =false;
    },
    allEmployee(){
      this.$http.get('employee/')
          .then(({data}) => (this.employees = data))
    },
    allMonth(){
      this.$http.get('month/')
          .then(({data}) => (this.months = data))
    },

    addSalary(){
      this.$http.post('add/salary',this.form)
          .then(() => {
            this.form.month_id =null;
            this.form.employee_id =null;
            this.form.amount =null;
            this.editModalDisplay = false;
            this.displayModal = false
            this.control();
          })
          .catch()

    },

    editSalary(id){
      let data  = new FormData()
      data.append('amount',this.editform.amount)
      // eslint-disable-next-line no-console
      this.$http.post('edit/salary/'+id,data)
          .then(() => {
            this.editModalDisplay = false;
            this.displayModal = false;
            this.control();
          })
          .catch()

    },
  }
}
</script>
<style scoped>
.salary:hover {
  cursor:pointer;
}

</style>