<template>
  <Layout>
    <div>
      <div class="col-xl-12">
        <div class="card card-statistics">
          <div class="card-body">
            <form-wizard color="#626ed4" @on-complete="onComplete" enctype="multipart/form-data">
              <!--                Start -->
              <tab-content icon="mdi mdi-pencil-box-outline">
                <div class="row">
                  <div class="form-row">
                    <div class="form-group col-md-4">
                      <label for="productName">Product Name</label>
                      <input type="text" v-model="form.product_name" class="form-control" id="productName"
                             :class="{'is-invalid': typeSubmit && $v.form.product_name.$error,}">
                      <div
                          v-if="typeSubmit && $v.form.product_name.$error"
                          class="invalid-feedback"
                      >
                      <span v-if="!$v.form.product_name.required"
                      >This value is required.</span
                      >
                      </div>
                    </div>
                    <div class="form-group col-md-4">
                      <label for="productTax">Product Tax</label>
                      <money style="width: 100%" v-model.lazy="form.product_tax" v-bind="money" id="productTax" class="form-control" :class="{'is-invalid': typeSubmit && $v.form.product_tax.$error,}"></money>
<!--                      <input type="text" v-model="form.product_tax" class="form-control" id="productTax"-->
<!--                             :class="{'is-invalid': typeSubmit && $v.form.product_tax.$error,}">-->
                      <div
                          v-if="typeSubmit && $v.form.product_tax.$error"
                          class="invalid-feedback"
                      >
                      <span v-if="!$v.form.product_tax.required"
                      >This value is required.</span
                      >
                      </div>
                    </div>
                    <div class="form-group col-md-4">
                      <label for="productStatus">Product Status</label>
                      <select id="productStatus" class="form-control" v-model="form.product_status"
                              :class="{'is-invalid': typeSubmit && $v.form.product_status.$error,}">
                        <option :key="1" :value="1">Active</option>
                        <option :key="0" :value="0">Passive</option>
                      </select>
                      <div
                          v-if="typeSubmit && $v.form.product_status.$error"
                          class="invalid-feedback"
                      >
                      <span v-if="!$v.form.product_status.required"
                      >This value is required.</span
                      >
                      </div>
                    </div>
                  </div>
                  <br>
                  <div class="form-row">
                    <div class="form-group col-md-4">
                      <label for="productCategory">Category</label>
                      <select @change="switchCategorySelect($event)" id="productCategory" class="form-control" v-model="form.category_id" :class="{'is-invalid': typeSubmit && $v.form.category_id.$error,}">
                        <option :key="category.id" :value="category.id" v-for="category in categories">{{ category.category_name }}</option>
                      </select>
                      <div
                          v-if="typeSubmit && $v.form.category_id.$error"
                          class="invalid-feedback"
                      >
                      <span v-if="!$v.form.category_id.required"
                      >This value is required.</span
                      >
                      </div>
                    </div>
                    <div class="form-group col-md-4">
                      <label for="productSubCategory">Sub Category</label>
                      <select id="productSubCategory" class="form-control" v-model="form.subcategory_id">
                        <option :key="subcategory.id" :value="subcategory.id" v-for="subcategory in getSubCategories">{{ subcategory.subcategory_name }}</option>
                      </select>
                    </div>
                    <div class="form-group col-md-4">
                      <label for="productCollection">Collection</label>
                      <select id="productCollection" class="form-control" v-model="form.collection_id">
                        <option :key="collection.id" :value="collection.id" v-for="collection in collections">{{ collection.collection_title }}</option>
                      </select>
                    </div>
                  </div>
                  <br>
                  <div class="form-row">
                    <div class="form-group col-md-4">
                      <label for="productQuantity">Product Quantity</label>
                      <input type="text" v-model="form.product_quantity" class="form-control" id="productQuantity"
                             :class="{'is-invalid': typeSubmit && $v.form.product_quantity.$error,}">
                      <div
                          v-if="typeSubmit && $v.form.product_quantity.$error"
                          class="invalid-feedback"
                      >
                      <span v-if="!$v.form.product_quantity.required"
                      >This value is required.</span
                      >
                      </div>
                    </div>
                    <div class="form-group col-md-4">
                      <label for="productSupplier">Supplier</label>
                      <select id="productSupplier" class="form-control" v-model="form.supplier_id" :class="{'is-invalid': typeSubmit && $v.form.supplier_id.$error,}">
                        <option :key="supplier.id" :value="supplier.id" v-for="supplier in suppliers">{{ supplier.supplier_name }} {{ supplier.supplier_surname }}</option>
                      </select>
                      <div
                          v-if="typeSubmit && $v.form.supplier_id.$error"
                          class="invalid-feedback"
                      >
                      <span v-if="!$v.form.supplier_id.required"
                      >This value is required.</span
                      >
                      </div>
                    </div>
                    <div class="form-group col-md-4">
                      <label for="productMainWarehouse">Main Warehouse</label>
                      <select id="productMainWarehouse" class="form-control" v-model="form.warehouse_id" :class="{'is-invalid': typeSubmit && $v.form.warehouse_id.$error,}">
                        <option :key="mainWarehouse.id" :value="mainWarehouse.id">{{ mainWarehouse.warehouse_name }}</option>
                      </select>
                      <div
                          v-if="typeSubmit && $v.form.warehouse_id.$error"
                          class="invalid-feedback"
                      >
                      <span v-if="!$v.form.warehouse_id.required"
                      >This value is required.</span
                      >
                      </div>
                    </div>
                  </div>
                  <!-- end col -->
                </div>
                <!-- end row -->
              </tab-content>
              <!--                Process -->
              <tab-content icon="mdi mdi-pencil-box-multiple-outline">
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-row">
                      <label>Product Image</label>
                      <div class="col-md-12 input-group">
                        <input type="file" @change="onFileSelected" class="form-control" id="inputGroupFile02">
                        <label class="input-group-text" for="inputGroupFile02">Upload</label>
                      </div>
                      <br><br>
                      <div class="form-group col-md-12 mt-3">
                        <img :src="form.product_image" id="product_image">
                      </div>

                      <div class="form-group col-md-12 mt-3">
                        <lazy-component wrapper-tag="section" >
                          <img :src="'https://crmtr.balinsoft.com/public/'+form.product_image" id="product_image">
                          <span slot="placeholder">Loading..</span> <!-- Optional -->
                        </lazy-component>
<!--                        <img :src="'https://crmtr.balinsoft.com/public/'+form.product_image" id="product_image">-->
                      </div>

                        <div class="row col-md-2 mt-3" v-for="(image, key) in form.product_multiple_image" :key="key">
                          <a class="form-group hover" @click="deleteProduct(form.id, image.id)"><i style="color: red;" class="fa fa-times"></i></a>
                          <lazy-component wrapper-tag="section" >
                            <img :src="'https://crmtr.balinsoft.com/public/'+image.product_image" id="product_image_multiple">
                            <span slot="placeholder">Loading..</span> <!-- Optional -->
                          </lazy-component>
<!--                          <img :src="'https://crmtr.balinsoft.com/public/'+image.product_image" id="product_image_multiple">-->
                        </div>

                      <div class="form-group col-md-12 mt-3">
                        <label>Product Images</label>
                        <vue-dropzone id="dropzone" ref="myVueDropzone" :options="dropzoneOptions"></vue-dropzone>
                      </div>

                    </div>
<!--                    <div class="form-group col-md-12 mt-3">-->
<!--                      <label>Product Images</label>-->
<!--                      <vue-dropzone id="dropzone" ref="myVueDropzone" :options="dropzoneOptions"></vue-dropzone>-->
<!--                    </div>-->

                  </div>
                  <!-- end col -->
                </div>
                <!-- end row -->
              </tab-content>
              <!--                Finish-->
              <tab-content icon="mdi mdi-pencil-box-multiple-outline">
                <div class="row">
                  <div class="col-12">
                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <label for="productDescription">Product Description</label>
                        <ckeditor :editor="editor" v-model="form.product_description" class="form-control" id="productDescription" :class="{'is-invalid': typeSubmit && $v.form.product_description.$error,}"></ckeditor>
                        <div
                            v-if="typeSubmit && $v.form.product_description.$error"
                            class="invalid-feedback"
                        >
                          <span v-if="!$v.form.product_description.required"
                          >This value is required.</span
                          >
                        </div>
                      </div>
                      <div class="form-group col-md-6">
                        <label for="productMetaDescription">Product Meta Description</label>
                        <ckeditor :editor="editor" v-model="form.product_meta_description" class="form-control" id="productMetaDescription"></ckeditor>

                      </div>
                    </div>
                  </div>
                  <!-- end col -->
                </div>
                <!-- end row -->
              </tab-content>
            </form-wizard>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>

<script>

import Layout from "../../router/layouts/main.vue";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import vue2Dropzone from "vue2-dropzone";
import { FormWizard, TabContent } from "vue-form-wizard";
import {required} from "vuelidate/lib/validators";
import LazyComponent from "v-lazy-component";
import {Money} from "v-money";

export default {
  components:{
    // eslint-disable-next-line vue/no-unused-components
    ckeditor: CKEditor.component,
    Layout,LazyComponent,Money,
    // eslint-disable-next-line vue/no-unused-components
    vueDropzone: vue2Dropzone,
    // eslint-disable-next-line vue/no-unused-components
    FormWizard, TabContent
  },
  name:'admin-create-product',
  created(){
    // eslint-disable-next-line no-undef
    if(!User.loggedIn()){
      this.$router.push({name: 'admin-login'})
    }
    this.$http.get('category/')
        .then(({data}) => (this.categories = data))
    this.$http.get('collection/')
        .then(({data}) => (this.collections = data))
    this.$http.get('supplier/')
        .then(({data}) => (this.suppliers = data))
    this.$http.get('main-warehouse/')
        .then(({data}) => (this.mainWarehouse = data))
    this.id = this.$route.params.id
    this.getProductDetail();


  },

  validations(){
    return{
      form:{
        product_name: {required},
        category_id: {required},
        supplier_id: {required},
        warehouse_id: {required},
        product_status: {required},
        product_description: {required},
        product_quantity: {required},
        product_tax: {required},
      },
    }
  },
  data(){
    return {
      money: {
        decimal: '.',
        thousands: ',',
        // prefix: 'R$ ',
        // suffix: ' #',
        precision: 2,
        masked: true /* doesn't work with directive */
      },
      typeSubmit:false,
      id:null,
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        maxFilesize: 10,
        autoProcessQueue: false,
        addRemoveLinks: true,
        // headers: { "My-Awesome-Header": "header value" },
        // accept: function (file) {
        //   let reader;
        //   reader = new FileReader();
        //   reader.onload = function (event) {
        //     let imageData = event.target.result;
        //     this.form.multipleImage.push(imageData);
        //   };
        //   reader.readAsDataURL(file);
        //   console.log(this.form.multipleImage);

        //},
      },
      form:{
        multipleImage:[],
        product_name: '',
        category_id: '',
        subcategory_id: '',
        collection_id: '',
        supplier_id: '',
        warehouse_id: '',
        product_status: '',
        product_description: '',
        product_meta_description: '',
        product_image: '',
        product_quantity: '',
        product_tax: '',
      },
      subcategories:{},
      errors:{},
      products:{},
      categories:{},
      collections:{},
      suppliers:{},
      mainWarehouse:{},
      getSubCategories:{},
      editor: ClassicEditor,
    }
  },

  methods:{
    onFileSelected(event){
      let file = event.target.files[0];
      if(file.size > 20971520) {
        this.$toasted.global.image_validation()
      } else {
        let reader = new FileReader();
        reader.onload = event => {
          this.form.new_product_image = event.target.result
          // eslint-disable-next-line no-console
          // console.log(event.target.result);
        };
        reader.readAsDataURL(file);
      }
    },
    switchCategorySelect(event){
      let id = event.target.value;
      if (id != null) {
        this.$http.get('select-to-category/'+this.id)
            .then(({data}) => (this.getSubCategories = data))
            .catch()
      } else {
        this.$toasted.global.selectCategory()
      }

    },
    onComplete(){
      let id = this.$route.params.id
      this.typeSubmit = true;
      this.$v.$touch();
      if(this.$v.$invalid){
        return;
      }else{
        this.form.multipleImage = [];
        let image = this.$refs.myVueDropzone.getAcceptedFiles();
        if(image.length >0){
          image.forEach(item=>{
            this.form.multipleImage.push(item.dataURL)
          })
        }
        this.$http.patch('product/'+id,this.form)
            .then(() => {
              this.$router.push({ name: 'admin-product-list'})
              this.$toasted.global.success()
            })
            .catch(error => this.errors = error.response.data.errors)
      }

    },
    getProductDetail(){
      this.$http.get('product/'+this.id)
          .then(({data}) => (this.form = data))
          .catch()
    },
        deleteProduct(productId, imageId){
      this.$http.delete('delete/'+productId+'/image/'+imageId).then((response)=>{
        console.log(response);
      this.$toasted.global.success();
      this.getProductDetail();
      }).catch(error=>{
        console.log(error);
      })
    }
    // productUpdate(){
    //   let id = this.$route.params.id
    //   this.$http.patch('product/'+id,this.form)
    //       .then(() => {
    //         this.$router.push({ name: 'admin-product-list'})
    //         this.$toasted.global.success()
    //       })
    //       .catch(error => this.errors = error.response.data.errors)
    // },
  }
}
</script>
<style scoped>
#product_image{
  width: 50px;
  height: 50px;
}

#product_image_multiple{
  width: 100px;
  height: 100px;
}

.hover:hover{
  cursor:pointer;
}
.v-lazy-component.v-lazy-component--loading {
  filter: blur(20px);
}

.v-lazy-component.v-lazy-component--loaded {
  filter: blur(0);
  transition: filter 1s;
}
</style>
