<template>
  <Layout>
    <div>
      <div class="row">
        <div class="col-xl-12">
          <div class="card card-statistics h-100 mb-0 apexchart-tool-force-top">
            <div class="card-header d-flex justify-content-between">
              <div class="card-heading">

                <h4 class="card-title .customCardTitle">All Transaction</h4>
              </div>
              <!--                        <a class="btn btn-sm btn-info"><font color="#ffffff">Add Customer</font></a>-->
            </div>
            <div class="card-body">
              <b-tabs justified pills class="navtab-bg" content-class="p-3">
                <b-tab active>
                  <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                  </span>
                    <span class="d-none d-sm-inline-block">Stock Transfer Transaction</span>
                  </template>
                  <div>
                    <div class="card-body">
                      <div class="row">
                        <b-row>
                          <b-col xl="6">

                            <b-input-group class="datatable-btn">
                              <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                              <b-input-group-append>
                                <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                              </b-input-group-append>
                            </b-input-group>

                          </b-col>

                          <b-col xl="6">
                            <b-form-group  label-cols="2" label="Per page" class="mb-4 datatable-select">
                              <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                            </b-form-group>
                          </b-col>
                        </b-row>

                        <div class="table-responsive datatable-vue">
                          <b-table
                              show-empty
                              stacked="md"
                              :items="items"
                              :fields="tablefields"
                              :filter="filter"
                              :current-page="currentPage"
                              :per-page="perPage"
                              @filtered="onFiltered"

                          >
                            <template v-slot:cell(productStockControl)="{item}">
                              <div v-if="item.product_quantity > 100">
                                <b-spinner
                                    type="grow"
                                    label="Spinning"
                                    variant="success"
                                    class="m-1"
                                ></b-spinner>
                              </div>
                              <div v-else-if="item.product_quantity < 100 && item.product_quantity > 0">
                                <b-spinner
                                    type="grow"
                                    label="Spinning"
                                    variant="warning"
                                    class="m-1"
                                ></b-spinner>
                              </div>
                              <div v-else="">
                                <b-spinner
                                    type="grow"
                                    label="Spinning"
                                    variant="danger"
                                    class="m-1"
                                ></b-spinner>
                              </div>
                            </template>
                            <template v-slot:cell(supplierFullName)="{item}">
                              {{item.supplier_name + ' '+ item.supplier_surname}}
                            </template>


                            <template v-slot:cell(status)="{item}">
                              <b-badge v-if="item.status === 0" class="btn btn-primary">{{item.status_text[0].status_text}}</b-badge>
                              <b-badge v-if="item.status === 1" class="btn btn-primary">{{item.status_text[0].status_text}}</b-badge>
                              <b-badge v-if="item.status === 2" class="btn btn-primary">{{item.status_text[0].status_text}}</b-badge>
                              <b-badge v-if="item.status === 3" class="btn btn-primary">{{item.status_text[0].status_text}}</b-badge>
                              <b-badge v-if="item.status === 4" class="btn btn-primary">{{item.status_text[0].status_text}}</b-badge>
                              <b-badge v-if="item.status === 5" class="btn btn-primary">{{item.status_text[0].status_text}}</b-badge>
                              <b-badge v-if="item.status === 6" class="btn btn-danger">{{item.status_text[0].status_text}}</b-badge>
                              <b-badge v-if="item.status === 7" class="btn btn-success">{{item.status_text[0].status_text}}</b-badge>
                              <b-badge v-if="item.status === 8" class="btn btn-primary">{{item.status_text[0].status_text}}</b-badge>
                              <b-badge v-if="item.status === 9" class="btn btn-danger">{{item.status_text[0].status_text}}</b-badge>
                              <b-badge v-if="item.status === 10" class="btn btn-danger">{{item.status_text[0].status_text}}</b-badge>
                              <b-badge v-if="item.status === 11" class="btn btn-danger">{{item.status_text[0].status_text}}</b-badge>
                              <b-badge v-if="item.status === 12" class="btn btn-primary">{{item.status_text[0].status_text}}</b-badge>
                            </template>

                            <template v-slot:cell(fromWarehouse_type)="{item}">
                              <div v-if="item.fromWarehouse_type === 1">
                                <b-badge class="btn btn-success">Main Warehouse</b-badge>
                              </div>
                              <div v-else>
                                <b-badge class="btn btn-warning">Sub Warehouse</b-badge>
                              </div>

                            </template>
                            <template v-slot:cell(toWarehouse_type)="{item}">
                              <div v-if="item.toWarehouse_type === 1">
                                <b-badge class="btn btn-success">Main Warehouse</b-badge>
                              </div>
                              <div v-else>
                                <b-badge class="btn btn-warning">Sub Warehouse</b-badge>
                              </div>

                            </template>

                            <template v-slot:cell(actions)="{item}">
                              <router-link :to="{ name: 'admin-stock-transfer-show',params:{id:item.id}}" class="btn btn-sm btn-dark">Show</router-link>
                              <router-link :to="{ name: 'admin-stock-transfer-invoice',params:{id:item.id}}" class="btn btn-sm btn-success">Invoice</router-link>
                            </template>


                          </b-table>
                        </div>

                        <b-col md="6" class="my-1 p-0">
                          <b-pagination
                              v-model="currentPage"
                              :total-rows="rows"
                              :per-page="perPage"
                              class="my-0"
                          ></b-pagination>
                        </b-col>
                      </div>
                    </div>
                  </div>
                </b-tab>

                                <b-tab>
                                  <template v-slot:title>
                                  <span class="d-inline-block d-sm-none">
                                    <i class="fas fa-cog"></i>
                                  </span>
                                    <span class="d-none d-sm-inline-block">Order Transaction</span>
                                  </template>
                                  <div>
                                    <div class="card-body">
                                      <div class="row">
                                        <b-row>
                                          <b-col xl="6">

                                            <b-input-group class="datatable-btn">
                                              <b-form-input v-model="filterOrder" placeholder="Type to Search"></b-form-input>
                                              <b-input-group-append>
                                                <b-button :disabled="!filterOrder" @click="filterOrder = ''">Clear</b-button>
                                              </b-input-group-append>
                                            </b-input-group>

                                          </b-col>

                                          <b-col xl="6">
                                            <b-form-group  label-cols="2" label="Per page" class="mb-4 datatable-select">
                                              <b-form-select v-model="perPageOrder" :options="pageOptionsOrder"></b-form-select>
                                            </b-form-group>
                                          </b-col>
                                        </b-row>

                                        <div class="table-responsive datatable-vue">
                                          <b-table
                                              show-empty
                                              stacked="md"
                                              :items="itemOrders"
                                              :fields="tablefieldsOrders"
                                              :filter="filterOrder"
                                              :current-page="currentPageOrder"
                                              :per-page="perPageOrder"
                                              @filtered="onFilteredOrder"

                                          >
                                            <template v-slot:cell(supplierFullName)="{item}">
                                              {{item.supplier_name + ' '+ item.supplier_surname}}
                                            </template>
                                            <template v-slot:cell(customerFullName)="{item}">
                                              {{item.customer_name + ' '+ item.customer_surname}}
                                            </template>
                                            <template v-slot:cell(status)="{item}">
                                              <b-badge v-if="item.status === 0" class="btn btn-primary">{{item.status_text[0].status_text}}</b-badge>
                                              <b-badge v-if="item.status === 1" class="btn btn-primary">{{item.status_text[0].status_text}}</b-badge>
                                              <b-badge v-if="item.status === 2" class="btn btn-primary">{{item.status_text[0].status_text}}</b-badge>
                                              <b-badge v-if="item.status === 3" class="btn btn-primary">{{item.status_text[0].status_text}}</b-badge>
                                              <b-badge v-if="item.status === 4" class="btn btn-primary">{{item.status_text[0].status_text}}</b-badge>
                                              <b-badge v-if="item.status === 5" class="btn btn-primary">{{item.status_text[0].status_text}}</b-badge>
                                            </template>
                                            <template v-slot:cell(actions)="{item}">
                                              <router-link :to="{ name: 'admin-order-show',params:{id:item.id}}" class="btn btn-sm btn-dark">Show</router-link>
                                              <router-link :to="{ name: 'admin-order-invoice-show',params:{id:item.id}}" class="btn btn-sm btn-success">Invoice</router-link>
                                            </template>

                                          </b-table>
                                        </div>

                                        <b-col md="6" class="my-1 p-0">
                                          <b-pagination
                                              v-model="currentPageOrder"
                                              :total-rows="rowsOrders"
                                              :per-page="perPageOrder"
                                              class="my-0"
                                          ></b-pagination>
                                        </b-col>
                                      </div>
                                    </div>
                                  </div>
                                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>

import Layout from "../../router/layouts/main.vue";
import PageHeader from "@/components/page-header";
export default {
  components:{
    // eslint-disable-next-line vue/no-unused-components
    Layout,PageHeader
  },
  created(){
    // eslint-disable-next-line no-undef
    if(!User.loggedIn()){
      this.$router.push({name: '/admin/login'})
    }
    this.getStockTransferTransaction();
    // this.getOrderTransaction();
    // eslint-disable-next-line no-undef
    Reload.$on('AfterAdd',() => {
    })
  },
  data(){
    return{
      tablefields: [
        { key: 'code', label: 'Code', sortable: true, },
        { key: 'supplierFullName', label: 'Supplier', sortable: true, },
        { key: 'truck_plate_number', label: 'Truck Plate Number', sortable: true, },
        { key: 'fromWarehouse_name', label: 'From Warehouse', sortable: true, },
        { key: 'fromWarehouse_type', label: 'From Warehouse Type', sortable: true, },
        { key: 'toWarehouse_name', label: 'To Warehouse', sortable: true, },
        { key: 'toWarehouse_type', label: 'To Warehouse Type', sortable: true, },
        { key: 'status', label: 'status', sortable: true, },
        { key: 'actions', label: 'Actions', sortable: true, },
      ],
      tablefieldsOrders: [
        { key: 'code', label: 'Code', sortable: true, },
        { key: 'supplierFullName', label: 'Supplier', sortable: true, },
        { key: 'truck_plate_number', label: 'Truck Plate Number', sortable: true, },
        { key: 'fromWarehouse_name', label: 'From Warehouse', sortable: true, },
        { key: 'fromWarehouse_type', label: 'From Warehouse Type', sortable: true, },
        { key: 'customerFullName', label: 'Customer', sortable: true, },
        { key: 'customer_email', label: 'Customer Email', sortable: true, },
        { key: 'customer_phone', label: 'Customer Phone', sortable: true, },
        { key: 'status', label: 'status', sortable: true, },
        { key: 'actions', label: 'Actions', sortable: true, },

      ],
      items: [

      ],
      itemOrders: [

      ],
      filter: null,
      filterOrder: null,
      totalRows: 1,
      totalRowsOrder: 1,
      currentPage: 1,
      currentPageOrder: 1,
      perPage: 10,
      perPageOrder: 10,
      pageOptions: [5, 10, 15],
      pageOptionsOrder: [5, 10, 15],
    }
  },
  computed:{
    sortOptions() {
      // Create an options list from our fields
      return this.tablefields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key };
          });
    },
    sortOptionsOrders() {
      // Create an options list from our fields
      return this.tablefieldsOrders
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key };
          });
    },
    rows(){
      return this.items.length;
    },
    rowsOrders(){
      return this.itemOrders.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.totalRowsOrder = this.itemOrders.length;

    this.getStockTransferTransaction();
    this.getOrderTransaction();
  },
  methods:{
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onFilteredOrder(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsOrders = filteredItems.length;
      this.currentPageOrder = 1;
    },
    getStockTransferTransaction(){
      let id = this.$route.params.id
      this.$http.get('supplier/stock/transfer/transaction/'+id)
          .then(({data}) => (this.items = data))
          .catch()
    },
    getOrderTransaction(){
      let id = this.$route.params.id
      this.$http.get('supplier/order/transaction/'+id)
          .then(({data}) => (this.itemOrders = data))
          .catch()
    },

  },


}
</script>

<style scoped>

</style>
