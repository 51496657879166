<template>
  <Layout>
    <div>
      <div class="col-xl-12">
        <div class="card card-statistics">
          <div class="card-body">
            <form @submit.prevent="palletInsert" enctype="multipart/form-data">
              <div class="form-row">
                <div class="form-group col-md-4">
                  <label for="palletName">Pallet Name</label>
                  <input type="text" v-model="form.pallet_name" class="form-control" id="palletName" :class="{
                    'is-invalid': typeSubmit && $v.form.pallet_name.$error,
                  }">
                  <div v-if="typeSubmit && $v.form.pallet_name.$error" class="invalid-feedback">
                    <span v-if="!$v.form.pallet_name.required">This value is required.</span>
                  </div>
                </div>
                <div class="form-group col-md-4">
                  <label for="palletDefaultWeight">Pallet Default Weight</label>
                  <money style="width: 100%" v-model.lazy="form.pallet_default_weight" v-bind="weight" id="palletDefaultWeight" class="form-control" :class="{
                    'is-invalid': typeSubmit && $v.form.pallet_default_weight.$error,
                  }"></money>
<!--                  <input type="text" v-model="form.pallet_default_weight" class="form-control" id="palletDefaultWeight" :class="{-->
<!--                    'is-invalid': typeSubmit && $v.form.pallet_default_weight.$error,-->
<!--                  }">-->
                  <div v-if="typeSubmit && $v.form.pallet_default_weight.$error" class="invalid-feedback">
                    <span v-if="!$v.form.pallet_default_weight.required">This value is required.</span>
                  </div>
                </div>
                <div class="form-group col-md-4">
                  <label for="palletMaxWeight">Pallet Max Weight</label>
                  <money style="width: 100%" v-model.lazy="form.pallet_max_weight" v-bind="weight" id="palletMaxWeight" class="form-control" :class="{
                    'is-invalid': typeSubmit && $v.form.pallet_max_weight.$error,
                  }"></money>
<!--                  <input type="text" v-model="form.pallet_max_weight" class="form-control" id="palletMaxWeight" :class="{-->
<!--                    'is-invalid': typeSubmit && $v.form.pallet_max_weight.$error,-->
<!--                  }">-->
                  <div v-if="typeSubmit && $v.form.pallet_max_weight.$error" class="invalid-feedback">
                    <span v-if="!$v.form.pallet_max_weight.required">This value is required.</span>
                  </div>
                </div>
              </div>
              <br>
              <div class="form-row">
                <div class="form-group col-md-3">
                  <label for="palletWidth">Pallet Width</label>
                  <money style="width: 100%" v-model.lazy="form.pallet_width" v-bind="money" id="palletWidth" class="form-control" :class="{
                    'is-invalid': typeSubmit && $v.form.pallet_width.$error,
                  }"></money>
<!--                  <input type="text" v-model="form.pallet_width" class="form-control" id="palletWidth" :class="{-->
<!--                    'is-invalid': typeSubmit && $v.form.pallet_width.$error,-->
<!--                  }">-->
                  <div v-if="typeSubmit && $v.form.pallet_width.$error" class="invalid-feedback">
                    <span v-if="!$v.form.pallet_width.required">This value is required.</span>
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label for="palletLength">Pallet Length</label>
                  <money style="width: 100%" v-model.lazy="form.pallet_length" v-bind="money" id="palletLength" class="form-control" :class="{
                    'is-invalid': typeSubmit && $v.form.pallet_length.$error,
                  }"></money>
<!--                  <input type="text" v-model="form.pallet_length" class="form-control" id="palletLength" :class="{-->
<!--                    'is-invalid': typeSubmit && $v.form.pallet_length.$error,-->
<!--                  }">-->
                  <div v-if="typeSubmit && $v.form.pallet_length.$error" class="invalid-feedback">
                    <span v-if="!$v.form.pallet_length.required">This value is required.</span>
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label for="palletHeight">Pallet Height</label>
                  <money style="width: 100%" v-model.lazy="form.pallet_height" v-bind="money" id="palletHeight" class="form-control" :class="{
                    'is-invalid': typeSubmit && $v.form.pallet_height.$error,
                  }"></money>
<!--                  <input type="text" v-model="form.pallet_height" class="form-control" id="palletHeight" :class="{-->
<!--                    'is-invalid': typeSubmit && $v.form.pallet_height.$error,-->
<!--                  }">-->
                  <div v-if="typeSubmit && $v.form.pallet_height.$error" class="invalid-feedback">
                    <span v-if="!$v.form.pallet_height.required">This value is required.</span>
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label for="palletPrice">Pallet Price</label>
                  <money style="width: 100%" v-model.lazy="form.pallet_price" v-bind="money" id="palletPrice" class="form-control" :class="{
                    'is-invalid': typeSubmit && $v.form.pallet_price.$error,
                  }"></money>
<!--                  <input type="text" v-model="form.pallet_price" class="form-control" id="palletPrice" :class="{-->
<!--                    'is-invalid': typeSubmit && $v.form.pallet_price.$error,-->
<!--                  }">-->
                  <div v-if="typeSubmit && $v.form.pallet_price.$error" class="invalid-feedback">
                    <span v-if="!$v.form.pallet_price.required">This value is required.</span>
                  </div>
                </div>
              </div>
              <br>
              <button type="submit" class="btn btn-primary col-xl-12 mt-5">Save</button>
            </form>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>

<script>

import Layout from "../../router/layouts/main.vue";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {Money} from "v-money";
import {
  required
} from "vuelidate/lib/validators";

export default {
  components:{
    // eslint-disable-next-line vue/no-unused-components
    ckeditor: CKEditor.component,
    Layout,Money
  },
  name:'admin-create-pallet',
  created(){
    // eslint-disable-next-line no-undef
    if(!User.loggedIn()){
      this.$router.push({name: 'admin-login'})
    }
    this.$http.get('pallet/')
        .then(({data}) => (this.pallets = data))
  },
  validations(){
    return{
      form:{
        pallet_name: {required},
        pallet_default_weight: {required},
        pallet_width: {required},
        pallet_height: {required},
        pallet_length: {required},
        pallet_max_weight: {required},
        pallet_price: {required},
      },
    }
  },
  data(){
    return {
      money: {
        decimal: '.',
        thousands: ',',
        // prefix: 'R$ ',
        // suffix: ' #',
        precision: 2,
        masked: true /* doesn't work with directive */
      },
      weight: {
        decimal: '.',
        thousands: ',',
        // prefix: 'R$ ',
        // suffix: ' #',
        precision: 3,
        masked: true /* doesn't work with directive */
      },
      form:{
        pallet_name: null,
        pallet_default_weight: null,
        pallet_width: null,
        pallet_height: null,
        pallet_length: null,
        pallet_max_weight: null,
        pallet_price: null,
      },
      errors:{},
      typeSubmit:false,
      pallets:{},
      editor: ClassicEditor,
    }
  },

  methods:{
    palletInsert(){
      this.typeSubmit = true;
      this.$v.$touch()

      if(this.$v.$invalid){
        return false;
      }else{
        this.$http.post('pallet',this.form)
            .then(() => {
              this.$router.push({ name: 'admin-pallet-list'})
              Notification.success()
            })
            .catch(error => this.errors = error.response.data.errors)
      }
    }
  }
}
</script>
<style scoped>

</style>
