

<template>
  <Layout>
    <PageHeader :items="item" :title="title"></PageHeader>
    <div>
      <div class="col-sm-6 col-md-3 mt-4">
        <div class="text-center">
          <b-modal id="modal-standard"  v-model="editModalDisplay" title="Add Advance Payment" hide title-class="font-18">
            <money v-model.lazy="formPayment.advance_payment" v-bind="money" class="form-control" id="advancePayment"></money>
<!--            <b-form-input id="test123" v-model="formPayment.advance_payment" placeholder="Add Advance Payment"></b-form-input>-->
            <template #modal-footer>
              <button data-dismiss="modal" @click="closeModal" class="btn btn-danger btn-sm m-1">Close</button>
              <b-button for="edit-modal" class="btn btn-success btn-sm m-1" @click="addPayment" >Save</b-button>
            </template>
          </b-modal>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">

              <div class="card-body">
                <div style="margin-left: 575px" class="col-sm-4">
                  <div class="float-end d-none d-md-block">
                  </div>
                </div>
                <b-row>

                  <b-col xl="6">

                    <b-input-group class="datatable-btn">
                      <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                      <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                      </b-input-group-append>

                    </b-input-group>

                  </b-col>

                  <b-col xl="6">
                    <b-form-group  label-cols="2" label="Per page" class="mb-4 datatable-select">
                      <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                    </b-form-group>

                  </b-col>
                </b-row>

                <div class="table-responsive datatable-vue">
                  <b-table
                      show-empty
                      stacked="md"
                      :items="items"
                      :fields="tablefields"
                      :filter="filter"
                      :current-page="currentPage"
                      :per-page="perPage"
                      @filtered="onFiltered"

                  >
                    <template v-slot:cell(customer)="{item}">
                      {{item.customer.customer_name + ' '+ item.customer.customer_surname}}
                    </template>


                    <template v-slot:cell(status)="{item}">
                      <b-badge v-if="['0', 0].includes(item.status)" class="btn btn-primary">{{item.order_status[0].status_text}}</b-badge>
                      <b-badge v-if="['1', 1].includes(item.status)" class="btn btn-primary">{{item.order_status[0].status_text}}</b-badge>
                      <b-badge v-if="['2', 2].includes(item.status)" class="btn btn-primary">{{item.order_status[0].status_text}}</b-badge>
                      <b-badge v-if="['3', 3].includes(item.status)" class="btn btn-primary">{{item.order_status[0].status_text}}</b-badge>
                      <b-badge v-if="['4', 4].includes(item.status)" class="btn btn-primary">{{item.order_status[0].status_text}}</b-badge>
                    </template>

                    <template v-slot:cell(fromWarehouse_type)="{item}">
                      <div v-if="item.fromWarehouse_type === 1">
                        <b-badge class="btn btn-success">Main</b-badge>
                      </div>
                      <div v-else>
                        <b-badge class="btn btn-warning">Sub</b-badge>
                      </div>

                    </template>

                    <template v-slot:cell(paymentStatus)="{item}">
                      <div v-if="item.payment_status === 1">
                        <b-badge class="btn btn-success">Paid</b-badge>
                      </div>
                      <div v-else>
                        <b-badge class="btn btn-danger">Not Paid</b-badge>
                      </div>
                    </template>
                    <template v-slot:cell(advancePaymentStatus)="{item}">
                      <div v-if="item.advance_payment_status === 1">
                        <b-badge class="btn btn-success">Paid</b-badge>
                      </div>
                      <div v-else>
                        <b-badge class="btn btn-danger">Not Paid</b-badge>
                      </div>
                    </template>

                    <template v-slot:cell(actions)="{item}">
                      <b-dropdown>
                        <template v-slot:button-content>
                          Dropdown button
                          <i class="mdi mdi-chevron-down"></i>
                        </template>
                        <b-dropdown-item v-if="item.advance_payment_status === 0 && item.payment_status === 0" @click="addAdvancePayment(item)">Advance Payment</b-dropdown-item>
                        <b-dropdown-item v-if="item.advance_payment_status === 0 && item.payment_status === 0" @click="payment(item)">Payment</b-dropdown-item>
                        <b-dropdown-item v-if="item.advance_payment_status === 1 && item.payment_status === 0" @click="payment(item)">Payment</b-dropdown-item>


                        <b-dropdown-item v-if="item.status === 0 && item.advance_payment_status === 1 && item.payment_status === 1" @click="statusChange(item)">Prepared</b-dropdown-item>
<!--                        <b-dropdown-item v-if="item.status === 1 && item.payment_status === 1" @click="statusChange(item)">Process</b-dropdown-item>-->
<!--                        <b-dropdown-item v-if="item.status === 2 && item.payment_status === 1" @click="statusChange(item)">Road</b-dropdown-item>-->
<!--                        <b-dropdown-item v-if="item.status === 3 && item.payment_status === 1" @click="statusChange(item)">Arrived</b-dropdown-item>-->
<!--                        <b-dropdown-item v-if="item.status === 4 && item.payment_status === 1" @click="statusChange(item)">Delivered</b-dropdown-item>-->
<!--                        <b-dropdown-item v-if="item.status === 5" @click="statusChange(item)">Return Request</b-dropdown-item>-->
<!--                        <b-dropdown-item v-if="item.status === 6" @click="statusChange(item)">Return Prepared</b-dropdown-item>-->
<!--                        <b-dropdown-item v-if="item.status === 7" @click="statusChange(item)">Return Process</b-dropdown-item>-->
<!--                        <b-dropdown-item v-if="item.status === 8" @click="statusChange(item)">Return Road</b-dropdown-item>-->
<!--                        <b-dropdown-item v-if="item.status === 9" @click="statusChange(item)">Return Arrived</b-dropdown-item>-->
<!--                        <b-dropdown-item v-if="item.status === 10" @click="statusChange(item)">Return Delivered</b-dropdown-item>-->
                        <b-dropdown-item v-if="item.status === 0 || item.status === 1 || item.status === 2" @click="statusChange(item,'cancel')">Cancel</b-dropdown-item>
                        <b-dropdown-item>
                          <router-link :to="{ name: 'admin-order-show',params:{id:item.id}}" class="btn btn-sm btn-dark">Show</router-link>
                        </b-dropdown-item>
                        <b-dropdown-item>
                          <router-link :to="{ name: 'admin-order-process-show',params:{id:item.id}}" class="btn btn-sm btn-warning">Process</router-link>
                        </b-dropdown-item>
                        <b-dropdown-item>
                          <router-link :to="{ name: 'admin-order-invoice-show',params:{id:item.id}}" class="btn btn-sm btn-success">Invoice</router-link>
                        </b-dropdown-item>
                      </b-dropdown>
                      <!--                      <a v-if="item.status === 0" @click="statusChange(item)" style="color: white;" class="btn btn-sm btn-secondary">Prepared</a>-->
                      <!--                      <a v-if="item.status === 1" @click="statusChange(item)" style="color: white;" class="btn btn-sm btn-dark">Process</a>-->
                      <!--                      <a v-if="item.status === 2" @click="statusChange(item)" style="color: white;" class="btn btn-sm btn-primary">Road</a>-->
                      <!--                      <a v-if="item.status === 3" @click="statusChange(item)" style="color: white;" class="btn btn-sm btn-warning">Arrived</a>-->
                      <!--                      <a v-if="item.status === 4" @click="statusChange(item)" style="color: white;" class="btn btn-sm btn-success">Delivered</a>-->
                      <!--                      <a v-if="item.status === 5" @click="statusChange(item)" style="color: white;" class="btn btn-sm btn-danger">Return Request</a>-->
                      <!--                      <a v-if="item.status === 6" @click="statusChange(item)" style="color: white;" class="btn btn-sm btn-danger">Return Prepared</a>-->
                      <!--                      <a v-if="item.status === 7" @click="statusChange(item)" style="color: white;" class="btn btn-sm btn-danger">Return Process</a>-->
                      <!--                      <a v-if="item.status === 8" @click="statusChange(item)" style="color: white;" class="btn btn-sm btn-danger">Return Road</a>-->
                      <!--                      <a v-if="item.status === 9" @click="statusChange(item)" style="color: white;" class="btn btn-sm btn-danger">Return Arrived</a>-->
                      <!--                      <a v-if="item.status === 10" @click="statusChange(item)" style="color: white;" class="btn btn-sm btn-danger">Return Delivered</a>-->
                      <!--                      <a v-if="item.status === 0 || item.status === 1 || item.status === 2" @click="statusChange(item,'cancel')" style="color: white;" class="btn btn-sm btn-danger">Cancel</a>-->
                      <!--                      <router-link :to="{ name: 'admin-stock-transfer-show',params:{id:item.id}}" class="btn btn-sm btn-dark">Show</router-link>-->
                      <!--                      <router-link :to="{ name: 'admin-stock-transfer-invoice',params:{id:item.id}}" class="btn btn-sm btn-success">Invoice</router-link>-->

                    </template>

                  </b-table>
                </div>

                <b-col md="6" class="my-1 p-0">
                  <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                      class="my-0"
                  ></b-pagination>
                </b-col>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>

import Layout from "../../router/layouts/main.vue";
import PageHeader from "@/components/page-header";
import {Money} from 'v-money'
// import axios from "axios";



export default {
  components:{
    // eslint-disable-next-line vue/no-unused-components
    Layout,PageHeader,Money
  },
  created(){
    this.getOrderList()
    // eslint-disable-next-line no-undef
    if(!User.loggedIn()){
      this.$router.push({name: '/admin/login'})
    }
    // eslint-disable-next-line no-undef
    Reload.$on('AfterStatus',() => {
      this.getOrderList()
    });


  },
  data(){
    return{
      money: {
        decimal: ',',
        thousands: '.',
        // prefix: 'R$ ',
        // suffix: ' #',
        precision: 2,
        masked: true /* doesn't work with directive */
      },
      formPayment:{
        advance_payment: null,
        orderId: null,
      },
      serviceType:[
        {
          id:'0',
          text:'Purchase Services'
        },
        {
          id:'1',
          text:'Delivery Services'
        }
      ],
      shippingCompanies:[],
      editModalDisplay:false,
      tablefields: [
        { key: 'code', label: 'Code', sortable: true, },
        { key: 'fromWarehouse_name', label: 'From Warehouse', sortable: true, },
        // { key: 'fromWarehouse_type', label: 'From Warehouse Type', sortable: true, },
        { key: 'customer', label: 'Customer', sortable: true, },
        { key: 'total', label: 'Total', sortable: true, },
        { key: 'total_product', label: 'Total Product', sortable: true, },
        { key: 'total_quantity', label: 'Total Quantity', sortable: true, },
        { key: 'total_process', label: 'Total Process', sortable: true, },
        { key: 'paymentStatus', label: 'Payment Status', sortable: true, },
        { key: 'payment_method', label: 'Payment Method', sortable: true, },
        { key: 'advancePaymentStatus', label: 'Advance Payment Status', sortable: true, },
        { key: 'advance_payment', label: 'Advance Payment', sortable: true, },
        // { key: 'total_weight', label: 'Total Weight', sortable: true, },
        // { key: 'pallet_name', label: 'Pallet Name', sortable: true, },
        // { key: 'pallet_count', label: 'Pallet Count', sortable: true, },
        // { key: 'start_transfer_date', label: 'Start Date', sortable: true, },
        // { key: 'finish_transfer_date', label: 'End Date', sortable: true, },
        { key: 'status', label: 'status', sortable: true, },

        { key: 'actions', label: 'Actions', sortable: true, },
      ],
      items: [

      ],
      id:null,
      title: "Form Elements",
      item: [
        {
          text: "Veltrix",
          href: "/"
        },
        {
          text: "Forms",
          href: "/"
        },
        {
          text: "Form Elements",
          active: true
        },


      ],
      categories:[],
      buttons: [
        {
          text: "New Category",
          href: "/",
          active: true
        },



      ],
      searchTerm:'',
      filter: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15],
    }
  },
  mounted(){
    this.totalRows = this.items.length;

  },
  computed:{
    sortOptions() {
      // Create an options list from our fields
      return this.tablefields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key };
          });
    },
    rows(){
      return this.items.length;
    },

  },
  methods:{
    closeModal(){
      this.displayModal = false;
      this.editModalDisplay =false;
    },
    addAdvancePayment(item){
      this.formPayment.orderId = item.id;
      this.editModalDisplay = true;

    },
    payment(item){
      this.$http.get('order/payment',{params:{id:item.id}})
          .then(() => {
            this.$toasted.global.success();
            this.getOrderList();
          })
          .catch(error=>{
            console.log(error);
            this.$toasted.global.warning();
          })
    },
    addPayment(){
      this.$http.get('order/advance/payment',{params:{advance_payment:this.formPayment.advance_payment, id:this.formPayment.orderId}})
          .then(() => {
            this.editModalDisplay = false;
            this.$toasted.global.success();
            this.formPayment.advance_payment =null;
            this.formPayment.orderId =null;
            this.getOrderList();
          })
          .catch(error=>{
            console.log(error);
            this.$toasted.global.warning();
          })
    },
    statusChange(item,data=null){
      if (data == null){
        this.$http.post('order/status/'+item.id+'/change/'+item.status,{item:data})
            .then(({data}) => {
              // eslint-disable-next-line no-undef
              Reload.$emit('AfterStatus');

              // eslint-disable-next-line no-console
              console.log(data);}).catch()
      }else{
        // eslint-disable-next-line no-undef
        Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.value) {
            this.$http.post('order/status/'+item.id+'/change/'+item.status,{item:data})
                .then(({data}) => {
                  // eslint-disable-next-line no-undef
                  Reload.$emit('AfterStatus');
                  this.getStockTransferList();
                  // eslint-disable-next-line no-console
                  console.log(data);}).catch()
            // eslint-disable-next-line no-undef
            Swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
            )
          }
        })
      }
      this.$http.post('order/status/'+item.id+'/change/'+item.status,{item:data})
          .then(({data}) => {
            // eslint-disable-next-line no-undef
            Reload.$emit('AfterStatus');

            // eslint-disable-next-line no-console
            console.log(data);}).catch()
    },
    getOrderList(){
      this.$http.get('order/',{params:{status:0}})
          .then(({data}) => {this.items = data;
            // eslint-disable-next-line no-console
            console.log(data);}).catch()
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  // created(){
  //     this.allEmployee();
  // }

}
</script>

<style scoped>
#product_image{
  width: 50px;
  height: 50px;
}
</style>
>>>>>>> Stashed changes
