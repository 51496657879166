<template>
  <Layout>
    <br>
    <div class="row">
      <div class="col-md-12">
        <!-- Right Sidebar -->
        <div class="card">
          <div class="card-body">
            <form @submit.prevent="sendPrivate">
              <div class="mb-3">
                <label for="customer-select">Customer:</label>
                <select id="customer-select" class="form-control" v-model="form.customer_id" @change="selectedCustomer($event)">
                  <option :key="customer.id" :value="customer.id" v-for="customer in customers">{{ customer.customer_name }} {{ customer.customer_surname}}</option>
                </select>
              </div>
              <div class="mb-3">
                <label for="to">To:</label>
                <input type="email" :readonly="true" class="form-control" placeholder="To" id="to" v-model="form.to"/>
              </div>

              <div class="mb-3">
                <label for="subject">Subject:</label>
                <input
                    type="text"
                    class="form-control"
                    placeholder="Subject"
                    id="subject"
                    v-model="form.subject"
                />
              </div>
              <div class="mb-3">
                <!-- Editor -->
                <label for="message">Content:</label>
                <ckeditor v-model="form.message" :editor="editor" id="message"></ckeditor>
              </div>
              <div class="mb-3">
                <label for="files">Files:</label>
                <input type="file" multiple class="form-control" id="files">
              </div>
              <div class="btn-toolbar form-group mb-0">
                <div class="col-xl-12">
                  <button class="btn btn-success col-xl-12">
                    <span>Send</span>
                    <i class="fab fa-telegram-plane ms-1"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <!-- end Right Sidebar -->
      </div>
    </div>
    <!-- End row -->
  </Layout>
</template>
<script>
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Layout from "../../router/layouts/main.vue";
import appConfig from "@/app.config";

/**
 * Email Compose component
 */
export default {
  page: {
    title: "Email Compose",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { ckeditor: CKEditor.component, Layout },
  created() {
    // eslint-disable-next-line no-undef
    if(!User.loggedIn()){
      this.$router.push({name: 'admin-login'})
    }
    this.getCustomers();
  },
  data() {
    return {
      customers:[],
      form:{
        customer_id: null,
        to: null,
        subject: null,
        message: null,
        files: null,
      },
      editor: ClassicEditor,
      errors:{},
    };
  },
  methods:{
    getCustomers: async function (){
      await this.$http.get('customer').then((response)=>{
        this.customers = response.data;
      }).catch((error)=>{
        console.log(error)
      });
    },
    selectedCustomer(event){
      this.customers.map(e => {
        if (e.id == parseInt(event.target.value)){
          this.form.to = e.customer_email;
        }
      })
    },
    sendPrivate(){
      this.$http.post('send/private/',this.form).then(() => {
        window.location.reload();
      }).catch(error=>{
        console.log(error);
      })
    },
  },
};
</script>
