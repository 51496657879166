<template>
  <Layout>
    <PageHeader :items="item" :title="title"></PageHeader>
    <div>
      <div class="col-sm-6 col-md-3 mt-4">
        <div class="text-center">
          <b-modal id="edit-modal" v-model="displayModal" title="Edit Size" hide title-class="font-18">
            <div class="form-group">
              <label for="edit-size-width">Width</label>
              <money style="width: 100%" v-model.lazy="editForm.size_width" v-bind="money" class="form-control"
                     :class="{'is-invalid': typeSubmit && $v.editForm.size_width.$error,}" id="edit-size-width"></money>
<!--              <b-form-input id="edit-size-width" @change="editChangeSize()" v-model="editForm.size_width" placeholder="Width"-->
<!--                            :class="{'is-invalid': typeSubmit && $v.editForm.size_width.$error,}" ></b-form-input>-->
              <div
                  v-if="typeSubmit && $v.editForm.size_width.$error"
                  class="invalid-feedback"
              >
              <span v-if="!$v.editForm.size_width.required"
              >This value is required.</span
              >
              </div>
            </div>
            <br>
            <div class="form-group">
              <label for="edit-size-length">Length</label>
              <money style="width: 100%" v-model.lazy="editForm.size_length" v-bind="money" class="form-control"
                     :class="{'is-invalid': typeSubmit && $v.editForm.size_length.$error,}" id="edit-size-length"></money>
<!--              <b-form-input id="edit-size-length" @change="editChangeSize()" v-model="editForm.size_length" placeholder="Length"-->
<!--                            :class="{'is-invalid': typeSubmit && $v.editForm.size_length.$error,}"></b-form-input>-->
              <div
                  v-if="typeSubmit && $v.editForm.size_length.$error"
                  class="invalid-feedback"
              >
              <span v-if="!$v.editForm.size_length.required"
              >This value is required.</span
              >
              </div>
            </div>
            <br>
            <div class="form-group">
              <label for="edit-size-height">Height</label>
              <money style="width: 100%" v-model.lazy="editForm.size_height" v-bind="money" class="form-control"
                     :class="{'is-invalid': typeSubmit && $v.editForm.size_height.$error,}" id="edit-size-height"></money>
<!--              <b-form-input id="edit-size-height" @change="editChangeSize()" v-model="editForm.size_height" placeholder="Height"-->
<!--                            :class="{'is-invalid': typeSubmit && $v.editForm.size_height.$error,}"></b-form-input>-->
              <div
                  v-if="typeSubmit && $v.editForm.size_height.$error"
                  class="invalid-feedback"
              >
              <span v-if="!$v.editForm.size_height.required"
              >This value is required.</span
              >
              </div>
            </div>
            <br>
<!--            <div class="form-group">-->
<!--              <label for="edit-size">Size</label>-->
<!--              <b-form-input id="edit-size" :readonly="true" v-model="editForm.size_name" placeholder="Edit Size"-->
<!--                            ></b-form-input>-->

<!--            </div>-->
            <template #modal-footer>
              <button v-b-modal.modal-close_visit data-dismiss="modal" @click="closeModal" class="btn btn-danger btn-sm m-1">Close</button>
              <button v-b-modal.modal-close_visit class="btn btn-success btn-sm m-1" @click="editSize(id)" >Save</button>
            </template>
          </b-modal>
        </div>

          <div class="text-center" v-on:keyup.enter="onEnter">
          <b-modal id="modal-standard" v-model="editModalDisplay" title="Add Size" hide title-class="font-18">
            <div class="form-group">
              <label for="add-size-width">Width</label>
              <money style="width: 100%" v-model.lazy="form.size_width" v-bind="money" class="form-control"
                     :class="{'is-invalid': typeSubmit && $v.form.size_width.$error,}" id="add-size-width"></money>
<!--              <b-form-input id="add-size-width" @change="changeSize()" v-model="form.size_width" placeholder="Width"-->
<!--                            :class="{'is-invalid': typeSubmit && $v.form.size_width.$error,}"></b-form-input>-->
              <div
                  v-if="typeSubmit && $v.form.size_width.$error"
                  class="invalid-feedback"
              >
              <span v-if="!$v.form.size_width.required"
              >This value is required.</span
              >
              </div>
            </div>
            <br>
            <div class="form-group">
              <label for="add-size-length">Length</label>
              <money style="width: 100%" v-model.lazy="form.size_length" v-bind="money" class="form-control"
                     :class="{'is-invalid': typeSubmit && $v.form.size_length.$error,}" id="add-size-length"></money>
<!--              <b-form-input id="add-size-length" @change="changeSize()" v-model="form.size_length" placeholder="Length"-->
<!--                            :class="{'is-invalid': typeSubmit && $v.form.size_length.$error,}"></b-form-input>-->
              <div
                  v-if="typeSubmit && $v.form.size_length.$error"
                  class="invalid-feedback"
              >
              <span v-if="!$v.form.size_length.required"
              >This value is required.</span
              >
              </div>
            </div>
            <br>
            <div class="form-group">
              <label for="add-size-height">Height</label>
              <money style="width: 100%" v-model.lazy="form.size_height" v-bind="money" class="form-control"
                     :class="{'is-invalid': typeSubmit && $v.form.size_height.$error,}" id="add-size-height"></money>
<!--              <b-form-input id="add-size-height" @change="changeSize()" v-model="form.size_height" placeholder="Height"-->
<!--                            :class="{'is-invalid': typeSubmit && $v.form.size_height.$error,}"></b-form-input>-->
              <div
                  v-if="typeSubmit && $v.form.size_height.$error"
                  class="invalid-feedback"
              >
              <span v-if="!$v.form.size_height.required"
              >This value is required.</span
              >
              </div>
            </div>
            <br>
<!--            <div class="form-group">-->
<!--              <label for="add-size">Size</label>-->
<!--              <b-form-input id="add-size" :readonly="true" v-model="form.size_name" placeholder="Add Size"></b-form-input>-->
<!--            </div>-->
            <template  #modal-footer>
              <button  data-dismiss="modal" @click="closeModal" class="btn btn-danger btn-sm m-1">Close</button>
              <b-button  for="edit-modal" class="btn btn-success btn-sm m-1" @click="addSize" >Save</b-button>
            </template>
          </b-modal>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row">


          <div class="col-md-12">

            <div class="card">

              <div class="card-body">
                <div style="margin-left: 575px" class="col-sm-4">
                  <div class="float-end d-none d-md-block">
                    <b-button style="float: right"  v-b-modal.modal-standard variant="primary">New Size</b-button>
                  </div>
                </div>
                <b-row>

                  <b-col xl="6">

                    <b-input-group class="datatable-btn">
                      <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                      <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                      </b-input-group-append>

                    </b-input-group>

                  </b-col>

                  <b-col xl="6">
                    <b-form-group  label-cols="2" label="Per page" class="mb-4 datatable-select">
                      <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                    </b-form-group>

                  </b-col>
                </b-row>

                <div class="table-responsive datatable-vue">
                  <b-table
                      show-empty
                      stacked="md"
                      :items="items"
                      :fields="tablefields"
                      :filter="filter"
                      :current-page="currentPage"
                      :per-page="perPage"
                      @filtered="onFiltered"

                  >

                    <template v-slot:cell(actions)="{item}">
                      <b-button  v-b-modal.edit-modal @click="editCategoryModal(item)" class="btn btn-sm btn-dark">Edit</b-button>
                      <a  @click="deleteSize(item.id)" v-role="['ROLE_ROOT']" style="color: white;" class="btn btn-sm btn-danger">Delete</a>
                    </template>

                  </b-table>
                </div>

                <b-col md="6" class="my-1 p-0">
                  <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                      class="my-0"
                  ></b-pagination>
                </b-col>


              </div>

            </div>
          </div>

        </div>
      </div>


      <!-- begin row -->
      <!-- end row -->
      <!-- begin row -->


      <!-- end row -->
    </div>
  </Layout>



</template>


<script>

import Layout from "../../router/layouts/main.vue";
import PageHeader from "@/components/page-header";
import {required} from "vuelidate/lib/validators";
import {Money} from "v-money";


export default {
  components:{
    // eslint-disable-next-line vue/no-unused-components
    Layout,PageHeader,Money
  },
  created(){
    this.getSizeList();
    // eslint-disable-next-line no-undef
    if(!User.loggedIn()){
      this.$router.push({name: '/admin/login'})
    }
  },
  data(){
    return{
      money: {
        decimal: '.',
        thousands: ',',
        // prefix: 'R$ ',
        // suffix: ' #',
        precision: 2,
        masked: true /* doesn't work with directive */
      },
      typeSubmit:false,
      errors:{},
      editModalDisplay:false,
      tablefields: [
        { key: 'size_name', label: 'Size Name', sortable: true, },
        { key: 'actions', label: 'Actions', sortable: true, },
      ],
      items: [

      ],
      editForm:{
        // size_name:null,
        size_width:null,
        size_length:null,
        size_height:null,
      },
      displayModal:false,
      form:{
        // size_name:null,
        size_width:null,
        size_length:null,
        size_height:null,
      },
      id:null,
      title: "Form Elements",
      item: [
        {
          text: "Veltrix",
          href: "/"
        },
        {
          text: "Forms",
          href: "/"
        },
        {
          text: "Form Elements",
          active: true
        },


      ],
      categories:[],
      buttons: [
        {
          text: "New Category",
          href: "/",
          active: true
        },
      ],
      searchTerm:'',
      filter: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15],
    }
  },
  validations(){
    return{
      form:{
        size_width:{required},
        size_length:{required},
        size_height:{required},
      },
      editForm:{
        size_width:{required},
        size_length:{required},
        size_height:{required},
      },
    }
  },
  mounted(){
    this.totalRows = this.items.length;

  },
  computed:{
    sortOptions() {
      // Create an options list from our fields
      return this.tablefields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key };
          });
    },
    rows(){
      return this.items.length;
    },

  },
  methods:{
    // changeSize(){
    //   if (this.form.size_width && this.form.size_length && this.form.size_height){
    //     this.form.size_name = this.form.size_width + 'x' + this.form.size_length + 'x' + this.form.size_height;
    //   }else{
    //     this.form.size_name = null;
    //   }
    // },
    // editChangeSize(){
    //   if (this.editForm.size_width && this.editForm.size_length && this.editForm.size_height){
    //     this.editForm.size_name = this.editForm.size_width + 'x' + this.editForm.size_length + 'x' + this.editForm.size_height;
    //   }else{
    //     this.editForm.size_name = null;
    //   }
    // },
    editCategoryModal(item){
      this.id = item.id;
      this.editForm.size_name = item.size_name;
      let splitSize = item.size_name.split(' X ');
      this.editForm.size_width = splitSize[0];
      this.editForm.size_length = splitSize[1];
      this.editForm.size_height = splitSize[2];

    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    closeModal(){
      this.displayModal = false;
      this.editModalDisplay =false;
    },
    addSize(){
      this.typeSubmit = true;
      this.$v.$touch()
      if(this.$v.form.$invalid){
        return;
      }else{
        this.$http.post('size',this.form)
            .then((data) => {
              // eslint-disable-next-line no-console
              console.log(data)
              this.editModalDisplay = false;
              this.getSizeList();
              this.form.size_name = null;
              this.form.size_width = null;
              this.form.size_length = null;
              this.form.size_height = null;
            })
            .catch()
      }


    },
    getSizeList(){
      this.$http.get('size')
          .then((res) => {
            this.items = res.data;
            // eslint-disable-next-line no-console
            console.log(this.items)
          })
          .catch()
    },
    editSize(id){
      this.typeSubmit = true;
      this.$v.$touch();
      if(this.$v.editForm.$invalid){
        return;
      }else{
        // eslint-disable-next-line no-console
        this.$http.patch('size/'+id,this.editForm)
            .then((res) => {
              this.items = res.data;
              this.typeSubmit = false;
              this.getSizeList();
              this.displayModal = false;
            })
            .catch()
      }


    },
    deleteSize(id){
      // eslint-disable-next-line no-undef
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          this.$http.delete('size/'+id)
              .then(() => {
                this.getSizeList();
                this.categories = this.categories.filter(role => {
                  return role.id != id
                })
              })
              .catch(() => {
              })
          // eslint-disable-next-line no-undef
          Swal.fire(
              'Deleted!',
              'Your data has been deleted.',
              'success'
          )
        }
      })

    }
  },
  // created(){
  //     this.allEmployee();
  // }

}
</script>

<style scoped>

</style>
