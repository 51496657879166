<template>
  <Layout>
    <div>

      <div class="col-xl-12">
        <div class="card card-statistics">
          <div class="card-body">
            <form class="row g-3" @submit.prevent="adminInsert" enctype="multipart/form-data">
              <div class="col-md-6">
                <label for="adminName" class="form-label">Name</label>
                <input type="text" v-model="form.name" class="form-control" id="adminName" :class="{
                    'is-invalid': typeSubmit && $v.form.name.$error,
                  }">
                <div
                    v-if="typeSubmit && $v.form.name.$error"
                    class="invalid-feedback"
                >
                  <span v-if="!$v.form.name.required"
                  >This value is required.</span
                  >
                </div>
              </div>
              <div class="col-md-6">
                <label for="adminSurname" class="form-label">Surname</label>
                <input type="text" v-model="form.surname" class="form-control" id="adminSurname" :class="{
                    'is-invalid': typeSubmit && $v.form.surname.$error,
                  }">
                <div
                    v-if="typeSubmit && $v.form.surname.$error"
                    class="invalid-feedback"
                >
                  <span v-if="!$v.form.surname.required"
                  >This value is required.</span
                  >
                </div>
              </div>
              <div class="col-md-6">
                <label for="adminEmail" class="form-label">Email</label>
                <input type="email" v-model="form.email" class="form-control" id="adminEmail" :class="{
                    'is-invalid': typeSubmit && $v.form.email.$error,
                  }">
                <div
                    v-if="typeSubmit && $v.form.email.$error"
                    class="invalid-feedback"
                >
                  <span v-if="!$v.form.email.required"
                  >This value is required.</span
                  >
                </div>
              </div>
              <div class="col-md-6">
                <label for="adminPassword" class="form-label">Password</label>
                <input type="password" v-model="form.password" class="form-control" id="adminPassword" :class="{
                    'is-invalid': typeSubmit && $v.form.password.$error,
                  }">
                <div
                    v-if="typeSubmit && $v.form.password.$error"
                    class="invalid-feedback"
                >
                  <span v-if="!$v.form.password.required"
                  >This value is required.</span
                  >
                </div>
              </div>
              <div class="col-md-4">
                <label for="adminCountry" class="form-label">Country</label>
                <select @change="switchCountrySelect($event)" id="adminCountry" class="form-control" v-model="form.country_id"
                        :class="{'is-invalid': typeSubmit && $v.form.country_id.$error}">
                  <option :key="ct.id" :value="ct.id" v-for="ct in country">{{ ct.country }}</option>
                </select>
                <div
                    v-if="typeSubmit && $v.form.country_id.$error"
                    class="invalid-feedback"
                >
                  <span v-if="!$v.form.country_id.required"
                  >This value is required.</span
                  >
                </div>
<!--                <input type="text" v-model="form.country" class="form-control" id="adminCountry" :class="{-->
<!--                    'is-invalid': typeSubmit && $v.form.country.$error,-->
<!--                  }">-->
<!--                <div-->
<!--                    v-if="typeSubmit && $v.form.country.$error"-->
<!--                    class="invalid-feedback"-->
<!--                >-->
<!--                  <span v-if="!$v.form.country.required"-->
<!--                  >This value is required.</span-->
<!--                  >-->
<!--                </div>-->
              </div>
              <div class="col-md-4">
                <label for="adminCity" class="form-label">City</label>
                <select id="adminCity" class="form-control" v-model="form.city_id"
                        :class="{'is-invalid': typeSubmit && $v.form.city_id.$error,}">
                  <option :key="c.id" :value="c.id" v-for="c in city">{{ c.city }}</option>
                </select>
                <div
                    v-if="typeSubmit && $v.form.city_id.$error"
                    class="invalid-feedback"
                >
                  <span v-if="!$v.form.city_id.required"
                  >This value is required.</span
                  >
                </div>
<!--                <input type="text" v-model="form.city" class="form-control" id="adminCity" :class="{-->
<!--                    'is-invalid': typeSubmit && $v.form.city.$error,-->
<!--                  }">-->
<!--                <div-->
<!--                    v-if="typeSubmit && $v.form.city.$error"-->
<!--                    class="invalid-feedback"-->
<!--                >-->
<!--                  <span v-if="!$v.form.city.required"-->
<!--                  >This value is required.</span-->
<!--                  >-->
<!--                </div>-->
              </div>
              <div class="col-md-4">
                <label for="adminPhone" class="form-label">Phone</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span v-if="form.phoneDialCode != null" class="input-group-text" id="basic-addon1">{{ form.phoneDialCode }}</span>
                  </div>
                  <input type="text" v-mask="form.phoneFormat" v-model="form.phone" class="form-control" id="adminPhone"
                         :class="{'is-invalid': typeSubmit && $v.form.phone.$error,}">
                  <div
                      v-if="typeSubmit && $v.form.phone.$error"
                      class="invalid-feedback"
                  >
                  <span v-if="!$v.form.phone.required"
                  >This value is required.</span
                  >
                  </div>
                </div>
<!--                <input type="text" v-model="form.phone" class="form-control" id="adminPhone" :class="{-->
<!--                    'is-invalid': typeSubmit && $v.form.phone.$error,-->
<!--                  }">-->
<!--                <div-->
<!--                    v-if="typeSubmit && $v.form.phone.$error"-->
<!--                    class="invalid-feedback"-->
<!--                >-->
<!--                  <span v-if="!$v.form.phone.required"-->
<!--                  >This value is required.</span-->
<!--                  >-->
<!--                </div>-->
              </div>
              <div class="col-md-3">
                <label for="adminDistrict" class="form-label">District</label>
                <input type="text" v-model="form.district" class="form-control" id="adminDistrict" :class="{
                    'is-invalid': typeSubmit && $v.form.district.$error,
                  }">
                <div
                    v-if="typeSubmit && $v.form.district.$error"
                    class="invalid-feedback"
                >
                  <span v-if="!$v.form.district.required"
                  >This value is required.</span
                  >
                </div>
              </div>
              <div class="col-md-3">
                <label for="adminGender" class="form-label">Gender</label>
                <select id="adminGender" class="form-select" v-model="form.gender" :class="{
                    'is-invalid': typeSubmit && $v.form.gender.$error,
                  }">
                  <option selected="">Choose...</option>
                  <option :key="0" :value="0">Mr</option>
                  <option :key="1" :value="1">Mrs</option>
                </select>
                <div
                    v-if="typeSubmit && $v.form.gender.$error"
                    class="invalid-feedback"
                >
                  <span v-if="!$v.form.gender.required"
                  >This value is required.</span
                  >
                </div>
              </div>
              <div class="col-md-3">
                <label for="adminRole" class="form-label">Role</label>
                <select id="adminRole" class="form-select" @change="changeRole" v-model="form.role" :class="{
                    'is-invalid': typeSubmit && $v.form.role.$error,
                  }">
                  <option selected="">Choose...</option>
                  <option :key="role.id" :value="role.id" v-for="role in roles">{{ role.role }}</option>
                </select>
                <div
                    v-if="typeSubmit && $v.form.role.$error"
                    class="invalid-feedback"
                >
                  <span v-if="!$v.form.role.required"
                  >This value is required.</span
                  >
                </div>
              </div>
              <div class="col-md-3" v-show="showWarehouseList">
                <label for="adminWarehouse" class="form-label">Warehouse</label>
                <select id="adminWarehouse" class="form-select" v-model="form.warehouse">
                  <option selected="">Choose...</option>
                  <option :key="warehouse.id" :value="warehouse.id" v-for="warehouse in warehouses">{{ warehouse.warehouse_name }}</option>
                </select>
              </div>
              <div class="col-12">
                <label for="adminAddress" class="form-label">Address</label>
                <ckeditor :editor="editor" v-model="form.address" class="form-control" id="adminAddress" :class="{
                    'is-invalid': typeSubmit && $v.form.address.$error,
                  }"></ckeditor>
                <div
                    v-if="typeSubmit && $v.form.address.$error"
                    class="invalid-feedback"
                >
                  <span v-if="!$v.form.address.required"
                  >This value is required.</span
                  >
                </div>
              </div>
              <label>Admin Image</label>
              <div class="col-md-8 input-group">
                <input type="file" @change="onFileSelected" class="form-control" id="inputGroupFile02" :class="{
                    'is-invalid': typeSubmit && $v.form.photo.$error,
                  }">
                <label class="input-group-text" for="inputGroupFile02">Upload</label>
                <div
                    v-if="typeSubmit && $v.form.photo.$error"
                    class="invalid-feedback"
                >
                  <span v-if="!$v.form.photo.required"
                  >This value is required.</span
                  >
                </div>
              </div>
              <div class="col-md-4">
                <img :src="form.photo" id="admin_image">
              </div>
              <div class="col-12">
                <button type="submit" class="btn btn-primary col-xl-12">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>

<script>

import Layout from "../../router/layouts/main.vue";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  required,email
} from "vuelidate/lib/validators";

export default {
  components:{
    // eslint-disable-next-line vue/no-unused-components
    ckeditor: CKEditor.component,
    Layout,
  },
  name:'admin-create',
  created(){
    // eslint-disable-next-line no-undef
    if(!User.loggedIn()){
      this.$router.push({name: 'admin-login'})
    }
    this.$http.get('role/')
        .then(({data}) => (this.roles = data))
    this.$http.get('warehouse/')
        .then(({data}) => (this.warehouses = data))
    this.$http.get('country/')
        .then(({data}) => (this.country = data))
  },
  validations(){
    return{
      form:{
        name: {required},
        surname: {required},
        phone: {required},
        email: {required,email},
        password: {required},
        // country: {required},
        // city: {required},
        country_id: {required},
        city_id: {required},
        district: {required},
        gender: {required},
        role: {required},
        address: {required},
        photo: {required},
      },
    }
  },
  data(){
    return {
      form:{
        name: null,
        surname: null,
        phone: null,
        email: null,
        password: null,
        // country: null,
        // city: null,
        district: null,
        gender: null,
        role: null,
        warehouse: null,
        address: null,
        photo: null,
        country_id: null,
        city_id: null,
        phoneDialCode: null,
        phoneFormat: null
      },
      errors:{},
      typeSubmit:false,
      roles:{},
      warehouses:{},
      editor: ClassicEditor,
      showWarehouseList: false,
      country:[],
      city:[],
    }
  },

  methods:{
    changeRole(event){
      if (event.target.selectedOptions[0].innerText === 'ROLE_WAREHOUSE'){
        this.showWarehouseList = true
      }else{
        this.showWarehouseList = false
      }
    },
    onFileSelected(event){
      let file = event.target.files[0];
      if(file.size > 20971520) {
        this.$toasted.global.image_validation()
      } else {
        let reader = new FileReader();
        reader.onload = event => {
          this.form.photo = event.target.result
          // eslint-disable-next-line no-console
          // console.log(event.target.result);
        };
        reader.readAsDataURL(file);
      }
    },
    adminInsert(){
      this.typeSubmit = true;
      this.$v.$touch()

      if(this.$v.$invalid){
        return false;
      }else{
        this.$http.post('admin',this.form)
            .then(() => {
              this.$router.push({ name: 'admin-list'})
              Notification.success()
            })
            .catch(error => this.errors = error.response.data.errors)
      }
    },
    switchCountrySelect(event){
      let id = event.target.value;
      if (id != null) {
        this.$http.get('select-to-country/'+id)
            .then(({data}) => (this.city = data))
            .catch()
        this.changePhoneFormatNumber();
      } else {
        this.$toasted.global.selectCountry()
      }

    },

    changePhoneFormatNumber(){
      // eslint-disable-next-line no-unused-vars
      let format;
      let dialCode;
      this.country.map(e=>{
        if(e.id === this.form.country_id){
          format = e.country_format;
          dialCode = e.country_dial_code;
          if (this.form.phone != null){
            this.form.phone = '';
          }
        }
      })
      this.form.phoneDialCode = dialCode;
      this.form.phoneFormat = format;

    },
  }
}
</script>
<style scoped>
#admin_image{
  width: 50px;
  height: 50px;
}
</style>
