<template>
  <Layout>
    <PageHeader :items="item" :title="title"></PageHeader>
    <div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body">

                <b-form-select id="category-select-add" v-model="form.id" class="form-control"  style="margin-bottom: 20px" value-field="id" text-field="text" :options="options">Category</b-form-select>
                <b-button @click="getExport" type="submit" class="btn btn-primary col-xl-12">Export</b-button>


              </div>

            </div>
          </div>

        </div>
      </div>


      <!-- begin row -->
      <!-- end row -->
      <!-- begin row -->


      <!-- end row -->
    </div>
  </Layout>



</template>


<script>

import Layout from "../../router/layouts/main.vue";
import PageHeader from "@/components/page-header";



export default {
  components:{
    // eslint-disable-next-line vue/no-unused-components
    Layout,PageHeader
  },
  created(){
    this.getCategoryList();
    // eslint-disable-next-line no-undef
    if(!User.loggedIn()){
      this.$router.push({name: '/admin/login'})
    }
  },
  data(){
    return{
      options:[
          {
        id:'user',
        text:'User Table'
      },
        {
          id:'supplier',
          text:'Supplier Table'
        },],
      editModalDisplay:false,
      tablefields: [
        { key: 'category_name', label: 'Category Name', sortable: true, },
        { key: 'actions', label: 'Actions', sortable: true, },
      ],
      items: [

      ],
      editForm:{
        category_name:null
      },
      displayModal:false,
      form:{
        category_name:null,
        id:null,
      },
      id:null,
      title: "Form Elements",
      item: [
        {
          text: "Veltrix",
          href: "/"
        },
        {
          text: "Forms",
          href: "/"
        },
        {
          text: "Form Elements",
          active: true
        },


      ],
      categories:[],
      buttons: [
        {
          text: "New Category",
          href: "/",
          active: true
        },

      ],

      searchTerm:'',
      filter: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15],
    }
  },
  mounted(){

  },
  computed:{


  },
  methods:{
    getExport(){
      this.$http.get('user-export',{params:{excelType:this.form.id}})
          .then((res) => {this.categories = res.data
            this.$toasted.global.success();
            // eslint-disable-next-line no-console
          }).catch((error)=> {
            console.log(error);
        this.$toasted.global.error();
      });
    },







  },
  // created(){
  //     this.allEmployee();
  // }

}
</script>

<style scoped>

</style>