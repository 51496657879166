class Token{

    isValid(token){
        const payload = this.payload(token)
        if (payload){
            return payload.iss = !!(process.env.ROOT_URL+"auth/admin/login" || process.env.ROOT_URL+"auth/admin/register")
            // return payload.iss = "https://crmtr.balinsoft.com/public/"
        }
        return false
    }

    payload(token){
        const payload = token.split('.')[1]
        return this.decode(payload)
    }

    decode(payload){
        return JSON.parse(atob(payload))
    }
}

// eslint-disable-next-line no-class-assign
export default Token = new Token();

