<template>
  <Layout>
    <div>

      <div class="col-xl-12">
        <div class="card card-statistics">
          <div class="card-body">
            <router-link :to="{ name: 'admin-site-setting-language',params:{id:0}}" class="btn btn-sm btn-warning">Language</router-link>
            <br><br>
            <form class="row g-3" @submit.prevent="websiteSettingsUpdate" enctype="multipart/form-data">
              <div class="form-row">
                <div class="form-group col-md-3">
                  <label for="siteTitle">Title</label>
                  <input type="text" v-model="form.title" class="form-control" id="siteTitle" :class="{
                    'is-invalid': typeSubmit && $v.form.title.$error,
                  }">
                  <div v-if="typeSubmit && $v.form.title.$error" class="invalid-feedback">
                    <span v-if="!$v.form.title.required">This value is required.</span>
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label for="siteEmail">Email</label>
                  <input type="email" v-model="form.email" class="form-control" id="siteEmail" :class="{
                    'is-invalid': typeSubmit && $v.form.email.$error,
                  }">
                   <div v-if="typeSubmit && $v.form.email.$error" class="invalid-feedback">
                    <span v-if="!$v.form.email.required">This value is required.</span>
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label for="sitePhone">Phone</label>
                  <input type="text" v-model="form.phone" class="form-control" id="sitePhone" :class="{
                    'is-invalid': typeSubmit && $v.form.phone.$error,
                  }">
                   <div v-if="typeSubmit && $v.form.phone.$error" class="invalid-feedback">
                    <span v-if="!$v.form.phone.required">This value is required.</span>
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label for="siteFax">Fax</label>
                  <input type="text" v-model="form.fax" class="form-control" id="siteFax">
                </div>
              </div>
              <br>
              <div class="form-row">
                <div class="form-group col-md-3">
                  <label for="siteFacebook">Facebook</label>
                  <input type="text" v-model="form.facebook" class="form-control" id="siteFacebook" :class="{
                    'is-invalid': typeSubmit && $v.form.facebook.$error,
                  }">
                   <div v-if="typeSubmit && $v.form.facebook.$error" class="invalid-feedback">
                    <span v-if="!$v.form.facebook.required">This value is required.</span>
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label for="siteTwitter">Twitter</label>
                  <input type="text" v-model="form.twitter" class="form-control" id="siteTwitter">
                </div>
                <div class="form-group col-md-3">
                  <label for="siteYoutube">Youtube</label>
                  <input type="text" v-model="form.youtube" class="form-control" id="siteYoutube">
                </div>
                <div class="form-group col-md-3">
                  <label for="siteInstagram">Instagram</label>
                  <input type="text" v-model="form.instagram" class="form-control" id="siteInstagram" :class="{
                    'is-invalid': typeSubmit && $v.form.instagram.$error,
                  }">
                   <div v-if="typeSubmit && $v.form.instagram.$error" class="invalid-feedback">
                    <span v-if="!$v.form.instagram.required">This value is required.</span>
                  </div>
                </div>
              </div>
              <br>
              <div class="form-row">
                <div class="form-group col-md-3">
                  <label for="siteLinkedin">Linkedin</label>
                  <input type="text" v-model="form.linkedin" class="form-control" id="siteLinkedin">
                </div>
                <div class="form-group col-md-3">
                  <label for="siteCountry">Country</label>
                  <input type="text" v-model="form.country" class="form-control" id="siteCountry" :class="{
                    'is-invalid': typeSubmit && $v.form.country.$error,
                  }">
                   <div v-if="typeSubmit && $v.form.country.$error" class="invalid-feedback">
                    <span v-if="!$v.form.country.required">This value is required.</span>
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label for="siteCity">City</label>
                  <input type="text" v-model="form.city" class="form-control" id="siteCity" :class="{
                    'is-invalid': typeSubmit && $v.form.city.$error,
                  }">
                   <div v-if="typeSubmit && $v.form.city.$error" class="invalid-feedback">
                    <span v-if="!$v.form.city.required">This value is required.</span>
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label for="siteZipCode">Zip Code</label>
                  <input type="text" v-model="form.zipcode" class="form-control" id="siteZipCode" :class="{
                    'is-invalid': typeSubmit && $v.form.zipcode.$error,
                  }">
                   <div v-if="typeSubmit && $v.form.zipcode.$error" class="invalid-feedback">
                    <span v-if="!$v.form.zipcode.required">This value is required.</span>
                  </div>
                </div>
              </div>
              <br>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="siteDescription">Description</label>
                  <ckeditor :editor="editor" v-model="form.description" class="form-control" id="siteDescription" :class="{
                    'is-invalid': typeSubmit && $v.form.description.$error,
                  }"></ckeditor>
                   <div v-if="typeSubmit && $v.form.description.$error" class="invalid-feedback">
                    <span v-if="!$v.form.description.required">This value is required.</span>
                  </div>
                </div>
                <div class="form-group col-md-6">
                  <label for="siteAddress">Address</label>
                  <ckeditor :editor="editor" v-model="form.address" class="form-control" id="siteAddress" :class="{
                    'is-invalid': typeSubmit && $v.form.address.$error,
                  }"></ckeditor>
                   <div v-if="typeSubmit && $v.form.address.$error" class="invalid-feedback">
                    <span v-if="!$v.form.address.required">This value is required.</span>
                  </div>
                </div>
              </div>
              <br>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="sitePrivacy">Privacy & Policy</label>
                  <ckeditor :editor="editor" v-model="form.privacy_policy" class="form-control" id="sitePrivacy" :class="{
                    'is-invalid': typeSubmit && $v.form.privacy_policy.$error,
                  }"></ckeditor>
                  <div v-if="typeSubmit && $v.form.privacy_policy.$error" class="invalid-feedback">
                    <span v-if="!$v.form.privacy_policy.required">This value is required.</span>
                  </div>
                </div>
                <div class="form-group col-md-6">
                  <label for="siteTerms">Terms & Conditions</label>
                  <ckeditor :editor="editor" v-model="form.terms_conditions" class="form-control" id="siteTerms" :class="{
                    'is-invalid': typeSubmit && $v.form.terms_conditions.$error,
                  }"></ckeditor>
                  <div v-if="typeSubmit && $v.form.terms_conditions.$error" class="invalid-feedback">
                    <span v-if="!$v.form.terms_conditions.required">This value is required.</span>
                  </div>
                </div>
              </div>
              <br>
              <div class="form-group">
                <label for="siteCopyRight">Copy Right</label>
                <input type="text" v-model="form.copyright" class="form-control" id="siteCopyRight" :class="{
                    'is-invalid': typeSubmit && $v.form.copyright.$error,
                  }">
                <div v-if="typeSubmit && $v.form.copyright.$error" class="invalid-feedback">
                  <span v-if="!$v.form.copyright.required">This value is required.</span>
                </div>
              </div>
              <br>
              <div class="form-group">
                <label for="siteTax">Tax</label>
                <input type="number" min="0" v-model="form.tax" class="form-control" id="siteTax">
              </div>
              <br>
              <div class="form-row">
                <label>Site Logo</label>
                <div class="col-md-8 input-group">
                  <input type="file" @change="onFileSelected" class="form-control" id="inputGroupFile02">
                  <label class="input-group-text" for="inputGroupFile02">Upload</label>
                </div>
                <br><br>
                <div class="form-group col-md-2">
                  <img :src="form.website_image" id="site_logo">
                </div>
              </div>
              <br>
              <div class="form-row">
                <label>Site Icon</label>
                <div class="col-md-8 input-group">
                  <input type="file" @change="onFileSelectedIcon" class="form-control" id="inputGroupFile03">
                  <label class="input-group-text" for="inputGroupFile03">Upload</label>
                </div>
                <br><br>
                <div class="form-group col-md-2">
                  <img :src="form.website_icon_image" id="site_icon">
                </div>
              </div>
              <br>
              <button type="submit" class="btn btn-primary col-xl-12">Save</button>
            </form>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>

<script>

import Layout from "../../router/layouts/main.vue";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  required
} from "vuelidate/lib/validators";

export default {
  components:{
    // eslint-disable-next-line vue/no-unused-components
    ckeditor: CKEditor.component,
    Layout,
  },
  name:'admin-edit-site-setting',
  created(){
    // eslint-disable-next-line no-undef
    if(!User.loggedIn()){
      this.$router.push({name: 'admin-login'})
    }
    this.webSiteSettings()
    // eslint-disable-next-line no-undef
    Reload.$on('AfterStatus',() => {
      this.webSiteSettings()
    })
  },
  validations(){
    return{
      form:{
        title: {required},
        email: {required},
        phone: {required},
        description: {required},
        address: {required},
        country: {required},
        city: {required},
        zipcode: {required},
        copyright: {required},
        facebook: {required},
        instagram: {required},
        privacy_policy: {required},
        terms_conditions: {required},
      },
    }
  },
  data(){
    return {
      form:{
        title: '',
        email: '',
        phone: '',
        fax: '',
        description: '',
        address: '',
        country: '',
        city: '',
        zipcode: '',
        copyright: '',
        facebook: '',
        twitter: '',
        youtube: '',
        instagram: '',
        linkedin: '',
        privacy_policy: '',
        terms_conditions: '',
        website_image: '',
        website_icon_image: '',
        new_website_image: '',
        new_website_icon_image: '',
        tax: '',
      },
      errors:{},
      typeSubmit:false,
      editor: ClassicEditor,
    }
  },

  methods:{
    webSiteSettings(){
      let id = 0;
      this.$http.get('website-settings/'+id)
          .then(({data}) => (this.form = data))
          .catch()
    },
    onFileSelected(event){
      let file = event.target.files[0];
      if(file.size > 20971520) {
        this.$toasted.global.image_validation()
      } else {
        let reader = new FileReader();
        reader.onload = event => {
          this.form.new_website_image = event.target.result
        };
        reader.readAsDataURL(file);
      }
    },
    onFileSelectedIcon(e){
      let fileIcon = e.target.files[0];
      if(fileIcon.size > 20971520) {
        this.$toasted.global.image_validation()
      } else {
        let readerIcon = new FileReader();
        readerIcon.onload = e => {
          this.form.new_website_icon_image = e.target.result
        };
        readerIcon.readAsDataURL(fileIcon);
      }
    },
    websiteSettingsUpdate(){
      this.typeSubmit = true;
      this.$v.$touch()

      if(this.$v.$invalid){
        return false;
      }else{
        this.$http.post('website-settings/',this.form)
            .then(() => {
              this.$router.push({ name: 'admin'})
              // eslint-disable-next-line no-undef
              Reload.$emit('AfterStatus');
              this.$toasted.global.success()
            })
            .catch(error => this.errors = error.response.data.errors)
      }
    }
  }
}
</script>
<style scoped>
#site_logo{
  width: 50px;
  height: 50px;
}
#site_icon{
  width: 50px;
  height: 50px;
}
</style>
