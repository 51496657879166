<template>
  <Layout>
    <div>

      <div class="col-xl-12">
        <div class="card card-statistics">
          <div class="card-body">
            <form @submit.prevent="blogUpdate" enctype="multipart/form-data">
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="blogTitle">Blog Title</label>
                  <input type="text" v-model="form.blog_title" class="form-control" id="blogTitle"
                         :class="{'is-invalid': typeSubmit && $v.form.blog_title.$error,}">
                  <div
                      v-if="typeSubmit && $v.form.blog_title.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.form.blog_title.required"
                      >This value is required.</span
                      >
                  </div>
                </div>
<!--                <div class="form-group col-md-4">-->
<!--                  <label for="blogSeq">Blog Seq Number</label>-->
<!--                  <input type="number" min="1" v-model="form.blog_seq" class="form-control" id="blogSeq"-->
<!--                         :class="{'is-invalid': typeSubmit && $v.form.blog_seq.$error,}">-->
<!--                  <div-->
<!--                      v-if="typeSubmit && $v.form.blog_seq.$error"-->
<!--                      class="invalid-feedback"-->
<!--                  >-->
<!--                      <span v-if="!$v.form.blog_seq.required"-->
<!--                      >This value is required.</span-->
<!--                      >-->
<!--                  </div>-->
<!--                </div>-->
                <div class="form-group col-md-6">
                  <label for="blogStatus">Blog Status</label>
                  <select id="blogStatus" class="form-control" v-model="form.blog_status"
                          :class="{'is-invalid': typeSubmit && $v.form.blog_status.$error,}">
                    <option :key="1" :value="1">Active</option>
                    <option :key="0" :value="0">Passive</option>
                  </select>
                  <div
                      v-if="typeSubmit && $v.form.blog_status.$error"
                      class="invalid-feedback"
                  >
                      <span v-if="!$v.form.blog_status.required"
                      >This value is required.</span
                      >
                  </div>
                </div>
              </div>
              <br>
              <div class="form-group">
                <label for="blogDescription">Blog Description</label>
                <ckeditor :editor="editor" v-model="form.blog_description" class="form-control" id="blogDescription"
                          :class="{'is-invalid': typeSubmit && $v.form.blog_description.$error,}"></ckeditor>
                <div
                    v-if="typeSubmit && $v.form.blog_description.$error"
                    class="invalid-feedback"
                >
                      <span v-if="!$v.form.blog_description.required"
                      >This value is required.</span
                      >
                </div>
              </div>
              <br>
              <div class="form-row">
                <label>Blog Image</label>
                <div class="col-md-8 input-group">
                  <input type="file" @change="onFileSelected" class="form-control" id="inputGroupFile02">
                  <label class="input-group-text" for="inputGroupFile02">Upload</label>
                </div>
                <br><br>
                <div class="form-group col-md-2">
                  <img :src="form.blog_image" id="blog_image">
                </div>
                <div class="form-group col-md-12 mt-3">
                  <lazy-component wrapper-tag="section" @intersected="optionalDispatch">
                    <img :src="'https://crmtr.balinsoft.com/public/'+form.blog_image" id="blog_image">
                    <span slot="placeholder">Loading..</span> <!-- Optional -->
                  </lazy-component>
                </div>
              </div>
              <br>
              <button type="submit" class="btn btn-primary col-xl-12">Save</button>
            </form>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>

<script>

import Layout from "../../router/layouts/main.vue";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {required} from "vuelidate/lib/validators";
import LazyComponent from "v-lazy-component";

export default {
  components:{
    // eslint-disable-next-line vue/no-unused-components
    ckeditor: CKEditor.component,
    Layout, LazyComponent
  },
  name:'admin-edit-blog',
  created(){
    // eslint-disable-next-line no-undef
    if(!User.loggedIn()){
      this.$router.push({name: 'admin-login'})
    }
    let id = this.$route.params.id
    this.$http.get('blog/'+id)
        .then(({data}) => (this.form = data))
        .catch()
  },

  data(){
    return {
      typeSubmit:false,
      form:{
        blog_title: '',
        // blog_seq: '',
        blog_status: '',
        blog_description: '',
        blog_image: '',
        new_blog_image: '',
      },
      errors:{},
      blogs:{},
      editor: ClassicEditor,
    }
  },
  validations(){
    return{
      form:{
        blog_title:  {required},
        // blog_seq:  {required},
        blog_status:  {required},
        blog_description:  {required},
      },
    }
  },

  methods:{
    onFileSelected(event){
      let file = event.target.files[0];
      if(file.size > 20971520) {
        Notification.image_validation()
      } else {
        let reader = new FileReader();
        reader.onload = event => {
          this.form.new_blog_image = event.target.result
          // eslint-disable-next-line no-console
          // console.log(event.target.result);
        };
        reader.readAsDataURL(file);
      }
    },
    blogUpdate(){
      let id = this.$route.params.id
      this.typeSubmit = true;
      this.$v.$touch();
      if(this.$v.$invalid){
        return;
      }else{
        this.$http.patch('blog/'+id,this.form)
            .then(() => {
              this.$router.push({ name: 'admin-blog-list'})
              Notification.success()
            })
            .catch(error => this.errors = error.response.data.errors)
      }

    }
  }
}
</script>
<style scoped>
#blog_image{
  width: 50px;
  height: 50px;
}
.v-lazy-component.v-lazy-component--loading {
  filter: blur(20px);
}

.v-lazy-component.v-lazy-component--loaded {
  filter: blur(0);
  transition: filter 1s;
}

</style>
