const menuItems = [{
    id: 1,
    label: "Main",
    isTitle: true
},
    {
        id: 2,
        label: 'Dashboard',
        icon: 'ti-home',
        badge: {
            variant: "primary",
        },
        link: '/admin'
    },
    {
        id: 41111111111111,
        label: 'Email',
        icon: 'ti-home',
        role:["ROLE_ADMIN", "ROLE_ROOT"],
        subItems: [
            {
                id: 5111111111111111111,
                label: 'Private Email',
                icon: 'ti-home',
                link: '/admin/private-email',
                role:["ROLE_ADMIN", "ROLE_ROOT"]
            },
            {
                id: 611111111111111111,
                label: 'Bulk Email',
                icon: 'ti-home',
                link: '/admin/bulk-email',
                role:["ROLE_ADMIN", "ROLE_ROOT"]
            },
        ]
    },
    {
        id: 3,
        label: 'Stock Management',
        isTitle: true,
        role:["ROLE_ADMIN", "ROLE_ROOT"]
    },

    {
        id: 4,
        label: 'Stock Transfer',
        icon: 'ti-home',
        role:["ROLE_ADMIN", "ROLE_ROOT"],
        subItems: [
            {
                id: 5,
                label: 'Stock Transfer',
                icon: 'ti-home',
                link: '/admin/stock-transfer',
                role:["ROLE_ADMIN", "ROLE_ROOT"]
            },
            {
                id: 6,
                label: 'Created',
                icon: 'ti-home',
                link: '/admin/stock-transfer-list',
                role:["ROLE_ADMIN", "ROLE_ROOT"]
            },
            {
                id: 689592615615,
                label: 'Prepared',
                icon: 'ti-home',
                link: '/admin/stock-transfer-prepared',
                role:["ROLE_ADMIN", "ROLE_ROOT"]

            },
            {
                id: 6895926156152,
                label: 'Loading',
                icon: 'ti-home',
                link: '/admin/stock-transfer-loading',
                role:["ROLE_ADMIN", "ROLE_ROOT"]

            },
            {
                id: 68959261561523,
                label: 'Road',
                icon: 'ti-home',
                link: '/admin/stock-transfer-road',
                role:["ROLE_ADMIN", "ROLE_ROOT"]

            },
            {
                id: 689592615615234,
                label: 'Arrived',
                icon: 'ti-home',
                link: '/admin/stock-transfer-arrived',
                role:["ROLE_ADMIN", "ROLE_ROOT"]

            },
            {
                id: 689592615615235,
                label: 'Delivered',
                icon: 'ti-home',
                link: '/admin/stock-transfer-delivered',
                role:["ROLE_ADMIN", "ROLE_ROOT"]

            },
            {
                id: 689592615615236,
                label: 'Return',
                icon: 'ti-home',
                link: '/admin/stock-transfer-return',
                role:["ROLE_ADMIN", "ROLE_ROOT"]
            },
            {
                id: 689592615615237,
                label: 'Return Prepared',
                icon: 'ti-home',
                link: '/admin/stock-transfer-return-prepared',
                role:["ROLE_ADMIN", "ROLE_ROOT"]
            },
            {
                id: 6895926156152378,
                label: 'Return Loading',
                icon: 'ti-home',
                link: '/admin/stock-transfer-return-loading',
                role:["ROLE_ADMIN", "ROLE_ROOT"]
            },
            {
                id: 6895926156152379,
                label: 'Return Road',
                icon: 'ti-home',
                link: '/admin/stock-transfer-return-road',
                role:["ROLE_ADMIN", "ROLE_ROOT"]
            },
            {
                id: 68959261561523710,
                label: 'Return Arrived',
                icon: 'ti-home',
                link: '/admin/stock-transfer-return-arrived',
                role:["ROLE_ADMIN", "ROLE_ROOT"]
            },
            {
                id: 68959261561523711,
                label: 'Return Delivered',
                icon: 'ti-home',
                link: '/admin/stock-transfer-return-delivered',
                role:["ROLE_ADMIN", "ROLE_ROOT"]
            },
            {
                id: 68959261561523712,
                label: 'Canceled',
                icon: 'ti-home',
                link: '/admin/stock-transfer-canceled',
                role:["ROLE_ADMIN", "ROLE_ROOT"]
            },
            // {
            //     id: 7,
            //     label: 'Out of Stock',
            //     icon: 'ti-home',
            //     link: '#'
            // },
        ]
    },
    {
        id: 8034793274,
        label: 'Order Management',
        isTitle: true,
        role:["ROLE_ADMIN", "ROLE_ROOT"]
    },
    {
        id: 7884687345873,
        label: 'Order',
        icon: 'ti-package',
        role:["ROLE_ADMIN", "ROLE_ROOT"],
        subItems: [
            {
                id: 84798454,
                label: 'Order',
                icon: 'ti-home',
                link: '/admin/create-order',
                role:["ROLE_ADMIN", "ROLE_ROOT"]
            },

            {
                id: 79324324,
                label: 'Created',
                icon: 'ti-home',
                link: '/admin/request-order-list',
                role:["ROLE_ADMIN", "ROLE_ROOT"]
            },
            {
                id: 3759834754935,
                label: 'Prepared',
                icon: 'ti-home',
                link: '/admin/prepared-list',
                role:["ROLE_ADMIN", "ROLE_ROOT"]
            },
            {
                id: 97837268723,
                label: 'Loading',
                icon: 'ti-home',
                link: '/admin/loading-list',
                role:["ROLE_ADMIN", "ROLE_ROOT"]
            },
            {
                id: 97868797889,
                label: 'Road',
                icon: 'ti-home',
                link: '/admin/order-road-list',
                role:["ROLE_ADMIN", "ROLE_ROOT"]
            },
            {
                id: 97868797889,
                label: 'Arrived',
                icon: 'ti-home',
                link: '/admin/order-arrived-list',
                role:["ROLE_ADMIN", "ROLE_ROOT"]
            },
            {
                id: 21838972349732,
                label: 'Delivered',
                icon: 'ti-home',
                link: '/admin/order-delivered-list',
                role:["ROLE_ADMIN", "ROLE_ROOT"]
            },
        ],

    },
    {
        id: 123123879,
        label: 'Site Management',
        icon: 'ti-package',
        isTitle: true

    },


    {
        id: 9,
        label: 'Site Management',
        icon: 'ti-package',
        role:["ROLE_ADMIN", "ROLE_ROOT"],

        subItems: [
            {
                id: 10,
                label: 'Customer',
                icon: 'ti-package',
                role:["ROLE_ADMIN", "ROLE_ROOT"],
                subItems: [
                    {
                        id: 12,
                        label: 'Create',
                        icon: 'ti-home',
                        link: '/admin/create-customer',
                        role:["ROLE_ADMIN", "ROLE_ROOT"]

                    },
                    {
                        id: 13,
                        label: 'List',
                        icon: 'ti-home',
                        link: '/admin/customer-list',
                        role:["ROLE_ADMIN", "ROLE_ROOT"],

                    },

                ]
            },
            {
                id: 14,
                label: 'Slider',
                icon: 'ti-package',
                role:["ROLE_ADMIN", "ROLE_ROOT"],
                subItems: [
                    {
                        id: 15,
                        label: 'Create',
                        icon: 'ti-home',
                        link: '/admin/create-slider',
                        role:["ROLE_ADMIN", "ROLE_ROOT"]
                    },
                    {
                        id: 16,
                        label: 'List',
                        icon: 'ti-home',
                        link: '/admin/slider-list',
                        role:["ROLE_ADMIN", "ROLE_ROOT"],
                    },

                ]
            },
            {
                id: 17,
                label: 'Category',
                icon: 'ti-package',
                role:["ROLE_ADMIN", "ROLE_ROOT"],
                subItems: [
                    {
                        id: 18,
                        label: 'List',
                        icon: 'ti-home',
                        link: '/admin/category-list',
                        role:["ROLE_ADMIN", "ROLE_ROOT"]
                    },

                ]
            },
            {
                id: 19,
                label: 'Sub Category',
                icon: 'ti-package',
                role:["ROLE_ADMIN", "ROLE_ROOT"],
                subItems: [
                    {
                        id: 20,
                        label: 'List',
                        icon: 'ti-home',
                        link: '/admin/subcategory-list',
                        role:["ROLE_ADMIN", "ROLE_ROOT"]
                    },

                ]
            },
            {
                id: 21,
                label: 'Collection',
                icon: 'ti-package',
                role:["ROLE_ADMIN", "ROLE_ROOT"],
                subItems: [
                    {
                        id: 22,
                        label: 'Create',
                        icon: 'ti-home',
                        link: '/admin/create-collection',
                        role:["ROLE_ADMIN", "ROLE_ROOT"]
                    },
                    {
                        id: 23,
                        label: 'List',
                        icon: 'ti-home',
                        link: '/admin/collection-list',
                        role:["ROLE_ADMIN", "ROLE_ROOT"]
                    },

                ]
            },
            {
                id: 24,
                label: 'Product',
                icon: 'ti-package',
                role:["ROLE_ADMIN", "ROLE_ROOT"],
                subItems: [
                    {
                        id: 25,
                        label: 'Create',
                        icon: 'ti-home',
                        link: '/admin/create-product',
                        role:["ROLE_ADMIN", "ROLE_ROOT"]
                    },
                    {
                        id: 26,
                        label: 'List',
                        icon: 'ti-home',
                        link: '/admin/product-list',
                        role:["ROLE_ADMIN", "ROLE_ROOT"]
                    },

                ]
            },
            // {
            //     id: 27,
            //     label: 'Variant',
            //     icon: 'ti-package',
            //     subItems: [
            //         {
            //             id: 28,
            //             label: 'Create',
            //             icon: 'ti-home',
            //             link: '/admin/create-variant'
            //         },
            //         {
            //             id: 29,
            //             label: 'List',
            //             icon: 'ti-home',
            //             link: '/admin/variant-list'
            //         },
            //
            //     ]
            // },
            {
                id: 30,
                label: 'BLog',
                icon: 'ti-package',
                role:["ROLE_ADMIN", "ROLE_ROOT"],
                subItems: [
                    {
                        id: 31,
                        label: 'Create',
                        icon: 'ti-home',
                        link: '/admin/create-blog',
                        role:["ROLE_ADMIN", "ROLE_ROOT"]
                    },
                    {
                        id: 32,
                        label: 'List',
                        icon: 'ti-home',
                        link: '/admin/blog-list',
                        role:["ROLE_ADMIN", "ROLE_ROOT"]
                    },

                ]
            },
            {
                id: 33,
                label: 'About Us',
                icon: 'ti-package',
                role:["ROLE_ADMIN", "ROLE_ROOT"],
                subItems: [
                    {
                        id: 34,
                        label: 'Create',
                        icon: 'ti-home',
                        link: '/admin/create-about',
                        role:["ROLE_ADMIN", "ROLE_ROOT"]
                    },
                    {
                        id: 35,
                        label: 'List',
                        icon: 'ti-home',
                        link: '/admin/about-list',
                        role:["ROLE_ADMIN", "ROLE_ROOT"]
                    },

                ]
            },
            {
                id: 36,
                label: 'FAQ',
                icon: 'ti-package',
                role:["ROLE_ADMIN", "ROLE_ROOT"],
                subItems: [
                    {
                        id: 37,
                        label: 'Create',
                        icon: 'ti-home',
                        link: '/admin/create-faq',
                        role:["ROLE_ADMIN", "ROLE_ROOT"]
                    },
                    {
                        id: 38,
                        label: 'List',
                        icon: 'ti-home',
                        link: '/admin/faq-list',
                        role:["ROLE_ADMIN", "ROLE_ROOT"]
                    },

                ]
            },
            {
                id: 39,
                label: 'Service',
                icon: 'ti-package',
                role:["ROLE_ADMIN", "ROLE_ROOT"],
                subItems: [
                    {
                        id: 40,
                        label: 'Create',
                        icon: 'ti-home',
                        link: '/admin/create-service',
                        role:["ROLE_ADMIN", "ROLE_ROOT"]
                    },
                    {
                        id: 41,
                        label: 'List',
                        icon: 'ti-home',
                        link: '/admin/service-list',
                        role:["ROLE_ADMIN", "ROLE_ROOT"]
                    },

                ]
            },
            {
                id: 42,
                label: 'Team',
                icon: 'ti-package',
                role:["ROLE_ADMIN", "ROLE_ROOT"],
                subItems: [
                    {
                        id: 43,
                        label: 'Create',
                        icon: 'ti-home',
                        link: '/admin/create-team',
                        role:["ROLE_ADMIN", "ROLE_ROOT"]
                    },
                    {
                        id: 44,
                        label: 'List',
                        icon: 'ti-home',
                        link: '/admin/team-list',
                        role:["ROLE_ADMIN", "ROLE_ROOT"]
                    },

                ]
            },
            {
                id: 45,
                label: 'Project',
                icon: 'ti-package',
                role:["ROLE_ADMIN", "ROLE_ROOT"],
                subItems: [
                    {
                        id: 46,
                        label: 'Create',
                        icon: 'ti-home',
                        link: '/admin/create-project',
                        role:["ROLE_ADMIN", "ROLE_ROOT"]
                    },
                    {
                        id: 47,
                        label: 'List',
                        icon: 'ti-home',
                        link: '/admin/project-list',
                        role:["ROLE_ADMIN", "ROLE_ROOT"]
                    },

                ]
            },
            {
                id: 48,
                label: 'Referance',
                icon: 'ti-package',
                role:["ROLE_ADMIN", "ROLE_ROOT"],
                subItems: [
                    {
                        id: 49,
                        label: 'Create',
                        icon: 'ti-home',
                        link: '/admin/create-referance',
                        role:["ROLE_ADMIN", "ROLE_ROOT"]
                    },
                    {
                        id: 50,
                        label: 'List',
                        icon: 'ti-home',
                        link: '/admin/referance-list',
                        role:["ROLE_ADMIN", "ROLE_ROOT"]
                    },

                ]
            },
            {
                id: 51,
                label: 'Campaing',
                icon: 'ti-package',
                role:["ROLE_ADMIN", "ROLE_ROOT"],
                subItems: [
                    {
                        id: 52,
                        label: 'Create',
                        icon: 'ti-home',
                        link: '/admin/create-campaign',
                        role:["ROLE_ADMIN", "ROLE_ROOT"]
                    },
                    {
                        id: 53,
                        label: 'List',
                        icon: 'ti-home',
                        link: '/admin/campaign-list',
                        role:["ROLE_ADMIN", "ROLE_ROOT"]
                    },

                ]
            },
            // {
            //     id: 54,
            //     label: 'Contact',
            //     icon: 'ti-home',
            //     link: '#'
            // },
            // {
            //     id: 55,
            //     label: 'Newslatter',
            //     icon: 'ti-home',
            //     link: '#'
            // },
        ]
    },

    {
        id: 56,
        label: 'Private Management',
        icon: 'ti-package',
        role:["ROLE_ADMIN", "ROLE_ROOT"],
        isTitle: true
    },

    {
        id: 57,
        label: 'Private Management',
        icon: 'ti-package',
        role:["ROLE_ROOT","*"],

        subItems: [
            {
                id: 58,
                label: 'Size',
                icon: 'ti-package',
                role:["ROLE_ADMIN", "ROLE_ROOT"],
                subItems: [
                    {
                        id: 59,
                        label: 'List',
                        icon: 'ti-home',
                        link: '/admin/size-list',
                        role:["ROLE_ADMIN", "ROLE_ROOT"]
                    },

                ]
            },
            {
                id: 60,
                label: 'Color',
                icon: 'ti-package',
                role:["ROLE_ADMIN", "ROLE_ROOT"],
                subItems: [
                    {
                        id: 61,
                        label: 'List',
                        icon: 'ti-home',
                        link: '/admin/color-list',
                        role:["ROLE_ADMIN", "ROLE_ROOT"]
                    },

                ]
            },
            {
                id: 62,
                label: 'Unit',
                icon: 'ti-package',
                role:["ROLE_ADMIN", "ROLE_ROOT"],
                subItems: [
                    {
                        id: 63,
                        label: 'List',
                        icon: 'ti-home',
                        link: '/admin/unit-list',
                        role:["ROLE_ADMIN", "ROLE_ROOT"]
                    },

                ]
            },
            {
                id: 64,
                label: 'Note',
                icon: 'ti-package',
                role:["ROLE_ADMIN", "ROLE_ROOT"],
                subItems: [
                //     {
                //         id: 65,
                //         label: 'Create',
                //         icon: 'ti-home',
                //         link: '#'
                //     },
                //     {
                //         id: 66,
                //         label: 'List',
                //         icon: 'ti-home',
                //         link: '#'
                //     },
                //
                ]
            },
            // {
            //     id: 67,
            //     label: 'Task',
            //     icon: 'ti-package',
            //     role:["ROLE_ROOT"],
            //     subItems: [
            //         {
            //             id: 68,
            //             label: 'List',
            //             icon: 'ti-home',
            //             link: '/admin/task-list',
            //             role:["ROLE_ROOT"]
            //         },
            //
            //     ]
            // },
            {
                id: 69,
                label: 'Warehouse',
                icon: 'ti-package',
                role:["ROLE_ADMIN", "ROLE_ROOT"],
                subItems: [
                    {
                        id: 70,
                        label: 'Create',
                        icon: 'ti-home',
                        link: '/admin/create-warehouse',
                        role:["ROLE_ADMIN", "ROLE_ROOT"]
                    },
                    {
                        id: 71,
                        label: 'List',
                        icon: 'ti-home',
                        link: '/admin/warehouse-list',
                        role:["ROLE_ADMIN", "ROLE_ROOT"]
                    },
                    {
                        id: 72,
                        label: 'Search',
                        icon: 'ti-home',
                        link: '/admin/search-warehouse',
                        role:["ROLE_ADMIN", "ROLE_ROOT"]
                    },
                    {
                        id: 73,
                        label: 'Item',
                        icon: 'ti-home',
                        link: '/admin/item-warehouse',
                        role:["ROLE_ADMIN", "ROLE_ROOT"]
                    },
                    {
                        id: 73,
                        label: 'Stock',
                        icon: 'ti-home',
                        link: '/admin/stock-warehouse',
                        role:["ROLE_ADMIN", "ROLE_ROOT"]
                    },

                ]
            },
            {
                id: 74,
                label: 'Admin',
                icon: 'ti-package',
                role:["ROLE_ADMIN", "ROLE_ROOT"],
                subItems: [
                    {
                        id: 75,
                        label: 'Create',
                        icon: 'ti-home',
                        link: '/admin/create',
                        role:["ROLE_ADMIN", "ROLE_ROOT"]
                    },
                    {
                        id: 76,
                        label: 'List',
                        icon: 'ti-home',
                        link: '/admin/list',
                        role:["ROLE_ADMIN", "ROLE_ROOT"]
                    },

                ]
            },
            {
                id: 74,
                label: 'User',
                icon: 'ti-package',
                role:["ROLE_ADMIN", "ROLE_ROOT"],
                subItems: [
                    {
                        id: 76,
                        label: 'List',
                        icon: 'ti-home',
                        link: '/admin/user-list',
                        role:["ROLE_ADMIN", "ROLE_ROOT"]
                    },

                ]
            },
            {
                id: 77,
                label: 'Role',
                icon: 'ti-package',
                role:["ROLE_ADMIN", "ROLE_ROOT"],
                subItems: [
                    {
                        id: 78,
                        label: 'List',
                        icon: 'ti-home',
                        link: '/admin/role-list',
                        role:["ROLE_ADMIN", "ROLE_ROOT"]
                    },

                ]
            },
            // {
            //     id: 79,
            //     label: 'Supplier',
            //     icon: 'ti-package',
            //     role:["ROLE_ADMIN", "ROLE_ROOT"],
            //     subItems: [
            //         {
            //             id: 80,
            //             label: 'Create',
            //             icon: 'ti-home',
            //             link: '/admin/create-supplier',
            //             role:["ROLE_ADMIN", "ROLE_ROOT"]
            //         },
            //         {
            //             id: 81,
            //             label: 'List',
            //             icon: 'ti-home',
            //             link: '/admin/supplier-list',
            //             role:["ROLE_ADMIN", "ROLE_ROOT"]
            //         },
            //
            //     ]
            // },
            // {
            //     id: 82,
            //     label: 'Truck',
            //     icon: 'ti-package',
            //     role:["ROLE_ADMIN", "ROLE_ROOT"],
            //     subItems: [
            //         {
            //             id: 83,
            //             label: 'Create',
            //             icon: 'ti-home',
            //             link: '/admin/create-truck',
            //             role:["ROLE_ADMIN", "ROLE_ROOT"]
            //         },
            //         {
            //             id: 84,
            //             label: 'List',
            //             icon: 'ti-home',
            //             link: '/admin/truck-list',
            //             role:["ROLE_ADMIN", "ROLE_ROOT"]
            //         },
            //
            //     ]
            // },
            {
                id: 825,
                label: 'Stock',
                icon: 'ti-package',
                role:["ROLE_ADMIN", "ROLE_ROOT"],
                subItems: [
                    {
                        id: 835,
                        label: 'Stock',
                        icon: 'ti-home',
                        link: '/admin/stock-list',
                        role:["ROLE_ADMIN", "ROLE_ROOT"]

                    },
                    {
                        id: 845,
                        label: 'Search',
                        icon: 'ti-home',
                        link: '/admin/search-stock-list',
                        role:["ROLE_ADMIN", "ROLE_ROOT"]

                    },

                ]
            },
            {
                id: 821,
                label: 'Announcements',
                icon: 'ti-package',
                role:["ROLE_ADMIN", "ROLE_ROOT"],

                subItems: [
                    {
                        id: 831,
                        label: 'List',
                        icon: 'ti-home',
                        link: '/admin/announcements',
                        role:["ROLE_ADMIN", "ROLE_ROOT"]

                    },
                ]
            },
            {
                id: 85,
                label: 'Settings',
                icon: 'ti-package',
                subItems: [
                    {
                        id: 86,
                        label: 'Image Resize',
                        icon: 'ti-home',
                        link: '/admin/edit-image-resize/:id',
                        role:["ROLE_ADMIN", "ROLE_ROOT"]

                    },
                    {
                        id: 87,
                        label: 'Site Settings',
                        icon: 'ti-home',
                        link: '/admin/edit-site-setting/:id',
                        role:["ROLE_ADMIN", "ROLE_ROOT"]
                    },
                    {
                        id: 88,
                        label: 'Settings',
                        icon: 'ti-home',
                        link: '/admin/edit-setting/:id',
                        role:["ROLE_ADMIN", "ROLE_ROOT"]
                    },
                    // {
                    //     id: 89,
                    //     label: 'Google Maps',
                    //     icon: 'ti-home',
                    //     link: '#'
                    // },
                    {
                        id: 90,
                        label: 'Backup',
                        icon: 'ti-home',
                        link: '/admin/backup-list',
                        role: ["ROLE_ADMIN"," ROLE_ROOT"]
                    },
                    {
                        id: 91,
                        label: 'File Manager',
                        icon: 'ti-home',
                        link: '/admin/file-manager',
                        role:["*"]
                    },
                    {
                        id: 911,
                        label: 'Route Group',
                        icon: 'ti-home',
                        link: '/admin/route-group',
                        role:["ROLE_ROOT","ROLE_ADMIN"]
                    },
                    {
                        id: 12313123,
                        label: 'Smtp Settings',
                        icon: 'ti-home',
                        link: '/admin/smtp-settings'
                    },
                    {
                        id: 5465756,
                        label: 'Google Api',
                        icon: 'ti-home',
                        link: '/admin/google-maps-api'
                    },
                    {
                        id: 912,
                        label: 'Route Permission',
                        icon: 'ti-home',
                        link: '/admin/route-permission',
                        role:["ROLE_ROOT", "ROLE_ADMIN"]
                    },
                    {
                        id: 912123213,
                        label: 'Excel',
                        icon: 'ti-home',
                        link: '/admin/excel',
                        role:["*"]
                    },
                    // {
                    //     id: 912,
                    //     label: 'Export',
                    //     icon: 'ti-home',
                    //     link: '/admin/export',
                    //     role:["ROLE_ROOT"]
                    // },
                ]
            },
            {
                id: 92,
                label: 'Employee',
                icon: 'ti-package',
                role:["*"],
                subItems: [
                    {
                        id: 93,
                        label: 'Create',
                        icon: 'ti-home',
                        link: '/admin/create-employee',
                        role:["*"]
                    },
                    {
                        id: 94,
                        label: 'List',
                        icon: 'ti-home',
                        link: '/admin/employee-list',
                        role:["*"]
                    },


                ]
            },
            {
                id: 92777,
                label: 'Country',
                icon: 'ti-package',
                role:["*"],
                subItems: [
                    {
                        id: 9309,
                        label: 'Create',
                        icon: 'ti-home',
                        link: '/admin/create-country',
                        role:["*"]
                    },
                    {
                        id: 9493,
                        label: 'List',
                        icon: 'ti-home',
                        link: '/admin/country-list',
                        role:["*"]
                    },


                ]
            },
            {
                id: 92666,
                label: 'City',
                icon: 'ti-package',
                role:["*"],
                subItems: [
                    {
                        id: 93091,
                        label: 'Create',
                        icon: 'ti-home',
                        link: '/admin/create-city',
                        role:["*"]
                    },
                    {
                        id: 94933243,
                        label: 'List',
                        icon: 'ti-home',
                        link: '/admin/city-list',
                        role:["*"]
                    },


                ]
            },
            {
                id: 1799999999999,
                label: 'Language',
                icon: 'ti-package',
                role:["ROLE_ADMIN", "ROLE_ROOT"],
                subItems: [
                    {
                        id: 18,
                        label: 'List',
                        icon: 'ti-home',
                        link: '/admin/language-list',
                        role:["ROLE_ADMIN", "ROLE_ROOT"]
                    },

                ]
            },
            // {
            //     id: 9266600,
            //     label: 'Pallet',
            //     icon: 'ti-package',
            //     role:["*"],
            //     subItems: [
            //         {
            //             id: 9309100,
            //             label: 'Create',
            //             icon: 'ti-home',
            //             link: '/admin/create-pallet',
            //             role:["*"]
            //         },
            //         {
            //             id: 9493324300,
            //             label: 'List',
            //             icon: 'ti-home',
            //             link: '/admin/pallet-list',
            //             role:["*"]
            //         },
            //
            //
            //     ]
            // },
            // {
            //     id: 95,
            //     label: 'Bank',
            //     icon: 'ti-package',
            //     role:["*"],
            //     subItems: [
            //         {
            //             id: 96,
            //             label: 'Create',
            //             icon: 'ti-home',
            //             link: '/admin/create-bank',
            //             role:["*"]
            //         },
            //         {
            //             id: 97,
            //             label: 'List',
            //             icon: 'ti-home',
            //             link: '/admin/bank-list',
            //             role:["*"]
            //         },
            //
            //
            //     ]
            // },
            // {
            //     id: 98,
            //     label: 'Coupon',
            //     icon: 'ti-package',
            //     role:["*"],
            //     subItems: [
            //         {
            //             id: 99,
            //             label: 'Create',
            //             icon: 'ti-home',
            //             link: '/admin/create-coupon',
            //             role:["*"]
            //         },
            //         {
            //             id: 100,
            //             label: 'List',
            //             icon: 'ti-home',
            //             link: '/admin/coupon-list',
            //             role:["*"]
            //         },
            //
            //
            //     ]
            // },

        ]
    },

    {
        id: 101,
        label: 'Shipping Company Management',
        icon: 'ti-package',
        role:["ROLE_ROOT"],

        isTitle: true
    },

    {
        id: 57,
        label: 'Shipping Company List',
        icon: 'ti-package',
        role:["ROLE_ROOT"],

        subItems: [
            {
                id: 102,
                label: 'Company',
                icon: 'ti-package',
                role:["ROLE_ROOT"],

                subItems: [
                    {
                        id: 103,
                        label: 'List',
                        icon: 'ti-home',
                        link: '/admin/shipping-company-list',
                        role:["ROLE_ROOT"]
                    },

                ]
            },
            // {
            //     id: 104,
            //     label: 'Deci',
            //     icon: 'ti-package',
            //     role:["ROLE_ROOT"],
            //
            //     subItems: [
            //         {
            //             id: 105,
            //             label: 'List',
            //             icon: 'ti-home',
            //             link: '/admin/shipping-company-deci-list'
            //         },
            //
            //     ]
            // },
            // {
            //     id: 106,
            //     label: 'Distance',
            //     icon: 'ti-package',
            //     role:["ROLE_ROOT"],
            //
            //     subItems: [
            //         {
            //             id: 107,
            //             label: 'List',
            //             icon: 'ti-home',
            //             link: '/admin/shipping-company-distance-list'
            //         },
            //
            //     ]
            // },
            // {
            //     id: 108,
            //     label: 'Post Type',
            //     icon: 'ti-package',
            //     role:["ROLE_ROOT"],
            //
            //     subItems: [
            //         {
            //             id: 109,
            //             label: 'List',
            //             icon: 'ti-home',
            //             link: '/admin/shipping-post-type-list'
            //         },
            //
            //     ]
            // },
            // {
            //     id: 110,
            //     label: 'Service',
            //     icon: 'ti-package',
            //     role:["ROLE_ROOT"],
            //
            //     subItems: [
            //         {
            //             id: 111,
            //             label: 'List',
            //             icon: 'ti-home',
            //             link: '/admin/shipping-service-list'
            //         },
            //
            //     ]
            // },

        ]
    },
    // {
    //     id: 101,
    //     label: 'Shipping Company',
    //     isTitle: true,
    //     role:["ROLE_ROOT"],
    //
    // },

    {
        id: 112,
        label: 'Payment',
        icon: 'ti-package',
        role:["ROLE_ROOT"],

        isTitle: true
    },
    {
        id: 113,
        label: 'Payment Method',
        icon: 'ti-package',
        role:["ROLE_ROOT"],

        subItems: [
            {
                id: 114,
                label: 'List',
                icon: 'ti-home',
                link: '/admin/payment-method-list'
            },

        ]
    },
    // {
    //     id: 115,
    //     label: 'Iyzico',
    //     icon: 'ti-package',
    //     link: '#',
    // },
    // {
    //     id: 116,
    //     label: 'Paypal',
    //     icon: 'ti-package',
    //     link: '#',
    // },

    {
        id: 117,
        label: 'Accounting Management',
        icon: 'ti-package',
        role:["ROLE_ROOT"],

        isTitle: true
    },

    {
        id: 118,
        label: 'Accounting',
        icon: 'ti-package',
        role:["ROLE_ROOT"],

        subItems: [
            {
                id: 119,
                label: 'Salary',
                icon: 'ti-package',
                link: '/admin/salary-list',
                role:["*"]
            },
            {
                id: 120,
                label: 'Report',
                icon: 'ti-package',
                link: '/admin/accounting-report-list',
                role:["ROLE_ROOT"]
            },
            {
                id: 121,
                label: 'Accounting Status',
                icon: 'ti-package',
                link: '/admin/accounting-status-list',
                role:["*"]
            },
            {
                id: 122,
                label: 'Revenue',
                icon: 'ti-package',
                link: '/admin/revenue-list',
                role:["*"]
            },
            {
                id: 123,
                label: 'Expense',
                icon: 'ti-package',
                link: '/admin/expense-list',
                role:["*"]
            },
            {
                id: 124,
                label: 'Calendar',
                icon: 'ti-package',
                link: '/admin/calendar-list',
                role:["*"],
            },
            {
                id: 1244,
                label: 'Whatsapp',
                icon: 'ti-package',
                link: '/admin/whatsapp',
                role:["*"],
            },
            {
                id: 1244132,
                label: 'Chat',
                icon: 'ti-package',
                link: '/admin/chat',
                role:["*"],
            },
            // {
            //     id: 1244132123,
            //     label: 'Deneme',
            //     icon: 'ti-package',
            //     link: '/admin/deneme',
            //     role:["*"],
            // },
        ]
    },



    {
        id: 125,
        label: 'Report',
        icon: 'ti-package',
        role:["ROLE_ROOT"],

        isTitle: true
    },
    {
        id: 126,
        label: 'Report',
        icon: 'ti-package',
        role:["ROLE_ROOT"],
        subItems: [
            {
                id: 127,
                label: 'Search',
                icon: 'ti-package',
                link:'/admin/report/search',
                role:["*"]

            },
            {
                id: 128,
                label: 'General',
                icon: 'ti-package',
                link:'/admin/report/general',
                role:["*"]

            },
            {
                id: 129,
                label: 'Daily',
                icon: 'ti-package',
                link:'#',
                role:["*"]

            },
            {
                id: 130,
                label: 'Monthly',
                icon: 'ti-package',
                link:'/admin/report/monthly',
                role:["*"]

            },
            {
                id: 131,
                label: 'Yearly',
                icon: 'ti-package',
                link:'#',
                role:["*"]

            },
            {
                id: 131,
                label: 'Timeline',
                icon: 'ti-package',
                link:'/admin/timeline',
                role:["ROLE_ROOT","*"]

            },


        ]

    },




















]

export {
    menuItems
};