// import Vue from 'vue'
// import Router from "vue-router";

//estest
// Auth
import admin_login from './views/account/login';
import admin_lock from './views/account/lock';
import admin_logout from './views/account/logout';
// Home
import admin from './views/home'

// Stock Transfer
import stocktransfer from "../pages/StockTransfer/stocktransfer";
// Stock Transfer List
import Created from "@/pages/StockTransfer/list";
// Prepared
import Prepared from '@/pages/StockTransfer/prepared';
// Loading
import Loading from '@/pages/StockTransfer/loading';
// Road
import Road from '@/pages/StockTransfer/road';
// Arrived
import Arrived from '@/pages/StockTransfer/arrived';
// Delivered
import Delivered from '@/pages/StockTransfer/delivered';
// Return
import Return from '@/pages/StockTransfer/return';
// Return Prepared
import ReturnPrepared from '@/pages/StockTransfer/returnPrepared';
// Return Loading
import ReturnLoading from '@/pages/StockTransfer/returnLoading';
// Return Road
import ReturnRoad from '@/pages/StockTransfer/returnRoad';
// Return Arrived
import ReturnArrived from '@/pages/StockTransfer/returnArrived';
// Return Delivered
import ReturnDelivered from '@/pages/StockTransfer/returnDelivered';
// Canceled
import Canceled from '@/pages/StockTransfer/canceled';
// Stock Transfer Show
import StocktransferShow from "@/pages/StockTransfer/show";
// Stock Transfer Show Process
import StocktransferShowProcess from '@/pages/StockTransfer/showProcess'
// Stock Transfer Invoice
import StocktransferInvoice from "@/pages/StockTransfer/invoice";

// Slider
import CreateSlider from "../pages/slider/create";
import SliderList from "../pages/slider/index";
import EditSlider from "../pages/slider/edit";
import SliderLanguage from "../pages/slider/language";
// Collection
import CreateCollection from "../pages/collection/create";
import CollectionList from "../pages/collection/index";
import EditCollection from "../pages/collection/edit";
import CollectionLanguage from "../pages/collection/language";
// Customer
import CreateCustomer from "../pages/customer/create";
import CustomerList from "../pages/customer/index";
import EditCustomer from "../pages/customer/edit";
// Admin
import CreateAdmin from "../pages/admin/create";
import AdminList from "../pages/admin/index";
import EditAdmin from "../pages/admin/edit";
// User
import UserList from "../pages/users/index";
import ShowUser from "../pages/users/show";
// Blog
import CreateBlog from "../pages/blog/create";
import BlogList from "../pages/blog/index";
import EditBlog from "../pages/blog/edit";
import BlogLanguage from "../pages/blog/language";
// Team
import CreateTeam from "../pages/team/create";
import TeamList from "../pages/team/index";
import EditTeam from "../pages/team/edit";
import TeamLanguage from "../pages/team/language";
// Faq
import CreateFaq from "../pages/faq/create";
import FaqList from "../pages/faq/index";
import EditFaq from "../pages/faq/edit";
import FaqLanguage from "../pages/faq/language";
// Product
import CreateProduct from "../pages/product/create";
import ProductList from "../pages/product/index";
import EditProduct from "../pages/product/edit";
import PriorityProduct from "../pages/product/priority";
import VariantProduct from "../pages/product/variant";
import ProductLanguage from "../pages/product/language";

// Variant
import CreateVariant from "../pages/variant/create";
import VariantList from "../pages/variant/index";
import EditVariant from "../pages/variant/edit";

// Warehouse
import CreateWarehouse from "../pages/warehouse/create";
import WarehouseList from "../pages/warehouse/index";
import EditWarehouse from "../pages/warehouse/edit";
import ItemWarehouse from "../pages/warehouse/item";
import ShowItemWarehouse from "../pages/warehouse/show";
import ShowItemVariantWarehouse from "../pages/warehouse/showVariant";
import SearchWarehouse from "../pages/warehouse/search";
import StockWarehouse from "../pages/warehouse/stock";

// Service
import CreateService from "../pages/service/create";
import ServiceList from "../pages/service/index";
import EditService from "../pages/service/edit";
import ServiceLanguage from "../pages/service/language";

// Referance
import CreateReferance from "../pages/referance/create";
import ReferanceList from "../pages/referance/index";
import EditReferance from "../pages/referance/edit";
import ReferanceLanguage from "../pages/referance/language";

// Project
import CreateProject from "../pages/project/create";
import ProjectList from "../pages/project/index";
import EditProject from "../pages/project/edit";
import ProjectLanguage from "../pages/project/language";

// Employee
import CreateEmployee from "../pages/employee/create";
import EmployeeList from "../pages/employee/index";
import EditEmployee from "../pages/employee/edit";

// Bank
import CreateBank from "../pages/bank/create";
import BankList from "../pages/bank/index";
import EditBank from "../pages/bank/edit";

// Coupon
import CreateCoupon from "../pages/coupon/create";
import CouponList from "../pages/coupon/index";
import EditCoupon from "../pages/coupon/edit";

// Supplier
import CreateSupplier from "../pages/supplier/create";
import SupplierList from "../pages/supplier/index";
import EditSupplier from "../pages/supplier/edit";
import TransactionSupplier from "../pages/supplier/transaction";

// About Us
import CreateAbout from "../pages/about/create";
import AboutList from "../pages/about/index";
import EditAbout from "../pages/about/edit";
import AboutLanguage from "../pages/about/language";

// Campaign
import CreateCampaign from "../pages/campaign/create";
import CampaignList from "../pages/campaign/index";
import EditCampaign from "../pages/campaign/edit";
import CampaignLanguage from "../pages/campaign/language";

// Image Resize
import EditImageResize from "../pages/imageResize/edit";
// Stock
import StockList from "../pages/stock/stock";
import SearchStockList from "../pages/stock/search";

// Route Group
import RouteGroup from "../pages/route/group";
// Route Permission
import RoutePermission from "../pages/route/permission";

// Site Setting
import EditSiteSetting from "../pages/sitesettings/edit";
import SiteSettingLanguage from "../pages/sitesettings/language";

// Setting
import EditSetting from "../pages/settings/edit";

// Backup
import BackupList from "../pages/backup/index";

// Category
import CategoryList from "../pages/category/index";
import CategoryLanguage from "../pages/category/language";
// Language
import LanguageList from "../pages/language/index";
// Sub-Category
import SubcategoryList from "../pages/subcategory/index";
import SubCategoryLanguage from "../pages/subcategory/language";
// Color
import ColorList from "../pages/color/index";
// Size
import SizeList from "../pages/size/index";
// Unit
import UnitList from "../pages/unit/index";
// Task
import TaskList from '../pages/task/index';
// Role
import RoleList from '../pages/role/index';
// Payment-Method
import PaymentMethodList from '../pages/payment_method/index';
// Shipping-Company
import ShippingCompanyList from '../pages/shipping_company/index';
// Shipping-Company-Deci
import ShippingCompanyDeciList from '../pages/shipping_company_deci/index';
// Shipping-Company-Distance
import ShipComDisList from '../pages/shipping_company_dinstance/index'
// Ship-Com-Post-Type
import ShipComPostType from '../pages/shipping_company_post_type/index';
// Ship-Com-Service
import ShipComServiceList from '../pages/shipping_company_service/index';
// Truck
import CreateTruck from "../pages/truck/create";
import TruckList from "../pages/truck/index";
import EditTruck from "../pages/truck/edit";
import TransactionTruck from "../pages/truck/transaction";

// Salary
import SalaryList from "../pages/accounting/salary";
// Accounting Report
import AccountingReportList from "../pages/accounting/report";
// Accounting Status
import AccountingStatusList from "../pages/accounting/status/status";
// Revenue
import RevenueList from "../pages/accounting/revenue/revenue";
import RevenueDetail from "../pages/accounting/revenue/detail";
// Expense
import ExpenseList from "../pages/accounting/expense/expense";
import ExpenseDetail from "../pages/accounting/expense/detail";
// Calendar
import CalendarList from "../pages/calendar/index";

// Export
import ExportPage from '../pages/export/export';
// Excel
import Excel from '../pages/excel/index';
// File Manager
import FileManager from '../pages/fileManager/fileManager';

// Announcements
import Annoumcements from '../pages/announcements/index';
// Show Item And Variant
import ShowItem from '../pages/stock/show';
import ShowItemVariant from '../pages/stock/showVariant';

// TimeLine
import TimeLine from '../pages/timeline/timeline';

// ERROR
import Error500 from '../pages/error/error500';
import Error404 from '../pages/error/error404';
import Whatsapp from '../pages/whatsapp/whatsapp';

//smtp settings
import SmtpSettings from '../pages/smptSettings/index';

//Google Maps Api
import GoogleMapsApi from '../pages/googleMapsApi/index'

// Chat
import Chat from '../pages/chat/chat';
// DENEME CHAT
import ChatUserRoom from '../pages/chat/chatUserRoom';
// DENEME
// import Deneme from '../pages/deneme/stockTransfer';

// Country
import CreateCountry from '../pages/country/create';
import CountryList from '../pages/country/index';
import EditCountry from '../pages/country/edit';

// City
import CreateCity from '../pages/city/create';
import CityList from '../pages/city/index';
import EditCity from '../pages/city/edit';

// Pallet
import CreatePallet from '../pages/pallet/create';
import PalletList from '../pages/pallet/index';
import EditPallet from '../pages/pallet/edit';

//Order
import CreateOrder from '../pages/order/create';
import CreatedOrder from '../pages/order/created';
import PreparerOrder from '../pages/order/preperad';
import LoadingOrder from '../pages/order/loading';
import RoadOrder from '../pages/order/road';
import ArrivedOrder from '../pages/order/arrived';
import DeliveredOrder from '../pages/order/delivered';

// Report Search
import ReportSearch from '../pages/report/search';
import ReportMonthly from '../pages/report/monthly';
import ReportStockTransfer from '../pages/report/reportStockTransfer';
import ReportMonthlyStockTransfer from '../pages/report/monthlyStockTransfer';
import ReportMonthlyOrder from '../pages/report/monthlyOrder';
import ReportOrder from '../pages/report/reportOrder';
import ReportGeneral from '../pages/report/general';

//order show
import OrderShow from '../pages/order/orderShow';

//Order Invoice
import OrderInvoice from '../pages/order/orderInvoice'
//Order process
import OrderProcess from '../pages/order/orderProcess';

// Private Email
import PrivateEmail from '../pages/email/private';
import BulkEmail from '../pages/email/bulk';

export default[
    { path: '', redirect: { name: 'admin-login' }},
    {
        path: '/admin',
        component: admin,
        roles:["*"],
        name: 'admin',

    },
    // Auth Login
    {
        path: '/admin/login',
        name: 'admin-login',
        component: admin_login,
        meta: {
            title: ' admin login',
        }
    },
    // Auth Lock Screen
    {
        path: '/admin/lock',
        name: 'admin-lock',
        component: admin_lock,
        meta: {
            title: ' admin lock',
        }
    },
    // Auth Logout
    {
        path: '/admin/logout',
        name: 'admin-logout',
        component: admin_logout,
        meta: {
            title: ' admin logout',
        }
    },
    // Stock Transfer
    {
        path: '/admin/stock-transfer',
        name: 'admin-stock-transfer',

        component: stocktransfer,
        meta: {
            title: ' admin stock transfer',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/stock-transfer-list',
        name: 'admin-stock-transfer-list',
        component: Created,
        meta: {
            title: ' admin stock transfer list',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }

    },
    {
        path: '/admin/stock-transfer-prepared',
        name: 'admin-stock-transfer-prepared',
        component: Prepared,
        meta: {
            title: ' admin stock transfer prepared',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }

    },
    {
        path: '/admin/stock-transfer-loading',
        name: 'admin-stock-transfer-loading',
        component: Loading,
        meta: {
            title: ' admin stock transfer loading',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }

    },
    {
        path: '/admin/stock-transfer-road',
        name: 'admin-stock-transfer-road',
        component: Road,
        meta: {
            title: ' admin stock transfer road',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }

    },
    {
        path: '/admin/stock-transfer-arrived',
        name: 'admin-stock-transfer-arrived',
        component: Arrived,
        meta: {
            title: ' admin stock transfer arrived',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }

    },
    {
        path: '/admin/stock-transfer-delivered',
        name: 'admin-stock-transfer-delivered',
        component: Delivered,
        meta: {
            title: ' admin stock transfer delivered',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }

    },
    {
        path: '/admin/stock-transfer-return',
        name: 'admin-stock-transfer-return',
        component: Return,
        meta: {
            title: ' admin stock transfer return',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }

    },
    {
        path: '/admin/stock-transfer-return-prepared',
        name: 'admin-stock-transfer-return-prepared',
        component: ReturnPrepared,
        meta: {
            title: ' admin stock transfer return prepared',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }

    },
    {
        path: '/admin/stock-transfer-return-loading',
        name: 'admin-stock-transfer-return-loading',
        component: ReturnLoading,
        meta: {
            title: ' admin stock transfer return loading',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }

    },
    {
        path: '/admin/stock-transfer-return-road',
        name: 'admin-stock-transfer-return-road',
        component: ReturnRoad,
        meta: {
            title: ' admin stock transfer return road',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }

    },
    {
        path: '/admin/stock-transfer-return-arrived',
        name: 'admin-stock-transfer-return-arrived',
        component: ReturnArrived,
        meta: {
            title: ' admin stock transfer return arrived',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }

    },
    {
        path: '/admin/stock-transfer-return-delivered',
        name: 'admin-stock-transfer-return-delivered',
        component: ReturnDelivered,
        meta: {
            title: ' admin stock transfer return delivered',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }

    },
    {
        path: '/admin/stock-transfer-canceled',
        name: 'admin-stock-transfer-canceled',
        component: Canceled,
        meta: {
            title: ' admin stock transfer canceled',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }

    },
    {
        path: '/admin/stock-transfer-show/:id',
        name: 'admin-stock-transfer-show',
        component: StocktransferShow,
        meta: {
            title: ' admin stock transfer show',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }

    },
    {
        path: '/admin/stock-transfer-show-process/:id',
        name: 'admin-stock-transfer-show-process',
        component: StocktransferShowProcess,
        meta: {
            title: ' admin stock transfer show process',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }

    },
    {
        path: '/admin/stock-transfer-invoice/:id',
        name: 'admin-stock-transfer-invoice',
        component: StocktransferInvoice,
        meta: {
            title: ' admin stock transfer invoice',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }

    },
    // Slider
    {
        path: '/admin/create-slider',
        name: 'admin-create-slider',
        component: CreateSlider,
        meta: {
            title: ' admin create slider',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/slider-list',
        name: 'admin-slider-list',
        component: SliderList,
        meta: {
            title: ' admin slider list',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/edit-slider/:id',
        name: 'admin-edit-slider',
        component: EditSlider,
        meta: {
            title: ' admin edit slider',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/slider-language/:id',
        name: 'admin-slider-language',
        component: SliderLanguage,
        meta: {
            title: ' admin slider language',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    // Collection
    {
        path: '/admin/create-collection',
        name: 'admin-create-collection',
        component: CreateCollection,
        meta: {
            title: ' admin create collection',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/collection-list',
        name: 'admin-collection-list',
        component: CollectionList,
        meta: {
            title: ' admin collection list',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/edit-collection/:id',
        name: 'admin-edit-collection',
        component: EditCollection,
        meta: {
            title: ' admin edit collection',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/collection-language/:id',
        name: 'admin-collection-language',
        component: CollectionLanguage,
        meta: {
            title: ' admin collection language',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    // Customer
    {
        path: '/admin/create-customer',
        name: 'admin-create-customer',
        component: CreateCustomer,
        meta: {
            title: ' admin create customer',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/customer-list',
        name: 'admin-customer-list',
        component: CustomerList,
        meta: {
            title: ' admin customer list',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/edit-customer/:id',
        name: 'admin-edit-customer',
        component: EditCustomer,
        meta: {
            title: ' admin edit customer',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    // Admin
    {
        path: '/admin/create',
        name: 'admin-create',
        component: CreateAdmin,
        meta: {
            title: ' admin create admin',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/list',
        name: 'admin-list',
        component: AdminList,
        meta: {
            title: ' admin admin list',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/edit/:id',
        name: 'admin-edit',
        component: EditAdmin,
        meta: {
            title: ' admin edit admin',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    // User
    {
        path: '/admin/user-list',
        name: 'admin-user-list',
        component: UserList,
        meta: {
            title: ' admin user list',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/user-show/:id',
        name: 'admin-user-show',
        component: ShowUser,
        meta: {
            title: ' admin user show',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    // Report Search
    {
        path: '/admin/report/search',
        name: 'admin-report-search',
        component: ReportSearch,
        meta: {
            title: ' admin report search',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    // Report Monthly
    {
        path: '/admin/report/monthly',
        name: 'admin-report-monthly',
        component: ReportMonthly,
        meta: {
            title: ' admin report monthly',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    // Report Monthly Stock Transfer
    {
        path: '/admin/report-monthly-stockTransfer/:month/:year',
        name: 'admin-report-monthly-stockTransfer',
        component: ReportMonthlyStockTransfer,
        meta: {
            title: ' admin report monthly stockTransfer',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],

        }
    },
    // Report Monthly Order
    {
        path: '/admin/report-monthly-order/:month/:year',
        name: 'admin-report-monthly-order',
        component: ReportMonthlyOrder,
        meta: {
            title: ' admin report monthly order',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],

        }
    },
    // Report Search Detail
    {
        path: '/admin/report-stockTransfer/:startDate/:endDate',
        name: 'admin-report-stockTransfer',
        component: ReportStockTransfer,
        meta: {
            title: ' admin report stockTransfer',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],

        }
    },
    {
        path: '/admin/report-order/:startDate/:endDate',
        name: 'admin-report-order',
        component: ReportOrder,
        meta: {
            title: ' admin report order',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],

        }
    },
    {
        path: '/admin/report/general',
        name: 'admin-report-general',
        component: ReportGeneral,
        meta: {
            title: ' admin report general',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],

        }
    },
    // Blog
    {
        path: '/admin/create-blog',
        name: 'admin-create-blog',
        component: CreateBlog,
        meta: {
            title: ' admin create blog',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/blog-list',
        name: 'admin-blog-list',
        component: BlogList,
        meta: {
            title: ' admin blog list',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/edit-blog/:id',
        name: 'admin-edit-blog',
        component: EditBlog,
        meta: {
            title: ' admin edit blog',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],

        }
    },
    {
        path: '/admin/blog-language/:id',
        name: 'admin-blog-language',
        component: BlogLanguage,
        meta: {
            title: ' admin blog language',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    // Team
    {
        path: '/admin/create-team',
        name: 'admin-create-team',
        component: CreateTeam,
        meta: {
            title: ' admin create team',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/team-list',
        name: 'admin-team-list',
        component: TeamList,
        meta: {
            title: ' admin team list',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/edit-team/:id',
        name: 'admin-edit-team',
        component: EditTeam,
        meta: {
            title: ' admin edit team',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/team-language/:id',
        name: 'admin-team-language',
        component: TeamLanguage,
        meta: {
            title: ' admin team language',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    // Faq
    {
        path: '/admin/create-faq',
        name: 'admin-create-faq',
        component: CreateFaq,
        meta: {
            title: ' admin create faq',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/faq-list',
        name: 'admin-faq-list',
        component: FaqList,
        meta: {
            title: ' admin faq list',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/edit-faq/:id',
        name: 'admin-edit-faq',
        component: EditFaq,
        meta: {
            title: ' admin edit faq',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/faq-language/:id',
        name: 'admin-faq-language',
        component: FaqLanguage,
        meta: {
            title: ' admin faq language',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    // Product
    {
        path: '/admin/create-product',
        name: 'admin-create-product',
        component: CreateProduct,
        meta: {
            title: ' admin create product',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/product-list',
        name: 'admin-product-list',
        component: ProductList,
        meta: {
            title: ' admin product list',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/edit-product/:id',
        name: 'admin-edit-product',
        component: EditProduct,
        meta: {
            title: ' admin edit product',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/priority-product/:id',
        name: 'admin-priority-product',
        component: PriorityProduct,
        meta: {
            title: ' admin priority product',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/variant-product/:id',
        name: 'admin-variant-product',
        component: VariantProduct,
        meta: {
            title: ' admin variant product',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/product-language/:id',
        name: 'admin-product-language',
        component: ProductLanguage,
        meta: {
            title: ' admin product language',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    // Variant
    {
        path: '/admin/create-variant',
        name: 'admin-create-variant',
        component: CreateVariant,
        meta: {
            title: ' admin create variant',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/variant-list',
        name: 'admin-variant-list',
        component: VariantList,
        meta: {
            title: ' admin variant list',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/edit-variant/:id',
        name: 'admin-edit-variant',
        component: EditVariant,
        meta: {
            title: ' admin edit variant',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    // Warehouse
    {
        path: '/admin/create-warehouse',
        name: 'admin-create-warehouse',
        component: CreateWarehouse,
        meta: {
            title: ' admin create warehouse',
            roles:["ROLE_ROOT","*"],
        }
    },
    {
        path: '/admin/warehouse-list',
        name: 'admin-warehouse-list',
        component: WarehouseList,
        meta: {
            title: ' admin warehouse list',
            roles:["ROLE_ROOT","*"],
        }
    },
    {
        path: '/admin/edit-warehouse/:id',
        name: 'admin-edit-warehouse',
        component: EditWarehouse,
        meta: {
            title: ' admin edit warehouse',
            roles:["ROLE_ROOT","*"],
        }
    },
    {
        path: '/admin/item-warehouse',
        name: 'admin-item-warehouse',
        component: ItemWarehouse,
        meta: {
            title: ' admin item warehouse',
            roles:["ROLE_ROOT","*"],
        }
    },
    {
        path: '/admin/show-warehouse-item/:id',
        name: 'admin-show-warehouse-item',
        component: ShowItemWarehouse,
        meta: {
            title: ' admin show warehouse item',
            roles:["ROLE_ROOT","*"],
        }
    },
    {
        path: '/admin/show-warehouse-item-variant/:id',
        name: 'admin-show-warehouse-item-variant',
        component: ShowItemVariantWarehouse,
        meta: {
            title: ' admin show warehouse item variant',
            roles:["ROLE_ROOT","*"],
        }
    },
    {
        path: '/admin/search-warehouse',
        name: 'admin-search-warehouse',
        component: SearchWarehouse,
        meta: {
            title: ' admin search warehouse',
            roles:["ROLE_ROOT","*"],
        }
    },
    {
        path: '/admin/stock-warehouse',
        name: 'admin-stock-warehouse',
        component: StockWarehouse,
        meta: {
            title: ' admin stock warehouse',
            roles:["ROLE_ROOT","*"],
        }
    },
    // Service
    {
        path: '/admin/create-service',
        name: 'admin-create-service',
        component: CreateService,
        meta: {
            title: ' admin create service',
            roles:["ROLE_ROOT","*"],
        }
    },
    {
        path: '/admin/service-list',
        name: 'admin-service-list',
        component: ServiceList,
        meta: {
            title: ' admin service list',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/edit-service/:id',
        name: 'admin-edit-service',
        component: EditService,
        meta: {
            title: ' admin edit service',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/service-language/:id',
        name: 'admin-service-language',
        component: ServiceLanguage,
        meta: {
            title: ' admin service language',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    // Referance
    {
        path: '/admin/create-referance',
        name: 'admin-create-referance',
        component: CreateReferance,
        meta: {
            title: ' admin create referance',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/referance-list',
        name: 'admin-referance-list',
        component: ReferanceList,
        meta: {
            title: ' admin referance list',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/edit-referance/:id',
        name: 'admin-edit-referance',
        component: EditReferance,
        meta: {
            title: ' admin edit referance',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/referance-language/:id',
        name: 'admin-referance-language',
        component: ReferanceLanguage,
        meta: {
            title: ' admin referance language',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    // Project
    {
        path: '/admin/create-project',
        name: 'admin-create-project',
        component: CreateProject,
        meta: {
            title: ' admin create project',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/project-list',
        name: 'admin-project-list',
        component: ProjectList,
        meta: {
            title: ' admin project list',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/edit-project/:id',
        name: 'admin-edit-project',
        component: EditProject,
        meta: {
            title: ' admin edit project',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/project-language/:id',
        name: 'admin-project-language',
        component: ProjectLanguage,
        meta: {
            title: ' admin project language',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    // Employee
    {
        path: '/admin/create-employee',
        name: 'admin-create-employee',
        component: CreateEmployee,
        meta: {
            title: ' admin create employee',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/employee-list',
        name: 'admin-employee-list',
        component: EmployeeList,
        meta: {
            title: ' admin employee list',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/edit-employee/:id',
        name: 'admin-edit-employee',
        component: EditEmployee,
        meta: {
            title: ' admin edit employee',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    // Bank
    {
        path: '/admin/create-bank',
        name: 'admin-create-bank',
        component: CreateBank,
        meta: {
            title: ' admin create bank',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/bank-list',
        name: 'admin-bank-list',
        component: BankList,
        meta: {
            title: ' admin bank list',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/edit-bank/:id',
        name: 'admin-edit-bank',
        component: EditBank,
        meta: {
            title: ' admin edit bank',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    // Coupon
    {
        path: '/admin/create-coupon',
        name: 'admin-create-coupon',
        component: CreateCoupon,
        meta: {
            title: ' admin create coupon',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/coupon-list',
        name: 'admin-coupon-list',
        component: CouponList,
        meta: {
            title: ' admin coupon list',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/edit-coupon/:id',
        name: 'admin-edit-coupon',
        component: EditCoupon,
        meta: {
            title: ' admin edit coupon',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    // Supplier
    {
        path: '/admin/create-supplier',
        name: 'admin-create-supplier',
        component: CreateSupplier,
        meta: {
            title: ' admin create supplier',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/supplier-list',
        name: 'admin-supplier-list',
        component: SupplierList,
        meta: {
            title: ' admin supplier list',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/edit-supplier/:id',
        name: 'admin-edit-supplier',
        component: EditSupplier,
        meta: {
            title: ' admin edit supplier',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/transaction-supplier/:id',
        name: 'admin-transaction-supplier',
        component: TransactionSupplier,
        meta: {
            title: ' admin transaction supplier',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    // About Us
    {
        path: '/admin/create-about',
        name: 'admin-create-about',
        component: CreateAbout,
        meta: {
            title: ' admin create about',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/about-list',
        name: 'admin-about-list',
        component: AboutList,
        meta: {
            title: ' admin about list',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/edit-about/:id',
        name: 'admin-edit-about',
        component: EditAbout,
        meta: {
            title: ' admin edit about',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/about-language/:id',
        name: 'admin-about-language',
        component: AboutLanguage,
        meta: {
            title: ' admin about language',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    // Campaign
    {
        path: '/admin/create-campaign',
        name: 'admin-create-campaign',
        component: CreateCampaign,
        meta: {
            title: ' admin create campaign',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/campaign-list',
        name: 'admin-campaign-list',
        component: CampaignList,
        meta: {
            title: ' admin campaign list',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/edit-campaign/:id',
        name: 'admin-edit-campaign',
        component: EditCampaign,
        meta: {
            title: ' admin edit campaign',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/campaign-language/:id',
        name: 'admin-campaign-language',
        component: CampaignLanguage,
        meta: {
            title: ' admin campaign language',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    // Image Resize
    {
        path: '/admin/edit-image-resize/:id',
        name: 'admin-edit-image-resize',
        component: EditImageResize,
        meta: {
            title: ' admin edit image resize',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    // Site Settings
    {
        path: '/admin/edit-site-setting/:id',
        name: 'admin-edit-site-setting',
        component: EditSiteSetting,
        meta: {
            title: ' admin edit site setting',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/site-setting-language/:id',
        name: 'admin-site-setting-language',
        component: SiteSettingLanguage,
        meta: {
            title: ' admin site-setting language',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    // Settings
    {
        path: '/admin/edit-setting/:id',
        name: 'admin-edit-setting',
        component: EditSetting,
        meta: {
            title: ' admin edit setting',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    // Backup
    {
        path: '/admin/backup-list',
        name: 'admin-backup-list',
        component: BackupList,
        meta: {
            title: ' admin backup list',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    //Category
    {
        path: '/admin/category-list',
        name: 'admin-category-list',
        component: CategoryList,
        meta: {
            title: ' admin category list',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/category-language/:id',
        name: 'admin-category-language',
        component: CategoryLanguage,
        meta: {
            title: ' admin category language',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    // Language
    {
        path: '/admin/language-list',
        name: 'admin-language-list',
        component: LanguageList,
        meta: {
            title: ' admin language list',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
//Sub-Category
    {
        path: '/admin/subcategory-list',
        name: 'admin-subcategory-list',
        component: SubcategoryList,
        meta: {
            title: ' admin subcategory list',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/subcategory-language/:id',
        name: 'admin-subcategory-language',
        component: SubCategoryLanguage,
        meta: {
            title: ' admin subcategory language',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
//Color
    {
        path: '/admin/color-list',
        name: 'admin-color-list',
        component: ColorList,
        meta: {
            title: ' admin color list',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
//Size
    {
        path: '/admin/size-list',
        name: 'admin-size-list',
        component: SizeList,
        meta: {
            title: ' admin size list',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
//Unit
    {
        path: '/admin/unit-list',
        name: 'admin-unit-list',
        component: UnitList,
        meta: {
            title: ' admin unit list',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
//Task
    {
        path: '/admin/task-list',
        name: 'admin-task-list',
        component: TaskList,
        meta: {
            title: ' admin task list',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
//Role
    {
        path: '/admin/role-list',
        name: 'admin-role-list',
        component: RoleList,
        meta: {
            title: ' admin role list',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
//Payment-Method
    {
        path: '/admin/payment-method-list',
        name: 'admin-payment-method-list',
        component: PaymentMethodList,
        meta: {
            title: ' admin payment-method list',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
//Shipping Company
    {
        path: '/admin/shipping-company-list',
        name: 'admin-shipping-company-list',
        component: ShippingCompanyList,
        meta: {
            title: ' admin shipping company list',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
//Shipping Company Deci
    {
        path: '/admin/shipping-company-deci-list',
        name: 'admin-shipping-company-deci-list',
        component: ShippingCompanyDeciList,
        meta: {
            title: ' admin shipping company deci list',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
//Shipping Company Distance
    {
        path: '/admin/shipping-company-distance-list',
        name: 'admin-shipping-company-distance-list',
        component: ShipComDisList,
        meta: {
            title: ' admin shipping company distance list',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
//Shipping Company Post Type
    {
        path: '/admin/shipping-post-type-list',
        name: 'admin-shipping-post-type-list',
        component: ShipComPostType,
        meta: {
            title: ' admin shipping company posttype list',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
//Shipping Company Service
    {
        path: '/admin/shipping-service-list',
        name: 'admin-shipping-service-list',
        component: ShipComServiceList,
        meta: {
            title: ' admin shipping company service list',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    // Truck
    {
        path: '/admin/create-truck',
        name: 'admin-create-truck',
        component: CreateTruck,
        meta: {
            title: ' admin create truck',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/truck-list',
        name: 'admin-truck-list',
        component: TruckList,
        meta: {
            title: ' admin truck list',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/edit-truck/:id',
        name: 'admin-edit-truck',
        component: EditTruck,
        meta: {
            title: ' admin edit truck',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/transaction-truck/:id',
        name: 'admin-transaction-truck',
        component: TransactionTruck,
        meta: {
            title: ' admin transaction truck',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    // Salary
    {
        path: '/admin/salary-list',
        name: 'admin-salary-list',
        component: SalaryList,
        meta: {
            title: ' admin salary list',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    // Accounting Report
    {
        path: '/admin/accounting-report-list',
        name: 'admin-accounting-report-list',
        component: AccountingReportList,
        meta: {
            title: ' admin accounting report list',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    // Accounting Status
    {
        path: '/admin/accounting-status-list',
        name: 'admin-accounting-status-list',
        component: AccountingStatusList,
        meta: {
            title: ' admin accounting status list',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    // Revenue
    {
        path: '/admin/revenue-list',
        name: 'admin-revenue-list',
        component: RevenueList,
        meta: {
            title: ' admin revenue list',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/revenue-detail/:id',
        name: 'admin-revenue-detail',
        component: RevenueDetail,
        meta: {
            title: ' admin revenue detail',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    // Expense
    {
        path: '/admin/expense-list',
        name: 'admin-expense-list',
        component: ExpenseList,
        meta: {
            title: ' admin expense list',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/expense-detail/:id',
        name: 'admin-expense-detail',
        component: ExpenseDetail,
        meta: {
            title: ' admin expense detail',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    // Calendar
    {
        path: '/admin/calendar-list',
        name: 'admin-calendar-list',
        component: CalendarList,
        meta: {
            title: ' admin calendar list',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    // Export
    {
        path: '/admin/export',
        name: 'admin-export',
        component: ExportPage,
        meta: {
            title: ' admin export',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    // Export
    {
        path: '/admin/excel',
        name: 'admin-excel',
        component: Excel,
        meta: {
            title: ' admin excel',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    // File Manager
    {
        path: '/admin/file-manager',
        name: 'admin-file-manager',
        component: FileManager,
        meta: {
            title: ' admin file manager',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    // Route Group
    {
        path: '/admin/route-group',
        name: 'admin-route-group',
        component: RouteGroup,
        meta: {
            title: ' admin rotue-group',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    // Route Permission
    {
        path: '/admin/route-permission',
        name: 'admin-route-permission',
        component: RoutePermission,
        meta: {
            title: ' admin rotue-permission',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    // Announcements
    {
        path: '/admin/announcements',
        name: 'admin-announcements',
        component: Annoumcements,
        meta: {
            title: ' admin announcements',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    // Stock Control
    {
        path: '/admin/stock-list',
        name: 'admin-stock-list',
        component: StockList,
        meta: {
            title: ' admin stock list',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/search-stock-list',
        name: 'admin-search-stock-list',
        component: SearchStockList,
        meta: {
            title: ' admin search stock list',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/show-item/:id',
        name: 'admin-show-item',
        component: ShowItem,
        meta: {
            title: ' admin show item',
            roles:["ROLE_ROOT","*"],
        }
    },
    {
        path: '/admin/show-item-variant/:id',
        name: 'admin-show-item-variant',
        component: ShowItemVariant,
        meta: {
            title: ' admin show item variant',
            roles:["ROLE_ROOT","*"],
        }
    },

    // Announcements
    {
        path: '/admin/timeline',
        name: 'admin-timeline',
        component: TimeLine,
        meta: {
            title: ' admin timeline',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    // ERROR
    {
        path: '/admin/error/500',
        name: 'admin-error-500',
        component: Error500,
        meta: {
            title: ' admin error 500',
        }

    },
    {
        path: '/admin/error/404',
        name: 'admin-error-404',
        component: Error404,
        meta: {
            title: ' admin error 404',
        }

    },
    // WHATSAPP
    {
        path: '/admin/whatsapp',
        name: 'admin-whatsapp',
        component: Whatsapp,
        meta: {
            title: ' admin whatsapp',
        }

    },
    // CHAT
    {
        path: '/admin/chat',
        name: 'admin-chat',
        component: Chat,
        meta: {
            title: ' admin chat',
        }

    },
    // DENEME CHAT
    {
        path: '/admin/chat-user/:id',
        name: 'admin-chat-user',
        component: ChatUserRoom,
        meta: {
            title: ' admin chat user',
            roles:["*"],
        }
    },
    // DENEME STOCK TRANSFER
    // {
    //     path: '/admin/deneme',
    //     name: 'admin-deneme',
    //     component: Deneme,
    //     meta: {
    //         title: ' admin deneme',
    //     }
    //
    // },

    {
        path: '/admin/smtp-settings',
        name: 'admin-smtp-settings',
        component:SmtpSettings,
        meta: {
            title: ' admin smtp settings',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    // Google Maps
    {
        path: '/admin/google-maps-api',
        name: 'admin-maps-api',
        component:GoogleMapsApi,
        meta: {
            title: ' admin maps api',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    // Country
    {
        path: '/admin/create-country',
        name: 'admin-create-country',
        component: CreateCountry,
        meta: {
            title: ' admin create country',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/country-list',
        name: 'admin-country-list',
        component: CountryList,
        meta: {
            title: ' admin country list',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/edit-country/:id',
        name: 'admin-edit-country',
        component: EditCountry,
        meta: {
            title: ' admin edit country',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],

        }
    },
    // City
    {
        path: '/admin/create-city',
        name: 'admin-create-city',
        component: CreateCity,
        meta: {
            title: ' admin create city',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/city-list',
        name: 'admin-city-list',
        component: CityList,
        meta: {
            title: ' admin city list',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/edit-city/:id',
        name: 'admin-edit-city',
        component: EditCity,
        meta: {
            title: ' admin edit city',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],

        }
    },

    // Pallet
    {
        path: '/admin/create-pallet',
        name: 'admin-create-pallet',
        component: CreatePallet,
        meta: {
            title: ' admin create pallet',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/pallet-list',
        name: 'admin-pallet-list',
        component: PalletList,
        meta: {
            title: ' admin pallet list',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],
        }
    },
    {
        path: '/admin/edit-pallet/:id',
        name: 'admin-edit-pallet',
        component: EditPallet,
        meta: {
            title: ' admin edit pallet',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],

        }
    },

    {
        path: '/admin/create-order',
        name: 'admin-create-order',
        component: CreateOrder,
        meta: {
            title: ' admin create order',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],

        }
    },

    {
        path: '/admin/request-order-list',
        name: 'admin-request-order-list',
        component: CreatedOrder,
        meta: {
            title: ' admin request order list',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],

        }
    },
    {
        path: '/admin/prepared-list',
        name: 'admin-prepared-list',
        component: PreparerOrder,
        meta: {
            title: ' admin prepared list',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],

        }
    },
    {
        path: '/admin/loading-list',
        name: 'admin-loading-list',
        component: LoadingOrder,
        meta: {
            title: ' admin loading list',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],

        }
    },
    {
        path: '/admin/order-road-list',
        name: 'admin-order-road-list',
        component: RoadOrder,
        meta: {
            title: ' admin order road list',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],

        }
    },
    {
        path: '/admin/order-arrived-list',
        name: 'admin-order-arrived-list',
        component: ArrivedOrder,
        meta: {
            title: ' admin order arrived list',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],

        }
    },
    {
        path: '/admin/order-delivered-list',
        name: 'admin-delivered-list',
        component: DeliveredOrder,
        meta: {
            title: 'admin delivered list',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],

        }
    },

    {
        path: '/admin/order-show/:id',
        name: 'admin-order-show',
        component: OrderShow,
        meta: {
            title: 'admin order show',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],

        }
    },

    {
        path: '/admin/order-invoice/:id',
        name: 'admin-order-invoice-show',
        component: OrderInvoice,
        meta: {
            title: 'admin order invoice show',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],

        }
    },

    {
        path: '/admin/order-process/:id',
        name: 'admin-order-process-show',
        component: OrderProcess,
        meta: {
            title: 'admin  order process show',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],

        }
    },
    {
        path: '/admin/private-email',
        name: 'admin-private-email',
        component: PrivateEmail,
        meta: {
            title: 'admin private email',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],

        }
    },
    {
        path: '/admin/bulk-email',
        name: 'admin-bulk-email',
        component: BulkEmail,
        meta: {
            title: 'admin bulk email',
            roles:["ROLE_ROOT", "ROLE_ADMIN"],

        }
    },


];
// const router = new Router({
//     routes,
//     base: '/',
//     mode: 'history',
//     linkActiveClass: "active",
//     scrollBehavior () {
//         return { x: 0, y: 0 }
//     }
// });
//
// export default router