<template>
  <div
      class="overlay"
  >
<!--    <div class="modal">-->
<!--      <div class="modal__title">-->
<!--        <span>Session Expired</span>-->
<!--      </div>-->
<!--      <div class="p-3">-->
<!--        <p>You have left this browser idle for 10 minutes.</p>-->
<!--        <p>{{ time }} second left</p>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
export default {
  data() {
    return {
      time: 10000
    }
  },
  created() {
    let timerId = setInterval(() => {
      this.time -= 1000;
      if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);
      if (this.time < 1) {
        clearInterval(timerId);
        // Your logout function should be over here
        this.$toasted.global.success();
        this.$router.push({name: 'admin-logout'})
      }
    }, 1000);
    // let timerInterval
    // // eslint-disable-next-line no-undef
    // Swal.fire({
    //   title: 'Auto Close Alert!',
    //   html: 'You have not taken any action within 30 minutes. The session will close after <b></b> seconds.',
    //   timer: this.time,
    //   timerProgressBar: true,
    //   didOpen: () => {
    //     // eslint-disable-next-line no-undef
    //     Swal.showLoading()
    //     // eslint-disable-next-line no-undef
    //     const b = Swal.getHtmlContainer().querySelector('b')
    //     timerInterval = setInterval(() => {
    //       // eslint-disable-next-line no-undef
    //       b.textContent = this.time
    //     }, 1000)
    //   },
    //   willClose: () => {
    //     clearInterval(timerInterval)
    //   }
    // }).then((result) => {
    //   /* Read more about handling dismissals below */
    //   // eslint-disable-next-line no-undef
    //   if (result.dismiss === Swal.DismissReason.timer) {
    //     // eslint-disable-next-line no-undef
    //     // console.log(result.dismiss === Swal.DismissReason.timer)
    //   }
    // })
  }
};
</script>