<template>
  <Layout>
    <br>
    <div>
      <div class="row">
        <div class="col-xl-6">
          <div class="card card-statistics text-center py-5">
            <div class="card-body pricing-content">
              <div class="pricing-content-card">
                  <h3>Stock Transfer Search</h3>
                <hr>
                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label">Start Date</label>
                      <br />
                      <input type="date" v-model="stockTransferSearch.startDate" class="form-control" placeholder="Select start date">
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label">End Date</label>
                      <br />
                      <input type="date" v-model="stockTransferSearch.endDate" class="form-control" placeholder="Select end date">
                    </div>
                  </div>
                  <router-link v-if="stockTransferSearch.startDate != '' && stockTransferSearch.endDate != ''" :to="{ name: 'admin-report-stockTransfer',params:{startDate:stockTransferSearch.startDate, endDate:stockTransferSearch.endDate}}" class="btn btn-sm btn-success">Search</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-6">
          <div class="card card-statistics text-center py-5">
            <div class="card-body pricing-content">
              <div class="pricing-content-card">
                <h3>Order Search</h3>
                <hr>
                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label">Start Date</label>
                      <br />
                      <input type="date" v-model="orderSearch.startDate" class="form-control" placeholder="Select start date">
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label">End Date</label>
                      <br />
                      <input type="date" v-model="orderSearch.endDate" class="form-control" placeholder="Select end date">
                    </div>
                  </div>
                  <router-link v-if="orderSearch.startDate != '' && orderSearch.endDate != ''" :to="{ name: 'admin-report-order',params:{startDate:orderSearch.startDate, endDate:orderSearch.endDate}}" class="btn btn-sm btn-success">Search</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>

</template>


<script>
import Layout from "../../router/layouts/main.vue";
import PageHeader from "@/components/page-header";
import DatePicker from "vue2-datepicker";

export default {
  components:{
    // eslint-disable-next-line vue/no-unused-components
    Layout,PageHeader,DatePicker
  },
  created(){
    // eslint-disable-next-line no-undef
    if(!User.loggedIn()){
      this.$router.push({name: '/admin/login'})
    }
    // let id = this.$route.params.id

  },
  data(){
    return {
      errors:{},
      stockTransferSearch:{
        startDate: '',
        endDate: '',
      },
      orderSearch:{
        startDate: '',
        endDate: '',
      },
    }
  },
  methods: {

  },
}
</script>


<style scoped>

</style>