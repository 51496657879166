<template>
  <Layout>
    <div>
      <div class="col-xl-12">
        <div class="card card-statistics">
          <div class="card-body">
            <form @submit.prevent="cityUpdate" enctype="multipart/form-data">
              <div class="form-row">
                <div class="form-group col-md-4">
                  <label for="city">City</label>
                  <input type="text" v-model="form.city" class="form-control" id="city" :class="{
                    'is-invalid': typeSubmit && $v.form.city.$error,
                  }">
                  <div v-if="typeSubmit && $v.form.city.$error" class="invalid-feedback">
                    <span v-if="!$v.form.city.required">This value is required.</span>
                  </div>
                </div>
                <div class="form-group col-md-4">
                  <label for="plateCode">Plate Code</label>
                  <input type="text" v-model="form.plate_code" class="form-control" id="plateCode">
                </div>
                <div class="form-group col-md-4">
                  <label for="country">Country</label>
                  <select id="country" class="form-control" v-model="form.country_id" :class="{
                    'is-invalid': typeSubmit && $v.form.country_id.$error,
                  }">
                    <option selected="">Choose...</option>
                    <option :key="country.id" :value="country.id" v-for="country in countries">{{ country.country }}</option>
                  </select>
                  <div v-if="typeSubmit && $v.form.country_id.$error" class="invalid-feedback">
                    <span v-if="!$v.form.country_id.required">This value is required.</span>
                  </div>
                </div>
              </div>
              <br>
              <button type="submit" class="btn btn-primary col-xl-12">Save</button>
            </form>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>

<script>

import Layout from "../../router/layouts/main.vue";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {required} from "vuelidate/lib/validators";

export default {
  components:{
    // eslint-disable-next-line vue/no-unused-components
    ckeditor: CKEditor.component,
    Layout,
  },
  name:'admin-create-city',
  created(){
    // eslint-disable-next-line no-undef
    if(!User.loggedIn()){
      this.$router.push({name: 'admin-login'})
    }
    let id = this.$route.params.id
    this.$http.get('city/'+id)
        .then(({data}) => (this.form = data))
        .catch()
    // this.allCountry();
  },
  validations(){
    return{
      form:{
        city: {required},
        country_id: {required},
      },
    }
  },
  data(){
    return {
      form:{
        city: '',
        plate_code: '',
        country_id: '',
      },
      errors:{},
      typeSubmit:false,
      cities:{},
      countries:{},
      editor: ClassicEditor,
    }
  },

  methods:{
    // allCountry(){
    //   this.$http.get('country')
    //       .then(({data}) => (this.countries = data))
    //       .catch()
    // },
    cityUpdate(){
      this.typeSubmit = true;
      this.$v.$touch()

      if(this.$v.$invalid){
        return false;
      }else{
        let id = this.$route.params.id
        this.$http.patch('city/'+id,this.form)
            .then(() => {
              this.$router.push({ name: 'admin-city-list'})
              Notification.success()
            })
            .catch(error => this.errors = error.response.data.errors)
      }
    }
  }
}
</script>
<style scoped>

</style>
