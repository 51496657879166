<template>
  <Layout>
    <div>

      <div class="col-xl-12">
        <div class="card card-statistics">
          <div class="card-body">
            <form @submit.prevent="customerInsert">
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label for="customer_name">Google Api</label>
                  <input type="text" v-model="form.google_api" class="form-control" id="customer_name">
                </div>

              </div>
              <br>

              <button type="submit" class="btn btn-primary col-xl-12">Save</button>
            </form>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>

<script>

import Layout from "../../router/layouts/main.vue";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  components:{
    // eslint-disable-next-line vue/no-unused-components
    ckeditor: CKEditor.component,
    Layout,
  },
  name:'admin-smtp-settings',
  created(){

    // eslint-disable-next-line no-undef
    if(!User.loggedIn()){
      this.$router.push({name: 'admin-login'})
    }
    this.getSmtpSettings();
  },

  data(){
    return {
      google_api:null,
      form:{
        google_api:null,

      },
      errors:{},
      customers:{},
      editor: ClassicEditor,
    }
  },

  methods:{
    getSmtpSettings(){
      this.$http.get('google-maps-api')
          .then((response) => {
            this.form = response.data;


          })
          .catch(error => this.errors = error.response.data.errors)
    },
    customerInsert(){
      this.$http.post('google-maps-api',this.form)
          .then(() => {
            this.$toasted.global.success()
          })
          .catch(error => this.errors = error.response.data.errors)
    }
  }
}
</script>
<style scoped>

</style>
