<template>
  <Layout>
    <br>
    <div class="row">
      <div class="col-md-12">
        <!-- Right Sidebar -->
        <div class="card">
          <div class="card-body">
            <form @submit.prevent="sendBulk">
              <div class="mb-3">
                <label for="customer-select">Customer:</label>
                <multiselect v-model="form.customerList" :options="options" :multiple="true" label="fullname" @input="updateSelected" tag-placeholder="Add this as new tag" track-by="fullname" :taggable="true"></multiselect>
              </div>
              <div class="mb-3">
                <label for="customer-select">To:</label>
                <textarea class="form-control" :readonly="true" v-model="emails" cols="30" rows="3"></textarea>
              </div>
              <div class="mb-3">
                <label for="subject">Subject:</label>
                <input
                    type="text"
                    class="form-control"
                    placeholder="Subject"
                    id="subject"
                    v-model="form.subject"
                />
              </div>
              <div class="mb-3">
                <!-- Editor -->
                <label for="content">Content:</label>
                <ckeditor v-model="form.message" :editor="editor" id="content"></ckeditor>
              </div>
              <div class="mb-3">
                <label for="files">Files:</label>
                <input type="file" multiple class="form-control" id="files">
              </div>
              <div class="btn-toolbar form-group mb-0">
                <div class="col-xl-12">
                  <button class="btn btn-success col-xl-12">
                    <span>Send</span>
                    <i class="fab fa-telegram-plane ms-1"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <!-- end Right Sidebar -->
      </div>
    </div>
    <!-- End row -->
  </Layout>
</template>
<script>
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Layout from "../../router/layouts/main.vue";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";

/**
 * Email Compose component
 */
export default {
  page: {
    title: "Email Compose",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { ckeditor: CKEditor.component, Layout, Multiselect, },
  created() {
    // eslint-disable-next-line no-undef
    if(!User.loggedIn()){
      this.$router.push({name: 'admin-login'})
    }
    this.getCustomers();
  },
  data() {
    return {
      customers:[],
      options:[],
      emails:null,
      form:{
        customerList: [],
        subject: null,
        message: null,
        files: null,
      },
      editor: ClassicEditor,
    };
  },
  methods:{
    getCustomers: async function (){
      await this.$http.get('customer').then((response)=>{
        for (let i=0; i < response.data.length; i++){
          this.options.push({'id':response.data[i].id, 'fullname':response.data[i].customer_name+' '+response.data[i].customer_surname, 'email':response.data[i].customer_email});
        }
        this.customers = response.data;
      }).catch((error)=>{
        console.log(error)
      });
    },
    updateSelected(value) {
      this.emails = null;
      for (let i=0; i < value.length; i++){
        if (this.emails == null){
          this.emails = value[i].email;
        }else{
          this.emails += ', '+value[i].email;
        }
      }
    },
    sendBulk(){
      this.$http.post('send/bulk/',this.form).then(() => {
        window.location.reload();
      }).catch(error=>{
        console.log(error);
      })
    },
  },
};
</script>
