var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('div',[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('b-row',[_c('b-col',{attrs:{"xl":"6"}},[_c('b-input-group',{staticClass:"datatable-btn"},[_c('b-form-input',{attrs:{"placeholder":"Type to Search"},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"disabled":!_vm.filter},on:{"click":function($event){_vm.filter = ''}}},[_vm._v("Clear")])],1)],1)],1),_c('b-col',{attrs:{"xl":"6"}},[_c('b-form-group',{staticClass:"mb-4 datatable-select",attrs:{"label-cols":"2","label":"Per page"}},[_c('b-form-select',{attrs:{"options":_vm.pageOptions},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}})],1)],1)],1),_c('div',{staticClass:"table-responsive datatable-vue"},[_c('b-table',{attrs:{"show-empty":"","stacked":"md","items":_vm.items,"fields":_vm.tablefields,"filter":_vm.filter,"current-page":_vm.currentPage,"per-page":_vm.perPage},on:{"filtered":_vm.onFiltered},scopedSlots:_vm._u([{key:"cell(online)",fn:function(ref){
var item = ref.item;
return [(item.isOnline === 1)?_c('div',[_c('b-badge',{staticClass:"btn btn-success"},[_vm._v("Online")])],1):_c('div',[_c('b-badge',{staticClass:"btn btn-danger"},[_vm._v("Offline")])],1)]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"btn btn-sm btn-dark",attrs:{"to":{ name: 'admin-edit',params:{id:item.id}}}},[_vm._v("Edit")]),_c('a',{directives:[{name:"role",rawName:"v-role",value:(['ROLE_ROOT']),expression:"['ROLE_ROOT']"}],staticClass:"btn btn-sm btn-danger",staticStyle:{"color":"white"},on:{"click":function($event){return _vm.deleteAdmin(item.id)}}},[_vm._v("Delete")])]}},{key:"cell(adminImage)",fn:function(ref){
var item = ref.item;
return [_c('lazy-component',{attrs:{"wrapper-tag":"section"},on:{"intersected":_vm.optionalDispatch}},[_c('img',{attrs:{"src":'https://crmtr.balinsoft.com/public/'+item.photo,"id":"admin_photo"}}),_c('span',{attrs:{"slot":"placeholder"},slot:"placeholder"},[_vm._v("Loading..")])])]}},{key:"cell(adminPhone)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.phone_dial_code + " " + item.phone)+" ")]}}])})],1),_c('b-col',{staticClass:"my-1 p-0",attrs:{"md":"6"}},[_c('b-pagination',{staticClass:"my-0",attrs:{"total-rows":_vm.rows,"per-page":_vm.perPage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }