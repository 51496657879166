<template>
  <Layout>
    <br>
    <div>
      <div class="row">
        <div class="col-xl-6">
          <div class="card card-statistics text-center py-5">
            <div class="card-body pricing-content">
              <div class="pricing-content-card">
                <h3>Stock Transfer Monthly Report</h3>
                <hr>
                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label">Month</label>
                      <br />
                      <select id="month" class="form-control" v-model="stockTransferSearch.month">
                        <option :key="month.id" :value="month.month" v-for="month in months">{{ month.month }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label">Year</label>
                      <br />
                      <select id="month" class="form-control" v-model="stockTransferSearch.year">
                        <option :key="year.id" :value="year.year" v-for="year in years">{{ year.year }}</option>
                      </select>
                    </div>
                  </div>
                  <router-link v-if="stockTransferSearch.month != '' && stockTransferSearch.year != ''" :to="{ name: 'admin-report-monthly-stockTransfer',params:{month:stockTransferSearch.month, year:stockTransferSearch.year}}" class="btn btn-sm btn-success">Search</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-6">
          <div class="card card-statistics text-center py-5">
            <div class="card-body pricing-content">
              <div class="pricing-content-card">
                <h3>Order Monthly Report</h3>
                <hr>
                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label">Month</label>
                      <br />
                      <select id="month" class="form-control" v-model="orderSearch.month">
                        <option :key="month.id" :value="month.month" v-for="month in months">{{ month.month }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label">Year</label>
                      <br />
                      <select id="month" class="form-control" v-model="orderSearch.year">
                        <option :key="year.id" :value="year.year" v-for="year in years">{{ year.year }}</option>
                      </select>
                    </div>
                  </div>
                  <router-link v-if="orderSearch.month != '' && orderSearch.year != ''" :to="{ name: 'admin-report-monthly-order',params:{month:orderSearch.month, year:orderSearch.year}}" class="btn btn-sm btn-success">Search</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>

</template>


<script>
import Layout from "../../router/layouts/main.vue";
import PageHeader from "@/components/page-header";
import DatePicker from "vue2-datepicker";

export default {
  components:{
    // eslint-disable-next-line vue/no-unused-components
    Layout,PageHeader,DatePicker
  },
  created(){
    // eslint-disable-next-line no-undef
    if(!User.loggedIn()){
      this.$router.push({name: '/admin/login'})
    }
    this.getMonth();
    this.getYear();
    // let id = this.$route.params.id

  },
  data(){
    return {
      months:[],
      years:[],
      errors:{},
      stockTransferSearch:{
        month: '',
        year: '',
      },
      orderSearch:{
        month: '',
        year: '',
      },
    }
  },
  methods: {
    getMonth(){
      this.$http.get('month/')
          .then(({data}) => (this.months = data))
          .catch()
    },
    getYear(){
      this.$http.get('year/')
          .then(({data}) => (this.years = data))
          .catch()
    },
  },
}
</script>


<style scoped>

</style>