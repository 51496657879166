<template>
  <Layout>
    <div>
      <!--breadcrumb-->
<!--      <div class="page-breadcrumb d-none d-md-flex align-items-center mb-3">-->
<!--        <div class="breadcrumb-title pe-3"><router-link to="/stock-transfer-list">List Stock Transfer</router-link></div>-->
<!--        <div class="ps-3">-->
<!--          <nav aria-label="breadcrumb">-->
<!--            <ol class="breadcrumb mb-0 p-0">-->
<!--              <li class="breadcrumb-item"><router-link to="/home"><i class='bx bx-home-alt'></i></router-link>-->
<!--              </li>-->
<!--              <li class="breadcrumb-item active" aria-current="page">Show Stock Transfer</li>-->
<!--            </ol>-->
<!--          </nav>-->
<!--        </div>-->
<!--      </div>-->
      <!--end breadcrumb-->
      <div class="row">
        <div class="col-xl-12">
          <div class="card card-statistics text-center py-5">
            <div class="card-body pricing-content">
              <div class="pricing-content-card">
                <h5>Item Detail</h5>
                <!--                            <h3 class="text-primary pt-3">From Warehouse Detail</h3>-->
                <ul class="py-2">
                  <li><strong style="color: black;">Product Name: </strong>{{ items.product_name }}</li>
                  <li><strong style="color: black;">Product Status: </strong>
                    <template>
                      <div v-if="items.product_status === 1 || items.product_status === '1'">
                        <b-badge class="btn btn-success">Active</b-badge>
                      </div>
                      <div v-else>
                        <b-badge class="btn btn-danger">Passive</b-badge>
                      </div>
                    </template>
                  </li>
                  <li><strong style="color: black;">Product Quantity: </strong>{{ items.product_quantity }}</li>
                  <li><strong style="color: black;">Product Tax: </strong>{{ items.product_tax }}</li>
                  <li><strong style="color: black;">Product Description: </strong>{{ items.product_description }}</li>
                  <li><strong style="color: black;">Product Discount Price: </strong>{{ items.product_discount_price }}</li>
                  <li><strong style="color: black;">Product Selling Price: </strong>{{ items.product_selling_price }}</li>
                  <li><strong style="color: black;">Product Code: </strong>{{ items.product_code }}</li>
                  <li><strong style="color: black;">Product Category: </strong>{{ items.category.category_name }}</li>
                  <li><strong style="color: black;">Product Sub Category: </strong>{{ items.subcategory.subcategory_name }}</li>
                  <li v-if="items.collection !== null"><strong style="color: black;">Product Collection: </strong>{{ items.collection.collection_title }}</li>
                  <li v-else=""><strong style="color: black;">Product Collection: </strong></li>
                  <li><strong style="color: black;">Product Unit: </strong>{{ items.unit.unit_name }}</li>
                  <li><strong style="color: black;">Product Barcode: </strong>
                    <div align="center" v-html="items.barcode">{{items.product_code}}</div>
                    <!--                    <h4 class="text-center" style="padding: 1em; margin-top: 2em;" v-html="items.product_code"></h4>-->
                  </li>
                  <li><strong style="color: black;">Product Image: </strong><br>
                    <lazy-component wrapper-tag="section" @intersected="optionalDispatch">
                      <img :src="'https://crmtr.balinsoft.com/public/'+items.product_image">
                      <span slot="placeholder">Loading..</span> <!-- Optional -->
                    </lazy-component>
                    <img :src="'https://crmtr.balinsoft.com/public/'+items.product_image">
                  </li>
                  <li><strong style="color: black;">Product Weight: </strong>{{ items.product_weight }}</li>
                  <li><strong style="color: black;">Product Length: </strong>{{ items.product_length }}</li>
                  <li><strong style="color: black;">Product Width: </strong>{{ items.product_width }}</li>
                  <li><strong style="color: black;">Product Height: </strong>{{ items.product_height }}</li>
                  <li><strong style="color: black;">Product Capacity: </strong>{{ items.product_capacity }}</li>

                  <li v-if="items.product_status === '1'">
                    <span class="badge badge-success">Active</span>
                  </li>
                  <li v-else="">
                    <span class="badge badge-danger">Passive</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../router/layouts/main.vue";
import PageHeader from "@/components/page-header";
import LazyComponent from "v-lazy-component";

export default {
  components:{
    // eslint-disable-next-line vue/no-unused-components
    Layout,PageHeader,LazyComponent
  },
  created(){
    // eslint-disable-next-line no-undef
    if(!User.loggedIn()){
      this.$router.push({name: '/admin/login'})
    }
    this.showItem();
  },
  data(){
    return{
      items:[],
    }
  },
  methods:{
    showItem(){
      let id = this.$route.params.id
      this.$http.get('show-item/'+id)
          .then(({data}) => (this.items = data))
          .catch()
    },
  },
}
</script>

<style scoped>
.v-lazy-component.v-lazy-component--loading {
  filter: blur(20px);
}

.v-lazy-component.v-lazy-component--loaded {
  filter: blur(0);
  transition: filter 1s;
}
</style>
