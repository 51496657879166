<template>
  <Layout>
<!--    <PageHeader :title="title" :items="items" />-->
    <br>
    <div class="row">
      <div class="col-xl-6">
        <div class="row mt-5">
          <div class="col-xl-12 col-md-6">
            <Stat
                :title="'Order Yearly Revenue'"
                :value="getOrderYearlyRevenueTotal"
            />
          </div>
        </div>
      </div>
      <div class="col-xl-6">
        <div class="row mt-5">
          <div class="col-xl-12 col-md-6">
            <Stat
                :title="'Stock Transfer Yearly Expense'"
                :value="getStockTransferYearlyExpenseTotal"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Order Process Expense Graphics</h4>
            <!-- basic column chart -->
            <apexchart
                class="apex-charts"
                dir="ltr"
                height="380"
                type="bar"
                :series="basicColumChart.series"
                :options="basicColumChart.chartOptions"
            ></apexchart>
          </div>
        </div>
      </div>
      <div class="col-xl-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Stock Transfer Process Expense Graphics</h4>
            <!-- basic column chart -->
            <apexchart
                class="apex-charts"
                dir="ltr"
                height="380"
                type="bar"
                :series="basicColumChartTransfer.series"
                :options="basicColumChartTransfer.chartOptions"
            ></apexchart>
          </div>
        </div>
      </div>
      <div class="col-xl-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Top Selling Categories</h4>
            <!-- Simple Pie Chart -->
            <apexchart
                class="apex-charts"
                height="320"
                type="pie"
                :series="simplePieChart.series"
                :options="simplePieChart.chartOptions"
            ></apexchart>
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
<script>
import Layout from "../../router/layouts/main.vue";
// import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Stat from "@/components/widgets/widget-stat";

import {
  linewithDataChart,
  stackedAreaChart,
  // basicColumChart,
  nagativeValueBarChart,
  lineColumAreaChart,
  simpleBubbleChart,
  simplePieChart,
  basicRadialBarChart,
  strokedCircularGuage
} from "./data-apex";

/**
 * Apex-chart component
 */
export default {
  page: {
    title: "Apex chart",
    meta: [{ name: "description", content: appConfig.description }]
  },
  created() {
    // this.getMonth();
    this.$http.get('get/report/graphics').then((response)=>{
      for (let i = 0; i < response.data['order'].length; i++){
        this.basicColumChart.series[0].data.push(response.data['order'][i]['total']);
        this.basicColumChart.chartOptions.xaxis.categories.push(response.data['order'][i].month)
      }
      for (let i = 0; i < response.data['stockTransfer'].length; i++){
        this.basicColumChartTransfer.series[0].data.push(response.data['stockTransfer'][i]['total']);
        this.basicColumChartTransfer.chartOptions.xaxis.categories.push(response.data['stockTransfer'][i].month)
      }
      this.orderYearlyRevenueTotal = response.data['orderYearlyRevenueTotal'];
      this.stockTransferYearlyExpenseTotal = response.data['stockTransferYearlyExpenseTotal'];
    }).catch((error)=>{
      console.log(error);
    })




  },
  components: { Layout,Stat },
  data() {
    return {
      basicColumChart: {
        chartOptions: {
          chart: {
            toolbar: {
              show: false
            }
          },
          plotOptions: {
            bar: {
              horizontal: false,
              endingShape: 'rounded',
              columnWidth: '55%',
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          colors: ['#02a499'],
          xaxis: {
            categories: [],
          },
          legend: {
            offsetY: -5,
          },
          // yaxis: {
          //   title: {
          //     text: '$ (thousands)'
          //   }
          // },
          fill: {
            opacity: 1

          },
          grid: {
            row: {
              colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.2
            },
            borderColor: '#f1f3fa'
          },
          tooltip: {
            y: {
              formatter(val) {
                // return '$ ' + val + ' thousands';
                // return new Intl.NumberFormat('en-IN').format(val);
                return new Intl.NumberFormat('tr-TR').format(val);
              }
            }
          }
        },
        series: [{
          name: 'Order',
          data: []
        }],

      },
      basicColumChartTransfer: {
        chartOptions: {
          chart: {
            toolbar: {
              show: false
            }
          },
          plotOptions: {
            bar: {
              horizontal: false,
              endingShape: 'rounded',
              columnWidth: '55%',
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          colors: ['#02a499'],
          xaxis: {
            categories: [],
          },
          legend: {
            offsetY: -5,
          },
          // yaxis: {
          //   title: {
          //     text: '$ (thousands)'
          //   }
          // },
          fill: {
            opacity: 1

          },
          grid: {
            row: {
              colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.2
            },
            borderColor: '#f1f3fa'
          },
          tooltip: {
            y: {
              formatter(val) {
                // return '$ ' + val + ' thousands';
                // return new Intl.NumberFormat('en-IN').format(val);
                // let value = parseFloat(val).toFixed(2);
                // return new Intl.NumberFormat('en-IN').format(val);
                return new Intl.NumberFormat('tr-TR').format(val);
              }
            }
          }
        },
        series: [{
          name: 'Stock Transfer',
          data: []
        }],

      },
      stockTransferYearlyExpenseTotal: null,
      orderYearlyRevenueTotal: null,
      linewithDataChart: linewithDataChart,
      stackedAreaChart: stackedAreaChart,
      // basicColumChart: basicColumChart,
      nagativeValueBarChart: nagativeValueBarChart,
      lineColumAreaChart: lineColumAreaChart,
      simplePieChart: simplePieChart,
      simpleBubbleChart: simpleBubbleChart,
      basicRadialBarChart: basicRadialBarChart,
      strokedCircularGuage: strokedCircularGuage,
      title: "Apexcharts",
      items: [
        {
          text: "Veltrix",
          href: "/"
        },
        {
          text: "Charts",
          href: "/"
        },
        {
          text: "Apexcharts",
          active: true
        }
      ]
    };
  },
  computed:{
    getOrderYearlyRevenueTotal(){
      return this.orderYearlyRevenueTotal;
    },
    getStockTransferYearlyExpenseTotal(){
      return this.stockTransferYearlyExpenseTotal;
    },
  },
  methods:{
    // getMonth() {
    //   this.$http.get('month').then((response)=>{
    //     for (let i = 0; i < response.data.length; i++){
    //       this.basicColumChart.chartOptions.xaxis.categories.push(response.data[i].month)
    //     }
    //   }).catch((error)=>{
    //     console.log(error);
    //   })
    // },
  }
};
</script>