<template>
  <Layout>
    <br>
    <div class="row">
      <div class="col-md-12" v-for="(process,index) in stockTransferProcess" :key="process.id">
        <b-card v-bind:header="index+1+'. Process'">
          <div class="row">
            <div class="col-md-12" v-if="process.airport_contact_number != null || process.port_contact_number != null || process.station_contact_number != null">
              <b-card header="Transport">
                <b-card-text v-if="process.airport_contact_number != null"><b>AirCraft Name:</b> {{ process.aircraft_name }}</b-card-text>
                <b-card-text v-if="process.airport_contact_number != null"><b>AirCraft Contact:</b> {{ process.dial_code }} {{ process.airport_contact_number }}</b-card-text>
<!--                <b-card-text v-else><b>AirCraft Contact:</b> </b-card-text>-->
                <b-card-text v-if="process.port_contact_number != null"><b>Ship Name:</b> {{ process.ship_name }}</b-card-text>
                <b-card-text v-if="process.port_contact_number != null"><b>Port Contact:</b> {{ process.dial_code }} {{ process.port_contact_number }}</b-card-text>
<!--                <b-card-text v-else><b>Port Contact:</b> </b-card-text>-->
                <b-card-text v-if="process.station_contact_number != null"><b>Train Name:</b> {{ process.train_name }}</b-card-text>
                <b-card-text v-if="process.station_contact_number != null"><b>Station Contact:</b> {{ process.dial_code }} {{ process.station_contact_number }}</b-card-text>
<!--                <b-card-text v-else><b>Station Contact:</b> </b-card-text>-->
              </b-card>
            </div>
<!--            <div class="col-md-4">-->
<!--              <b-card header="Ship">-->
<!--                <b-card-text><b>Ship Name:</b> {{ process.ship_name }}</b-card-text>-->
<!--                <b-card-text v-if="process.port_contact_number != null"><b>Port Contact:</b> {{ process.dial_code }} {{ process.port_contact_number }}</b-card-text>-->
<!--                <b-card-text v-else><b>Port Contact:</b> </b-card-text>-->
<!--              </b-card>-->
<!--            </div>-->
<!--            <div class="col-md-4">-->
<!--              <b-card header="Train">-->
<!--                <b-card-text><b>Train Name:</b> {{ process.train_name }}</b-card-text>-->
<!--                <b-card-text v-if="process.station_contact_number != null"><b>Station Contact:</b> {{ process.dial_code }} {{ process.station_contact_number }}</b-card-text>-->
<!--                <b-card-text v-else><b>Station Contact:</b> </b-card-text>-->
<!--              </b-card>-->
<!--            </div>-->
          </div>
          <div class="row">
            <div class="col-md-4">
              <b-card header="Supplier">
                <b-card-text><b>Supplier:</b> {{ process.supplierInformation.supplier_name }} {{ process.supplierInformation.supplier_surname }}</b-card-text>
                <b-card-text><b>Supplier Phone:</b> {{ process.supplierInformation.supplier_phone_dial_code }} {{ process.supplierInformation.supplier_phone }}</b-card-text>
                <b-card-text><b>Supplier Email:</b> {{ process.supplierInformation.supplier_email }}</b-card-text>
              </b-card>
            </div>
            <div class="col-md-4">
              <b-card header="Truck">
                <b-card-text>
                  <lazy-component wrapper-tag="section" @intersected="optionalDispatch">
                    <img :src="'https://crmtr.balinsoft.com/public/'+process.truckInformation.truck_image" alt="" id="truck_image">
                    <span slot="placeholder">Loading..</span> <!-- Optional -->
                  </lazy-component>
<!--                  <img :src="'https://crmtr.balinsoft.com/public/'+process.truckInformation.truck_image" alt="" id="truck_image">-->
                </b-card-text>
                <b-card-text><b>Truck Brand - Model:</b> {{ process.truckInformation.truck_brand }} - {{ process.truckInformation.truck_model }}</b-card-text>
                <b-card-text><b>Truck Code:</b> {{ process.truckInformation.truck_code }}</b-card-text>
                <b-card-text><b>Truck Plate Number:</b> {{ process.truckInformation.truck_plate_number }}</b-card-text>
              </b-card>
            </div>
            <div class="col-md-4">
              <b-card header="Detail">
                <b-card-text><b>Source:</b> {{ process.source }}</b-card-text>
                <b-card-text><b>Target:</b> {{ process.target }}</b-card-text>
                <b-card-text><b>Start Date:</b> {{ process.start_date }}</b-card-text>
                <b-card-text><b>End Date:</b> {{ process.end_date }}</b-card-text>
                <b-card-text><b>Price:</b> {{ process.price }}</b-card-text>
                <b-card-text><b>Extra Price:</b> {{ process.extra_price }}</b-card-text>
                <b-card-text v-if="process.status === 0"><b>Status:</b> <span class="btn btn-sm btn-danger">Request Created</span></b-card-text>
                <b-card-text v-if="process.status === 0"><b-progress :value="25" :max="100" class="mb-3" variant="danger" animated></b-progress></b-card-text>
                <b-card-text v-if="process.status === 1"><b>Status:</b> <span class="btn btn-sm btn-warning">Products are being prepared</span></b-card-text>
                <b-card-text v-if="process.status === 1"><b-progress :value="50" :max="100" class="mb-3" variant="warning" animated></b-progress></b-card-text>
                <b-card-text v-if="process.status === 2"><b>Status:</b> <span class="btn btn-sm btn-primary">On The Road</span></b-card-text>
                <b-card-text v-if="process.status === 2"><b-progress :value="75" :max="100" class="mb-3" variant="primary" animated></b-progress></b-card-text>
                <b-card-text v-if="process.status === 3"><b>Status:</b> <span class="btn btn-sm btn-success">Delivered</span></b-card-text>
                <b-card-text v-if="process.status === 3"><b-progress :value="100" :max="100" class="mb-3" variant="success" animated></b-progress></b-card-text>
              </b-card>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <b-card header="Note">
                <b-card-text>{{ process.notes }}</b-card-text>
              </b-card>
            </div>
          </div>

          <b-card-text><button :id="'buttonShow'+index" class="btn btn-sm btn-success col-xl-12" v-if="process.status < 3" @click="processStatus(index)">Change</button></b-card-text>
        </b-card>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/router/layouts/main";
import PageHeader from "@/components/page-header";
import LazyComponent from "v-lazy-component";

export default {
  name: "showProcess",
  components:{
    // eslint-disable-next-line vue/no-unused-components
    Layout,PageHeader,LazyComponent
  },
  created(){
    // eslint-disable-next-line no-undef
    if(!User.loggedIn()){
      this.$router.push({name: '/admin/login'})
    }
    this.id = this.$route.params.id
    this.getProcess();
  },
  data(){
    return {
      stockTransferProcess: [],
      errors:{},
      id: null,
    }
  },
  methods:{
    getProcess(){
      this.$http.get('stockTransfer/show/process/'+this.id)
          .then(({data}) => (this.stockTransferProcess = data))
          .catch()
    },
    processStatus(index){
        this.$http.post('search-stock-transfer/' + this.id + '/' + index)
            .then(() => {
              this.getProcess();
            })
            .catch()
    },
  },
  computed:{

  },
}
</script>

<style scoped>
#truck_image{
  width: 100px;
  height: 100px;
}
.v-lazy-component.v-lazy-component--loading {
  filter: blur(20px);
}

.v-lazy-component.v-lazy-component--loaded {
  filter: blur(0);
  transition: filter 1s;
}
</style>
