var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('div',[_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"card card-statistics"},[_c('div',{staticClass:"card-body"},[_c('form',{attrs:{"enctype":"multipart/form-data"},on:{"submit":function($event){$event.preventDefault();return _vm.truckUpdate.apply(null, arguments)}}},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-12"},[_c('label',{attrs:{"for":"supplier"}},[_vm._v("Supplier")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.supplier_id),expression:"form.supplier_id"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.typeSubmit && _vm.$v.form.supplier_id.$error,
                },attrs:{"id":"supplier"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "supplier_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.suppliers),function(supplier){return _c('option',{key:supplier.id,domProps:{"value":supplier.id}},[_vm._v(_vm._s(supplier.supplier_name)+" "+_vm._s(supplier.supplier_surname))])}),0),(_vm.typeSubmit && _vm.$v.form.supplier_id.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.supplier_id.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()])]),_c('br'),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-6"},[_c('label',{attrs:{"for":"truckBrand"}},[_vm._v("Truck Brand")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.truck_brand),expression:"form.truck_brand"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.typeSubmit && _vm.$v.form.truck_brand.$error,
                },attrs:{"type":"text","min":"1","id":"truckBrand"},domProps:{"value":(_vm.form.truck_brand)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "truck_brand", $event.target.value)}}}),(_vm.typeSubmit && _vm.$v.form.truck_brand.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.truck_brand.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group col-md-6"},[_c('label',{attrs:{"for":"truckModel"}},[_vm._v("Truck Model")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.truck_model),expression:"form.truck_model"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.typeSubmit && _vm.$v.form.truck_model.$error,
                },attrs:{"type":"text","min":"1","id":"truckModel"},domProps:{"value":(_vm.form.truck_model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "truck_model", $event.target.value)}}}),(_vm.typeSubmit && _vm.$v.form.truck_model.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.truck_model.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()])]),_c('br'),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-6"},[_c('label',{attrs:{"for":"truckPlateNumber"}},[_vm._v("Truck Plate Number")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.truck_plate_number),expression:"form.truck_plate_number"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.typeSubmit && _vm.$v.form.truck_plate_number.$error,
                },attrs:{"type":"text","id":"truckPlateNumber"},domProps:{"value":(_vm.form.truck_plate_number)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "truck_plate_number", $event.target.value)}}}),(_vm.typeSubmit && _vm.$v.form.truck_plate_number.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.truck_plate_number.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group col-md-6"},[_c('label',{attrs:{"for":"truckStartKm"}},[_vm._v("Truck Start Km")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.truck_start_km),expression:"form.truck_start_km"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.typeSubmit && _vm.$v.form.truck_start_km.$error,
                },attrs:{"type":"text","min":"1","id":"truckStartKm"},domProps:{"value":(_vm.form.truck_start_km)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "truck_start_km", $event.target.value)}}}),(_vm.typeSubmit && _vm.$v.form.truck_start_km.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.truck_start_km.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()])]),_c('br'),_c('div',{staticClass:"form-row"},[_c('label',[_vm._v("Truck Image")]),_c('div',{staticClass:"col-md-8 input-group"},[_c('input',{staticClass:"form-control",class:{
                  'is-invalid': _vm.typeSubmit && _vm.$v.form.truck_image.$error,
                },attrs:{"type":"file","id":"inputGroupFile02"},on:{"change":_vm.onFileSelected}}),_c('label',{staticClass:"input-group-text",attrs:{"for":"inputGroupFile02"}},[_vm._v("Upload")]),(_vm.typeSubmit && _vm.$v.form.truck_image.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.truck_image.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()]),_c('br'),_c('br'),_c('div',{staticClass:"form-group col-md-2"},[_c('img',{attrs:{"src":_vm.form.truck_image,"id":"truck_photo"}})]),_c('div',{staticClass:"form-group col-md-12 mt-3"},[_c('lazy-component',{attrs:{"wrapper-tag":"section"},on:{"intersected":_vm.optionalDispatch}},[_c('img',{attrs:{"src":'https://crmtr.balinsoft.com/public/'+_vm.form.truck_image,"id":"truck_photo"}}),_c('span',{attrs:{"slot":"placeholder"},slot:"placeholder"},[_vm._v("Loading..")])])],1)]),_c('br'),_c('button',{staticClass:"btn btn-primary col-xl-12",attrs:{"type":"submit"}},[_vm._v("Save")])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }