<template>
  <Layout>
    <div class="container">
      <div class="row justify-content-center">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-header">We Code Messenger</div>
              <div class="card-body">
                <div class="chat-app">
                  <Conversation :contact="selectedContact" :messages="messages" @new="saveNewMessage"/>
                  <ContactsList :contacts="contacts" @selected="startConversationWith" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
<!--    <div class="chat-app">-->
<!--      selam-->
<!--      <Conversation :contact="selectedContact" :messages="messages" />-->
<!--      <ContactsList :contacts="contacts" @selected="startConversationWith" />-->
<!--    </div>-->
  </Layout>
</template>

<script>

import Layout from "../../router/layouts/main.vue";
import PageHeader from "@/components/page-header";
import Conversation from "./Conversation";
import ContactsList from "./ContactsList";
// import Echo from 'laravel-echo'
// if (process.client) {
//   window.io = require('socket.io-client')
// }


export default {
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  components:{
    // eslint-disable-next-line vue/no-unused-components
    Conversation,ContactsList,
    // eslint-disable-next-line vue/no-unused-components
    Layout,PageHeader
  },
  created(){
    // eslint-disable-next-line no-undef
    if(!User.loggedIn()){
      this.$router.push({name: '/admin/login'})
    }
  },
  data(){
    return{
      selectedContact: null,
      messages: [],
      contacts: [],
      userId: 1,
    }
  },


  mounted(){
    // let echo = new Echo({
    //   broadcaster: 'pusher',
    //   key: process.env.VUE_APP_WEBSOCKETS_KEY,
    //   wsHost: process.env.VUE_APP_WEBSOCKETS_SERVER,
    //   cluster: process.env.PUSHER_APP_CLUSTER,
    //   encrypted: true,
    //   wsPort: 6001,
    //   forceTLS: false,
    //   disableStats: true
    // })
    // echo.private(`messages${this.user.id}`)
    //     .listen('NewMessages', (e) => {
    //       this.handleInComing(e.message);
    //     })
    // eslint-disable-next-line no-undef
    // Echo.private(`messages${this.user.id}`).listen('NewMessages',(e) => {
    //   this.handleInComing(e.message);
    // })
    window.Echo.channel(`messages${window.localStorage.getItem('user_id')}`).listen('NewMessages',(e) => {
      this.handleInComing(e.message);
    });
    this.$http.get('contacts/').then(response=>{
      this.contacts = response.data;
      // this.setChatRoom(response.data[0]);
    }).catch();
  },
  computed:{


  },
  methods:{
    startConversationWith(contact){
      this.$http.get(`conversation/${contact.id}`).then(response=>{
        this.messages = response.data;
        this.selectedContact = contact;
        // this.setChatRoom(response.data[0]);
      }).catch();
    },
    saveNewMessage(text){
      this.messages.push(text);
    },
    handleInComing(message){
      if (this.selectedContact && message.from === this.selectedContact.id){
        this.saveNewMessage(message);
      }
      alert(message.text);
    },
  },


}
</script>

<style lang="scss" scoped>
.card-body{
  padding: 0 !important;
}
.chat-app{
  display: flex;
}
</style>