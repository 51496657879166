var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"card card-statistics h-100 mb-0 apexchart-tool-force-top"},[_c('div',{staticClass:"card-header d-flex justify-content-between"},[_c('div',{staticClass:"card-heading"},[_c('h4',{staticClass:"card-title .customCardTitle"},[_vm._v("All Transaction")])])]),_c('div',{staticClass:"card-body"},[_c('b-tabs',{staticClass:"navtab-bg",attrs:{"justified":"","pills":"","content-class":"p-3"}},[_c('b-tab',{attrs:{"active":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticClass:"d-inline-block d-sm-none"},[_c('i',{staticClass:"fas fa-home"})]),_c('span',{staticClass:"d-none d-sm-inline-block"},[_vm._v("Stock Transfer Transaction")])]},proxy:true}])},[_c('div',[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('b-row',[_c('b-col',{attrs:{"xl":"6"}},[_c('b-input-group',{staticClass:"datatable-btn"},[_c('b-form-input',{attrs:{"placeholder":"Type to Search"},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"disabled":!_vm.filter},on:{"click":function($event){_vm.filter = ''}}},[_vm._v("Clear")])],1)],1)],1),_c('b-col',{attrs:{"xl":"6"}},[_c('b-form-group',{staticClass:"mb-4 datatable-select",attrs:{"label-cols":"2","label":"Per page"}},[_c('b-form-select',{attrs:{"options":_vm.pageOptions},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}})],1)],1)],1),_c('div',{staticClass:"table-responsive datatable-vue"},[_c('b-table',{attrs:{"show-empty":"","stacked":"md","items":_vm.items,"fields":_vm.tablefields,"filter":_vm.filter,"current-page":_vm.currentPage,"per-page":_vm.perPage},on:{"filtered":_vm.onFiltered},scopedSlots:_vm._u([{key:"cell(productStockControl)",fn:function(ref){
var item = ref.item;
return [(item.product_quantity > 100)?_c('div',[_c('b-spinner',{staticClass:"m-1",attrs:{"type":"grow","label":"Spinning","variant":"success"}})],1):(item.product_quantity < 100 && item.product_quantity > 0)?_c('div',[_c('b-spinner',{staticClass:"m-1",attrs:{"type":"grow","label":"Spinning","variant":"warning"}})],1):_c('div',[_c('b-spinner',{staticClass:"m-1",attrs:{"type":"grow","label":"Spinning","variant":"danger"}})],1)]}},{key:"cell(supplierFullName)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.supplier_name + ' '+ item.supplier_surname)+" ")]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [(item.status === 0)?_c('b-badge',{staticClass:"btn btn-primary"},[_vm._v(_vm._s(item.status_text[0].status_text))]):_vm._e(),(item.status === 1)?_c('b-badge',{staticClass:"btn btn-primary"},[_vm._v(_vm._s(item.status_text[0].status_text))]):_vm._e(),(item.status === 2)?_c('b-badge',{staticClass:"btn btn-primary"},[_vm._v(_vm._s(item.status_text[0].status_text))]):_vm._e(),(item.status === 3)?_c('b-badge',{staticClass:"btn btn-primary"},[_vm._v(_vm._s(item.status_text[0].status_text))]):_vm._e(),(item.status === 4)?_c('b-badge',{staticClass:"btn btn-primary"},[_vm._v(_vm._s(item.status_text[0].status_text))]):_vm._e(),(item.status === 5)?_c('b-badge',{staticClass:"btn btn-primary"},[_vm._v(_vm._s(item.status_text[0].status_text))]):_vm._e(),(item.status === 6)?_c('b-badge',{staticClass:"btn btn-danger"},[_vm._v(_vm._s(item.status_text[0].status_text))]):_vm._e(),(item.status === 7)?_c('b-badge',{staticClass:"btn btn-success"},[_vm._v(_vm._s(item.status_text[0].status_text))]):_vm._e(),(item.status === 8)?_c('b-badge',{staticClass:"btn btn-primary"},[_vm._v(_vm._s(item.status_text[0].status_text))]):_vm._e(),(item.status === 9)?_c('b-badge',{staticClass:"btn btn-danger"},[_vm._v(_vm._s(item.status_text[0].status_text))]):_vm._e(),(item.status === 10)?_c('b-badge',{staticClass:"btn btn-danger"},[_vm._v(_vm._s(item.status_text[0].status_text))]):_vm._e(),(item.status === 11)?_c('b-badge',{staticClass:"btn btn-danger"},[_vm._v(_vm._s(item.status_text[0].status_text))]):_vm._e(),(item.status === 12)?_c('b-badge',{staticClass:"btn btn-primary"},[_vm._v(_vm._s(item.status_text[0].status_text))]):_vm._e()]}},{key:"cell(fromWarehouse_type)",fn:function(ref){
var item = ref.item;
return [(item.fromWarehouse_type === 1)?_c('div',[_c('b-badge',{staticClass:"btn btn-success"},[_vm._v("Main Warehouse")])],1):_c('div',[_c('b-badge',{staticClass:"btn btn-warning"},[_vm._v("Sub Warehouse")])],1)]}},{key:"cell(toWarehouse_type)",fn:function(ref){
var item = ref.item;
return [(item.toWarehouse_type === 1)?_c('div',[_c('b-badge',{staticClass:"btn btn-success"},[_vm._v("Main Warehouse")])],1):_c('div',[_c('b-badge',{staticClass:"btn btn-warning"},[_vm._v("Sub Warehouse")])],1)]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"btn btn-sm btn-dark",attrs:{"to":{ name: 'admin-stock-transfer-show',params:{id:item.id}}}},[_vm._v("Show")]),_c('router-link',{staticClass:"btn btn-sm btn-success",attrs:{"to":{ name: 'admin-stock-transfer-invoice',params:{id:item.id}}}},[_vm._v("Invoice")])]}}])})],1),_c('b-col',{staticClass:"my-1 p-0",attrs:{"md":"6"}},[_c('b-pagination',{staticClass:"my-0",attrs:{"total-rows":_vm.rows,"per-page":_vm.perPage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)])])]),_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticClass:"d-inline-block d-sm-none"},[_c('i',{staticClass:"fas fa-cog"})]),_c('span',{staticClass:"d-none d-sm-inline-block"},[_vm._v("Order Transaction")])]},proxy:true}])},[_c('div',[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('b-row',[_c('b-col',{attrs:{"xl":"6"}},[_c('b-input-group',{staticClass:"datatable-btn"},[_c('b-form-input',{attrs:{"placeholder":"Type to Search"},model:{value:(_vm.filterOrder),callback:function ($$v) {_vm.filterOrder=$$v},expression:"filterOrder"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"disabled":!_vm.filterOrder},on:{"click":function($event){_vm.filterOrder = ''}}},[_vm._v("Clear")])],1)],1)],1),_c('b-col',{attrs:{"xl":"6"}},[_c('b-form-group',{staticClass:"mb-4 datatable-select",attrs:{"label-cols":"2","label":"Per page"}},[_c('b-form-select',{attrs:{"options":_vm.pageOptionsOrder},model:{value:(_vm.perPageOrder),callback:function ($$v) {_vm.perPageOrder=$$v},expression:"perPageOrder"}})],1)],1)],1),_c('div',{staticClass:"table-responsive datatable-vue"},[_c('b-table',{attrs:{"show-empty":"","stacked":"md","items":_vm.itemOrders,"fields":_vm.tablefieldsOrders,"filter":_vm.filterOrder,"current-page":_vm.currentPageOrder,"per-page":_vm.perPageOrder},on:{"filtered":_vm.onFilteredOrder},scopedSlots:_vm._u([{key:"cell(supplierFullName)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.supplier_name + ' '+ item.supplier_surname)+" ")]}},{key:"cell(customerFullName)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.customer_name + ' '+ item.customer_surname)+" ")]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [(item.status === 0)?_c('b-badge',{staticClass:"btn btn-primary"},[_vm._v(_vm._s(item.status_text[0].status_text))]):_vm._e(),(item.status === 1)?_c('b-badge',{staticClass:"btn btn-primary"},[_vm._v(_vm._s(item.status_text[0].status_text))]):_vm._e(),(item.status === 2)?_c('b-badge',{staticClass:"btn btn-primary"},[_vm._v(_vm._s(item.status_text[0].status_text))]):_vm._e(),(item.status === 3)?_c('b-badge',{staticClass:"btn btn-primary"},[_vm._v(_vm._s(item.status_text[0].status_text))]):_vm._e(),(item.status === 4)?_c('b-badge',{staticClass:"btn btn-primary"},[_vm._v(_vm._s(item.status_text[0].status_text))]):_vm._e(),(item.status === 5)?_c('b-badge',{staticClass:"btn btn-primary"},[_vm._v(_vm._s(item.status_text[0].status_text))]):_vm._e()]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"btn btn-sm btn-dark",attrs:{"to":{ name: 'admin-order-show',params:{id:item.id}}}},[_vm._v("Show")]),_c('router-link',{staticClass:"btn btn-sm btn-success",attrs:{"to":{ name: 'admin-order-invoice-show',params:{id:item.id}}}},[_vm._v("Invoice")])]}}])})],1),_c('b-col',{staticClass:"my-1 p-0",attrs:{"md":"6"}},[_c('b-pagination',{staticClass:"my-0",attrs:{"total-rows":_vm.rowsOrders,"per-page":_vm.perPageOrder},model:{value:(_vm.currentPageOrder),callback:function ($$v) {_vm.currentPageOrder=$$v},expression:"currentPageOrder"}})],1)],1)])])])],1)],1)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }