<script>
/**
 * Transaction component
 */
export default {
  props: {
    transactions: {
      type: Array,
      default: function() {
        return [];
      }
    }
  }
};
</script>

<template>
  <div class="table-responsive mb-0">
    <table class="table table-hover table-centered table-nowrap">
      <thead>
        <tr>
          <th scope="col">Code</th>
          <th scope="col">Start Date</th>
          <th scope="col">Finish Date</th>
          <th scope="col">Total Process</th>
          <th scope="col" colspan="2">Status</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(data, key) in transactions" :key="data.id">
          <th scope="row">{{data.code}}</th>
          <td>
            <div>
              {{data.start_transfer_date}}
            </div>
          </td>
          <td>{{data.finish_transfer_date}}</td>
          <td>{{data.total_process}}</td>
          <td>
            {{transactions[key].status.status_text}}
          </td>
          <td>
            <div>
              <router-link :to="{ name: 'admin-order-show',params:{id:data.id}}" class="btn btn-sm btn-dark">Show</router-link>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>