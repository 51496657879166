<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          {{ panelSettings.copyright}}
        </div>
      </div>
    </div>
  </footer>
  <!-- end footer -->
</template>
<script>

export default {
  // eslint-disable-next-line vue/no-unused-components
  created(){
    // eslint-disable-next-line no-undef
    this.allPanelSettings();

  },
  data(){
    return{
      panelSettings:[],
    }
  },
  methods:{
    allPanelSettings(){
      this.$http.get('panel-settings')
          .then(({data}) => (this.panelSettings = data))
          .catch()
    },

  },


}
</script>