<!--<template>-->
<!--  <div class="account-pages my-5 pt-5">-->
<!--    <div class="container">-->
<!--      <div class="row justify-content-center">-->
<!--        <div class="col-md-8 col-lg-6 col-xl-4">-->
<!--          <div class="card overflow-hidden">-->
<!--            <div class="bg-primary">-->
<!--              <div class="text-primary text-center p-4">-->
<!--                <h5 class="text-white font-size-20">{{ panelSettings.title }}</h5>-->
<!--                <p class="text-white-50">{{ panelSettings.description }}</p>-->
<!--                <a href="/" class="logo logo-admin">-->
<!--                  <img-->
<!--                    src="@/assets/images/logo-sm.png"-->
<!--                    height="24"-->
<!--                    alt="logo"-->
<!--                  />-->
<!--                </a>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="card-body p-4">-->
<!--              <div class="p-3">-->
<!--                <b-form-->
<!--                  class="form-horizontal mt-4" @submit.prevent="login">-->
<!--                  <b-form-group-->
<!--                    id="input-group-1"-->
<!--                    label="Email"-->
<!--                    label-for="input-1"-->
<!--                    class="mb-3"-->
<!--                    label-class="form-label"-->
<!--                  >-->
<!--                    <b-form-input-->
<!--                      id="input-1"-->
<!--                      v-model="form.email"-->
<!--                      type="email"-->
<!--                      placeholder="Enter email"-->
<!--                    ></b-form-input>-->

<!--                  </b-form-group>-->

<!--                  <b-form-group-->
<!--                    id="input-group-2"-->
<!--                    label="Password"-->
<!--                    label-for="input-2"-->
<!--                    class="mb-3"-->
<!--                    label-class="form-label"-->
<!--                  >-->
<!--                    <b-form-input-->
<!--                      id="input-2"-->
<!--                      v-model="form.password"-->
<!--                      type="password"-->
<!--                      placeholder="Enter password"-->
<!--                    ></b-form-input>-->
<!--                    <div-->
<!--                      class="invalid-feedback"-->
<!--                    >-->
<!--                      Password is required.-->
<!--                    </div>-->
<!--                  </b-form-group>-->

<!--                  <div class="form-group row">-->
<!--                    <div class="col-sm-12 text-end">-->
<!--                      <b-button type="submit" variant="primary" class="col-md-12"-->
<!--                        >Log In</b-button-->
<!--                      >-->
<!--                    </div>-->
<!--                  </div>-->

<!--                  <div class="mt-2 mb-0 row text-center">-->
<!--                    <div class="col-12 mt-4">-->
<!--                      <router-link to="/forgot-password">-->
<!--                        <i class="mdi mdi-lock"></i> Forgot your password?-->
<!--                      </router-link>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </b-form>-->
<!--              </div>-->
<!--            </div>-->
<!--            &lt;!&ndash; end card-body &ndash;&gt;-->
<!--          </div>-->
<!--          &lt;!&ndash; end card &ndash;&gt;-->
<!--          <div class="mt-5 text-center">-->
<!--            <p class="mb-0">-->
<!--              {{ panelSettings.copyright }}-->
<!--            </p>-->
<!--          </div>-->
<!--        </div>-->
<!--        &lt;!&ndash; end col &ndash;&gt;-->
<!--      </div>-->
<!--      &lt;!&ndash; end row &ndash;&gt;-->
<!--    </div>-->
<!--  </div>-->
<!--</template>-->

<template>
  <div>
    <div
        class="accountbg"
        :style="{'background': 'url(' + require('@/assets/images/bg.jpg') + ')', 'background-size': 'cover', 'background-position': 'center'}"
    >
    </div>
    <div class="wrapper-page account-page-full">
      <div class="card shadow-none">
        <div class="card-block">
          <div class="account-box">
            <div class="card-box shadow-none p-4">
              <div class="p-2">
                <div class="text-center mt-4">
                  <router-link to="/">
                    <img src="@/assets/images/logo-dark.png" height="22" alt="logo" />
                  </router-link>
                </div>

                <h4 class="font-size-18 mt-5 text-center">{{ panelSettings.title }}</h4>
                <p class="text-muted text-center">{{ panelSettings.description }}</p>

                <b-form
                    class="form-horizontal mt-4" @submit.prevent="login">
                  <b-form-group
                      id="input-group-1"
                      label="Email"
                      label-for="input-1"
                      class="mb-3"
                      label-class="form-label"
                  >
                    <b-form-input
                        id="input-1"
                        v-model="form.email"
                        type="email"
                        placeholder="Enter email"
                    ></b-form-input>

                  </b-form-group>

                  <b-form-group
                      id="input-group-2"
                      label="Password"
                      label-for="input-2"
                      class="mb-3"
                      label-class="form-label"
                  >
                    <b-form-input
                        id="input-2"
                        v-model="form.password"
                        type="password"
                        placeholder="Enter password"
                    ></b-form-input>
                    <div
                        class="invalid-feedback"
                    >
                      Password is required.
                    </div>
                  </b-form-group>

                  <div class="form-group row">
                    <div class="col-sm-12 text-end">
                      <b-button type="submit" variant="primary" class="col-md-12"
                      >Log In</b-button
                      >
                    </div>
                  </div>

                  <div class="mt-2 mb-0 row text-center">
                    <div class="col-12 mt-4">
                      <router-link to="/forgot-password">
                        <i class="mdi mdi-lock"></i> Forgot your password?
                      </router-link>
                    </div>
                  </div>
                </b-form>
<!--                <form class="mt-4" action="#">-->
<!--                  <div class="mb-3">-->
<!--                    <label class="form-label" for="username">Username</label>-->
<!--                    <input-->
<!--                        type="text"-->
<!--                        class="form-control"-->
<!--                        id="username"-->
<!--                        placeholder="Enter username"-->
<!--                    />-->
<!--                  </div>-->

<!--                  <div class="mb-3">-->
<!--                    <label class="form-label" for="userpassword">Password</label>-->
<!--                    <input-->
<!--                        type="password"-->
<!--                        class="form-control"-->
<!--                        id="userpassword"-->
<!--                        placeholder="Enter password"-->
<!--                    />-->
<!--                  </div>-->

<!--                  <div class="mb-3 row">-->
<!--                    <div class="col-sm-6">-->
<!--                      <div class="form-check">-->
<!--                        <input type="checkbox" class="form-check-input" id="customControlInline">-->
<!--                        <label class="form-check-label" for="customControlInline">Remember me</label>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="col-sm-6 text-end">-->
<!--                      <button class="btn btn-primary w-md" type="submit">Log In</button>-->
<!--                    </div>-->
<!--                  </div>-->

<!--                  <div class="form-group mt-2 mb-0 row">-->
<!--                    <div class="col-12 mt-3">-->
<!--                      <router-link to="/forgot-password">-->
<!--                        <i class="mdi mdi-lock"></i> Forgot your password?-->
<!--                      </router-link>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </form>-->

                <div class="mt-5 pt-4 text-center">
<!--                  <p>-->
<!--                    Don't have an account ?-->
<!--                    <router-link-->

<!--                        to="/pages/register-2"-->
<!--                        class="fw-medium text-primary"-->
<!--                    >Signup now</router-link>-->
<!--                  </p>-->
                  <p>
                    {{ panelSettings.copyright }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import User from "../../../helpers/authservice/User";

export default {
  // eslint-disable-next-line vue/no-unused-components
  // components: {  },
  created(){
    this.allPanelSettings();
    if(User.loggedIn()){
      this.$router.push({name: 'admin'})
    }
  },

  data(){
    return {
      form:{
        email: null,
        password: null
      },
      errors:{},
      panelSettings:[],
    }
  },
  methods:{
    allPanelSettings(){
      this.$http.get('panel-settings')
          .then(({data}) => (this.panelSettings = data))
          .catch()
    },
    login(){
      this.$http.post('auth/admin/login',this.form)
          .then(res => {
            // eslint-disable-next-line no-console
            User.responseAfterLogin(res)
            this.$router.push({ name: 'admin' })
            // window.location.href = '/';
          })
          .catch(error => this.errors = error.response.data,
          )
    }
  }
}
</script>
<style lang="scss" module></style>
