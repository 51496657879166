<template>
  <div>
    <div
        class="accountbg"
        :style="{'background': 'url(' + require('@/assets/images/bg.jpg') + ')', 'background-size': 'cover', 'background-position': 'center'}"
    >
    </div>
    <div class="wrapper-page account-page-full">
      <div class="card shadow-none">
        <div class="card-block">
          <div class="account-box">
            <div class="card-box shadow-none p-4">
              <div class="p-2">
                <div class="text-center mt-4">
                  <router-link to="/">
                    <img src="@/assets/images/logo-dark.png" height="22" alt="logo" />
                  </router-link>
                </div>

                <h4 class="font-size-18 mt-5 text-center">{{ panelSettings.title }}</h4>
                <p class="text-muted text-center">{{ panelSettings.description }}</p>
                <h4 class="font-size-18 mt-5 text-center">Locked</h4>
                <p
                    class="text-muted text-center"
                >Hello, enter your password to unlock the screen!</p>
                <b-form
                    class="form-horizontal mt-4" @submit.prevent="unlock">
                  <b-form-group
                      id="input-group-2"
                      label="Password"
                      label-for="input-2"
                      class="mb-3"
                      label-class="form-label"
                  >
                    <b-form-input
                        id="input-2"
                        v-model="form.password"
                        type="password"
                        placeholder="Enter password"
                    ></b-form-input>
                    <div
                        class="invalid-feedback"
                    >
                      Password is required.
                    </div>
                  </b-form-group>

                  <div class="form-group row">
                    <div class="col-sm-12 text-end">
                      <b-button type="submit" variant="primary" class="col-md-12"
                      >Unlock</b-button
                      >
                    </div>
                  </div>
                  <div class="mt-2 mb-0 row text-center">
                    <div class="col-12 mt-4">
                      <router-link to="/admin/logout">
                        <i class="mdi mdi-lock"></i> Login
                      </router-link>
                    </div>
                  </div>
                </b-form>

                <div class="mt-5 pt-4 text-center">
                  <p>
                    {{ panelSettings.copyright }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import User from "../../../helpers/authservice/User";

export default {
  // eslint-disable-next-line vue/no-unused-components
  // components: {  },
  created(){
    this.allPanelSettings();
    localStorage.removeItem('token')
    if(User.loggedIn()){
      this.$router.push({name: 'admin'})
    }
  },

  data(){
    return {
      form:{
        email: User.userEmail(),
        password: null
      },
      errors:{},
      panelSettings:[],
    }
  },
  computed:{
    userFullName(){
      return User.userName() + ' ' + User.userSurname();
    },
  },
  methods:{
    allPanelSettings(){
      this.$http.get('panel-settings')
          .then(({data}) => (this.panelSettings = data))
          .catch()
    },
    unlock(){
      this.$http.post('auth/admin/login',this.form)
          .then(res => {
            localStorage.clear();
            // eslint-disable-next-line no-console
            User.responseAfterLogin(res)
            this.$router.push({ name: 'admin' })
            // window.location.href = '/';
          })
          .catch(error => this.errors = error.response.data,
          )
    }
  }
}
</script>
<style lang="scss" module></style>
