<template>
  <Layout>
    <div>

      <div class="col-xl-12">
        <div class="card card-statistics">
          <div class="card-body">
            <form @submit.prevent="customerUpdate">
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="customer_name">Customer Name</label>
                  <input type="text" v-model="form.customer_name" class="form-control" id="customer_name">
                </div>
                <div class="form-group col-md-6">
                  <label for="customer_surname">Customer Surname</label>
                  <input type="text" v-model="form.customer_surname" class="form-control" id="customer_surname">
                </div>
              </div>
              <br>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="customer_country">Country</label>
                  <select @change="switchCountrySelect($event)" id="customer_country" class="form-control" v-model="form.country_id">
                    <option :key="ct.id" :value="ct.id" v-for="ct in country">{{ ct.country }}</option>
                  </select>
                </div>
                <div class="form-group col-md-6">
                  <label for="customer_city">City</label>
                  <select id="customer_city" class="form-control" v-model="form.city_id">
                    <option :key="c.id" :value="c.id" v-for="c in city">{{ c.city }}</option>
                  </select>
                </div>
<!--                <div class="form-group col-md-4">-->
<!--                  <label for="customer_country">Customer Country</label>-->
<!--                  <input type="text" v-model="form.customer_country" class="form-control" id="customer_country">-->
<!--                </div>-->
<!--                <div class="form-group col-md-4">-->
<!--                  <label for="customer_city">Customer City</label>-->
<!--                  <input type="text" v-model="form.customer_city" class="form-control" id="customer_city">-->
<!--                </div>-->
              </div>
              <br>
              <div class="form-row">
                <div class="form-group col-md-4">
                  <label for="customer_phone">Customer Phone</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span v-if="form.customerPhoneDialCode != null" class="input-group-text" id="basic-addon1">{{ form.customerPhoneDialCode }}</span>
                      <span v-else class="input-group-text" id="basic-addon1">{{ form.customer_phone_dial_code }}</span>
                    </div>
                    <input type="text" v-mask="form.customerPhoneFormat" v-model="form.customer_phone" class="form-control" id="customer_phone">
                  </div>
<!--                  <input type="tel" v-model="form.customer_phone" class="form-control" id="customer_phone">-->
                </div>
                <div class="form-group col-md-4">
                  <label for="customer_email">Customer Email</label>
                  <input type="email" v-model="form.customer_email" class="form-control" id="customer_email">
                </div>
                <div class="form-group col-md-4">
                  <label for="customer_zipcode">Customer Zipcode</label>
                  <input type="text" v-model="form.customer_zipcode" class="form-control" id="customer_zipcode">
                </div>
              </div>
              <br>
              <div class="form-row">
                <div class="form-group col-md-4">
                  <label for="customer_address">Customer Address</label>
                  <ckeditor :editor="editor" v-model="form.customer_address" class="form-control" id="customer_address"></ckeditor>
                </div>
                <div class="form-group col-md-4">
                  <label for="customer_shipping_address">Customer Shipping Address</label>
                  <ckeditor :editor="editor" v-model="form.customer_shipping_address" class="form-control" id="customer_shipping_address"></ckeditor>
                </div>
                <div class="form-group col-md-4">
                  <label for="customer_billing_address">Customer Billing Address</label>
                  <ckeditor :editor="editor" v-model="form.customer_billing_address" class="form-control" id="customer_billing_address"></ckeditor>
                </div>
              </div>
              <br>
              <button type="submit" class="btn btn-primary col-xl-12">Save</button>
            </form>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>

<script>

import Layout from "../../router/layouts/main.vue";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  components:{
    // eslint-disable-next-line vue/no-unused-components
    ckeditor: CKEditor.component,
    Layout,
  },
  name:'admin-edit-customer',
  created(){
    // eslint-disable-next-line no-undef
    if(!User.loggedIn()){
      this.$router.push({name: 'admin-login'})
    }
    this.$http.get('country/')
        .then(({data}) => (this.country = data))
    let id = this.$route.params.id
    this.$http.get('customer/'+id)
        .then(({data}) => {this.form = data;
          this.phoneFormatNumber();
          this.$http.get('select-to-country/'+data.country_id)
              .then(({data}) => (this.city = data))
              .catch()})
        .catch()
  },

  data(){
    return {
      form:{
        customer_name: '',
        customer_surname: '',
        customer_phone: '',
        customer_email: '',
        customer_country: '',
        customer_city: '',
        customer_zipcode: '',
        customer_address: '',
        customer_shipping_address: '',
        customer_billing_address: '',
        country_id: '',
        city_id: '',
        customerPhoneDialCode: '',
        customerPhoneFormat: '',
        customer_phone_dial_code: '',
      },
      errors:{},
      customers:{},
      country:[],
      city:[],
      editor: ClassicEditor,
    }
  },

  methods:{
    customerUpdate(){
      let id = this.$route.params.id
      this.$http.patch('customer/'+id,this.form)
          .then(() => {
            this.$router.push({ name: 'admin-customer-list'})
            this.$toasted.global.success()
          })
          .catch(error => this.errors = error.response.data.errors)
    },
    switchCountrySelect(event){
      let id = event.target.value;
      if (id != null) {
        this.$http.get('select-to-country/'+id)
            .then(({data}) => (this.city = data))
            .catch()
        this.changePhoneFormatNumber();
      } else {
        this.$toasted.global.selectCountry()
      }
    },
    changePhoneFormatNumber(){
      // eslint-disable-next-line no-unused-vars
      let format;
      let dialCode;
      this.country.map(e=>{
        if(e.id === this.form.country_id){
          format = e.country_format;
          dialCode = e.country_dial_code;
          if (this.form.customer_phone != null){
            this.form.customer_phone = '';
          }
        }
      })
      this.form.customerPhoneDialCode = dialCode;
      this.form.customerPhoneFormat = format;

    },
    phoneFormatNumber(){
      let format;
      let dialCode;
      this.country.map(e=>{
        if(e.id === this.form.country_id){
          format = e.country_format;
          dialCode = e.country_dial_code;
        }
      })
      this.form.customerPhoneDialCode = dialCode;
      this.form.customerPhoneFormat = format;
    }
  }
}
</script>
<style scoped>

</style>
