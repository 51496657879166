<template>
  <Layout>
    <div>
      <div class="row">
        <div class="col-xl-12">
          <div class="card">
<!--            <div class="card-header d-flex justify-content-between">-->
<!--              <div class="card-heading">-->

<!--                <h4 class="card-title .customCardTitle">Warehouse</h4>-->
<!--              </div>-->
<!--              &lt;!&ndash;                        <a class="btn btn-sm btn-info"><font color="#ffffff">Add Customer</font></a>&ndash;&gt;-->
<!--            </div>-->
            <div class="card-body">
              <div class="form-group">
                <label for="inputFromWarehouse">Select Warehouse</label>
                <select id="inputFromWarehouse" @change="selectWarehouse($event)" class="form-control">
                  <option value="">Select Warehouse</option>
                  <option :key="warehouse.id" :value="warehouse.id" v-for="warehouse in warehouses">{{ warehouse.warehouse_name }}</option>
                </select>
              </div>
              <br>
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <b-row>
                      <b-col xl="6">

                        <b-input-group class="datatable-btn">
                          <b-form-input v-model="filterVariant" placeholder="Type to Search"></b-form-input>
                          <b-input-group-append>
                            <b-button :disabled="!filterVariant" @click="filterVariant = ''">Clear</b-button>
                          </b-input-group-append>
                        </b-input-group>

                      </b-col>

                      <b-col xl="6">
                        <b-form-group  label-cols="2" label="Per page" class="mb-4 datatable-select">
                          <b-form-select v-model="perPageVariant" :options="pageOptionsVariant"></b-form-select>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <div class="table-responsive datatable-vue">
                      <b-table
                          show-empty
                          stacked="md"
                          :items="itemVariants"
                          :fields="tablefieldsVariants"
                          :filter="filterVariant"
                          :current-page="currentPageVariant"
                          :per-page="perPageVariant"
                          @filtered="onFilteredVariant"

                      >
                        <template v-slot:cell(variantStockControl)="{item}">
                          <div v-if="item.variant_quantity > 100">
                            <b-spinner
                                type="grow"
                                label="Spinning"
                                variant="success"
                                class="m-1"
                            ></b-spinner>
                          </div>
                          <div v-else-if="item.variant_quantity < 100 && item.variant_quantity > 0">
                            <b-spinner
                                type="grow"
                                label="Spinning"
                                variant="warning"
                                class="m-1"
                            ></b-spinner>
                          </div>
                          <div v-else="">
                            <b-spinner
                                type="grow"
                                label="Spinning"
                                variant="danger"
                                class="m-1"
                            ></b-spinner>
                          </div>
                        </template>
                        <template v-slot:cell(variantImage)="{item}">
                          <lazy-component wrapper-tag="section" @intersected="optionalDispatch">
                            <img :src="'https://crmtr.balinsoft.com/public/'+item.variant_image" id="variant_photo">
                            <span slot="placeholder">Loading..</span> <!-- Optional -->
                          </lazy-component>
<!--                          <img :src="'https://crmtr.balinsoft.com/public/'+item.variant_image" id="variant_photo">-->
                        </template>
                        <template v-slot:cell(status)="{item}">
                          <div v-if="item.product_status === 1 || item.product_status === '1'">
                            <b-badge class="btn btn-success">Active</b-badge>
                          </div>
                          <div v-else>
                            <b-badge class="btn btn-danger">Passive</b-badge>
                          </div>
                        </template>
                        <template v-slot:cell(variantQuantity)="{item}">
                          <div v-if="item.variant_quantity > 0">
                            <p>{{item.variant_quantity}}</p>
                          </div>
                          <div v-else>
                            <b-badge class="btn btn-danger">Out Of Stock</b-badge>
                          </div>
                        </template>
                        <template v-slot:cell(actions)="{item}">
                          <router-link :to="{ name: 'admin-show-warehouse-item-variant',params:{id:item.id}}" class="btn btn-sm btn-dark">Show</router-link>
                          <!--                              <a @click="deleteWarehouseItem(itemVariant.id)" style="color: white;" class="btn btn-sm btn-danger">Delete</a>-->
                        </template>

                      </b-table>
                    </div>

                    <b-col md="6" class="my-1 p-0">
                      <b-pagination
                          v-model="currentPageVariant"
                          :total-rows="rowsVariants"
                          :per-page="perPageVariant"
                          class="my-0"
                      ></b-pagination>
                    </b-col>
                  </div>
                </div>
              </div>
            </div>
            <!-- end card-body -->
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>

import Layout from "../../router/layouts/main.vue";
import PageHeader from "@/components/page-header";
import LazyComponent from "v-lazy-component";

export default {
  components:{
    // eslint-disable-next-line vue/no-unused-components
    Layout,PageHeader,LazyComponent
  },
  created(){
    // eslint-disable-next-line no-undef
    if(!User.loggedIn()){
      this.$router.push({name: '/admin/login'})
    }
    this.subWarehouses();
    // eslint-disable-next-line no-undef
    Reload.$on('AfterAdd',() => {
    })
  },
  data(){
    return{
      warehouses:{},
      // tablefields: [
      //   { key: 'productStockControl', label: '', sortable: true, },
      //   { key: 'product_name', label: 'Name', sortable: true, },
      //   { key: 'product_code', label: 'Code', sortable: true, },
      //   { key: 'unit_name', label: 'Unit', sortable: true, },
      //   { key: 'productQuantity', label: 'Quantity', sortable: true, },
      //   { key: 'productImage', label: 'Image', sortable: true, },
      //   { key: 'status', label: 'Status', sortable: true, },
      //   { key: 'actions', label: 'Actions', sortable: true, },
      //
      //
      //
      // ],
      tablefieldsVariants: [
        { key: 'variantStockControl', label: '', sortable: true, },
        { key: 'sku_no', label: 'Sku', sortable: true, },
        { key: 'product_name', label: 'Name', sortable: true, },
        { key: 'product_code', label: 'Code', sortable: true, },
        { key: 'unit_name', label: 'Unit', sortable: true, },
        { key: 'variantQuantity', label: 'Quantity', sortable: true, },
        { key: 'variantImage', label: 'Image', sortable: true, },
        { key: 'status', label: 'Status', sortable: true, },
        { key: 'actions', label: 'Actions', sortable: true, },



      ],
      items: [

      ],
      itemVariants: [

      ],
      filter: null,
      filterVariant: null,
      totalRows: 1,
      totalRowsVariant: 1,
      currentPage: 1,
      currentPageVariant: 1,
      perPage: 10,
      perPageVariant: 10,
      pageOptions: [5, 10, 15],
      pageOptionsVariant: [5, 10, 15],
    }
  },
  computed:{
    sortOptions() {
      // Create an options list from our fields
      return this.tablefields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key };
          });
    },
    sortOptionsVariant() {
      // Create an options list from our fields
      return this.tablefieldsVariants
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key };
          });
    },
    rows(){
      return this.items.length;
    },
    rowsVariants(){
      return this.itemVariants.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.totalRowsVariant = this.itemVariants.length;

    // this.getWarehouseItem();
    this.getWarehouseItemVariant();
  },
  methods:{
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onFilteredVariant(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsVariant = filteredItems.length;
      this.currentPageVariant = 1;
    },
    subWarehouses(){
      this.$http.get('sub-warehouse/list')
          .then(({data}) => (this.warehouses = data))
          .catch()
    },
    selectWarehouse(event){
      let id = event.target.value;
      if (id !== "") {
        this.show = true;
        // this.selectedWarehouseItem(id);
        this.selectedWarehouseItemVariant(id);
      } else {
        this.show = false;
        this.$toasted.global.selectFromWarehouse()
      }
    },
    // selectedWarehouseItem(id){
    //   if (id !== "") {
    //     this.show = true;
    //     this.$http.get('select-warehouse-item/'+id)
    //         .then(({data}) => (this.items = data))
    //         .catch()
    //   } else {
    //     this.show = false;
    //     this.$toasted.global.selectFromWarehouse()
    //   }
    //
    // },
    selectedWarehouseItemVariant(id){
      if (id !== "") {
        this.show = true;
        this.$http.get('select-warehouse-item-variant/'+id)
            .then(({data}) => (this.itemVariants = data))
            .catch()
      } else {
        this.show = false;
        this.$toasted.global.selectFromWarehouse()
      }

    },
    // deleteWarehouseItem(id){
    //   // eslint-disable-next-line no-undef
    //   Swal.fire({
    //     title: 'Are you sure?',
    //     text: "You won't be able to revert this!",
    //     icon: 'warning',
    //     showCancelButton: true,
    //     confirmButtonColor: '#3085d6',
    //     cancelButtonColor: '#d33',
    //     confirmButtonText: 'Yes, delete it!'
    //   }).then((result) => {
    //     if (result.value) {
    //       this.$http.get('warehouse-item/delete/'+id)
    //           .then(() => {
    //             this.warehouseItems = this.warehouseItems.filter(warehouseItem => {
    //               return warehouseItem.id !== id
    //             })
    //           })
    //           .catch(() => {
    //             this.$router.push({name: 'admin-item-warehouse'})
    //           })
    //       // eslint-disable-next-line no-undef
    //       Swal.fire(
    //           'Deleted!',
    //           'Your file has been deleted.',
    //           'success'
    //       )
    //     }
    //   })
    //
    // },
    // deleteWarehouseItemVariant(id){
    //   // eslint-disable-next-line no-undef
    //   Swal.fire({
    //     title: 'Are you sure?',
    //     text: "You won't be able to revert this!",
    //     icon: 'warning',
    //     showCancelButton: true,
    //     confirmButtonColor: '#3085d6',
    //     cancelButtonColor: '#d33',
    //     confirmButtonText: 'Yes, delete it!'
    //   }).then((result) => {
    //     if (result.value) {
    //       this.$http.get('warehouse-item-variant/delete/'+id)
    //           .then(() => {
    //             this.warehouseItemVariants = this.warehouseItemVariants.filter(warehouseItemVariant => {
    //               return warehouseItemVariant.id !== id
    //             })
    //           })
    //           .catch(() => {
    //             this.$router.push({name: 'admin-item-warehouse'})
    //           })
    //       // eslint-disable-next-line no-undef
    //       Swal.fire(
    //           'Deleted!',
    //           'Your file has been deleted.',
    //           'success'
    //       )
    //     }
    //   })
    //
    // },
  },


}
</script>

<style scoped>
#product_photo{
  height: 100px;
  width: 135px;
}
#product_photo_two{
  height: 100px;
  width: 135px;
}
#variant_photo{
  height: 100px;
  width: 135px;
}
#variant_photo_two{
  height: 100px;
  width: 135px;
}
.v-lazy-component.v-lazy-component--loading {
  filter: blur(20px);
}

.v-lazy-component.v-lazy-component--loaded {
  filter: blur(0);
  transition: filter 1s;
}
</style>
