<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div class="invoice-title">
                  <h4 class="float-end font-size-16">
                    <strong>{{ order.order.code }}</strong> <br>
                    {{ order.order.created_at }}
                  </h4>
                  <h3 class="mt-0">
                    <img :src="'https://crmtr.balinsoft.com/public/'+order.order.logo" alt="logo" height="24" />
                  </h3>
                </div>
                <hr />
                <div class="row">
                  <div class="col-4 text-center">
                    <address>
                      <strong>From:</strong>
                      <br /><strong>Warehouse: </strong>{{ order.order.fromWarehouse_name }}
                      <br /><strong>Phone: </strong>{{ order.order.fromWarehouse_phone }}
                      <br /><strong>Email: </strong>{{ order.order.fromWarehouse_email }}
                      <br /><strong>Country: </strong>{{ order.order.fromWarehouse_country }}
                      <br /><strong>City: </strong>{{ order.order.fromWarehouse_city }}
                      <br /><strong>Post Code: </strong>{{ order.order.fromWarehouse_postcode }}
                      <br /><strong>Address: </strong>{{ order.order.fromWarehouse_address }}
                    </address>
                  </div>
                  <div class="col-4 text-center">
                    <!--                    <address>-->
                    <!--                      <strong>Detail:</strong>-->
                    <!--                      <br /><strong>Code: </strong> {{ StockTransfer.stockTransfer.code }}-->
                    <!--                      <br /><strong>Start Date: </strong> {{ StockTransfer.stockTransfer.start_transfer_date }}-->
                    <!--                      <br /><strong>End Date: </strong> {{ StockTransfer.stockTransfer.finish_transfer_date }}-->
                    <!--                      <br /><strong>Total Product: </strong>{{ StockTransfer.stockTransfer.total_product }}-->
                    <!--                      <br /><strong>Total Quantity: </strong>{{ StockTransfer.stockTransfer.total_quantity }}-->
                    <!--                      <br /><strong>Total Process: </strong>{{ StockTransfer.stockTransfer.total_process }}-->
                    <!--                      <br /><strong>Total Weight: </strong>{{ StockTransfer.stockTransfer.total_weight }}-->
                    <!--                      <br /><strong>Pallet: </strong>{{ StockTransfer.stockTransfer.pallet_name }}-->
                    <!--                      <br /><strong>Total Pallet: </strong>{{ StockTransfer.stockTransfer.pallet_count }}-->
                    <!--                      <br /><strong>Total: </strong>{{ StockTransfer.stockTransfer.total }}-->
                    <!--                    </address>-->
                  </div>
                  <div class="col-4 text-center">
                    <address>
                      <strong>Customer:</strong>
                      <br /><strong>Name: </strong>{{ customer.customer_name }}
                      <br /><strong>Surname: </strong>{{ customer.customer_surname }}
                      <br /><strong>Email: </strong>{{ customer.customer_email }}
                      <br /><strong>Country: </strong>{{ customer.customer_country }}
                      <br /><strong>City: </strong>{{ customer.customer_city }}
                      <br /><strong>Post Code: </strong>{{ customer.customer_zipcode }}
                      <br /><strong>Address: </strong>{{ customer.customer_address }}
                      <br /><strong>Billing Address: </strong>{{ customer.customer_billing_address }}
                      <br /><strong>Shipping Address: </strong>{{ customer.customer_shipping_address }}
                    </address>
                  </div>
                </div>
                <hr>
                <div class="row">
                  <div class="col-12 text-center">
                    <br /><strong>Start - End Date: </strong> {{ order.order.start_transfer_date }} - {{ order.order.finish_transfer_date }}
                  </div>
                  <div class="col-3 text-center">
                    <address>
                      <br /><strong>Pallet: </strong>{{ order.order.pallet_name }}
                      <br /><strong>Total Weight: </strong>{{ order.order.total_weight }}
                      <br /><strong>Number of Pallet: </strong>{{ order.order.pallet_count }}

                    </address>
                  </div>
                  <div class="col-3 text-center">
                    <address>
                      <br /><strong>Total Product: </strong>{{ order.order.total_product }}
                      <br /><strong>Total Quantity: </strong>{{ order.order.total_quantity }}
                      <br /><strong>Total Process: </strong>{{ order.order.total_process }}
                    </address>
                  </div>
                  <div class="col-3 text-center">
                    <address>
                      <br /><strong>Total Pallet: </strong>{{ order.order.total_pallet }}
                      <br /><strong>Subtotal For Process: </strong>{{ order.order.subtotal_process }}
                      <br /><strong>Subtotal For Products: </strong>{{ order.order.subtotal_products }}
                      <br /><strong>Total: </strong>{{ order.order.total }}
                    </address>
                  </div>
                  <div class="col-3 text-center">
                    <address>
                      <br /><strong>Payment Method: </strong>{{ order.order.payment_method }}
                      <br /><strong>Payment Status: </strong>{{ order.order.payment_status }}
                      <br /><strong>Advance Payment: </strong>{{ order.order.advance_payment }}
                      <br /><strong>Advance Payment Status: </strong>{{ order.order.advance_payment_status }}
                    </address>
                  </div>
                </div>
                <br>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div>
                  <div class="p-2">
                    <h3 class="font-size-20 text-center">
                      <strong>Stock Transfer Summary</strong>
                    </h3>
                  </div>
                  <div class>
                    <div class="table-responsive" v-if="OrderVariants.length > 0">
                      <table class="table">
                        <thead>
                        <tr>
                          <td class="text-center">
                            <strong>Sku No</strong>
                          </td>
                          <td class="text-center">
                            <strong>Color</strong>
                          </td>
                          <td class="text-center">
                            <strong>Color Code</strong>
                          </td>
                          <td class="text-center">
                            <strong>Size</strong>
                          </td>
                          <td class="text-center">
                            <strong>Code</strong>
                          </td>
                          <td class="text-center">
                            <strong>Name</strong>
                          </td>
                          <td class="text-center">
                            <strong>Quantity</strong>
                          </td>
                          <td class="text-center">
                            <strong>Unit</strong>
                          </td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="orderVariant in OrderVariants" :key="orderVariant.id">
                          <td class="text-center">{{ orderVariant.sku_no }}</td>
                          <td class="text-center">{{ orderVariant.color_name }}</td>
                          <td class="text-center">{{ orderVariant.color_code }}</td>
                          <td class="text-center">{{ orderVariant.size_name }}</td>
                          <td class="text-center">{{ orderVariant.product_code }}</td>
                          <td class="text-center">{{ orderVariant.product_name }}</td>
                          <td class="text-center">{{ orderVariant.product_quantity }}</td>
                          <td class="text-center">{{ orderVariant.unit_name }}</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="d-print-none">
                      <div class="float-end">
                        <a href="javascript:window.print()" class="btn btn-success">
                          <i class="fa fa-print"></i>
                        </a>
                        <a href="#" class="btn btn-primary ms-1">Send</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end row -->
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>


<script>
import Layout from "../../router/layouts/main.vue";
import PageHeader from "@/components/page-header";
export default {
  components:{
    // eslint-disable-next-line vue/no-unused-components
    Layout,PageHeader
  },
  created(){
    // eslint-disable-next-line no-undef
    if(!User.loggedIn()){
      this.$router.push({name: '/admin/login'})
    }
    let id = this.$route.params.id
    this.$http.get('order/'+id)
        .then(({data}) => {this.order = data
          this.OrderVariants = this.order.variant;
          this.customer = this.order.order.customer;
          console.log(this.order)
        })
        .catch()

  },
  data(){
    return {
      order: [],
      customer:[],
      StockTransferProducts: {},
      OrderVariants: {},
      errors:{},
    }
  },
  methods:{
  }
}
</script>


<style scoped>
#product_photo{
  height: 40px;
  width: 40px;
}
#variant_photo{
  width: 40px;
  height: 40px;
}

</style>
