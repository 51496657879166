<template>
  <Layout>
    <div>
      <div class="col-xl-12">
        <div class="card card-statistics">
          <div class="card-body">
            <form @submit.prevent="countryInsert" enctype="multipart/form-data">
              <div class="form-row">
                <div class="form-group col-md-4">
                  <label for="country">Country</label>
                  <input type="text" v-model="form.country" class="form-control" id="country" :class="{
                    'is-invalid': typeSubmit && $v.form.country.$error,
                  }">
                  <div v-if="typeSubmit && $v.form.country.$error" class="invalid-feedback">
                    <span v-if="!$v.form.country.required">This value is required.</span>
                  </div>
                </div>
                <div class="form-group col-md-4">
                  <label for="countryDialCode">Country Dial Code</label>
                  <input type="text" v-model="form.country_dial_code" class="form-control" id="countryDialCode" :class="{
                    'is-invalid': typeSubmit && $v.form.country_dial_code.$error,
                  }">
                  <div v-if="typeSubmit && $v.form.country_dial_code.$error" class="invalid-feedback">
                    <span v-if="!$v.form.country_dial_code.required">This value is required.</span>
                  </div>
                </div>
                <div class="form-group col-md-4">
                  <label for="countryCode">Country Code</label>
                  <input type="text" v-model="form.country_code" class="form-control" id="countryCode" :class="{
                    'is-invalid': typeSubmit && $v.form.country_code.$error,
                  }">
                  <div v-if="typeSubmit && $v.form.country_code.$error" class="invalid-feedback">
                    <span v-if="!$v.form.country_code.required">This value is required.</span>
                  </div>
                </div>
              </div>
              <br>
              <div class="form-row">
                <div class="form-group col-md-4">
                  <label for="countryPhoneFormat">Country Format</label>
                  <input type="text" placeholder="## ### ####" v-model="form.country_format" class="form-control" id="countryPhoneFormat" :class="{
                    'is-invalid': typeSubmit && $v.form.country_format.$error,
                  }">
                  <div v-if="typeSubmit && $v.form.country_format.$error" class="invalid-feedback">
                    <span v-if="!$v.form.country_format.required">This value is required.</span>
                  </div>
                </div>
                <div class="form-group col-md-4">
                  <label for="countryStatus">Country Status</label>
                  <select id="countryStatus" class="form-control" v-model="form.country_status" :class="{
                    'is-invalid': typeSubmit && $v.form.country_status.$error,
                  }">
                    <option :key="1" :value="1">Active</option>
                    <option :key="0" :value="0">Passive</option>
                  </select>
                  <div v-if="typeSubmit && $v.form.country_status.$error" class="invalid-feedback">
                    <span v-if="!$v.form.country_status.required">This value is required.</span>
                  </div>
                </div>
              </div>
              <br>
              <button type="submit" class="btn btn-primary col-xl-12">Save</button>
            </form>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>

<script>

import Layout from "../../router/layouts/main.vue";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  required
} from "vuelidate/lib/validators";

export default {
  components:{
    // eslint-disable-next-line vue/no-unused-components
    ckeditor: CKEditor.component,
    Layout,
  },
  name:'admin-create-country',
  created(){
    // eslint-disable-next-line no-undef
    if(!User.loggedIn()){
      this.$router.push({name: 'admin-login'})
    }
    this.$http.get('country/')
        .then(({data}) => (this.countries = data))
  },
  validations(){
    return{
      form:{
        country: {required},
        country_dial_code: {required},
        country_code: {required},
        country_format: {required},
        country_status: {required},
      },
    }
  },
  data(){
    return {
      form:{
        country: null,
        country_dial_code: null,
        country_code: null,
        country_format: null,
        country_status: null,
      },
      errors:{},
      typeSubmit:false,
      countries:{},
      editor: ClassicEditor,
    }
  },

  methods:{
    countryInsert(){
      this.typeSubmit = true;
      this.$v.$touch()

      if(this.$v.$invalid){
        return false;
      }else{
        this.$http.post('country',this.form)
            .then(() => {
              this.$router.push({ name: 'admin-country-list'})
              Notification.success()
            })
            .catch(error => this.errors = error.response.data.errors)
      }
    }
  }
}
</script>
<style scoped>

</style>
