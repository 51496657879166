<template>
  <Layout>
    <div>

      <div class="col-xl-12">
        <div class="card card-statistics">
          <div class="card-body">
            <form @submit.prevent="collectionInsert" enctype="multipart/form-data">
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="collectionTitle">Collection Title</label>
                  <input type="text" v-model="form.collection_title" class="form-control" id="collectionTitle"
                         :class="{'is-invalid': typeSubmit && $v.form.collection_title.$error,}">

                  <div
                      v-if="typeSubmit && $v.form.collection_title.$error"
                      class="invalid-feedback"
                  >
                  <span v-if="!$v.form.collection_title.required"
                  >This value is required.</span
                  >
                  </div>
                </div>
                <div class="form-group col-md-6">
                  <label for="collectionCode">Collection Code</label>
                  <input type="text" v-model="form.collection_code" class="form-control" id="collectionCode"
                         :class="{'is-invalid': typeSubmit && $v.form.collection_code.$error,}">
                  <div
                      v-if="typeSubmit && $v.form.collection_code.$error"
                      class="invalid-feedback"
                  >
                  <span v-if="!$v.form.collection_code.required"
                  >This value is required.</span
                  >
                  </div>
                </div>
              </div>
              <br>
              <div class="form-row">
<!--                <div class="form-group col-md-4">-->
<!--                  <label for="collectionSeq">Collection Seq Number</label>-->
<!--                  <input type="number" min="1" v-model="form.collection_seq" class="form-control" id="collectionSeq"-->
<!--                         :class="{'is-invalid': typeSubmit && $v.form.collection_seq.$error,}">-->
<!--                  <div-->
<!--                      v-if="typeSubmit && $v.form.collection_seq.$error"-->
<!--                      class="invalid-feedback"-->
<!--                  >-->
<!--                  <span v-if="!$v.form.collection_seq.required"-->
<!--                  >This value is required.</span-->
<!--                  >-->
<!--                  </div>-->
<!--                </div>-->
                <div class="form-group col-md-6">
                  <label for="collectionStatus">Collection Status</label>
                  <select id="collectionStatus" class="form-control" v-model="form.collection_status"
                          :class="{'is-invalid': typeSubmit && $v.form.collection_status.$error,}">
                    <option :value="1">Active</option>
                    <option :value="0">Passive</option>
                  </select>
                  <div
                      v-if="typeSubmit && $v.form.collection_status.$error"
                      class="invalid-feedback"
                  >
                  <span v-if="!$v.form.collection_status.required"
                  >This value is required.</span
                  >
                  </div>
                </div>
                <div class="form-group col-md-6">
                  <label for="collectionPublished">Collection Published</label>
                  <select id="collectionPublished" class="form-control" v-model="form.is_published" :class="{'is-invalid': typeSubmit && $v.form.is_published.$error,}">
                    <option :value="1">Yes</option>
                    <option :value="0">No</option>
                  </select>
                  <div
                      v-if="typeSubmit && $v.form.is_published.$error"
                      class="invalid-feedback"
                  >
                  <span v-if="!$v.form.is_published.required"
                  >This value is required.</span
                  >
                  </div>
                </div>
              </div>
              <br>
              <div class="form-group">
                <label for="collectionDescription">Collection Description</label>
                <ckeditor :editor="editor" v-model="form.collection_description" class="form-control" id="collectionDescription"
                          :class="{'is-invalid': typeSubmit && $v.form.collection_description.$error,}"></ckeditor>
                <div
                    v-if="typeSubmit && $v.form.collection_description.$error"
                    class="invalid-feedback"
                >
                  <span v-if="!$v.form.collection_description.required"
                  >This value is required.</span
                  >
                </div>
              </div>
              <br>
              <div class="form-row">
                <label>Collection Image</label>
                <div class="col-md-8 input-group">
                  <input type="file" @change="onFileSelected" class="form-control" id="inputGroupFile02" :class="{'is-invalid': typeSubmit && $v.form.collection_image.$error,}">
                  <label class="input-group-text" for="inputGroupFile02">Upload</label>
                  <div
                      v-if="typeSubmit && $v.form.collection_image.$error"
                      class="invalid-feedback"
                  >
                  <span v-if="!$v.form.collection_image.required"
                  >This value is required.</span
                  >
                  </div>
                </div>

                <br><br>
                <div class="form-group col-md-2">
                  <img :src="form.collection_image" id="collection_image">
                </div>
              </div>
              <br>
              <button type="submit" class="btn btn-primary col-xl-12">Save</button>
            </form>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>

<script>

import Layout from "../../router/layouts/main.vue";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {required} from "vuelidate/lib/validators";

export default {
  components:{
    // eslint-disable-next-line vue/no-unused-components
    ckeditor: CKEditor.component,
    Layout,
  },
  name:'admin-create-collection',
  created(){
    // eslint-disable-next-line no-undef
    if(!User.loggedIn()){
      this.$router.push({name: 'admin-login'})
    }
    this.$http.get('collection/')
        .then(({data}) => (this.collections = data))
  },

  data(){
    return {
      typeSubmit:false,
      form:{
        collection_code: null,
        collection_title: null,
        // collection_seq: null,
        collection_status: null,
        collection_description: null,
        collection_image: null,
        is_published: null,
      },
      errors:{},
      sliders:{},
      editor: ClassicEditor,
    }
  },
  validations(){
    return{
      form:{
        collection_code: {required},
        collection_title: {required},
        // collection_seq: {required},
        collection_status: {required},
        collection_description: {required},
        collection_image: {required},
        is_published: {required},
      },
    }
  },
  methods:{
    onFileSelected(event){
      let file = event.target.files[0];
      if(file.size > 20971520) {
        this.$toasted.global.image_validation()
      } else {
        let reader = new FileReader();
        reader.onload = event => {
          this.form.collection_image = event.target.result
          // eslint-disable-next-line no-console
          // console.log(event.target.result);
        };
        reader.readAsDataURL(file);
      }
    },
    collectionInsert(){
      this.typeSubmit = true;
      this.$v.$touch();
      if(this.$v.$invalid){
        return;
      }else{
        this.$http.post('collection',this.form)
            .then(() => {
              this.$router.push({ name: 'admin-collection-list'})
              this.$toasted.global.success()
            })
            .catch(error => this.errors = error.response.data.errors)
      }

    }
  }
}
</script>
<style scoped>
#collection_image{
  width: 50px;
  height: 50px;
}
</style>
