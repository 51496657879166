<template>
  <Layout>
    <div>

      <div class="col-xl-12">
        <div class="card card-statistics">
          <div class="card-body">
            <form @submit.prevent="supplierUpdate">
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="supplierName">Supplier Name</label>
                  <input type="text" v-model="form.supplier_name" class="form-control" id="supplierName" :class="{
                    'is-invalid': typeSubmit && $v.form.supplier_name.$error,
                  }">
                  <div
                      v-if="typeSubmit && $v.form.supplier_name.$error"
                      class="invalid-feedback"
                  >
                  <span v-if="!$v.form.supplier_name.required"
                  >This value is required.</span
                  >
                  </div>
                </div>
                <div class="form-group col-md-6">
                  <label for="supplierSurname">Supplier Surname</label>
                  <input type="text" v-model="form.supplier_surname" class="form-control" id="supplierSurname" :class="{
                    'is-invalid': typeSubmit && $v.form.supplier_surname.$error,
                  }">
                  <div
                      v-if="typeSubmit && $v.form.supplier_surname.$error"
                      class="invalid-feedback"
                  >
                  <span v-if="!$v.form.supplier_surname.required"
                  >This value is required.</span
                  >
                  </div>
                </div>
              </div>
              <br>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="supplierCountry">Supplier Country</label>
                  <select :class="{
                    'is-invalid': typeSubmit && $v.form.country_id.$error
                  }" @change="switchCountrySelect($event)" id="supplierCountry" class="form-control" v-model="form.country_id">
                    <option :key="ct.id" :value="ct.id" v-for="ct in country">{{ ct.country }}</option>
                  </select>
                  <div
                      v-if="typeSubmit && $v.form.country_id.$error"
                      class="invalid-feedback"
                  >
                  <span v-if="!$v.form.country_id.required"
                  >This value is required.</span
                  >
                  </div>
<!--                  <input type="text" v-model="form.supplier_country" class="form-control" id="supplierCountry" :class="{-->
<!--                    'is-invalid': typeSubmit && $v.form.supplier_country.$error,-->
<!--                  }">-->
<!--                  <div-->
<!--                      v-if="typeSubmit && $v.form.supplier_country.$error"-->
<!--                      class="invalid-feedback"-->
<!--                  >-->
<!--                  <span v-if="!$v.form.supplier_country.required"-->
<!--                  >This value is required.</span-->
<!--                  >-->
<!--                  </div>-->
                </div>
                <div class="form-group col-md-6">
                  <label for="supplierCity">Supplier City</label>
                  <select :class="{
                    'is-invalid': typeSubmit && $v.form.city_id.$error,
                  }" id="supplierCity" class="form-control" v-model="form.city_id">
                    <option :key="c.id" :value="c.id" v-for="c in city">{{ c.city }}</option>
                  </select>
                  <div
                      v-if="typeSubmit && $v.form.city_id.$error"
                      class="invalid-feedback"
                  >
                  <span v-if="!$v.form.city_id.required"
                  >This value is required.</span
                  >
                  </div>
<!--                  <input type="text" v-model="form.supplier_city" class="form-control" id="supplierCity" :class="{-->
<!--                    'is-invalid': typeSubmit && $v.form.supplier_city.$error,-->
<!--                  }">-->
<!--                  <div-->
<!--                      v-if="typeSubmit && $v.form.supplier_city.$error"-->
<!--                      class="invalid-feedback"-->
<!--                  >-->
<!--                  <span v-if="!$v.form.supplier_city.required"-->
<!--                  >This value is required.</span-->
<!--                  >-->
<!--                  </div>-->
                </div>
              </div>
              <br>
              <div class="form-row">
                <div class="form-group col-md-4">
                  <label for="supplierPhone">Supplier Phone</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span v-if="form.supplierPhoneDialCode != null" class="input-group-text" id="basic-addon1">{{ form.supplierPhoneDialCode }}</span>
                      <span v-else class="input-group-text" id="basic-addon1">{{ form.supplier_phone_dial_code }}</span>
                    </div>
                    <input type="text" v-mask="form.supplierPhoneFormat" v-model="form.supplier_phone" class="form-control" id="supplierPhone">
                  </div>
                  <!--                  <input type="tel" v-model="form.supplier_phone" data-mask="(999) 999-9999" class="form-control inputmask" id="supplierPhone" :class="{-->
                  <!--                    'is-invalid': typeSubmit && $v.form.supplier_phone.$error,-->
                  <!--                  }">-->
                  <div
                      v-if="typeSubmit && $v.form.supplier_phone.$error"
                      class="invalid-feedback"
                  >
                  <span v-if="!$v.form.supplier_phone.required"
                  >This value is required.</span
                  >
                  </div>
                </div>
                <div class="form-group col-md-4">
                  <label for="supplierEmail">Supplier Email</label>
                  <input type="email" v-model="form.supplier_email" class="form-control" id="supplierEmail" :class="{
                    'is-invalid': typeSubmit && $v.form.supplier_email.$error,
                  }">
                  <div
                      v-if="typeSubmit && $v.form.supplier_email.$error"
                      class="invalid-feedback"
                  >
                  <span v-if="!$v.form.supplier_email.required"
                  >This value is required.</span
                  >
                  </div>
                </div>
                <div class="form-group col-md-4">
                  <label for="supplierZipCode">Supplier Zipcode</label>
                  <input type="text" v-model="form.supplier_zipcode" class="form-control" id="supplierZipCode" :class="{
                    'is-invalid': typeSubmit && $v.form.supplier_zipcode.$error,
                  }">
                  <div
                      v-if="typeSubmit && $v.form.supplier_zipcode.$error"
                      class="invalid-feedback"
                  >
                  <span v-if="!$v.form.supplier_zipcode.required"
                  >This value is required.</span
                  >
                  </div>
                </div>
              </div>
              <br>
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label for="supplierAddress">Supplier Address</label>
                  <ckeditor :editor="editor" v-model="form.supplier_address" class="form-control" id="supplierAddress" :class="{
                    'is-invalid': typeSubmit && $v.form.supplier_address.$error,
                  }"></ckeditor>
                  <div
                      v-if="typeSubmit && $v.form.supplier_address.$error"
                      class="invalid-feedback"
                  >
                  <span v-if="!$v.form.supplier_address.required"
                  >This value is required.</span
                  >
                  </div>
                </div>
              </div>
              <br>
              <button type="submit" class="btn btn-primary col-xl-12">Save</button>
            </form>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>

<script>

import Layout from "../../router/layouts/main.vue";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  required,email
} from "vuelidate/lib/validators";

export default {
  components:{
    // eslint-disable-next-line vue/no-unused-components
    ckeditor: CKEditor.component,
    Layout,
  },
  name:'admin-edit-supplier',
  created(){
    // eslint-disable-next-line no-undef
    if(!User.loggedIn()){
      this.$router.push({name: 'admin-login'})
    }
    this.$http.get('country/')
        .then(({data}) => (this.country = data))
    let id = this.$route.params.id
    this.$http.get('supplier/'+id)
        .then(({data}) => {this.form = data;
          this.phoneFormatNumber();
          this.$http.get('select-to-country/'+data.country_id)
              .then(({data}) => (this.city = data))
              .catch()})
        .catch()
  },
  validations(){
    return{
      form:{
        supplier_name: {required},
        supplier_surname: {required},
        supplier_phone: {required},
        supplier_email: {required,email},
        // supplier_country: {required},
        // supplier_city: {required},
        supplier_zipcode: {required},
        supplier_address: {required},
        country_id: {required},
        city_id: {required},
      },
    }
  },
  data(){
    return {
      form:{
        supplier_name: '',
        supplier_surname: '',
        supplier_phone: '',
        supplier_email: '',
        // supplier_country: '',
        // supplier_city: '',
        supplier_zipcode: '',
        supplier_address: '',
        country_id: '',
        city_id: '',
        supplierPhoneDialCode: '',
        supplierPhoneFormat: '',
        supplier_phone_dial_code: '',
      },
      errors:{},
      typeSubmit:false,
      editor: ClassicEditor,
      country:[],
      city:[],
    }
  },

  methods:{
    supplierUpdate(){
      this.typeSubmit = true;
      this.$v.$touch()

      if(this.$v.$invalid){
        return false;
      }else{
        let id = this.$route.params.id
        this.$http.patch('supplier/'+id,this.form)
            .then(() => {
              this.$router.push({ name: 'admin-supplier-list'})
              this.$toasted.global.success()
            })
            .catch(error => this.errors = error.response.data.errors)
      }
    },
    switchCountrySelect(event){
      let id = event.target.value;
      if (id != null) {
        this.$http.get('select-to-country/'+id)
            .then(({data}) => (this.city = data))
            .catch()
        this.changePhoneFormatNumber();
      } else {
        this.$toasted.global.selectCountry()
      }
    },
    changePhoneFormatNumber(){
      // eslint-disable-next-line no-unused-vars
      let format;
      let dialCode;
      this.country.map(e=>{
        if(e.id === this.form.country_id){
          format = e.country_format;
          dialCode = e.country_dial_code;
          if (this.form.supplier_phone != null){
            this.form.supplier_phone = '';
          }
        }
      })
      this.form.supplierPhoneDialCode = dialCode;
      this.form.supplierPhoneFormat = format;

    },
    phoneFormatNumber(){
      let format;
      let dialCode;
      this.country.map(e=>{
        if(e.id === this.form.country_id){
          format = e.country_format;
          dialCode = e.country_dial_code;
        }
      })
      this.form.supplierPhoneDialCode = dialCode;
      this.form.supplierPhoneFormat = format;
    }
  }
}
</script>
<style scoped>

</style>
