<template>
<Layout>
  <div>

    <div class="col-xl-12">
      <div class="card card-statistics">
        <div class="card-body">
          <form @submit.prevent="sliderInsert" enctype="multipart/form-data">
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="sliderTitle">Slider Title</label>
                <input type="text" v-model="form.slider_title" class="form-control" id="sliderTitle"
                       :class="{'is-invalid': typeSubmit && $v.form.slider_title.$error,}">
                <div
                    v-if="typeSubmit && $v.form.slider_title.$error"
                    class="invalid-feedback"
                >
                  <span v-if="!$v.form.slider_title.required"
                  >This value is required.</span
                  >
                </div>
              </div>
<!--              <div class="form-group col-md-4">-->
<!--                <label for="sliderSeq">Slider Seq Number</label>-->
<!--                <input type="number" min="1" v-model="form.slider_seq" class="form-control" id="sliderSeq"-->
<!--                       :class="{-->
<!--                    'is-invalid': typeSubmit && $v.form.slider_seq.$error,-->
<!--                  }">-->
<!--                <div-->
<!--                    v-if="typeSubmit && $v.form.slider_seq.$error"-->
<!--                    class="invalid-feedback"-->
<!--                >-->
<!--                  <span v-if="!$v.form.slider_seq.required"-->
<!--                  >This value is required.</span-->
<!--                  >-->
<!--                </div>-->
<!--              </div>-->
              <div class="form-group col-md-6">
                <label for="sliderStatus">Slider Status</label>
                <select id="sliderStatus" class="form-control" v-model="form.slider_status" :class="{
                    'is-invalid': typeSubmit && $v.form.slider_status.$error,
                  }">
                  <option :value="1">Active</option>
                  <option :value="0">Passive</option>
                </select>
                <div
                    v-if="typeSubmit && $v.form.slider_status.$error"
                    class="invalid-feedback"
                >
                  <span v-if="!$v.form.slider_status.required"
                  >This value is required.</span
                  >
                </div>
              </div>
            </div>
            <br>
            <div class="form-group">
              <label for="sliderDescription">Slider Description</label>
              <ckeditor :editor="editor" v-model="form.slider_description" class="form-control" id="sliderDescription"
                        :class="{
                    'is-invalid': typeSubmit && $v.form.slider_description.$error,
                  }"></ckeditor>
              <div
                  v-if="typeSubmit && $v.form.slider_description.$error"
                  class="invalid-feedback"
              >
                  <span v-if="!$v.form.slider_description.required"
                  >This value is required.</span
                  >
              </div>
            </div>
            <br>
            <div class="form-row">
              <label>Slider Image</label>
              <div class="col-md-8 input-group">
                <input type="file" @change="onFileSelected" class="form-control" id="inputGroupFile02"
                       :class="{
                    'is-invalid': typeSubmit && $v.form.slider_image.$error,
                  }">
                <label class="input-group-text" for="inputGroupFile02">Upload</label>
                <div
                    v-if="typeSubmit && $v.form.slider_image.$error"
                    class="invalid-feedback"
                >
                  <span v-if="!$v.form.slider_image.required"
                  >This value is required.</span
                  >
                </div>
              </div>
              <br><br>
              <div class="form-group col-md-2">
                <img :src="form.slider_image" style="height: 50px; width: 50px;">
              </div>
            </div>
            <br>
            <button type="submit" class="btn btn-primary col-xl-12">Save</button>
          </form>
        </div>
      </div>
    </div>
  </div>

</Layout>
</template>

<script>

import Layout from "../../router/layouts/main.vue";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {required} from "vuelidate/lib/validators";


export default {
  components:{
    // eslint-disable-next-line vue/no-unused-components
    ckeditor: CKEditor.component,
    Layout,
  },
  name:'admin-create-slider',
  created(){
    // eslint-disable-next-line no-undef
    if(!User.loggedIn()){
      this.$router.push({name: 'admin-login'})
    }
    this.$http.get('slider/')
        .then(({data}) => (this.sliders = data))
  },

  data(){
    return {
      typeSubmit:false,
      form:{
        slider_title: null,
        slider_status: null,
        slider_description: null,
        slider_image: null,
        // slider_seq: null,
      },
      errors:{},
      sliders:{},
      editor: ClassicEditor,
    }
  },
  validations(){
    return{
      form:{
        slider_title: {required},
        slider_status: {required},
        slider_description: {required},
        slider_image: {required},
        // slider_seq: {required}
      },
    }
  },

  methods:{
    onFileSelected(event){
      let file = event.target.files[0];
      if(file.size > 20971520) {
        this.$toasted.global.image_validation()
      } else {
        let reader = new FileReader();
        reader.onload = event => {
          this.form.slider_image = event.target.result
          // eslint-disable-next-line no-console
          console.log(event.target.result);
        };
        reader.readAsDataURL(file);
      }
    },
    sliderInsert(){
      this.typeSubmit = true;
      this.$v.$touch()

      if(this.$v.$invalid){
        return;
      }
      else{
        this.$http.post('slider',this.form)
            .then(() => {
              this.$router.push({ name: 'admin-slider-list'})
              this.$toasted.global.success()
            })
            .catch(error => this.errors = error.response.data.errors)
      }

    }
  }
}
</script>
<style scoped>

</style>
