<template>
  <Layout>
    <div>

      <div class="col-xl-12">
        <div class="card card-statistics">
          <div class="card-body">
            <form @submit.prevent="teamInsert" enctype="multipart/form-data">
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="teamName">Team Name</label>
                  <input type="text" v-model="form.team_name" class="form-control" id="teamName"
                         :class="{'is-invalid': typeSubmit && $v.form.team_name.$error,}">
                  <div
                      v-if="typeSubmit && $v.form.team_name.$error"
                      class="invalid-feedback"
                  >
                      <span v-if="!$v.form.team_name.required"
                      >This value is required.</span
                      >
                  </div>
                </div>
                <div class="form-group col-md-6">
                  <label for="teamSurname">Team Surname</label>
                  <input type="text" min="1" v-model="form.team_surname" class="form-control" id="teamSurname"
                         :class="{'is-invalid': typeSubmit && $v.form.team_surname.$error,}">
                  <div
                      v-if="typeSubmit && $v.form.team_surname.$error"
                      class="invalid-feedback"
                  >
                      <span v-if="!$v.form.team_surname.required"
                      >This value is required.</span
                      >
                  </div>
                </div>
<!--                <div class="form-group col-md-4">-->
<!--                  <label for="teamSeq">Team Seq Number</label>-->
<!--                  <input type="number" min="1" v-model="form.team_seq" class="form-control" id="teamSeq"-->
<!--                         :class="{'is-invalid': typeSubmit && $v.form.team_seq.$error,}">-->
<!--                  <div-->
<!--                      v-if="typeSubmit && $v.form.team_seq.$error"-->
<!--                      class="invalid-feedback"-->
<!--                  >-->
<!--                      <span v-if="!$v.form.team_seq.required"-->
<!--                      >This value is required.</span-->
<!--                      >-->
<!--                  </div>-->
<!--                </div>-->
              </div>
              <br>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="teamCountry">Country</label>
                  <select @change="switchCountrySelect($event)" id="teamCountry" class="form-control" v-model="form.country_id">
                    <option :key="ct.id" :value="ct.id" v-for="ct in country">{{ ct.country }}</option>
                  </select>
                </div>


                <div class="form-group col-md-6">
                  <label for="teamCity">City</label>
                  <select id="teamCity" class="form-control" v-model="form.city_id">
                    <option :key="c.id" :value="c.id" v-for="c in city">{{ c.city }}</option>
                  </select>
                </div>
              </div>
              <br>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="teamPhone">Team Phone</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span v-if="form.teamPhoneDialCode != null" class="input-group-text" id="basic-addon1">{{ form.teamPhoneDialCode }}</span>
                    </div>
                    <input type="text" v-mask="form.teamPhoneFormat" v-model="form.team_phone" class="form-control" id="teamPhone"
                           :class="{'is-invalid': typeSubmit && $v.form.team_phone.$error,}">
                    <div
                        v-if="typeSubmit && $v.form.team_phone.$error"
                        class="invalid-feedback"
                    >
                      <span v-if="!$v.form.team_phone.required"
                      >This value is required.</span
                      >
                    </div>
                  </div>
                </div>
<!--                <div class="form-group col-md-4">-->
<!--                  <label for="teamFax">Team Fax</label>-->
<!--                  <input type="text" min="1" v-model="form.team_fax" class="form-control" id="teamFax"-->
<!--                         :class="{'is-invalid': typeSubmit && $v.form.team_fax.$error,}">-->
<!--                  <div-->
<!--                      v-if="typeSubmit && $v.form.team_fax.$error"-->
<!--                      class="invalid-feedback"-->
<!--                  >-->
<!--                      <span v-if="!$v.form.team_fax.required"-->
<!--                      >This value is required.</span-->
<!--                      >-->
<!--                  </div>-->
<!--                </div>-->
                <div class="form-group col-md-6">
                  <label for="teamEmail">Team Email</label>
                  <input type="email" min="1" v-model="form.team_email" class="form-control" id="teamEmail"
                         :class="{'is-invalid': typeSubmit && $v.form.team_email.$error,}">
                  <div
                      v-if="typeSubmit && $v.form.team_email.$error"
                      class="invalid-feedback"
                  >
                      <span v-if="!$v.form.team_email.required"
                      >This value is required.</span
                      >
                  </div>
                </div>
              </div>
              <br>
              <div class="form-row">
                <div class="form-group col-md-3">
                  <label for="teamPosition">Team Position</label>
                  <input type="text" v-model="form.team_position" class="form-control" id="teamPosition"
                         :class="{'is-invalid': typeSubmit && $v.form.team_position.$error,}">
                  <div
                      v-if="typeSubmit && $v.form.team_position.$error"
                      class="invalid-feedback"
                  >
                      <span v-if="!$v.form.team_position.required"
                      >This value is required.</span
                      >
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label for="teamExperience">Team Experience</label>
                  <input type="text" min="1" v-model="form.team_experience" class="form-control" id="teamExperience"
                         :class="{'is-invalid': typeSubmit && $v.form.team_experience.$error,}">
                  <div
                      v-if="typeSubmit && $v.form.team_experience.$error"
                      class="invalid-feedback"
                  >
                      <span v-if="!$v.form.team_experience.required"
                      >This value is required.</span
                      >
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label for="teamPracticeArea">Team Practice Area</label>
                  <input type="text" min="1" v-model="form.team_practice_area" class="form-control" id="teamPracticeArea"
                         :class="{'is-invalid': typeSubmit && $v.form.team_practice_area.$error,}">
                  <div
                      v-if="typeSubmit && $v.form.team_practice_area.$error"
                      class="invalid-feedback"
                  >
                      <span v-if="!$v.form.team_practice_area.required"
                      >This value is required.</span
                      >
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label for="teamJob">Team Job</label>
                  <input type="text" min="1" v-model="form.team_job" class="form-control" id="teamJob"
                         :class="{'is-invalid': typeSubmit && $v.form.team_job.$error,}">
                  <div
                      v-if="typeSubmit && $v.form.team_job.$error"
                      class="invalid-feedback"
                  >
                      <span v-if="!$v.form.team_job.required"
                      >This value is required.</span
                      >
                  </div>
                </div>
              </div>
              <br>
              <div class="form-row">
                <div class="form-group col-md-3">
                  <label for="teamFacebook">Team Facebook</label>
                  <input type="text" v-model="form.team_facebook" class="form-control" id="teamFacebook">
                </div>
                <div class="form-group col-md-3">
                  <label for="teamTwitter">Team Twitter</label>
                  <input type="text" min="1" v-model="form.team_twitter" class="form-control" id="teamTwitter">
                </div>
                <div class="form-group col-md-3">
                  <label for="teamLinkedin">Team Linkedin</label>
                  <input type="text" min="1" v-model="form.team_linkedin" class="form-control" id="teamLinkedin">
                </div>
                <div class="form-group col-md-3">
                  <label for="teamInstagram">Team Instagram</label>
                  <input type="text" min="1" v-model="form.team_instagram" class="form-control" id="teamInstagram">
                </div>
              </div>
              <br>
              <div class="form-group">
                <label for="teamLocation">Team Location</label>
                <ckeditor :editor="editor" v-model="form.team_location" class="form-control" id="teamLocation"></ckeditor>
              </div>
              <br>
              <div class="form-group">
                <label for="teamDescription">Team Description</label>
                <ckeditor :editor="editor" v-model="form.team_description" class="form-control" id="teamDescription"
                          :class="{'is-invalid': typeSubmit && $v.form.team_description.$error,}"></ckeditor>
                <div
                    v-if="typeSubmit && $v.form.team_description.$error"
                    class="invalid-feedback"
                >
                      <span v-if="!$v.form.team_description.required"
                      >This value is required.</span
                      >
                </div>
              </div>
              <br>
              <div class="form-row">
                <label>Team Image</label>
                <div class="col-md-8 input-group">
                  <input type="file" @change="onFileSelected" class="form-control" id="inputGroupFile02"
                         :class="{'is-invalid': typeSubmit && $v.form.team_image.$error,}">
                  <label class="input-group-text" for="inputGroupFile02">Upload</label>
                  <div
                      v-if="typeSubmit && $v.form.team_image.$error"
                      class="invalid-feedback"
                  >
                      <span v-if="!$v.form.team_image.required"
                      >This value is required.</span
                      >
                  </div>
                </div>
                <br><br>
                <div class="form-group col-md-2">
                  <img :src="form.team_image" id="team_photo">
                </div>
              </div>
              <br>
              <button type="submit" class="btn btn-primary col-xl-12">Save</button>
            </form>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>

<script>

import Layout from "../../router/layouts/main.vue";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {required} from "vuelidate/lib/validators";

export default {
  components:{
    // eslint-disable-next-line vue/no-unused-components
    ckeditor: CKEditor.component,
    Layout,
  },
  name:'admin-create-team',
  created(){
    // eslint-disable-next-line no-undef
    if(!User.loggedIn()){
      this.$router.push({name: 'admin-login'})
    }
    this.$http.get('team/')
        .then(({data}) => (this.teams = data))
    this.$http.get('country/')
        .then(({data}) => (this.country = data))
  },

  data(){
    return {
      typeSubmit:false,
      form:{
        team_name: null,
        team_surname: null,
        // team_seq: null,
        team_phone: null,
        // team_fax: null,
        team_email: null,
        team_position: null,
        team_experience: null,
        team_practice_area: null,
        team_job: null,
        team_location: null,
        team_facebook: null,
        team_twitter: null,
        team_linkedin: null,
        team_instagram: null,
        team_description: null,
        team_image: null,
        country_id: null,
        city_id: null,
        teamPhoneDialCode: null,
        teamPhoneFormat: null
      },
      errors:{},
      teams:{},
      country:[],
      city:[],
      editor: ClassicEditor,
    }
  },
  validations(){
    return{
      form:{
        team_name: {required},
        team_surname: {required},
        // team_seq: {required},
        team_phone: {required},
        // team_fax: {required},
        team_email: {required},
        team_position: {required},
        team_experience: {required},
        team_practice_area: {required},
        team_job: {required},
        team_description: {required},
        team_image: {required},
        country_id: {required},
        city_id: {required},
        // country_id: {required},
        // city_id: {required},
      },
    }
  },

  methods:{
    onFileSelected(event){
      let file = event.target.files[0];
      if(file.size > 20971520) {
        Notification.image_validation()
      } else {
        let reader = new FileReader();
        reader.onload = event => {
          this.form.team_image = event.target.result
          // eslint-disable-next-line no-console
          // console.log(event.target.result);
        };
        reader.readAsDataURL(file);
      }
    },
    teamInsert(){
      this.typeSubmit = true;
      this.$v.$touch();
      if(this.$v.$invalid){
        return;
      }else{
        this.$http.post('team',this.form)
            .then(() => {
              this.$router.push({ name: 'admin-team-list'})
              Notification.success()
            })
            .catch(error => this.errors = error.response.data.errors)
      }

    },
    switchCountrySelect(event){
      let id = event.target.value;
      if (id != null) {
        this.$http.get('select-to-country/'+id)
            .then(({data}) => (this.city = data))
            .catch()
        this.changePhoneFormatNumber();
      } else {
        this.$toasted.global.selectCountry()
      }

    },
    changePhoneFormatNumber(){
      // eslint-disable-next-line no-unused-vars
      let format;
      let dialCode;
      this.country.map(e=>{
        if(e.id === this.form.country_id){
          format = e.country_format;
          dialCode = e.country_dial_code;
          if (this.form.team_phone != null){
            this.form.team_phone = '';
          }
        }
      })
      this.form.teamPhoneDialCode = dialCode;
      this.form.teamPhoneFormat = format;

    },
  }
}
</script>
<style scoped>
#team_photo{
  height: 50px;
  width: 50px;
}
</style>
