var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('div',[_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"card card-statistics"},[_c('div',{staticClass:"card-body"},[_c('form',{attrs:{"enctype":"multipart/form-data"},on:{"submit":function($event){$event.preventDefault();return _vm.palletUpdate.apply(null, arguments)}}},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-4"},[_c('label',{attrs:{"for":"palletName"}},[_vm._v("Pallet Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.pallet_name),expression:"form.pallet_name"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.typeSubmit && _vm.$v.form.pallet_name.$error,
                  },attrs:{"type":"text","id":"palletName"},domProps:{"value":(_vm.form.pallet_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "pallet_name", $event.target.value)}}}),(_vm.typeSubmit && _vm.$v.form.pallet_name.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.pallet_name.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group col-md-4"},[_c('label',{attrs:{"for":"palletDefaultWeight"}},[_vm._v("Pallet Default Weight")]),_c('money',_vm._b({staticClass:"form-control",class:{
                    'is-invalid': _vm.typeSubmit && _vm.$v.form.pallet_default_weight.$error,
                  },staticStyle:{"width":"100%"},attrs:{"id":"palletDefaultWeight"},model:{value:(_vm.form.pallet_default_weight),callback:function ($$v) {_vm.$set(_vm.form, "pallet_default_weight", $$v)},expression:"form.pallet_default_weight"}},'money',_vm.weight,false)),(_vm.typeSubmit && _vm.$v.form.pallet_default_weight.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.pallet_default_weight.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()],1),_c('div',{staticClass:"form-group col-md-4"},[_c('label',{attrs:{"for":"palletMaxWeight"}},[_vm._v("Pallet Max Weight")]),_c('money',_vm._b({staticClass:"form-control",class:{
                    'is-invalid': _vm.typeSubmit && _vm.$v.form.pallet_max_weight.$error,
                  },staticStyle:{"width":"100%"},attrs:{"id":"palletMaxWeight"},model:{value:(_vm.form.pallet_max_weight),callback:function ($$v) {_vm.$set(_vm.form, "pallet_max_weight", $$v)},expression:"form.pallet_max_weight"}},'money',_vm.weight,false)),(_vm.typeSubmit && _vm.$v.form.pallet_max_weight.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.pallet_max_weight.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()],1)]),_c('br'),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"palletWidth"}},[_vm._v("Pallet Width")]),_c('money',_vm._b({staticClass:"form-control",class:{
                    'is-invalid': _vm.typeSubmit && _vm.$v.form.pallet_width.$error,
                  },staticStyle:{"width":"100%"},attrs:{"id":"palletWidth"},model:{value:(_vm.form.pallet_width),callback:function ($$v) {_vm.$set(_vm.form, "pallet_width", $$v)},expression:"form.pallet_width"}},'money',_vm.weight,false)),(_vm.typeSubmit && _vm.$v.form.pallet_width.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.pallet_width.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()],1),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"palletLength"}},[_vm._v("Pallet Length")]),_c('money',_vm._b({staticClass:"form-control",class:{
                    'is-invalid': _vm.typeSubmit && _vm.$v.form.pallet_length.$error,
                  },staticStyle:{"width":"100%"},attrs:{"id":"palletLength"},model:{value:(_vm.form.pallet_length),callback:function ($$v) {_vm.$set(_vm.form, "pallet_length", $$v)},expression:"form.pallet_length"}},'money',_vm.weight,false)),(_vm.typeSubmit && _vm.$v.form.pallet_length.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.pallet_length.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()],1),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"palletHeight"}},[_vm._v("Pallet Height")]),_c('money',_vm._b({staticClass:"form-control",class:{
                    'is-invalid': _vm.typeSubmit && _vm.$v.form.pallet_height.$error,
                  },staticStyle:{"width":"100%"},attrs:{"id":"palletHeight"},model:{value:(_vm.form.pallet_height),callback:function ($$v) {_vm.$set(_vm.form, "pallet_height", $$v)},expression:"form.pallet_height"}},'money',_vm.weight,false)),(_vm.typeSubmit && _vm.$v.form.pallet_height.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.pallet_height.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()],1),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"palletPrice"}},[_vm._v("Pallet Price")]),_c('money',_vm._b({staticClass:"form-control",class:{
                    'is-invalid': _vm.typeSubmit && _vm.$v.form.pallet_price.$error,
                  },staticStyle:{"width":"100%"},attrs:{"id":"palletPrice"},model:{value:(_vm.form.pallet_price),callback:function ($$v) {_vm.$set(_vm.form, "pallet_price", $$v)},expression:"form.pallet_price"}},'money',_vm.money,false)),(_vm.typeSubmit && _vm.$v.form.pallet_price.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.pallet_price.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()],1)]),_c('br'),_c('button',{staticClass:"btn btn-primary col-xl-12",attrs:{"type":"submit"}},[_vm._v("Save")])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }