var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('div',[_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"card card-statistics"},[_c('div',{staticClass:"card-body"},[_c('form',{staticClass:"row g-3",attrs:{"enctype":"multipart/form-data"},on:{"submit":function($event){$event.preventDefault();return _vm.panelSettingsUpdate.apply(null, arguments)}}},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"settingsTitle"}},[_vm._v("Title")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.title),expression:"form.title"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.typeSubmit && _vm.$v.form.title.$error,
                },attrs:{"type":"text","id":"settingsTitle"},domProps:{"value":(_vm.form.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "title", $event.target.value)}}}),(_vm.typeSubmit && _vm.$v.form.title.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.title.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"settingsEmail"}},[_vm._v("Email")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.typeSubmit && _vm.$v.form.email.$error,
                },attrs:{"type":"email","id":"settingsEmail"},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "email", $event.target.value)}}}),(_vm.typeSubmit && _vm.$v.form.email.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.email.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"settingsPhone"}},[_vm._v("Phone")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.phone),expression:"form.phone"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.typeSubmit && _vm.$v.form.phone.$error,
                },attrs:{"type":"text","id":"settingsPhone"},domProps:{"value":(_vm.form.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "phone", $event.target.value)}}}),(_vm.typeSubmit && _vm.$v.form.phone.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.phone.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"settingsFax"}},[_vm._v("Fax")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.fax),expression:"form.fax"}],staticClass:"form-control",attrs:{"type":"text","id":"settingsFax"},domProps:{"value":(_vm.form.fax)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "fax", $event.target.value)}}})])]),_c('br'),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"settingsFacebook"}},[_vm._v("Facebook")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.facebook),expression:"form.facebook"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.typeSubmit && _vm.$v.form.facebook.$error,
                },attrs:{"type":"text","id":"settingsFacebook"},domProps:{"value":(_vm.form.facebook)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "facebook", $event.target.value)}}}),(_vm.typeSubmit && _vm.$v.form.facebook.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.facebook.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"settingsTwitter"}},[_vm._v("Twitter")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.twitter),expression:"form.twitter"}],staticClass:"form-control",attrs:{"type":"text","id":"settingsTwitter"},domProps:{"value":(_vm.form.twitter)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "twitter", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"settingsYoutube"}},[_vm._v("Youtube")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.youtube),expression:"form.youtube"}],staticClass:"form-control",attrs:{"type":"text","id":"settingsYoutube"},domProps:{"value":(_vm.form.youtube)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "youtube", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"settingsInstagram"}},[_vm._v("Instagram")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.instagram),expression:"form.instagram"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.typeSubmit && _vm.$v.form.instagram.$error,
                },attrs:{"type":"text","id":"settingsInstagram"},domProps:{"value":(_vm.form.instagram)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "instagram", $event.target.value)}}}),(_vm.typeSubmit && _vm.$v.form.instagram.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.instagram.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()])]),_c('br'),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"settingsLinkedin"}},[_vm._v("Linkedin")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.linkedin),expression:"form.linkedin"}],staticClass:"form-control",attrs:{"type":"text","id":"settingsLinkedin"},domProps:{"value":(_vm.form.linkedin)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "linkedin", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"settingsCountry"}},[_vm._v("Country")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.country),expression:"form.country"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.typeSubmit && _vm.$v.form.country.$error,
                },attrs:{"type":"text","id":"settingsCountry"},domProps:{"value":(_vm.form.country)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "country", $event.target.value)}}}),(_vm.typeSubmit && _vm.$v.form.country.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.country.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"settingsCity"}},[_vm._v("City")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.city),expression:"form.city"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.typeSubmit && _vm.$v.form.city.$error,
                },attrs:{"type":"text","id":"settingsCity"},domProps:{"value":(_vm.form.city)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "city", $event.target.value)}}}),(_vm.typeSubmit && _vm.$v.form.city.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.city.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"settingsZipCode"}},[_vm._v("Zip Code")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.zipcode),expression:"form.zipcode"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.typeSubmit && _vm.$v.form.zipcode.$error,
                },attrs:{"type":"text","id":"settingsZipCode"},domProps:{"value":(_vm.form.zipcode)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "zipcode", $event.target.value)}}}),(_vm.typeSubmit && _vm.$v.form.zipcode.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.zipcode.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()])]),_c('br'),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-6"},[_c('label',{attrs:{"for":"settingsDescription"}},[_vm._v("Description")]),_c('ckeditor',{staticClass:"form-control",class:{
                  'is-invalid': _vm.typeSubmit && _vm.$v.form.description.$error,
                },attrs:{"editor":_vm.editor,"id":"settingsDescription"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),(_vm.typeSubmit && _vm.$v.form.description.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.description.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()],1),_c('div',{staticClass:"form-group col-md-6"},[_c('label',{attrs:{"for":"settingsAddress"}},[_vm._v("Address")]),_c('ckeditor',{staticClass:"form-control",class:{
                  'is-invalid': _vm.typeSubmit && _vm.$v.form.address.$error,
                },attrs:{"editor":_vm.editor,"id":"settingsAddress"},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}}),(_vm.typeSubmit && _vm.$v.form.address.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.address.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()],1)]),_c('br'),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"settingsCopyRight"}},[_vm._v("Copy Right")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.copyright),expression:"form.copyright"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.typeSubmit && _vm.$v.form.copyright.$error,
                },attrs:{"type":"text","id":"settingsCopyRight"},domProps:{"value":(_vm.form.copyright)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "copyright", $event.target.value)}}}),(_vm.typeSubmit && _vm.$v.form.copyright.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.copyright.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"settingsTax"}},[_vm._v("Tax")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.tax),expression:"form.tax"}],staticClass:"form-control",attrs:{"type":"number","min":"0","id":"settingsTax"},domProps:{"value":(_vm.form.tax)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "tax", $event.target.value)}}})]),_c('br'),_c('div',{staticClass:"form-row"},[_c('label',[_vm._v("Settings Logo")]),_c('div',{staticClass:"col-md-8 input-group"},[_c('input',{staticClass:"form-control",attrs:{"type":"file","id":"inputGroupFile02"},on:{"change":_vm.onFileSelected}}),_c('label',{staticClass:"input-group-text",attrs:{"for":"inputGroupFile02"}},[_vm._v("Upload")])]),_c('br'),_c('br'),_c('div',{staticClass:"form-group col-md-2"},[_c('img',{attrs:{"src":_vm.form.panel_image,"id":"settings_logo"}})])]),_c('br'),_c('div',{staticClass:"form-row"},[_c('label',[_vm._v("Settings Icon")]),_c('div',{staticClass:"col-md-8 input-group"},[_c('input',{staticClass:"form-control",attrs:{"type":"file","id":"inputGroupFile03"},on:{"change":_vm.onFileSelectedIcon}}),_c('label',{staticClass:"input-group-text",attrs:{"for":"inputGroupFile03"}},[_vm._v("Upload")])]),_c('br'),_c('br'),_c('div',{staticClass:"form-group col-md-2"},[_c('img',{attrs:{"src":_vm.form.panel_icon_image,"id":"settings_icon"}})])]),_c('br'),_c('button',{staticClass:"btn btn-primary col-xl-12",attrs:{"type":"submit"}},[_vm._v("Save")])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }