<template>
  <Layout>
    <div>
      <!--breadcrumb-->
<!--      <div class="page-breadcrumb d-none d-md-flex align-items-center mb-3">-->
<!--        <div class="breadcrumb-title pe-3"><router-link to="/stock-transfer-list">List Stock Transfer</router-link></div>-->
<!--        <div class="ps-3">-->
<!--          <nav aria-label="breadcrumb">-->
<!--            <ol class="breadcrumb mb-0 p-0">-->
<!--              <li class="breadcrumb-item"><router-link to="/home"><i class='bx bx-home-alt'></i></router-link>-->
<!--              </li>-->
<!--              <li class="breadcrumb-item active" aria-current="page">Show Stock Transfer</li>-->
<!--            </ol>-->
<!--          </nav>-->
<!--        </div>-->
<!--      </div>-->
      <!--end breadcrumb-->
      <div class="row">
        <div class="col-xl-12">
          <div class="card card-statistics text-center py-5">
            <div class="card-body pricing-content">
              <div class="pricing-content-card">
                <h5>Item Variant Detail</h5>
                <div class="row">
                  <div class="col-md-6">
                    <b-card header="Product">
                      <b-card-text>
                        <lazy-component wrapper-tag="section" @intersected="optionalDispatch">
                          <img :src="'https://crmtr.balinsoft.com/public/'+items.product.product_image" alt="" id="product_image">
                          <span slot="placeholder">Loading..</span> <!-- Optional -->
                        </lazy-component>
<!--                        <img :src="'https://crmtr.balinsoft.com/public/'+items.product.product_image" alt="" id="product_image">-->
                      </b-card-text>
                      <b-card-text><div align="center" v-html="items.product.barcode">{{items.product.product_code}}</div></b-card-text>
                      <b-card-text><b>Product Name: </b> {{ items.product.product_name }}</b-card-text>
                      <b-card-text><b>Product Code: </b> {{ items.product.product_code }}</b-card-text>
                      <b-card-text><b>Product Tax: </b> {{ items.product.product_tax }}</b-card-text>
<!--                      <b-card-text v-if="items.product.product_status === 1"><b>Status: </b> <span class="badge badge-success">Active</span></b-card-text>-->
<!--                      <b-card-text v-else><b>Status: </b> <span class="badge badge-danger">Passive</span></b-card-text>-->
                    </b-card>
                  </div>
                  <div class="col-md-6">
                    <b-card header="Variant">
                      <b-card-text>
                        <lazy-component wrapper-tag="section" @intersected="optionalDispatch">
                          <img :src="'https://crmtr.balinsoft.com/public/'+items.variant_image" alt="" id="variant_image">
                          <span slot="placeholder">Loading..</span> <!-- Optional -->
                        </lazy-component>
<!--                        <img :src="'https://crmtr.balinsoft.com/public/'+items.variant_image" alt="" id="variant_image">-->
                      </b-card-text>
                      <b-card-text><b>Variant Sku: </b> {{ items.sku_no }}</b-card-text>
                      <b-card-text><b>Variant Size: </b> {{ items.size.size_name }}</b-card-text>
                      <b-card-text><b>Variant Color: </b> {{ items.color.color_name }}</b-card-text>
                      <b-card-text><b>Variant Unit: </b> {{ items.unit.unit_name }}</b-card-text>
                      <b-card-text><b>Variant Weight: </b> {{ items.variant_weight }}</b-card-text>
                      <b-card-text><b>Variant Capacity: </b> {{ items.variant_capacity }}</b-card-text>
                      <b-card-text><b>Variant Tax: </b> {{ items.variant_tax }}</b-card-text>
                      <b-card-text><b>Variant Selling Price: </b> {{ items.variant_selling_price }}</b-card-text>
                      <b-card-text><b>Variant Discount Price: </b> {{ items.variant_discount_price }}</b-card-text>
                    </b-card>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../router/layouts/main.vue";
import PageHeader from "@/components/page-header";
import LazyComponent from "v-lazy-component";

export default {
  components:{
    // eslint-disable-next-line vue/no-unused-components
    Layout,PageHeader,LazyComponent
  },
  created(){
    // eslint-disable-next-line no-undef
    if(!User.loggedIn()){
      this.$router.push({name: '/admin/login'})
    }
    this.showWarehouseItemVariant();
  },
  data(){
    return{
      items:[],
    }
  },
  methods:{
    showWarehouseItemVariant(){
      let id = this.$route.params.id
      this.$http.get('show-warehouse-item-variant/'+id)
          .then(({data}) => (this.items = data))
          .catch()
    },
  },
}
</script>

<style scoped>
#product_image{
  height: 100px;
  width: 100px;
}
#variant_image{
  width: 100px;
  height: 100px;
}
.v-lazy-component.v-lazy-component--loading {
  filter: blur(20px);
}

.v-lazy-component.v-lazy-component--loaded {
  filter: blur(0);
  transition: filter 1s;
}
</style>
