<template>
  <Layout>
    <div>

      <div class="col-xl-12">
        <div class="card card-statistics">
          <div class="card-body">
            <form @submit.prevent="smtpInsert">
              <div class="form-row">
                <div class="form-group col-md-3">
                  <label for="smtpServer">Smtp Server</label>
                  <input type="text" v-model="form.smtp_server" class="form-control" id="smtpServer" :class="{
                    'is-invalid': typeSubmit && $v.form.smtp_server.$error,
                  }">
                  <div v-if="typeSubmit && $v.form.smtp_server.$error" class="invalid-feedback">
                    <span v-if="!$v.form.smtp_server.required">This value is required.</span>
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label for="smtpEmail">Smtp Email</label>
                  <input type="email" v-model="form.smtp_email" class="form-control" id="smtpEmail" :class="{
                    'is-invalid': typeSubmit && $v.form.smtp_email.$error,
                  }">
                  <div v-if="typeSubmit && $v.form.smtp_email.$error" class="invalid-feedback">
                    <span v-if="!$v.form.smtp_email.required">This value is required.</span>
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label for="smtpPassword">Smtp Password</label>
                  <input type="tel" v-model="form.smtp_password" class="form-control" id="smtpPassword" :class="{
                    'is-invalid': typeSubmit && $v.form.smtp_password.$error,
                  }">
                  <div v-if="typeSubmit && $v.form.smtp_password.$error" class="invalid-feedback">
                    <span v-if="!$v.form.smtp_password.required">This value is required.</span>
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label for="smtpPort">Smtp Port</label>
                  <input type="text" v-model="form.smtp_port" class="form-control" id="smtpPort" :class="{
                    'is-invalid': typeSubmit && $v.form.smtp_port.$error,
                  }">
                  <div v-if="typeSubmit && $v.form.smtp_port.$error" class="invalid-feedback">
                    <span v-if="!$v.form.smtp_port.required">This value is required.</span>
                  </div>
                </div>
              </div>
              <br>

              <button type="submit" class="btn btn-primary col-xl-12">Save</button>
            </form>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>

<script>

import Layout from "../../router/layouts/main.vue";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  required
} from "vuelidate/lib/validators";

export default {
  components:{
    // eslint-disable-next-line vue/no-unused-components
    ckeditor: CKEditor.component,
    Layout,
  },
  name:'admin-smtp-settings',
  created(){

    // eslint-disable-next-line no-undef
    if(!User.loggedIn()){
      this.$router.push({name: 'admin-login'})
    }
    this.getSmtpSettings();
  },
  validations(){
    return{
      form:{
        smtp_server: {required},
        smtp_email: {required},
        smtp_password: {required},
        smtp_port: {required},
      },
    }
  },
  data(){
    return {
      form:{
        smtp_server:null,
        smtp_email:null,
        smtp_password:null,
        smtp_port:null,
      },
      id:null,
      errors:{},
      typeSubmit:false,
      customers:{},
      editor: ClassicEditor,
    }
  },

  methods:{
    getSmtpSettings(){
      this.$http.get('smtp-settings')
          .then((response) => {
            console.log(response);
            this.form = response.data;
          })
          .catch(error => this.errors = error.response.data.errors)
    },
    smtpInsert(){
      this.typeSubmit = true;
      this.$v.$touch()

      if(this.$v.$invalid){
        return false;
      }else{
        this.$http.post('smtp-settings',this.form)
            .then(() => {
              this.$toasted.global.success()
            })
            .catch(error => this.errors = error.response.data.errors)
      }
    }
  }
}
</script>
<style scoped>

</style>
