<template>
  <Layout>
    <div>

      <div class="col-xl-12">
        <div class="card card-statistics">
          <div class="card-body">
            <form class="row g-3" @submit.prevent="imageResizeUpdate">
              <div class="form-row">
                <div class="form-group col-md-3">
                  <label for="sliderImageWidth">Slider Image Width</label>
                  <input type="text" min="1" v-model="form.slider_image_width" class="form-control" id="sliderImageWidth" :class="{
                    'is-invalid': typeSubmit && $v.form.slider_image_width.$error,
                  }">
                  <div v-if="typeSubmit && $v.form.slider_image_width.$error" class="invalid-feedback">
                    <span v-if="!$v.form.slider_image_width.required">This value is required.</span>
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label for="sliderImageHeight">Slider Image Height</label>
                  <input type="text" min="1" v-model="form.slider_image_height" class="form-control" id="sliderImageHeight" :class="{
                    'is-invalid': typeSubmit && $v.form.slider_image_height.$error,
                  }">
                  <div v-if="typeSubmit && $v.form.slider_image_height.$error" class="invalid-feedback">
                    <span v-if="!$v.form.slider_image_height.required">This value is required.</span>
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label for="blogImageWidth">Blog Image Width</label>
                  <input type="text" min="1" v-model="form.blog_image_width" class="form-control" id="blogImageWidth" :class="{
                    'is-invalid': typeSubmit && $v.form.blog_image_width.$error,
                  }">
                  <div v-if="typeSubmit && $v.form.blog_image_width.$error" class="invalid-feedback">
                    <span v-if="!$v.form.blog_image_width.required">This value is required.</span>
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label for="blogImageHeight">Blog Image Height</label>
                  <input type="text" min="1" v-model="form.blog_image_height" class="form-control" id="blogImageHeight" :class="{
                    'is-invalid': typeSubmit && $v.form.blog_image_height.$error,
                  }">
                  <div v-if="typeSubmit && $v.form.blog_image_height.$error" class="invalid-feedback">
                    <span v-if="!$v.form.blog_image_height.required">This value is required.</span>
                  </div>
                </div>
              </div>
              <br>
              <div class="form-row">
                <div class="form-group col-md-3">
                  <label for="productImageWidth">Product Image Width</label>
                  <input type="text" min="1" v-model="form.product_image_width" class="form-control" id="productImageWidth" :class="{
                    'is-invalid': typeSubmit && $v.form.product_image_width.$error,
                  }">
                  <div v-if="typeSubmit && $v.form.product_image_width.$error" class="invalid-feedback">
                    <span v-if="!$v.form.product_image_width.required">This value is required.</span>
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label for="productImageHeight">Product Image Height</label>
                  <input type="text" min="1" v-model="form.product_image_height" class="form-control" id="productImageHeight" :class="{
                    'is-invalid': typeSubmit && $v.form.product_image_height.$error,
                  }">
                  <div v-if="typeSubmit && $v.form.product_image_height.$error" class="invalid-feedback">
                    <span v-if="!$v.form.product_image_height.required">This value is required.</span>
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label for="aboutUsImageWidth">About Us Image Width</label>
                  <input type="text" min="1" v-model="form.about_us_image_width" class="form-control" id="aboutUsImageWidth" :class="{
                    'is-invalid': typeSubmit && $v.form.about_us_image_width.$error,
                  }">
                  <div v-if="typeSubmit && $v.form.about_us_image_width.$error" class="invalid-feedback">
                    <span v-if="!$v.form.about_us_image_width.required">This value is required.</span>
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label for="aboutUsImageHeight">About Us Image Height</label>
                  <input type="text" min="1" v-model="form.about_us_image_height" class="form-control" id="aboutUsImageHeight" :class="{
                    'is-invalid': typeSubmit && $v.form.about_us_image_height.$error,
                  }">
                  <div v-if="typeSubmit && $v.form.about_us_image_height.$error" class="invalid-feedback">
                    <span v-if="!$v.form.about_us_image_height.required">This value is required.</span>
                  </div>
                </div>
              </div>
              <br>
              <div class="form-row">
                <div class="form-group col-md-3">
                  <label for="serviceImageWidth">Service Image Width</label>
                  <input type="text" min="1" v-model="form.service_image_width" class="form-control" id="serviceImageWidth" :class="{
                    'is-invalid': typeSubmit && $v.form.service_image_width.$error,
                  }">
                  <div v-if="typeSubmit && $v.form.service_image_width.$error" class="invalid-feedback">
                    <span v-if="!$v.form.service_image_width.required">This value is required.</span>
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label for="serviceImageHeight">Service Image Height</label>
                  <input type="text" min="1" v-model="form.service_image_height" class="form-control" id="serviceImageHeight" :class="{
                    'is-invalid': typeSubmit && $v.form.service_image_height.$error,
                  }">
                  <div v-if="typeSubmit && $v.form.service_image_height.$error" class="invalid-feedback">
                    <span v-if="!$v.form.service_image_height.required">This value is required.</span>
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label for="teamImageWidth">Team Image Width</label>
                  <input type="text" min="1" v-model="form.team_image_width" class="form-control" id="teamImageWidth" :class="{
                    'is-invalid': typeSubmit && $v.form.team_image_width.$error,
                  }">
                  <div v-if="typeSubmit && $v.form.team_image_width.$error" class="invalid-feedback">
                    <span v-if="!$v.form.team_image_width.required">This value is required.</span>
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label for="teamImageHeight">Team Image Height</label>
                  <input type="text" min="1" v-model="form.team_image_height" class="form-control" id="teamImageHeight" :class="{
                    'is-invalid': typeSubmit && $v.form.team_image_height.$error,
                  }">
                  <div v-if="typeSubmit && $v.form.team_image_height.$error" class="invalid-feedback">
                    <span v-if="!$v.form.team_image_height.required">This value is required.</span>
                  </div>
                </div>
              </div>
              <br>
              <div class="form-row">
                <div class="form-group col-md-3">
                  <label for="projectImageWidth">Project Image Width</label>
                  <input type="text" min="1" v-model="form.project_image_width" class="form-control" id="projectImageWidth" :class="{
                    'is-invalid': typeSubmit && $v.form.project_image_width.$error,
                  }">
                  <div v-if="typeSubmit && $v.form.project_image_width.$error" class="invalid-feedback">
                    <span v-if="!$v.form.project_image_width.required">This value is required.</span>
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label for="projectImageHeight">Project Image Height</label>
                  <input type="text" min="1" v-model="form.project_image_height" class="form-control" id="projectImageHeight" :class="{
                    'is-invalid': typeSubmit && $v.form.project_image_height.$error,
                  }">
                  <div v-if="typeSubmit && $v.form.project_image_height.$error" class="invalid-feedback">
                    <span v-if="!$v.form.project_image_height.required">This value is required.</span>
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label for="referanceImageWidth">Referance Image Width</label>
                  <input type="text" min="1" v-model="form.referance_image_width" class="form-control" id="referanceImageWidth" :class="{
                    'is-invalid': typeSubmit && $v.form.referance_image_width.$error,
                  }">
                  <div v-if="typeSubmit && $v.form.referance_image_width.$error" class="invalid-feedback">
                    <span v-if="!$v.form.referance_image_width.required">This value is required.</span>
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label for="referanceImageHeight">Referance Image Height</label>
                  <input type="text" min="1" v-model="form.referance_image_height" class="form-control" id="referanceImageHeight" :class="{
                    'is-invalid': typeSubmit && $v.form.referance_image_height.$error,
                  }">
                  <div v-if="typeSubmit && $v.form.referance_image_height.$error" class="invalid-feedback">
                    <span v-if="!$v.form.referance_image_height.required">This value is required.</span>
                  </div>
                </div>
              </div>
              <br>
              <div class="form-row">
                <div class="form-group col-md-3">
                  <label for="adminImageWidth">Admin Image Width</label>
                  <input type="text" min="1" v-model="form.admin_image_width" class="form-control" id="adminImageWidth" :class="{
                    'is-invalid': typeSubmit && $v.form.admin_image_width.$error,
                  }">
                  <div v-if="typeSubmit && $v.form.admin_image_width.$error" class="invalid-feedback">
                    <span v-if="!$v.form.admin_image_width.required">This value is required.</span>
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label for="adminImageHeight">Admin Image Height</label>
                  <input type="text" min="1" v-model="form.admin_image_height" class="form-control" id="adminImageHeight" :class="{
                    'is-invalid': typeSubmit && $v.form.admin_image_height.$error,
                  }">
                  <div v-if="typeSubmit && $v.form.admin_image_height.$error" class="invalid-feedback">
                    <span v-if="!$v.form.admin_image_height.required">This value is required.</span>
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label for="noteImageWidth">Note Image Width</label>
                  <input type="text" min="1" v-model="form.note_image_width" class="form-control" id="noteImageWidth" :class="{
                    'is-invalid': typeSubmit && $v.form.note_image_width.$error,
                  }">
                  <div v-if="typeSubmit && $v.form.note_image_width.$error" class="invalid-feedback">
                    <span v-if="!$v.form.note_image_width.required">This value is required.</span>
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label for="noteImageHeight">Note Image Height</label>
                  <input type="text" min="1" v-model="form.note_image_height" class="form-control" id="noteImageHeight" :class="{
                    'is-invalid': typeSubmit && $v.form.note_image_height.$error,
                  }">
                  <div v-if="typeSubmit && $v.form.note_image_height.$error" class="invalid-feedback">
                    <span v-if="!$v.form.note_image_height.required">This value is required.</span>
                  </div>
                </div>
              </div>
              <br>
              <div class="form-row">
                <div class="form-group col-md-3">
                  <label for="webSiteImageWidth">Web Site Settings Image Width</label>
                  <input type="text" min="1" v-model="form.website_settings_image_width" class="form-control" id="webSiteImageWidth" :class="{
                    'is-invalid': typeSubmit && $v.form.website_settings_image_width.$error,
                  }">
                  <div v-if="typeSubmit && $v.form.website_settings_image_width.$error" class="invalid-feedback">
                    <span v-if="!$v.form.website_settings_image_width.required">This value is required.</span>
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label for="webSiteImageHeight">Web Site Settings Image Height</label>
                  <input type="text" min="1" v-model="form.website_settings_image_height" class="form-control" id="webSiteImageHeight" :class="{
                    'is-invalid': typeSubmit && $v.form.website_settings_image_height.$error,
                  }">
                  <div v-if="typeSubmit && $v.form.website_settings_image_height.$error" class="invalid-feedback">
                    <span v-if="!$v.form.website_settings_image_height.required">This value is required.</span>
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label for="panelImageWidth">Panel Settings Image Width</label>
                  <input type="text" min="1" v-model="form.panel_settings_image_width" class="form-control" id="panelImageWidth" :class="{
                    'is-invalid': typeSubmit && $v.form.panel_settings_image_width.$error,
                  }">
                  <div v-if="typeSubmit && $v.form.panel_settings_image_width.$error" class="invalid-feedback">
                    <span v-if="!$v.form.panel_settings_image_width.required">This value is required.</span>
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label for="panelImageHeight">Panel Settings Image Height</label>
                  <input type="text" min="1" v-model="form.panel_settings_image_height" class="form-control" id="panelImageHeight" :class="{
                    'is-invalid': typeSubmit && $v.form.panel_settings_image_height.$error,
                  }">
                  <div v-if="typeSubmit && $v.form.panel_settings_image_height.$error" class="invalid-feedback">
                    <span v-if="!$v.form.panel_settings_image_height.required">This value is required.</span>
                  </div>
                </div>
              </div>
              <br>
              <div class="form-row">
                <div class="form-group col-md-3">
                  <label for="orderTrackingImageWidth">Order Tracking Status Image Width</label>
                  <input type="text" min="1" v-model="form.order_tracking_status_image_width" class="form-control" id="orderTrackingImageWidth" :class="{
                    'is-invalid': typeSubmit && $v.form.order_tracking_status_image_width.$error,
                  }">
                  <div v-if="typeSubmit && $v.form.order_tracking_status_image_width.$error" class="invalid-feedback">
                    <span v-if="!$v.form.order_tracking_status_image_width.required">This value is required.</span>
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label for="orderTrackingImageHeight">Order Tracking Status Image Height</label>
                  <input type="text" min="1" v-model="form.order_tracking_status_image_height" class="form-control" id="orderTrackingImageHeight" :class="{
                    'is-invalid': typeSubmit && $v.form.order_tracking_status_image_height.$error,
                  }">
                  <div v-if="typeSubmit && $v.form.order_tracking_status_image_height.$error" class="invalid-feedback">
                    <span v-if="!$v.form.order_tracking_status_image_height.required">This value is required.</span>
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label for="employeeImageWidth">Employee Image Width</label>
                  <input type="text" min="1" v-model="form.employee_image_width" class="form-control" id="employeeImageWidth" :class="{
                    'is-invalid': typeSubmit && $v.form.employee_image_width.$error,
                  }">
                  <div v-if="typeSubmit && $v.form.employee_image_width.$error" class="invalid-feedback">
                    <span v-if="!$v.form.employee_image_width.required">This value is required.</span>
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label for="employeeImageHeight">Employee Image Height</label>
                  <input type="text" min="1" v-model="form.employee_image_height" class="form-control" id="employeeImageHeight" :class="{
                    'is-invalid': typeSubmit && $v.form.employee_image_height.$error,
                  }">
                  <div v-if="typeSubmit && $v.form.employee_image_height.$error" class="invalid-feedback">
                    <span v-if="!$v.form.employee_image_height.required">This value is required.</span>
                  </div>
                </div>
              </div>
              <br>
              <div class="form-row">
                <div class="form-group col-md-3">
                  <label for="campaignImageWidth">Campaign Image Width</label>
                  <input type="text" min="1" v-model="form.campaign_image_width" class="form-control" id="campaignImageWidth" :class="{
                    'is-invalid': typeSubmit && $v.form.campaign_image_width.$error,
                  }">
                  <div v-if="typeSubmit && $v.form.campaign_image_width.$error" class="invalid-feedback">
                    <span v-if="!$v.form.campaign_image_width.required">This value is required.</span>
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label for="campaignImageHeight">Campaign Image Height</label>
                  <input type="text" min="1" v-model="form.campaign_image_height" class="form-control" id="campaignImageHeight" :class="{
                    'is-invalid': typeSubmit && $v.form.campaign_image_height.$error,
                  }">
                  <div v-if="typeSubmit && $v.form.campaign_image_height.$error" class="invalid-feedback">
                    <span v-if="!$v.form.campaign_image_height.required">This value is required.</span>
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label for="shippingCompanyImageWidth">Shipping Company Image Width</label>
                  <input type="text" min="1" v-model="form.shipping_company_image_width" class="form-control" id="shippingCompanyImageWidth" :class="{
                    'is-invalid': typeSubmit && $v.form.shipping_company_image_width.$error,
                  }">
                  <div v-if="typeSubmit && $v.form.shipping_company_image_width.$error" class="invalid-feedback">
                    <span v-if="!$v.form.shipping_company_image_width.required">This value is required.</span>
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label for="shippingCompanyImageHeight">Shipping Company Image Height</label>
                  <input type="text" min="1" v-model="form.shipping_company_image_height" class="form-control" id="shippingCompanyImageHeight" :class="{
                    'is-invalid': typeSubmit && $v.form.shipping_company_image_height.$error,
                  }">
                  <div v-if="typeSubmit && $v.form.shipping_company_image_height.$error" class="invalid-feedback">
                    <span v-if="!$v.form.shipping_company_image_height.required">This value is required.</span>
                  </div>
                </div>
              </div>
              <br>
              <div class="form-row">
                <div class="form-group col-md-3">
                  <label for="variantImageWidth">Variant Image Width</label>
                  <input type="text" min="1" v-model="form.variant_image_width" class="form-control" id="variantImageWidth" :class="{
                    'is-invalid': typeSubmit && $v.form.variant_image_width.$error,
                  }">
                  <div v-if="typeSubmit && $v.form.variant_image_width.$error" class="invalid-feedback">
                    <span v-if="!$v.form.variant_image_width.required">This value is required.</span>
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label for="variantImageHeight">Variant Image Height</label>
                  <input type="text" min="1" v-model="form.variant_image_height" class="form-control" id="variantImageHeight" :class="{
                    'is-invalid': typeSubmit && $v.form.variant_image_height.$error,
                  }">
                  <div v-if="typeSubmit && $v.form.variant_image_height.$error" class="invalid-feedback">
                    <span v-if="!$v.form.variant_image_height.required">This value is required.</span>
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label for="commentImageWidth">Comment Image Width</label>
                  <input type="text" min="1" v-model="form.comment_image_width" class="form-control" id="commentImageWidth" :class="{
                    'is-invalid': typeSubmit && $v.form.comment_image_width.$error,
                  }">
                  <div v-if="typeSubmit && $v.form.comment_image_width.$error" class="invalid-feedback">
                    <span v-if="!$v.form.comment_image_width.required">This value is required.</span>
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label for="commentImageHeight">Comment Image Height</label>
                  <input type="text" min="1" v-model="form.comment_image_height" class="form-control" id="commentImageHeight" :class="{
                    'is-invalid': typeSubmit && $v.form.comment_image_height.$error,
                  }">
                  <div v-if="typeSubmit && $v.form.comment_image_height.$error" class="invalid-feedback">
                    <span v-if="!$v.form.comment_image_height.required">This value is required.</span>
                  </div>
                </div>
              </div>
              <br>
              <div class="form-row">
                <div class="form-group col-md-3">
                  <label for="collectionImageWidth">Collection Image Width</label>
                  <input type="text" min="1" v-model="form.collection_image_width" class="form-control" id="collectionImageWidth" :class="{
                    'is-invalid': typeSubmit && $v.form.collection_image_width.$error,
                  }">
                  <div v-if="typeSubmit && $v.form.collection_image_width.$error" class="invalid-feedback">
                    <span v-if="!$v.form.collection_image_width.required">This value is required.</span>
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label for="collectionImageHeight">Collection Image Height</label>
                  <input type="text" min="1" v-model="form.collection_image_height" class="form-control" id="collectionImageHeight" :class="{
                    'is-invalid': typeSubmit && $v.form.collection_image_height.$error,
                  }">
                  <div v-if="typeSubmit && $v.form.collection_image_height.$error" class="invalid-feedback">
                    <span v-if="!$v.form.collection_image_height.required">This value is required.</span>
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label for="truckImageWidth">Truck Image Width</label>
                  <input type="text" min="1" v-model="form.truck_image_width" class="form-control" id="truckImageWidth" :class="{
                    'is-invalid': typeSubmit && $v.form.truck_image_width.$error,
                  }">
                  <div v-if="typeSubmit && $v.form.truck_image_width.$error" class="invalid-feedback">
                    <span v-if="!$v.form.truck_image_width.required">This value is required.</span>
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label for="truckImageHeight">Truck Image Height</label>
                  <input type="text" min="1" v-model="form.truck_image_height" class="form-control" id="truckImageHeight" :class="{
                    'is-invalid': typeSubmit && $v.form.truck_image_height.$error,
                  }">
                  <div v-if="typeSubmit && $v.form.truck_image_height.$error" class="invalid-feedback">
                    <span v-if="!$v.form.truck_image_height.required">This value is required.</span>
                  </div>
                </div>
              </div>
              <br>
              <button type="submit" class="btn btn-primary col-xl-12">Save</button>
            </form>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>

<script>

import Layout from "../../router/layouts/main.vue";
import CKEditor from "@ckeditor/ckeditor5-vue";
import {
  required
} from "vuelidate/lib/validators";

export default {
  components:{
    // eslint-disable-next-line vue/no-unused-components
    ckeditor: CKEditor.component,
    Layout,
  },
  name:'admin-create-project',
  created(){
    // eslint-disable-next-line no-undef
    if(!User.loggedIn()){
      this.$router.push({name: 'admin-login'})
    }
    this.imageResize()
    // eslint-disable-next-line no-undef
    Reload.$on('AfterStatus',() => {
      this.imageResize()
    })
  },
  validations(){
    return{
      form:{
        slider_image_width: {required},
        slider_image_height: {required},
        blog_image_width: {required},
        blog_image_height: {required},
        product_image_width: {required},
        product_image_height: {required},
        about_us_image_width: {required},
        about_us_image_height: {required},
        service_image_width: {required},
        service_image_height: {required},
        team_image_width: {required},
        team_image_height: {required},
        project_image_width: {required},
        project_image_height: {required},
        referance_image_width: {required},
        referance_image_height: {required},
        admin_image_width: {required},
        admin_image_height: {required},
        note_image_width: {required},
        note_image_height: {required},
        website_settings_image_width: {required},
        website_settings_image_height: {required},
        panel_settings_image_width: {required},
        panel_settings_image_height: {required},
        order_tracking_status_image_width: {required},
        order_tracking_status_image_height: {required},
        employee_image_width: {required},
        employee_image_height: {required},
        campaign_image_width: {required},
        campaign_image_height: {required},
        shipping_company_image_width: {required},
        shipping_company_image_height: {required},
        variant_image_width: {required},
        variant_image_height: {required},
        comment_image_width: {required},
        comment_image_height: {required},
        collection_image_width: {required},
        collection_image_height: {required},
        truck_image_width: {required},
        truck_image_height: {required},
      },
    }
  },
  data(){
    return {
      form:{
        slider_image_width: '',
        slider_image_height: '',
        blog_image_width: '',
        blog_image_height: '',
        product_image_width: '',
        product_image_height: '',
        about_us_image_width: '',
        about_us_image_height: '',
        service_image_width: '',
        service_image_height: '',
        team_image_width: '',
        team_image_height: '',
        project_image_width: '',
        project_image_height: '',
        referance_image_width: '',
        referance_image_height: '',
        admin_image_width: '',
        admin_image_height: '',
        note_image_width: '',
        note_image_height: '',
        website_settings_image_width: '',
        website_settings_image_height: '',
        panel_settings_image_width: '',
        panel_settings_image_height: '',
        order_tracking_status_image_width: '',
        order_tracking_status_image_height: '',
        employee_image_width: '',
        employee_image_height: '',
        campaign_image_width: '',
        campaign_image_height: '',
        shipping_company_image_width: '',
        shipping_company_image_height: '',
        variant_image_width: '',
        variant_image_height: '',
        comment_image_width: '',
        comment_image_height: '',
        collection_image_width: '',
        collection_image_height: '',
        truck_image_width: '',
        truck_image_height: '',
      },
      errors:{},
      typeSubmit:false,
    }
  },

  methods:{
    imageResize(){
      let id = 0
      this.$http.get('image-resize/'+id)
          .then(({data}) => (this.form = data))
          .catch()
    },
    imageResizeUpdate(){
      this.typeSubmit = true;
      this.$v.$touch()

      if(this.$v.$invalid){
        return false;
      }else{
        // let id = this.$route.params.id
        this.$http.post('image-resize/',this.form)
            .then(() => {
              this.$router.push({ name: 'admin'})
              // eslint-disable-next-line no-undef
              Reload.$emit('AfterStatus');
              this.$toasted.global.success()
            })
            .catch(error => this.errors = error.response.data.errors)
      }
    }
  }
}
</script>
<style scoped>

</style>
