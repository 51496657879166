var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('div',[_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"card card-statistics"},[_c('div',{staticClass:"card-body"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.smtpInsert.apply(null, arguments)}}},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"smtpServer"}},[_vm._v("Smtp Server")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.smtp_server),expression:"form.smtp_server"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.typeSubmit && _vm.$v.form.smtp_server.$error,
                },attrs:{"type":"text","id":"smtpServer"},domProps:{"value":(_vm.form.smtp_server)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "smtp_server", $event.target.value)}}}),(_vm.typeSubmit && _vm.$v.form.smtp_server.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.smtp_server.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"smtpEmail"}},[_vm._v("Smtp Email")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.smtp_email),expression:"form.smtp_email"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.typeSubmit && _vm.$v.form.smtp_email.$error,
                },attrs:{"type":"email","id":"smtpEmail"},domProps:{"value":(_vm.form.smtp_email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "smtp_email", $event.target.value)}}}),(_vm.typeSubmit && _vm.$v.form.smtp_email.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.smtp_email.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"smtpPassword"}},[_vm._v("Smtp Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.smtp_password),expression:"form.smtp_password"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.typeSubmit && _vm.$v.form.smtp_password.$error,
                },attrs:{"type":"tel","id":"smtpPassword"},domProps:{"value":(_vm.form.smtp_password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "smtp_password", $event.target.value)}}}),(_vm.typeSubmit && _vm.$v.form.smtp_password.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.smtp_password.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"smtpPort"}},[_vm._v("Smtp Port")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.smtp_port),expression:"form.smtp_port"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.typeSubmit && _vm.$v.form.smtp_port.$error,
                },attrs:{"type":"text","id":"smtpPort"},domProps:{"value":(_vm.form.smtp_port)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "smtp_port", $event.target.value)}}}),(_vm.typeSubmit && _vm.$v.form.smtp_port.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.smtp_port.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()])]),_c('br'),_c('button',{staticClass:"btn btn-primary col-xl-12",attrs:{"type":"submit"}},[_vm._v("Save")])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }