<template>
  <Layout>
    <br>
    <div>
      <!-- Container-fluid starts-->
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header d-flex justify-content-between">
                <div class="card-heading">
                  <h4 class="card-title .customCardTitle">Slider Language</h4>
                </div>
              </div>
              <div class="card-body">
                <b-tabs justified pills class="navtab-bg" content-class="p-3">
                  <b-tab active>
                    <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                  </span>
                      <span class="d-none d-sm-inline-block">Add</span>
                    </template>
                    <div>
                      <div class="card-body">
                        <div class="row">
                          <div class="form-row">
                            <div class="form-group col-md-3">
                              <div class="checkbox checbox-switch switch-success">
                                <button type="button" class="btn btn-block btn-round btn-warning" @click="addNewVariantRow">Add More</button>
                              </div>
                            </div>
                          </div>
                          <form class="row g-3" @submit.prevent="insertVariant">
                            <table class="display compact table table-striped table-bordered" width="100%" style="border-color: #ddd;">
                              <thead>
                              <th>Language</th>
                              <th>Slider Title</th>
                              <th>Slider Description</th>
                              <th>Action</th>
                              </thead>
                              <tbody id="addRow" class="addRow">

                              </tbody>
                              <tbody>
                              <tr v-for="(variant, k) in variants" :key="k">
                                <td>
                                  <select id="color" class="form-control" v-model="variant.language_id">
                                    <option :key="lang.id" :value="lang.id" v-for="lang in languages">{{ lang.name }}</option>
                                  </select>
                                </td>
                                <td>
                                  <input class="form-control" style="width: 100%" type="text" v-model="variant.slider_title">
                                </td>
                                <td>
                                  <ckeditor :editor="editor" v-model="variant.slider_description" style="width: 100%;" class="form-control" id="productDescription"></ckeditor>
                                </td>
                                <td>
                                  <button type="button" class="btn btn-block btn-round btn-danger" @click="deleteVariantRow(k,variant)">X</button>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                            <button type="submit" class="btn btn-primary col-xl-12">Save</button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </b-tab>

                  <b-tab>
                    <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-cog"></i>
                  </span>
                      <span class="d-none d-sm-inline-block">Show</span>
                    </template>
                    <div>
                      <div class="card-body">
                        <div class="row">
                          <form class="row g-3" @submit.prevent="updateVariant">
                            <table class="display compact table table-striped table-bordered" width="100%" style="border-color: #ddd;">
                              <thead>
                              <th>Language</th>
                              <th>Slider Title</th>
                              <th>Slider Description</th>
                              <th>Action</th>
                              </thead>
                              <tbody id="addRow" class="addRow">

                              </tbody>
                              <tbody>
                              <tr v-for="(show_variant, r) in show_variants" :key="r">
                                <input type="hidden" v-model="show_variant.id">
                                <input type="hidden" v-model="show_variant.language_code">
                                <td>
                                  <select id="color" class="form-control" v-model="show_variant.language_id">
                                    <option :key="lang.id" :value="lang.id" v-for="lang in languages">{{ lang.name }}</option>
                                  </select>
                                </td>
                                <td>
                                  <input class="form-control" style="width: 100%" type="text" v-model="show_variant.slider_title">
                                </td>
                                <td>
                                  <ckeditor :editor="editor" v-model="show_variant.slider_description" style="width: 100%;" class="form-control"></ckeditor>
                                </td>
                                <td>
                                  <a @click="deleteVariant(show_variant.language_code)" style="color: white;" class="btn btn-block btn-round btn-danger">X</a>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                            <button type="submit" class="btn btn-primary col-xl-12">Update</button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </b-tab>
                </b-tabs>

              </div>

            </div>
          </div>

        </div>
      </div>
      <!-- Container-fluid Ends-->
    </div>
  </Layout>

</template>

<script>
import Layout from "../../router/layouts/main.vue";
import axios from "axios";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { Layout, ckeditor: CKEditor.component,},
  created(){
    // eslint-disable-next-line no-undef
    if(!User.loggedIn()){
      this.$router.push({name: 'admin-login'})
    }
    this.id = this.$route.params.id
    this.showVariant();
    this.getLanguage();
  },
  data(){
    return{
      id: '',
      variants: [{
        language_id: '',
        slider_title: '',
        slider_description: '',
      }],
      show_variants: [{
        id: '',
        language_code: '',
        language_id: '',
        slider_title: '',
        slider_description: '',
      }],
      searchTerm:'',
      languages:{},
      editor: ClassicEditor,
    }
  },
  computed:{
    // filtersearch(){
    //   return this.productVariants.filter(productVariant => {
    //     return productVariant.product.product_name.match(this.searchTerm)
    //
    //   })
    // }
  },
  methods:{
    addNewVariantRow() {
      this.variants.push({
        language_id: '',
        slider_title: '',
        slider_description: '',
      })
    },
    deleteVariantRow(index,variant) {
      var idx = this.variants.indexOf(variant);
      if(idx > -1) {
        this.variants.splice(idx, 1);
      }
    },
    getLanguage(){
      this.$http.get('language/')
          .then(({data}) => (this.languages = data))
    },
    showVariant(){
      let id = this.$route.params.id
      this.$http.get('show/slider-language/'+id)
          .then(({data}) =>{
            this.show_variants = data
          })
          .catch()
    },
    insertVariant(){
      let id = this.$route.params.id
      this.$http.post('slider-language-store/'+id,this.variants)
          .then(() => {
            this.$router.push({name: 'admin-slider-language',params:{id:id}})
            this.$router.push({ name: 'admin-slider-list'})
            Notification.success()
          })
          .catch(error => this.errors = error.response.data.errors)
    },
    updateVariant(){
      let id = this.$route.params.id
      this.$http.post('slider-language-update/'+id,this.show_variants)
          .then(() => {
            this.$router.push({ name: 'admin-slider-list'})
            Notification.success()
          })
          .catch(error => this.errors = error.response.data.errors)
    },
    deleteVariant(code){
      // eslint-disable-next-line no-undef
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          axios.delete('slider-language-delete/'+this.id+'/'+code)
              .then(() => {
                this.showVariant();
                // this.$router.push({ name: 'admin-product-list'})
              })
              .catch(() => {
                // this.$router.push({name: 'admin-variant-list'})
              })
          // eslint-disable-next-line no-undef
          Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
          )
        }
      })

    },
  },


}
</script>

<style scoped>
#variant_photo{
  height: 40px;
  width: 40px;
}
#variant_image{
  width: 50px;
  height: 50px;
}
</style>
