import axios from 'axios'
import Vue from 'vue'
import router from '../../router'
// import store from '../state/store'

import store from '@/state/store'

// import {
//     authMethods
// } from "@/state/helpers";

export default () => {

    axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

    axios.interceptors.request.use(function (config) {
        // Do something before request is sent
        let url = config.url;

        config.headers['x-logger-info'] = '';
        // let token = window.localStorage.getItem('token');
        //
        // if (token) {
        //     config.headers['Authorization'] = `Bearer ${token}`;
        // }

        // if (config.method === 'post' || config.method === 'put' || config.method === 'delete') {
        //
        // }
        //
        if (config.method === 'post' || config.method === 'put' || config.method === 'delete') {
            let token = localStorage.getItem('token');
            if (token) {
                config.headers['x-logger-info'] = `${token}`;
            }
        }

        // api silme
        // if (url.startsWith('api/')) {
        //     url = url.slice(4);
        // }

        if (!url.startsWith('api/') && !url.includes('//')) {
            url = 'api/' + url;
        }
        config.url = url;
        document.body.classList.add('loading-indicator')
        return config;
    }, function (error) {
        // Do something with request error
        return Promise.reject(error);
    });

    axios.interceptors.response.use(
        (response) => {
            document.body.classList.remove('loading-indicator');
            return response
        },
        (error) => {
            document.body.classList.remove('loading-indicator');
            const {status} = error.response;
            if (status === 401) {
                store.dispatch('auth/logOut');
                store.dispatch('authfack/logout');
                router.push({name:'admin-logout'});
            }
            if (status === 403) {
                router.push({name: 'admin-error-500'});
            }
            // if (status === 500) {
            //     router.push({name:'admin-logout'});
            // }
            return Promise.reject(error.response)
        }
    )
    Vue.prototype.$http = axios


    let headers = {
        'cache-control': 'no-cache'
    };
    let accessToken = localStorage.getItem('jwt-token');

    if (accessToken && accessToken !== '') {
        headers.Authorization = accessToken;

    }
    const instance = axios.create({
        baseURL: 'http://192.168:8001/',
        headers: headers
    });

    instance.interceptors.response.use((response) => {
        if(response.status === 401) {
            //add your code
            alert("You are not authorized");
        }
        return response;
    }, (error) => {
        if (error.response && error.response.data) {
            //add your code
            return Promise.reject(error.response.data);
        }
        return Promise.reject(error.message);
    });

    return instance;

}
