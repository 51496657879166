<template>
  <Layout>
<!--        <PageHeader :title="title" :items="items" />-->
    <div class="row">
<!--      <div class="text-center">-->
<!--        <b-modal id="modal-standard"  v-model="modalDisplay" title="Add Quantity" hide title-class="font-18">-->
<!--          <b-form-input v-on:keypress="NumbersOnly" v-model="productForm.quantity" placeholder="Add Quantity"></b-form-input>-->
<!--          <template  #modal-footer>-->
<!--            <button  data-dismiss="modal" @click="closeModal" class="btn btn-danger btn-sm m-1">Close</button>-->
<!--            <b-button  for="edit-modal" @click="AddToStockTransferCart(productForm.id)" class="btn btn-success btn-sm m-1"  >Save</b-button>-->
<!--          </template>-->
<!--        </b-modal>-->
<!--      </div>-->

      <div class="text-center">
        <b-modal id="variant-standard"  v-model="variantmodalDisplay" title="Add Quantity" hide title-class="font-18">
          <b-form-input v-on:keypress="NumbersOnly" v-model="productForm.quantity" placeholder="Add Quantity"></b-form-input>
          <template #modal-footer>
            <button data-dismiss="modal" @click="closeModal" class="btn btn-danger btn-sm m-1">Close</button>
            <b-button for="edit-modal" @click="AddToStockTransferCart(variantForm.product_id, variantForm.id)" class="btn btn-success btn-sm m-1">Save</b-button>
          </template>
        </b-modal>
      </div>

      <div class="text-center">
        <b-modal id="edit-variant-standard"  v-model="editVariantmodalDisplay" title="Edit Quantity" hide title-class="font-18">
          <b-form-input v-on:keypress="NumbersOnly" v-model="variantForm.quantity" placeholder="Edit Quantity"></b-form-input>
          <template #modal-footer>
            <button data-dismiss="modal" @click="closeModal" class="btn btn-danger btn-sm m-1">Close</button>
            <b-button for="edit-modal" @click="increment(variantForm.id)" class="btn btn-success btn-sm m-1"  >Save</b-button>
          </template>
        </b-modal>
      </div>

<!--      <div class="text-center">-->
<!--        <b-modal id="edit-variant-standard"  v-model="editProductModalDisplay" title="Edit Quantity" hide title-class="font-18">-->
<!--          <b-form-input v-on:keypress="NumbersOnly" v-model="productForm.quantity" placeholder="Edit Quantity"></b-form-input>-->
<!--          <template  #modal-footer>-->
<!--            <button  data-dismiss="modal" @click="closeModal" class="btn btn-danger btn-sm m-1">Close</button>-->
<!--            <b-button  for="edit-modal" @click="increment(productForm.id)" class="btn btn-success btn-sm m-1"  >Save</b-button>-->
<!--          </template>-->
<!--        </b-modal>-->
<!--      </div>-->
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-3">Stock Transfer</h4>
            <form-wizard color="#626ed4" @on-complete="onComplete">
              <!--                Start -->
              <tab-content icon="mdi mdi-pencil-box-outline">
                <div class="row">
                  <div class="col-12">
                    <label for="inputFromWarehouse">From Warehouse</label>
                    <select id="inputFromWarehouse" @change="fromWarehouseChange($event)" class="form-control" v-model="form.from_warehouse_id">
                      <option :key="fromWarehouse.id" :value="fromWarehouse.id" v-for="fromWarehouse in fromWarehouses">{{ fromWarehouse.warehouse_name }}</option>
                    </select>
                    <br>
                    <label for="inputToWarehouse">To Warehouse</label>
                    <select id="inputToWarehouse" class="form-control" @change="toWarehouseChange($event)" v-model="form.to_warehouse_id">
                      <option :key="toWarehouse.id" :value="toWarehouse.id" v-for="toWarehouse in toWarehouses">{{ toWarehouse.warehouse_name }}</option>
                    </select>
                    <br>
                    <label>Start Stock Transfer Date</label>
                    <div class="form-group mb-0">
                      <input type="datetime-local" class="form-control" v-model="form.start_transfer_date">
                    </div>
                    <br>
                    <label>End Stock Transfer Date</label>
                    <div class="form-group mb-0">
                      <input type="datetime-local" class="form-control" v-model="form.finish_transfer_date">
                    </div>
                    <br>
                    <label for="inputFromWarehouse">Pallet</label>
                    <select id="inputFromWarehouse"  @change="palletChange($event)" class="form-control" v-model="form.pallet_id">
                      <option :key="pallet.id" :value="pallet.id" v-for="pallet in pallets">{{ pallet.pallet_name }}</option>
                    </select>
                    <br>
                    <label class="form-label" for="name">Process Count</label>
                    <input
                        :min="1"
                        :max="20"
                        :maxlength="2"
                        type="text"
                        v-on:keypress="NumbersOnly"
                        @change="calculateProcess($event)"
                        v-model="form.processCount"
                        id="name12"
                        name="untyped-input"
                        class="form-control mask-money"
                    />
                    <br>

                  </div>

                  <!-- end col -->
                </div>
                <!-- end row -->
              </tab-content>
              <!--                Process -->
              <tab-content icon="mdi mdi-pencil-box-multiple-outline">
                <div class="row">
                  <div class="col-xl-12">
                    <form class="repeater" ref="form" enctype="multipart/form-data">
                      <div data-repeater-list="group-a" v-show="showProcess">
                        <div
                            v-for="(field) in data"
                            :key="field.id"
                            class="row"
                        >

                          <div class="mb-3 col-lg-2">
                            <label class="form-label" for="name">Source</label>
                            <input
                                type="text"
                                v-model="field.source"
                                id="name1"
                                name="untyped-input"
                                class="form-control"
                                v-if="field.id === callFirstIndex"
                                readonly
                            />
                            <input
                                type="text"
                                v-model="field.source"
                                id="name"
                                name="untyped-input"
                                class="form-control"
                                v-else
                            />
                          </div>

                          <div class="mb-3 col-lg-2">
                            <label class="form-label" for="email">Target</label>
                            <input
                                type="email"
                                v-model="field.target"
                                id="email1"
                                class="form-control"
                                v-if="field.id === callLastIndex"
                                readonly
                            />
                            <input
                                type="email"
                                v-model="field.target"
                                id="email"
                                class="form-control"
                                v-else
                            />
                          </div>

                          <div class="mb-3 col-lg-2">
                            <label class="form-label" for="transportCategory1">Transport</label>
                            <select name="transportCategory" id="transportCategory1" @change="transportChange($event,field.id)" class="form-control" v-model="field.transport">
                              <option value=""></option>
                              <option value="1">Truck</option>
                              <option value="2">Ship</option>
                              <option value="3">Train</option>
                              <option value="4">Aircraft</option>
                              <!--                              <option value="5">Shipping Company</option>-->
                            </select>
                          </div>

                          <div class="mb-3 col-lg-2">
                            <label class="form-label" for="startProcessDate">Start Date</label>
                            <input type="datetime-local" v-model="field.start_date" class="form-control" id="startProcessDate">
                          </div>

                          <div class="mb-3 col-lg-2">
                            <label class="form-label" for="finishProcessDate">Finish Date</label>
                            <input type="datetime-local" v-model="field.end_date" class="form-control" id="finishProcessDate">
                          </div>

                          <div class="mb-3 col-lg-2" v-show="field.truckShow">
                            <label for="inputSupplier">Supplier</label>
                            <select id="inputSupplier" class="form-control" @change="supplierChange($event,field.id)" v-model="field.supplier">
                              <option :key="supplier.id" :value="supplier.id" v-for="supplier in suppliers">{{ supplier.supplier_name }} {{ supplier.supplier_surname }}</option>
                            </select>
                          </div>
                          <div class="mb-3 col-lg-2" v-show="field.truckShow">
                            <label for="inputSupplier">Trucks</label>
                            <select id="inputSupplier" class="form-control" v-model="field.truck">
                              <option :key="truck.id" :value="truck.id" v-for="truck in field.truckList">{{ truck.truck_brand }} -- {{ truck.truck_model }} / {{ truck.truck_plate_number}}</option>
                            </select>
                          </div>

                          <div class="mb-3 col-lg-2">
                            <label class="form-label">Country</label>
                            <b-form-select id="category-select-add" class="form-control"  @change="changePhoneFormatNumber(field.id)" v-model="field.country" value-field="id" text-field="country" :options="countryList"></b-form-select>
                          </div>
                          <div class="mb-3 col-lg-2">
                            <label class="form-label" for="price">Price</label>
                            <money v-model.lazy="field.price" v-bind="money" class="form-control" id="price"></money>
<!--                            <input type="text"  v-model.lazy="field.price" v-money="money" @change="getPriceChange" class="form-control" id="price">-->
                          </div>

                          <div class="mb-3 col-lg-2">
                            <label class="form-label" for="extraPrice">Extra Price</label>
                            <money v-model.lazy="field.extra_price" v-bind="money" class="form-control" id="extraPrice"></money>
<!--                            <input type="text" v-model.lazy="field.extra_price" v-money="money" @change="getPriceChange" class="form-control" id="extraPrice">-->
                          </div>

                          <div class="mb-3 col-lg-2" v-show="field.shipShow">
                            <label class="form-label" for="shipName">Ship Name</label>
                            <input type="text" v-model="field.ship_name" class="form-control" id="shipName">
                          </div>

                          <div class="mb-3 col-lg-2" v-show="field.shipShow" v-if="field.country != null">
                            <label class="form-label" for="portContactNumber">Port Contact Number</label>
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon1">{{ field.dial_code }}</span>
                              </div>
                              <input type="text" v-mask="field.shipPhoneFormat" v-model="field.port_contact_number" class="form-control" id="portContactNumber">
                            </div>
                          </div>

                          <div class="mb-3 col-lg-2" v-show="field.airportShow">
                            <label class="form-label">Aircraft Name</label>
                            <input type="text" v-model="field.aircraft_name" class="form-control">
                          </div>

                          <div class="mb-3 col-lg-2" v-show="field.airportShow" v-if="field.country != null">
                            <label class="form-label" for="airportContactNumber">Airport Contact Number</label>
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon1">{{ field.dial_code }}</span>
                              </div>
                              <input type="text" v-mask="field.airportPhoneFormat" v-model="field.airport_contact_number" class="form-control" id="airportContactNumber">
                            </div>
                          </div>
                          <!--                          <div class="mb-3 col-lg-2" v-show="field.airportShow">-->
                          <!--                            <label class="form-label" for="airportContactNumber">Airport Contact Number</label>-->
                          <!--                            <input type="text" :readonly="true" v-mask="field.airportPhoneFormat" v-model="field.airport_contact_number" class="form-control" id="airportContactNumber">-->
                          <!--                          </div>-->

                          <div class="mb-3 col-lg-2" v-show="field.stationShow">
                            <label class="form-label" for="trainName">Train Name</label>
                            <input type="text" v-model="field.train_name" class="form-control" id="trainName">
                          </div>
                          <div class="mb-3 col-lg-2" v-show="field.stationShow" v-if="field.country != null">
                            <label class="form-label" for="stationContactNumber">Station Contact Number</label>
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon1">{{ field.dial_code }}</span>
                              </div>
                              <input type="text" v-mask="field.stationPhoneFormat" v-model="field.station_contact_number" class="form-control" id="stationContactNumber">
                            </div>
                          </div>
                          <!--                          <div class="mb-3 col-lg-2" v-show="field.stationShow">-->
                          <!--                            <label class="form-label" for="stationContactNumber">Station Contact Number</label>-->
                          <!--                            <input type="text" :readonly="true" v-mask="field.stationPhoneFormat" v-model="field.station_contact_number" class="form-control" id="stationContactNumber">-->
                          <!--                          </div>-->

                          <div class="mb-3 col-lg-12">
                            <label class="form-label" for="message">Notes</label>
                            <textarea
                                id="message"
                                v-model="field.notes"
                                class="form-control"
                            ></textarea>
                          </div>
                          <hr style="border: 5px solid #4E58AA;">
                        </div>
                      </div>
                    </form>

                  </div>
                  <!-- end col -->
                </div>
                <!-- end row -->
              </tab-content>
              <!--                All Product And Variant -->
              <tab-content icon="mdi mdi-collapse-all-outline">
                <div class="row">
                  <div class="col-xl-12">
                    <div class="card">
                      <div class="card-header d-flex justify-content-between">
                        <div class="card-heading">

                          <h4 class="card-title .customCardTitle">Warehouse Products And Variants</h4>
                        </div>
                        <!--                        <a class="btn btn-sm btn-info"><font color="#ffffff">Add Customer</font></a>-->
                      </div>
                      <div class="card-body">
                        <div class="row">


                          <b-row>
                            <b-col xl="6">

                              <b-input-group class="datatable-btn">
                                <b-form-input v-model="filterVariant" placeholder="Type to Search"></b-form-input>
                                <b-input-group-append>
                                  <b-button :disabled="!filterVariant" @click="filterVariant = ''">Clear</b-button>
                                </b-input-group-append>
                              </b-input-group>

                            </b-col>

                            <b-col xl="6">
                              <b-form-group  label-cols="2" label="Per page" class="mb-4 datatable-select">
                                <b-form-select v-model="perPageVariant" :options="pageOptionsVariant"></b-form-select>
                              </b-form-group>
                            </b-col>
                          </b-row>

                          <div class="table-responsive datatable-vue">
                            <b-table
                                show-empty
                                stacked="md"
                                :items="itemVariants"
                                :fields="tablefieldsVariants"
                                :filter="filterVariant"
                                :current-page="currentPageVariant"
                                :per-page="perPageVariant"
                                @filtered="onFilteredVariant"

                            >
                              <template v-slot:cell(variantStockControl)="{item}">
                                <div v-if="item.variant_quantity > 100">
                                  <b-spinner
                                      type="grow"
                                      label="Spinning"
                                      variant="success"
                                      class="m-1"
                                  ></b-spinner>
                                </div>
                                <div v-else-if="item.variant_quantity < 100 && item.variant_quantity > 0">
                                  <b-spinner
                                      type="grow"
                                      label="Spinning"
                                      variant="warning"
                                      class="m-1"
                                  ></b-spinner>
                                </div>
                                <div v-else="">
                                  <b-spinner
                                      type="grow"
                                      label="Spinning"
                                      variant="danger"
                                      class="m-1"
                                  ></b-spinner>
                                </div>
                              </template>
                              <template v-slot:cell(variantImage)="{item}">
                                <lazy-component wrapper-tag="section" @intersected="optionalDispatch">
                                  <img :src="'https://crmtr.balinsoft.com/public/'+item.variant_image" id="variant_photo">
                                  <span slot="placeholder">Loading..</span> <!-- Optional -->
                                </lazy-component>
<!--                                <img :src="'https://crmtr.balinsoft.com/public/'+item.variant_image" id="variant_photo">-->
                              </template>
                              <template v-slot:cell(status)="{item}">
                                <div v-if="item.product_status === 1 || item.product_status === '1'">
                                  <b-badge class="btn btn-success">Active</b-badge>
                                </div>
                                <div v-else>
                                  <b-badge class="btn btn-danger">Passive</b-badge>
                                </div>
                              </template>
                              <template v-slot:cell(variantQuantity)="{item}">
                                <div v-if="item.variant_quantity > 0">
                                  <p>{{item.variant_quantity}}</p>
                                </div>
                                <div v-else>
                                  <b-badge class="btn btn-danger">Out Of Stock</b-badge>
                                </div>
                              </template>
                              <template v-slot:cell(actions)="{item}">
                                <button class="btn btn-sm btn-success" @click.prevent="catchVariantQuantity(item.product_id,item.vid)" v-if="item.variant_quantity > '0'">
                                  ADD TO CART
                                </button>
                              </template>

                            </b-table>
                          </div>

                          <b-col md="6" class="my-1 p-0">
                            <b-pagination
                                v-model="currentPageVariant"
                                :total-rows="rowsVariants"
                                :per-page="perPageVariant"
                                class="my-0"
                            ></b-pagination>
                          </b-col>
                        </div>
                      </div>
                      <!-- end card-body -->
                    </div>
                  </div>
                  <!-- end col -->
                </div>
                <!-- end row -->
              </tab-content>
              <!--                Cart -->
              <tab-content icon="mdi mdi-cart">
                <div class="row">
                  <div class="col-xl-12">

                    <table id="datatable-buttons" class="table">
                      <thead>
                      <tr>
                        <th>Name</th>
                        <th>Sku No</th>
                        <th>Color</th>
                        <th>Size</th>
                        <th>Qty</th>
                        <th>Unit</th>
                        <th>Action</th>
                      </tr>
                      </thead>
                      <tbody class="text-muted">
                      <tr v-for="(stockTransferCart) in stockTransferCarts" :key="stockTransferCart.cartId">
                        <td>{{ stockTransferCart.product_name }}</td>
                        <td>{{ stockTransferCart.variant_sku_no }}</td>
                        <td>{{ stockTransferCart.color_name }} {{ stockTransferCart.color_code }}</td>
                        <td>{{ stockTransferCart.size_name }}</td>
                        <td>
                          <!--                    <button @click.prevent="increment(stockTransferCart.id)" class="btn btn-sm btn-success">+</button>-->
                          <input type="text" readonly="" style="width: 40px;" :value="stockTransferCart.product_quantity">
                          <button class="btn btn-primary btn-sm" v-if="stockTransferCart.variant_id !== null" @click="editCatchVariantQuantity(stockTransferCart.cartId,stockTransferCart.variant_id,stockTransferCart.product_quantity)"><i class="ti-pencil-alt" style="color: white; margin-left: 2px;"></i></button>
<!--                          <button class="btn btn-primary btn-sm" v-if="stockTransferCart.variant_id === null" @click="editCatchProductQuantity(stockTransferCart.id, stockTransferCart.product_quantity)"><i class="ti-pencil-alt" style="color: white; margin-left: 2px;"></i></button>-->

                          <!--                    <button class="btn btn-sm btn-danger" v-if="stockTransferCart.product_quantity === '1'" disabled="">-</button>-->
                          <!--                    <button @click.prevent="decrement(stockTransferCart.id)" class="btn btn-sm btn-danger" v-else="">-</button>-->
                        </td>
                        <td>{{ stockTransferCart.unit_name }}</td>
                        <td><a @click="removeItem(stockTransferCart.cartId)" style="color:white;" class="btn btn-sm btn-danger">X</a></td>
                      </tr>
                      </tbody>
                    </table>


                  </div>
                </div>
                <!-- end row -->
              </tab-content>
              <!--                Finish-->
              <tab-content icon="mdi mdi-truck-outline">
                <div class="row">
                  <div class="col-12">
                    <div class="text-center">
                      <ul class="list-group">
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                          Total Product: <strong>{{ stockTransferCarts.length }}</strong>
                        </li>
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                          Total Quantity: <strong>{{ totalQuantity }}</strong>
                        </li>
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                          Total Process: <strong>{{ data.length }}</strong>
                        </li>
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                          SubTotal For Process: <strong>{{ calculateSubTotalPrice }} $</strong>
                        </li>
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                          Total Weight: <strong>{{ totalWeight }} gram</strong>
                        </li>
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                          Pallet Count: <strong>{{ totalPalletCount }}</strong>
                        </li>
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                          Total Pallet: <strong>{{ calculatePalletPrice }} $</strong>
                        </li>
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                          Total: <strong>{{ calculateTotalPrice }} $</strong>
                        </li>
                      </ul>
                      <br>
                    </div>
                  </div>
                  <!-- end col -->
                </div>
                <!-- end row -->
              </tab-content>
            </form-wizard>
          </div>
        </div>
      </div>

    </div>
    <!-- end row -->

  </Layout>
</template>

<script>
import Layout from "../../router/layouts/main";
import LazyComponent from "v-lazy-component";
// import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import {FormWizard, TabContent} from "vue-form-wizard";
import moment from "moment/moment";
import {Money} from 'v-money'

/**
 * Form Repeater component
 */
export default {
  page: {
    title: "Form Repeater",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, LazyComponent, FormWizard, TabContent, Money},
  created(){
    this.getLastTotalData();
    this.removeAllStockTransfer();
    // eslint-disable-next-line no-undef
    if(!User.loggedIn()){
      this.$router.push({name: 'admin-login'})
    }
    this.fromWarehouse();
    this.allStockTransferCart();
    this.allOutOfStock();
    this.getCountyList();
    this.allSupplier();
    this.getPallet();
    // eslint-disable-next-line no-undef

  },
  watch:{
    stockTransferCarts:{
      handler:function (){
        this.getLastTotalData();
      }
    }

  },
  data() {
    return {
      errors:{},
      money: {
        decimal: '.',
        thousands: ',',
        // prefix: 'R$ ',
        // suffix: ' #',
        precision: 2,
        masked: true /* doesn't work with directive */
      },
      um: {
        decimal: '.',
        thousands: ',',
        // prefix: 'R$ ',
        // suffix: ' #',
        precision: 3,
        masked: true /* doesn't work with directive */
      },
      countryList:[],
      pallets:[],
      trucks:{},
      suppliers:{},
      title: "Stock Transfer",
      tablefields: [
        { key: 'productStockControl', label: '', sortable: true, },
        { key: 'product_name', label: 'Name', sortable: true, },
        { key: 'product_code', label: 'Code', sortable: true, },
        { key: 'unit_name', label: 'Unit', sortable: true, },
        { key: 'product_weight', label: 'Weight', sortable: true, },
        { key: 'productSize', label: 'Size (L*W*H)', sortable: true, },
        { key: 'product_capacity', label: 'Capacity', sortable: true, },
        { key: 'productQuantity', label: 'Quantity', sortable: true, },
        { key: 'productImage', label: 'Image', sortable: true, },
        { key: 'status', label: 'Status', sortable: true, },
        { key: 'actions', label: 'Actions', sortable: true, },
      ],
      tablefieldsVariants: [
        { key: 'variantStockControl', label: '', sortable: true, },
        { key: 'sku_no', label: 'Sku', sortable: true, },
        { key: 'product_name', label: 'Name', sortable: true, },
        { key: 'product_code', label: 'Code', sortable: true, },
        { key: 'unit_name', label: 'Unit', sortable: true, },
        { key: 'color_name', label: 'Color', sortable: true, },
        { key: 'size_name', label: 'Size', sortable: true, },
        { key: 'variantQuantity', label: 'Quantity', sortable: true, },
        { key: 'variantImage', label: 'Image', sortable: true, },
        { key: 'status', label: 'Status', sortable: true, },
        { key: 'actions', label: 'Actions', sortable: true, },
      ],
      items: [

      ],
      itemVariants: [

      ],
      totalQuantity:null,
      subtotalPrice:null,
      totalPrice:null,
      palletTotalPrice: null,
      filter: null,
      filterVariant: null,
      totalRows: 1,
      totalRowsVariant: 1,
      currentPage: 1,
      currentPageVariant: 1,
      perPage: 10,
      perPageVariant: 10,
      pageOptions: [5, 10, 15],
      pageOptionsVariant: [5, 10, 15],
      showProcess: false,
      fromWarehouses:{},
      toWarehouses:{},
      removeAllStockTransferCart:{},
      warehouseItems:[],
      warehouseItemVariants:[],
      stockTransferCarts:[],
      modalDisplay:false,
      productForm:{
        quantity:null,
        id:null,
      },
      variantmodalDisplay:false,
      editVariantmodalDisplay:false,
      editProductModalDisplay:false,
      outOfStocks:{},
      total: null,
      variantForm:{
        quantity:null,
        id:null,
        product_id:null,
      },
      data:[],
      callFirstIndex: null,
      callLastIndex: null,
      form:{
        from_warehouse_id: '',
        to_warehouse_id: '',
        start_transfer_date: moment().format("YYYY-MM-DD"),
        finish_transfer_date: moment().format("YYYY-MM-DD"),
        total: '',
        processCount: null,
        pallet_id: '',
        total_weight: null,
        pallet_count: null,
        total_pallet: null,
        subtotal: '',
      },
      fields: {
        status:0,
        shipPhoneFormat:null,
        airportPhoneFormat:null,
        stationPhoneFormat:null,
        shippingCompanyShow: false,
        truckShow: false,
        shipShow: false,
        airportShow: false,
        stationShow: false,
        source: '',
        target: '',
        transport: '',
        supplier: '',
        truck: '',
        notes: '',
        price: '',
        extra_price: '',
        ship_name: '',
        port_contact_number: '',
        aircraft_name: '',
        airport_contact_number: '',
        train_name: '',
        station_contact_number: '',
        start_date: '',
        end_date: '',
        truckList:[],
        dial_code: '',
        supplierInformation:[],
        truckInformation:[],
      },
      id:null,
      selectedPallet: [],
      selectedPalletPrice: null,
      // processCount: null,
    };
  },
  // directives: {money: VMoney,um:VMoney},
  computed:{
    // eslint-disable-next-line vue/return-in-computed-property
    totalWeight(){
      let sum = 0;
      let newSum = '';
      if (this.form.pallet_id != null){
        this.stockTransferCarts.map(e=>{
          let qty = e.product_quantity.replace(".","");
          let weight = e.variant_weight.replaceAll(",","");
          let weight2 = weight.replace(".","");
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          sum += qty * weight2;
        })
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        // this.form.total_weight = sum.toLocaleString('en-US');
        sum = this.number_format(sum,3,'.',',');
        newSum = this.custom_number_format(sum);
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.form.total_weight = newSum;
        return newSum;
      }
    },

    // eslint-disable-next-line vue/return-in-computed-property
    totalPalletCount(){
      let count = 0;
      if (this.form.total_weight != "0"){
        let totalWeight = this.form.total_weight.replaceAll(",","");
        let totalWeight2 = totalWeight.replace(".","");
        let palletMaxWeight = this.selectedPallet.pallet_max_weight.replaceAll(",","");
        let palletMaxWeight2 = palletMaxWeight.replace(".","");
        count = totalWeight2 / palletMaxWeight2;
        if (count < 1) {
          count = 1;
        }
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.form.pallet_count = Math.round(count);
        return Math.round(count);
      }
    },
    // eslint-disable-next-line vue/return-in-computed-property
    // calculateSubTotalPrice(){
    //   // let sum = 0;
    //   // this.data.map(e=>{
    //   //   // console.log(e)
    //   //   // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    //   //   sum = sum + parseFloat(e.price.replace(",", "."))
    //   //   sum = sum + parseFloat(e.extra_price.replace(",", "."));
    //   // })
    //   // // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    //   // this.subtotalPrice = sum;
    //   // return sum;
    //
    //
    //
    //
    //   let sum = 0;
    //   // eslint-disable-next-line no-unused-vars
    //   let newSum = '';
    //   // for (let i = 0; i < this.data.length; i++){
    //   //   sum += this.data[i].price;
    //   //   sum += this.data[i].extra_price;
    //   // }
    //   this.data.map(e=>{
    //     if (e.price !== "0,00"){
    //       // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    //       let price = e.price;
    //       price = price.replace(".", "");
    //       let price2 = price.replace(",", "");
    //       let extraPrice = e.extra_price;
    //       extraPrice = extraPrice.replace(".", "");
    //       let extraPrice2 = extraPrice.replace(",", "");
    //       sum = sum + parseFloat(price2);
    //       sum = sum + parseFloat(extraPrice2);
    //     }
    //   })
    //   sum = this.number_format(sum,2,'.',',');
    //   console.log("------------------");
    //   console.log(sum);
    //   // newSum = this.custom_number_format(sum);
    //   // newSum = sum.toLocaleString("en-US");
    //   // console.log(newSum)
    //   // var x = "1234".slice(0,-2)
    //   // console.log(x)
    //   console.log("------------------");
    //   // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    //   this.subtotalPrice = sum;
    //   return sum;
    // },


    calculateSubTotalPrice(){
      let sum = 0;
      this.data.map(e=>{
        if (e.price !== "0,00"){
          let price = e.price;
          let extraPrice = e.extra_price;
          price = price.replaceAll(",", "");
          extraPrice = extraPrice.replaceAll(",", "");
          sum = sum + parseFloat(price);
          sum = sum + parseFloat(extraPrice);
        }
      })
      sum = this.number_format(sum,2,'.',',');
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.subtotalPrice = sum;
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.form.subtotal = sum;
      return sum;
    },


    // eslint-disable-next-line vue/return-in-computed-property
    calculatePalletPrice(){
      let sum = 0;
      sum = this.selectedPalletPrice * this.form.pallet_count;
      sum = this.number_format(sum,2,'.',',');
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.palletTotalPrice = sum;
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.form.total_pallet = sum;
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return sum;
    },

    calculateTotalPrice(){
      let sum = 0;
      let subTotalPrice = this.subtotalPrice;
      let subTotalPrice2 = subTotalPrice.replaceAll(",", "");
      let palletPrice = this.palletTotalPrice;
      let palletPrice2 = palletPrice.replaceAll(",", "");
      sum = this.roundNumber(parseFloat(subTotalPrice2) + parseFloat(palletPrice2),2);
      sum = this.numberFormat(this.roundNumber(sum,2),2,'.',',');
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.form.total = sum;
      return sum;
    },


    // calculateTotalPrice(){
    //   // eslint-disable-next-line no-unused-vars
    //   let sum = '';
    //   let subTotalPrice = this.calculateSubTotalPrice.toString();
    //   subTotalPrice = subTotalPrice.replace(".", "");
    //   let subTotalPrice2 = subTotalPrice.replace(",", "");
    //   let palletPrice = this.calculatePalletPrice.toString();
    //   palletPrice = palletPrice.replace(".", "");
    //   let palletPrice2 = palletPrice.replace(",", "");
    //   sum = parseFloat(subTotalPrice2) + parseFloat(palletPrice2);
    //   // sum += parseFloat(palletPrice2);
    //   // sum = this.number_format(sum,3,'.',',');
    //   // newSum = this.custom_number_format(sum);
    //   // console.log(subTotalPrice2)
    //   // console.log(palletPrice2)
    //   // console.log(sum)
    //   return sum;
    // },
    // calculateTotalPrice(){
    //   let sum = 0;
    //   this.data.map(e=>{
    //     // console.log(e)
    //     // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    //     sum += parseFloat(e.price.replace(",", "."))
    //     sum += parseFloat(e.extra_price.replace(",", "."));
    //   })
    //   alert(sum);
    //   // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    //   this.totalPrice = sum;
    //   return sum;
    // },
    sortOptions() {
      // Create an options list from our fields
      return this.tablefields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key };
          });
    },
    sortOptionsVariant() {
      // Create an options list from our fields
      return this.tablefieldsVariants
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key };
          });
    },
    rows(){
      return this.items.length;
    },
    rowsVariants(){
      return this.itemVariants.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.totalRowsVariant = this.itemVariants.length;
    //
    // this.getWarehouseItem();
    // this.getWarehouseItemVariant();
  },
  methods: {
    numberFormat(number, decimals, decPoint, thousandsSep) {
      number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
      var n = !isFinite(+number) ? 0 : +number;
      var prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
      var sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep;
      var dec = (typeof decPoint === 'undefined') ? '.' : decPoint;
      var s = '';

      var toFixedFix = function (n, prec) {
        var k = Math.pow(10, prec);
        return '' + (Math.round(n * k) / k).toFixed(prec)
      };

      s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
      if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
      }
      if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0')
      }

      return s.join(dec)
    },
    roundNumber(num, scale) {
    if (Math.round(num) != num) {
      if (Math.pow(0.1, scale) > num) {
        return 0;
      }
      var sign = Math.sign(num);
      var arr = ("" + Math.abs(num)).split(".");
      if (arr.length > 1) {
        if (arr[1].length > scale) {
          var integ = +arr[0] * Math.pow(10, scale);
          var dec = integ + (+arr[1].slice(0, scale) + Math.pow(10, scale));
          var proc = +arr[1].slice(scale, scale + 1);
          if (proc >= 5) {
            dec = dec + 1;
          }
          dec = sign * (dec - Math.pow(10, scale)) / Math.pow(10, scale);
          return dec;
        }
      }
    }
    return num;
  },
    custom_number_format(sum){
      // eslint-disable-next-line no-unused-vars
      let newSum = '';
      let sumDot = sum.split(".");
      sum = sumDot[0];
      sum = sum.split(",");
      let lastData = sum.slice(-1)[0];
      sum.splice(-1);
      for (let i = 0; i < sum.length; i++){
        console.log(sum.slice(-1)[0] === sum[i])
        if (sum.slice(-1)[0] === sum[i]){
          newSum += sum[i]+".";
        }else{
          newSum += sum[i]+",";
        }
      }
      newSum = newSum + lastData;
      return newSum;
    },
    number_format(number, decimals, decPoint, thousandsSep) {
      number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
      var n = !isFinite(+number) ? 0 : +number;
      var prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
      var sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep;
      var dec = (typeof decPoint === 'undefined') ? '.' : decPoint;
      var s = '';

      var toFixedFix = function (n, prec) {
        var k = Math.pow(10, prec);
        return '' + (Math.round(n * k) / k).toFixed(prec)
      };

      s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
      if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
      }
      if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0')
      }
      return s.join(dec)
    },
    NumbersOnly(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 && charCode !== 8 && charCode !== 0) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    palletChange(event){
      this.pallets.map(e=>{
        if (e.id === parseInt(event.target.value)){
          this.selectedPallet = e;
          this.selectedPalletPrice = e.pallet_price.replaceAll(',','');
        }
      })
    },
    getPallet(){
      this.$http.get('pallet')
          .then(({data}) => (this.pallets = data))
          .catch()
    },
    // All Supplier
    allSupplier(){
      this.$http.get('supplier')
          .then(({data}) => (this.suppliers = data))
          .catch()
    },
    // Supplier Change
    supplierChange(event,index){
      let id = event.target.value;
      // this.supplierInfo(id,index);
      if (id != null || id === '') {
        this.$http.get('supplier/change/'+id)
            .then(({data}) => (this.data[index].truckList = data))
            .catch()
      } else {
        this.$toasted.global.selectFromWarehouse()
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onFilteredVariant(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsVariant = filteredItems.length;
      this.currentPageVariant = 1;
    },
    removeAllStockTransfer(){
      this.$http.get('user/stock/transfer/delete')
          .then(({data}) => (this.removeAllStockTransferCart = data))
          .catch()
    },
    fromWarehouse(){
      this.$http.get('warehouse')
          .then(({data}) => {this.fromWarehouses = data})
          .catch()
    },
    fromWarehouseChange(event){
      let id = event.target.value;
      if (id != null || id === '') {
        this.show = true;
        this.$http.get('select-from-warehouse/'+id)
            .then(({data}) => (this.toWarehouses = data))
            .catch()
        // this.selectedWarehouseItem(id);
        this.selectedWarehouseItemVariant(id);
      } else {
        this.removeAllStockTransfer();
        // this.show = false;
        this.$toasted.global.selectFromWarehouse()
      }
    },
    getCountyList(){
      this.$http.get('country-list').then(response=>{
        this.countryList = response.data;
      }).catch();
    },
    // Change To Warehouse
    toWarehouseChange(event){
      this.markers.splice(0);
      let id = event.target.value;
      if (id != null) {
        this.$http.get('to/warehouse/change/'+id)
            .then(() => {})
            .catch()
      } else {
        this.$toasted.global.selectToWarehouse()
      }
    },
    //Format Phone Number
    changePhoneFormatNumber(value){
      // eslint-disable-next-line no-unused-vars
      let format;
      let dialCode;
      this.countryList.map(e=>{
        if(e.id === this.data[value].country){
          format = e.country_format;
          dialCode = e.country_dial_code;
          if (this.data[value].port_contact_number != null){
            this.data[value].port_contact_number = '';
          }
          if (this.data[value].airport_contact_number != null){
            this.data[value].airport_contact_number = '';
          }
          if (this.data[value].station_contact_number != null){
            this.data[value].station_contact_number = '';
          }
        }
      })
      this.data[value].dial_code = dialCode;
      const show = {
        "shipShow":"shipPhoneFormat",
        "airportShow":  "airportPhoneFormat",
        "stationShow": "stationPhoneFormat"
      }
      Object.keys(show).forEach(item=>{
        if(this.data[value][item] === true){
          return this.data[value][show[item]] = format;

        }
      })

    },
    // Select Warehouse Item Variant
    selectedWarehouseItemVariant(id){
      this.$http.get('select-from-warehouse-item-variant/'+id)
          .then(({data}) => (this.itemVariants = data))
          .catch()
    },
    // All Out Of Stock
    allOutOfStock(){
      this.$http.get('get-out-of-stock')
          .then(({data}) => (this.outOfStocks = data))
          .catch()
    },
    catchVariantQuantity(product_id, id = null){
      this.variantForm.id = id;
      this.variantForm.product_id= product_id
      this.variantmodalDisplay = true;
    },
    editCatchVariantQuantity(id, product_id = null,quantity){
      this.variantForm.id = id;
      this.variantForm.product_id= product_id
      this.variantForm.quantity =quantity;
      this.editVariantmodalDisplay = true;
    },
    editCatchProductQuantity(id,quantity){
      this.productForm.id = id;
      this.productForm.quantity =quantity;
      this.editProductModalDisplay = true;
    },
    catchStocktransferCartId(id){
      this.productForm.id = id;
      this.modalDisplay = true;
    },
    allStockTransferCart(){
      this.$http.get('stocktransfer/cart/')
          .then(({data}) =>  {
            this.stockTransferCarts = data
            // this.calculatePalletCount();
          })
          .catch()
    },
    editQuantity(id,variant_id = null){
      let warehouse_id = this.form.from_warehouse_id;
      this.$http.get('add/stocktransfer/cart/'+variant_id+'/'+warehouse_id,{params:{product_quantity:this.productForm.quantity}})
          .then(({data}) => {
            if (data === 'unsuccessful') {
              // eslint-disable-next-line no-undef
              this.allStockTransferCart()
              this.$toasted.global.stocktransfercart_error()
            } else if (data === 'no found quantity'){
              this.allStockTransferCart()
              this.$toasted.global.stocktransfercart_error()
            }else {
              this.modalDisplay = false;
              this.variantmodalDisplay = false;
              this.editVariantmodalDisplay = false;
              this.productForm.quantity = null;
              this.allStockTransferCart()
              // this.productForm.quantity = null;

              // eslint-disable-next-line no-undef
              // this.calculatePalletCount();
              this.$toasted.global.stocktransfercart_success()
            }
          })
          .catch()
    },
    // Add Stock Transfer Cart
    AddToStockTransferCart(id,variant_id = null){
      let warehouse_id = this.form.from_warehouse_id;
      this.$http.get('add/stocktransfer/cart/'+variant_id+'/'+warehouse_id,{params:{product_quantity:this.productForm.quantity}})
          .then(({data}) => {
            if (data === 'unsuccessful') {
              // eslint-disable-next-line no-undef
              this.allStockTransferCart()
              this.$toasted.global.stocktransfercart_error()
            } else if (data === 'no found quantity'){
              this.allStockTransferCart()
              this.$toasted.global.stocktransfercart_error()
            }
            else{
              this.modalDisplay = false;
              this.variantmodalDisplay = false;
              this.editVariantmodalDisplay = false;
              this.productForm.quantity = null;
              this.allStockTransferCart()
              // this.productForm.quantity = null;
              // this.calculatePalletCount();
              // eslint-disable-next-line no-undef
              this.$toasted.global.stocktransfercart_success()
            }
          })
          .catch()
    },
    // Remove Item Cart
    removeItem(id){
      this.$http.get('remove/stocktransfer/cart/'+id)
          .then(() => {
            // eslint-disable-next-line no-undef
            this.allStockTransferCart()
            // this.calculatePalletCount();
            this.$toasted.global.stocktransfercart_delete()
          })
          .catch()
    },
    increment(id){
      let warehouse_id = this.form.from_warehouse_id;
      this.$http.get('increment/stocktransfer/cart/'+id+'/'+warehouse_id,{params:{product_quantity:this.productForm.quantity,variant_quantity:this.variantForm.quantity}})
          .then(() => {
            // console.log(data);
            this.editVariantmodalDisplay = false;
            this.productForm.quantity = null;
            this.variantForm.quantity = null;
            this.editProductModalDisplay = false;
            this.allStockTransferCart();
            // this.calculatePalletCount();

          })
          .catch()
    },
    decrement(id){
      this.$http.get('decrement/stocktransfer/cart/'+id)
          .then(() => {
            // eslint-disable-next-line no-undef
            Reload.$emit('AfterAdd');
            // this.calculatePalletCount();
            // Notification.success()
          })
          .catch()
    },
    closeModal(){
      this.editVariantmodalDisplay = false;
      this.editProductModalDisplay = false;
      this.modalDisplay = false;
      this.variantmodalDisplay = false;
    },
    calculateProcess(event) {
      this.data = [];
      this.form.processCount = event.target.value;
      if (event.target.value <= 20){
        if (this.form.processCount !== '' || this.form.processCount !== null){
          this.showProcess = true;
          for (let i =0; i < this.form.processCount; i++) {
            this.data.push({ id: i, ...this.fields});
          }
          if (this.form.from_warehouse_id !== ""){
            this.getFirstIndex();
            this.getLastIndex();
            this.sourceValue();

          }else{
            this.$toasted.global.choose_warehouse()
            this.form.processCount = null
          }
        }else{
          for (let f =0; f < this.data.length; f++) {
            this.data.splice(this.data[f], this.data.length-1);
          }
          this.showProcess = false;
        }
      }else{
        this.$toasted.global.maxprocess_error()
      }
    },
    onComplete(){
      this.form['total_price'] = this.totalPrice;
      this.form['total_quantity'] = this.totalQuantity;
      this.$http.post('store/stock-transfer/',{
        form:this.form,
        process:this.data}).then(() => {
        this.$router.push({name: 'admin-stock-transfer-list'})
      }).catch(error => this.errors = error.response.data.errors)
    },
    transportChange(key,id) {
      const show = {
        "5": "shippingCompanyShow",
        "1": "truckShow",
        "2": "shipShow",
        "4": "airportShow",
        "3": "stationShow"
      }
      if (Object.keys(show).includes(key.target.value)) {
        Object.keys(show).forEach(item => {
          if (key.target.value === item) {
            this.changePhoneFormatNumber(id)
            return this.data[id][show[item]] = true
          }
          this.changePhoneFormatNumber(id)
          return this.data[id][show[item]] = false
        })
      }
    },
    processInputChange(event) {
      if (this.data.length !== event.target.value){
        this.data = [];
      }
      this.processCount = event.target.value;
      // this.calculateProcess(event);
      // for (let i = 0; i < this.processCount; i++) {
      //   this.data.push({id: i, ...this.fields});
      // }
      //
      // this.getFirstIndex();
      // this.getLastIndex();
      // this.sourceValue();
    },
    sourceValue(){
      // eslint-disable-next-line no-unused-vars
      let fromWarehouseData;
      let toWarehouseData;
      try {
        Object.keys(this.fromWarehouses).forEach(item => {
          if (this.fromWarehouses[item].id === this.form.from_warehouse_id){
            fromWarehouseData = this.fromWarehouses[item];
          }
        });
        Object.keys(this.toWarehouses).forEach(value => {
          if (this.toWarehouses[value].id === this.form.to_warehouse_id){
            toWarehouseData = this.toWarehouses[value];
          }
        });

        if (this.data.length > 0){
          this.data[0].source = fromWarehouseData.warehouse_address+' / '+fromWarehouseData.warehouse_country+' / '+fromWarehouseData.warehouse_city
          this.data[this.callLastIndex].target = toWarehouseData.warehouse_address+' / '+toWarehouseData.warehouse_city+' / '+toWarehouseData.warehouse_country
        }
      } catch (error) {
        this.$toasted.global.something_went_wrong()
      }
    },
    getLastIndex(){
      return this.callLastIndex = this.data.at(-1).id;
    },
    getFirstIndex(){
      this.callFirstIndex = this.data[0].id;
    },
    getLastTotalData(){
      this.totalQuantity = null;
      this.stockTransferCarts.forEach((item)=>{
        this.totalQuantity += parseInt(item.product_quantity);
      })

    }
  },
};
</script>
<style scoped>
.v-lazy-component.v-lazy-component--loading {
  filter: blur(20px);
}

.v-lazy-component.v-lazy-component--loaded {
  filter: blur(0);
  transition: filter 1s;
}
</style>