<template>
<!--  <Layout>-->
    <div class="composer">
      <textarea v-model="message" @keydown.enter="send" placeholder="Message..."></textarea>
    </div>
<!--  </Layout>-->
</template>

<script>
import Layout from "../../router/layouts/main.vue";
import PageHeader from "@/components/page-header";

export default {
  name: "MessageComposer",
  components:{
    // eslint-disable-next-line vue/no-unused-components
    Layout,PageHeader
  },
  data(){
    return{
      message: ''
    }
  },
  methods:{
    send(e){
      e.preventDefault();
      if (this.message == ''){
        return;
      }
      this.$emit('send',this.message);
      this.message = '';
    }
  }
}
</script>

<style lang="scss" scoped>
.composer textarea{
  width: 96%;
  margin: 10px;
  resize: none;
  border-radius: 3px;
  border: 1px solid lightgray;
  padding: 6px;
}
</style>