<template>
  <Layout>
    <br>
    <div>
      <div class="row">
        <div class="col-xl-12">
          <div class="card card-statistics text-center py-5">
            <div class="card-body pricing-content">
              <div class="pricing-content-card">
                <h5>User Detail</h5>
                <!--                            <h3 class="text-primary pt-3">From Warehouse Detail</h3>-->
                <ul class="py-2">
                  <li><strong style="color: black;">Nane: </strong>{{ user.name }} {{ user.surname }}</li>
                  <li><strong style="color: black;">Phone: </strong>{{ user.phone }}</li>
                  <li><strong style="color: black;">Email: </strong>{{ user.email }}</li>
                  <li><strong style="color: black;">Country: </strong>{{ user.country }}</li>
                  <li><strong style="color: black;">City: </strong>{{ user.city }}</li>
                  <li><strong style="color: black;">District: </strong>{{ user.district }}</li>
                  <li><strong style="color: black;">Address: </strong>{{ user.address }}</li>
<!--                  <li><strong style="color: black;">Created At: </strong>{{ user.created_at }}</li>-->
<!--                  <li><strong style="color: black;">Updated At: </strong>{{ user.updated_at }}</li>-->
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>

</template>


<script>
import Layout from "../../router/layouts/main.vue";
import PageHeader from "@/components/page-header";
export default {
  components:{
    // eslint-disable-next-line vue/no-unused-components
    Layout,PageHeader
  },
  created(){
    // eslint-disable-next-line no-undef
    if(!User.loggedIn()){
      this.$router.push({name: '/admin/login'})
    }
    let id = this.$route.params.id
    this.$http.get('user/show/'+id)
        .then(({data}) => (this.user = data))
        .catch()
  },
  data(){
    return {
      user: [],
      errors:{},
    }
  },
  methods: {

  },
}
</script>


<style scoped>
#user_photo{
  height: 80px;
  width: 80px;
}
.py-2 li{
  list-style: none;
}
</style>