<template>
  <Layout>
    <div>
      <div class="col-xl-12">
        <div class="card card-statistics">
          <div class="card-body">
            <form @submit.prevent="couponInsert">
              <div class="form-row">
                <div class="form-group col-md-4">
                  <label for="couponName">Coupon Name</label>
                  <input type="text" v-model="form.coupon_name" class="form-control" id="couponName" :class="{
                    'is-invalid': typeSubmit && $v.form.coupon_name.$error,
                  }">
                  <div v-if="typeSubmit && $v.form.coupon_name.$error" class="invalid-feedback">
                    <span v-if="!$v.form.coupon_name.required">This value is required.</span>
                  </div>
                </div>
                <div class="form-group col-md-4">
                  <label for="couponQuantity">Coupon Quantity</label>
                  <input type="text" v-model="form.coupon_quantity" class="form-control" id="couponQuantity" :class="{
                    'is-invalid': typeSubmit && $v.form.coupon_quantity.$error,
                  }">
                   <div v-if="typeSubmit && $v.form.coupon_quantity.$error" class="invalid-feedback">
                    <span v-if="!$v.form.coupon_quantity.required">This value is required.</span>
                  </div>
                </div>
                <div class="form-group col-md-4">
                  <label for="couponStatus">Coupon Status</label>
                  <select id="couponStatus" class="form-control" v-model="form.coupon_status" :class="{
                    'is-invalid': typeSubmit && $v.form.coupon_status.$error,
                  }">
                    <option :value="1">Active</option>
                    <option :value="0">Passive</option>
                  </select>
                   <div v-if="typeSubmit && $v.form.coupon_status.$error" class="invalid-feedback">
                    <span v-if="!$v.form.coupon_status.required">This value is required.</span>
                  </div>
                </div>
              </div>
              <br>
              <div class="form-row">
                <div class="form-group col-md-4">
                  <label for="couponDiscount">Coupon Discount</label>
                  <input type="text" v-model="form.coupon_discount" class="form-control" id="couponDiscount" :class="{
                    'is-invalid': typeSubmit && $v.form.coupon_discount.$error,
                  }">
                   <div v-if="typeSubmit && $v.form.coupon_discount.$error" class="invalid-feedback">
                    <span v-if="!$v.form.coupon_discount.required">This value is required.</span>
                  </div>
                </div>
                <div class="form-group col-md-4">
                  <label for="couponStartDate">Coupon Start Date</label>
                  <input type="datetime-local" v-model="form.coupon_start_date" class="form-control" id="couponStartDate" :class="{
                    'is-invalid': typeSubmit && $v.form.coupon_start_date.$error,
                  }">
                   <div v-if="typeSubmit && $v.form.coupon_start_date.$error" class="invalid-feedback">
                    <span v-if="!$v.form.coupon_start_date.required">This value is required.</span>
                  </div>
                </div>
                <div class="form-group col-md-4">
                  <label for="couponEndDate">Coupon End Date</label>
                  <input type="datetime-local" v-model="form.coupon_end_date" class="form-control" id="couponEndDate" :class="{
                    'is-invalid': typeSubmit && $v.form.coupon_end_date.$error,
                  }">
                   <div v-if="typeSubmit && $v.form.coupon_end_date.$error" class="invalid-feedback">
                    <span v-if="!$v.form.coupon_end_date.required">This value is required.</span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="couponDescription">Coupon Description</label>
                <ckeditor :editor="editor" v-model="form.coupon_description" class="form-control" id="couponDescription" :class="{
                    'is-invalid': typeSubmit && $v.form.coupon_description.$error,
                  }"></ckeditor>
                 <div v-if="typeSubmit && $v.form.coupon_description.$error" class="invalid-feedback">
                    <span v-if="!$v.form.coupon_description.required">This value is required.</span>
                  </div>
              </div>
              <br>
              <button type="submit" class="btn btn-primary col-xl-12">Save</button>
            </form>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>

<script>
import Layout from "../../router/layouts/main.vue";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  required
} from "vuelidate/lib/validators";

export default {
  components:{
    // eslint-disable-next-line vue/no-unused-components
    ckeditor: CKEditor.component,
    Layout,
  },
  name:'admin-create-coupon',
  created(){
    // eslint-disable-next-line no-undef
    if(!User.loggedIn()){
      this.$router.push({name: 'admin-login'})
    }
    this.$http.get('coupon/')
        .then(({data}) => (this.coupons = data))
  },
  validations(){
    return{
      form:{
        coupon_name: {required},
        coupon_quantity: {required},
        coupon_discount: {required},
        coupon_start_date: {required},
        coupon_end_date: {required},
        coupon_status: {required},
        coupon_description: {required},

      },
    }
  },
  data(){
    return {
      form:{
        coupon_name: null,
        coupon_quantity: null,
        coupon_discount: null,
        coupon_start_date: null,
        coupon_end_date: null,
        coupon_status: null,
        coupon_description: null,
      },
      errors:{},
      typeSubmit:false,
      coupons:{},
      editor: ClassicEditor,
    }
  },

  methods:{
    couponInsert(){
      this.typeSubmit = true;
      this.$v.$touch()

      if(this.$v.$invalid){
        return false;
      }else{
        this.$http.post('coupon',this.form)
            .then(() => {
              this.$router.push({ name: 'admin-coupon-list'})
              this.$toasted.global.success()
            })
            .catch(error => this.errors = error.response.data.errors)
      }
    }
  }
}
</script>

<style scoped>

</style>