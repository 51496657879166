<template>
  <Layout>
    <div>

      <div class="col-xl-12">
        <div class="card card-statistics">
          <div class="card-body">
            <form @submit.prevent="faqUpdate">
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="faqTitle">Faq Title</label>
                  <input type="text" v-model="form.faq_title" class="form-control" id="faqTitle"
                         :class="{'is-invalid': typeSubmit && $v.form.faq_title.$error,}">
                  <div
                      v-if="typeSubmit && $v.form.faq_title.$error"
                      class="invalid-feedback"
                  >
                      <span v-if="!$v.form.faq_title.required"
                      >This value is required.</span
                      >
                  </div>
                </div>
<!--                <div class="form-group col-md-4">-->
<!--                  <label for="faqSeq">Faq Seq Number</label>-->
<!--                  <input type="number" min="1" v-model="form.faq_seq" class="form-control" id="faqSeq"-->
<!--                         :class="{'is-invalid': typeSubmit && $v.form.faq_seq.$error,}">-->
<!--                  <div-->
<!--                      v-if="typeSubmit && $v.form.faq_seq.$error"-->
<!--                      class="invalid-feedback"-->
<!--                  >-->
<!--                      <span v-if="!$v.form.faq_seq.required"-->
<!--                      >This value is required.</span-->
<!--                      >-->
<!--                  </div>-->
<!--                </div>-->
                <div class="form-group col-md-6">
                  <label for="faqStatus">Faq Status</label>
                  <select id="faqStatus" class="form-control" v-model="form.faq_status" :class="{'is-invalid': typeSubmit && $v.form.faq_status.$error,}">
                    <option :value="1">Active</option>
                    <option :value="0">Passive</option>
                  </select>
                  <div
                      v-if="typeSubmit && $v.form.faq_status.$error"
                      class="invalid-feedback"
                  >
                      <span v-if="!$v.form.faq_status.required"
                      >This value is required.</span
                      >
                  </div>
                </div>
              </div>
              <br>
              <div class="form-group">
                <label for="faqDescription">Faq Description</label>
                <ckeditor :editor="editor" v-model="form.faq_description" class="form-control" id="faqDescription"
                          :class="{'is-invalid': typeSubmit && $v.form.faq_description.$error,}"></ckeditor>
                <div
                    v-if="typeSubmit && $v.form.faq_description.$error"
                    class="invalid-feedback"
                >
                      <span v-if="!$v.form.faq_description.required"
                      >This value is required.</span
                      >
                </div>
              </div>
              <br>
              <button type="submit" class="btn btn-primary col-xl-12">Save</button>
            </form>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>

<script>

import Layout from "../../router/layouts/main.vue";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {required} from "vuelidate/lib/validators";

export default {
  components:{
    // eslint-disable-next-line vue/no-unused-components
    ckeditor: CKEditor.component,
    Layout,
  },
  name:'admin-edit-faq',
  created(){
    // eslint-disable-next-line no-undef
    if(!User.loggedIn()){
      this.$router.push({name: 'admin-login'})
    }
    let id = this.$route.params.id
    this.$http.get('faq/'+id)
        .then(({data}) => (this.form = data))
        .catch()
  },

  data(){
    return {
      typeSubmit:false,
      form:{
        faq_title: '',
        // faq_seq: '',
        faq_status: '',
        faq_description: '',
      },
      errors:{},
      faqs:{},
      editor: ClassicEditor,
    }
  },
  validations(){
    return{
      form:{
        faq_title: {required},
        // faq_seq: {required},
        faq_status: {required},
        faq_description: {required},
      },
    }
  },

  methods:{
    faqUpdate(){
      let id = this.$route.params.id
      this.typeSubmit = true;
      this.$v.$touch();
      if(this.$v.$invalid){
        return;
      }else{
        this.$http.patch('faq/'+id,this.form)
            .then(() => {
              this.$router.push({ name: 'admin-faq-list'})
              Notification.success()
            })
            .catch(error => this.errors = error.response.data.errors)
      }

    }
  }
}
</script>
<style scoped>

</style>
