<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import listPlugin from "@fullcalendar/list";

// import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";


import Layout from "../../router/layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import { categories } from "./data";

/**
 * Calendar component
 */
export default {
  page: {
    title: "Calendar",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    FullCalendar,
    Layout,
    PageHeader,
  },
  created(){
    // eslint-disable-next-line no-undef
    if(!User.loggedIn()){
      this.$router.push({name: '/admin/login'})
    }
    this.allCalendar();
    // this.showCalendar();
  },
  data() {
    return {
      title: "Calendar",
      items: [
        {
          text: "Veltrix",
          href: "/",
        },
        {
          text: "Calendar",
          active: true,
        },
      ],
      // calendarEvents: calendarEvents,
      calendarEvents: [],
      calendarOptions: {
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
        },
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
          bootstrapPlugin,
          listPlugin,
        ],
        initialView: "dayGridMonth",
        themeSystem: "bootstrap",
        initialEvents: [],
        editable: true,
        droppable: true,
        eventResizableFromStart: true,
        dateClick: this.dateClicked,
        eventClick: this.editEvent,
        eventsSet: this.handleEvents,
        weekends: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        events:[],
      },
      currentEvents: [],
      showModal: false,
      eventModal: false,
      categories: categories,
      submitted: false,
      submit: false,
      newEventData: [],
      edit: {},
      deleteId: {},
      event: {
        title: "",
        category: "",
        startDate: "",
        endDate: "",
      },
      editevent: {
        editTitle: "",
        editcategory: "",
        startDate: "",
        endDate: "",
      },
      errors:{},
    };
  },
  // validations: {
  //   event: {
  //     title: { required },
  //     category: { required },
  //   },
  // },
  methods: {
    allCalendar(){
      this.$http.get('calendar')
          .then(({data}) => {
            // console.log(data)
            data.forEach((value) => {
              let id = value.id;
              let title = value.title;
              let category = value.category;
              let startDate = value.start_date;
              let endDate = value.end_date;
              let calendarApi = this.newEventData.view.calendar;
              this.calendarEvents = calendarApi.addEvent({
                id: id,
                title,
                start: startDate,
                end: endDate,
                classNames: [category],
                // allDay: false,
              });
            });
          })
          .catch(error => this.errors = error)
    },
    /**
     * Modal form submit
     */
    // eslint-disable-next-line no-unused-vars
    handleSubmit() {

      this.submitted = true;
      this.$http.post('calendar',this.event)
          .then(() => {
            // console.log(res)
            this.$router.push({ name: 'admin-calendar-list'})
            this.$toasted.global.success()
            this.allCalendar();
            // const id = res.data.id;
            // const title = res.data.title;
            // const category = res.data.category;
            // const startDate = res.data.start_date;
            // const endDate = res.data.end_date;
            // let calendarApi = this.newEventData.view.calendar;
            // this.currentEvents = calendarApi.addEvent({
            //   id: id,
            //   title,
            //   start: startDate,
            //   end: endDate,
            //   classNames: [category],
            // });
            this.successmsg();
            this.showModal = false;
            this.newEventData = {};
          })
          .catch(error => this.errors = error.response.data.errors)
      this.submitted = false;
      this.event = {};
    },
    // eslint-disable-next-line no-unused-vars
    hideModal(e) {
      this.submitted = false;
      this.showModal = false;
      this.event = {};
    },
    /**
     * Edit event modal submit
     */
    // eslint-disable-next-line no-unused-vars
    editSubmit(e) {
      this.submit = true;
      const editTitle = this.editevent.editTitle;
      const editcategory = this.editevent.editcategory;

      this.edit.setProp("title", editTitle);
      this.edit.setProp("classNames", editcategory);
      this.successmsg();
      this.eventModal = false;
    },

    /**
     * Delete event
     */
    deleteEvent() {
      this.edit.remove();
      this.eventModal = false;
    },
    /**
     * Modal open for add event
     */
    dateClicked(info) {
      this.newEventData = info;
      this.showModal = true;
    },
    /**
     * Modal open for edit event
     */
    editEvent(info) {
      this.edit = info.event;
      this.editevent.editTitle = this.edit.title;
      this.editevent.editcategory = this.edit.classNames[0];
      this.eventModal = true;
    },

    closeModal() {
      this.eventModal = false;
    },

    confirm() {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to delete this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          this.deleteEvent();
          Swal.fire("Deleted!", "Event has been deleted.", "success");
        }
      });
    },

    /**
     * Show list of events
     */
    handleEvents(events) {
      this.currentEvents = events;
    },

    /**
     * Show successfull Save Dialog
     */
    successmsg() {
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Event has been saved",
        showConfirmButton: false,
        timer: 1000,
      });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="app-calendar">
              <!-- Calendar -->
              <FullCalendar
                  ref="fullCalendar"
                  :options="calendarOptions" :events="events"
              ></FullCalendar>
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->

      <b-modal
          centered
          v-model="showModal"
          title="Add New Event"
          title-class="text-black font-18"
          body-class="p-4"
          hide-footer
      >
        <form @submit.prevent="handleSubmit">
          <div class="row">
            <div class="col-12">
              <div class="mb-3">
                <label for="name" class="form-label">Event Name</label>
                <input
                    id="name"
                    v-model="event.title"
                    type="text"
                    class="form-control"
                    placeholder="Insert Event name"
                    :class="{ 'is-invalid': submitted && $v.event.title.$error }"
                />
                <div
                    v-if="submitted && !$v.event.title.required"
                    class="invalid-feedback"
                >
                  This value is required.
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="mb-3">
                <label class="form-label">Category</label>
                <select
                    v-model="event.category"
                    class="form-control"
                    name="category"
                    :class="{
                    'is-invalid': submitted && $v.event.category.errors,
                  }"
                >
                  <option
                      v-for="option in categories"
                      :key="option.backgroundColor"
                      :value="`${option.value}`"
                  >
                    {{ option.name }}
                  </option>
                </select>
                <div
                    v-if="submitted && !$v.event.category.required"
                    class="invalid-feedback"
                >
                  This value is required.
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="mb-3">
                <label class="form-label">Start Date</label>
                <b-form-input
                    type="datetime-local"
                    id="example-datetime-local-input"
                    v-model="event.startDate"
                ></b-form-input>
                <div
                    v-if="submitted && !$v.event.startDate.required"
                    class="invalid-feedback"
                >
                  This value is required.
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="mb-3">
                <label class="form-label">End Date</label>
                <b-form-input
                    type="datetime-local"
                    id="example-datetime-local-input"
                    v-model="event.endDate"
                ></b-form-input>
                <div
                    v-if="submitted && !$v.event.endDate.required"
                    class="invalid-feedback"
                >
                  This value is required.
                </div>
              </div>
            </div>
          </div>

          <div class="mt-2 text-end">
            <b-button variant="light" @click="hideModal">Close</b-button>
            <b-button type="submit" variant="success" class="ms-1"
            >Save</b-button
            >
          </div>
        </form>
      </b-modal>

      <!-- Edit Modal -->
      <b-modal
          centered
          v-model="eventModal"
          title="Edit Event"
          title-class="text-black font-18"
          hide-footer
          body-class="p-4"
      >
        <div slot="modal-title">
          Edit Event
        </div>
        <form @submit.prevent="editSubmit">
          <div class="row mt-2">
            <div class="col-12">
              <div class="mb-3">
                <label for="name" class="form-label">Event Name</label>
                <input
                    id="name"
                    v-model="editevent.editTitle"
                    type="text"
                    class="form-control"
                    placeholder="Insert Event name"
                />
              </div>
            </div>
            <div class="col-12">
              <div class="mb-3">
                <label class="form-label">Category</label>
                <select
                    v-model="editevent.editcategory"
                    class="form-control"
                    name="category"
                >
                  <option
                      v-for="option in categories"
                      :key="option.backgroundColor"
                      :value="`${option.value}`"
                  >
                    {{ option.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-6">
              <b-button class="ms-1" variant="danger" @click="confirm"
              >Delete</b-button
              >
            </div>
            <div class="col-6 text-end">
              <b-button variant="light" @click="closeModal">Close</b-button>

              <b-button class="ms-1" variant="success" @click="editSubmit"
              >Save</b-button
              >
            </div>
          </div>
        </form>
      </b-modal>
    </div>
    <!-- end row -->
  </Layout>
</template>
