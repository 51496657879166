<template>
  <Layout>
    <div>

      <div class="col-xl-12">
        <div class="card card-statistics">
          <div class="card-body">
            <form @submit.prevent="projectInsert" enctype="multipart/form-data">
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="projectTitle">Project Title</label>
                  <input type="text" v-model="form.project_title" class="form-control" id="projectTitle"
                         :class="{'is-invalid': typeSubmit && $v.form.project_title.$error,}">
                  <div
                      v-if="typeSubmit && $v.form.project_title.$error"
                      class="invalid-feedback"
                  >
                      <span v-if="!$v.form.project_title.required"
                      >This value is required.</span
                      >
                  </div>
                </div>
<!--                <div class="form-group col-md-4">-->
<!--                  <label for="projectSeq">Project Seq Number</label>-->
<!--                  <input type="number" min="1" v-model="form.project_seq" class="form-control" id="projectSeq"-->
<!--                         :class="{'is-invalid': typeSubmit && $v.form.project_seq.$error,}">-->
<!--                  <div-->
<!--                      v-if="typeSubmit && $v.form.project_seq.$error"-->
<!--                      class="invalid-feedback"-->
<!--                  >-->
<!--                      <span v-if="!$v.form.project_seq.required"-->
<!--                      >This value is required.</span-->
<!--                      >-->
<!--                  </div>-->
<!--                </div>-->
                <div class="form-group col-md-6">
                  <label for="projectStatus">Project Status</label>
                  <select id="projectStatus" class="form-control" v-model="form.project_status"
                          :class="{'is-invalid': typeSubmit && $v.form.project_status.$error,}">
                    <option :value="1">Active</option>
                    <option :value="0">Passive</option>
                  </select>
                  <div
                      v-if="typeSubmit && $v.form.project_status.$error"
                      class="invalid-feedback"
                  >
                      <span v-if="!$v.form.project_status.required"
                      >This value is required.</span
                      >
                  </div>
                </div>
              </div>
              <br>
              <div class="form-group">
                <label for="projectDescription">Project Description</label>
                <ckeditor :editor="editor" v-model="form.project_description" class="form-control" id="projectDescription"
                          :class="{'is-invalid': typeSubmit && $v.form.project_description.$error,}"></ckeditor>
                <div
                    v-if="typeSubmit && $v.form.project_description.$error"
                    class="invalid-feedback"
                >
                      <span v-if="!$v.form.project_description.required"
                      >This value is required.</span
                      >
                </div>
              </div>
              <br>
              <div class="form-row">
                <label>Project Image</label>
                <div class="col-md-8 input-group">
                  <input type="file" @change="onFileSelected" class="form-control" id="inputGroupFile02"
                         :class="{'is-invalid': typeSubmit && $v.form.project_image.$error,}">
                  <label class="input-group-text" for="inputGroupFile02">Upload</label>
                  <div
                      v-if="typeSubmit && $v.form.project_image.$error"
                      class="invalid-feedback"
                  >
                      <span v-if="!$v.form.project_image.required"
                      >This value is required.</span
                      >
                  </div>
                </div>
                <br><br>
                <div class="form-group col-md-2">
                  <img :src="form.project_image" id="project_image">
                </div>
              </div>
              <br>
              <button type="submit" class="btn btn-primary col-xl-12">Save</button>
            </form>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>

<script>

import Layout from "../../router/layouts/main.vue";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {required} from "vuelidate/lib/validators";

export default {
  components:{
    // eslint-disable-next-line vue/no-unused-components
    ckeditor: CKEditor.component,
    Layout,
  },
  name:'admin-create-project',
  created(){
    // eslint-disable-next-line no-undef
    if(!User.loggedIn()){
      this.$router.push({name: 'admin-login'})
    }
    this.$http.get('project/')
        .then(({data}) => (this.projects = data))
  },
  validations(){
    return{
      form:{
        project_title: {required},
        // project_seq: {required},
        project_status: {required},
        project_description: {required},
        project_image: {required},
      },
    }
  },

  data(){
    return {
      typeSubmit:false,
      form:{
        project_title: null,
        // project_seq: null,
        project_status: null,
        project_description: null,
        project_image: null,
      },
      errors:{},
      projects:{},
      editor: ClassicEditor,
    }
  },

  methods:{
    onFileSelected(event){
      let file = event.target.files[0];
      if(file.size > 20971520) {
        this.$toasted.global.image_validation()
      } else {
        let reader = new FileReader();
        reader.onload = event => {
          this.form.project_image = event.target.result
          // eslint-disable-next-line no-console
          // console.log(event.target.result);
        };
        reader.readAsDataURL(file);
      }
    },
    projectInsert(){
      this.typeSubmit = true;
      this.$v.$touch();
      if(this.$v.$invalid){
        return;
      }else{
        this.$http.post('project',this.form)
            .then(() => {
              this.$router.push({ name: 'admin-project-list'})
              this.$toasted.global.success()
            })
            .catch(error => this.errors = error.response.data.errors)
      }

    }
  }
}
</script>
<style scoped>
#project_image{
  width: 50px;
  height: 50px;
}
</style>
