<template>
  <Layout>
    <div>

      <div class="col-xl-12">
        <div class="card card-statistics">
          <div class="card-body">
            <form @submit.prevent="truckUpdate" enctype="multipart/form-data">
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label for="supplier">Supplier</label>
                  <select id="supplier" class="form-control" v-model="form.supplier_id" :class="{
                    'is-invalid': typeSubmit && $v.form.supplier_id.$error,
                  }">
                    <option :key="supplier.id" :value="supplier.id" v-for="supplier in suppliers">{{ supplier.supplier_name }} {{ supplier.supplier_surname }}</option>
                  </select>
                  <div
                      v-if="typeSubmit && $v.form.supplier_id.$error"
                      class="invalid-feedback"
                  >
                  <span v-if="!$v.form.supplier_id.required"
                  >This value is required.</span
                  >
                  </div>
                </div>
              </div>
              <br>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="truckBrand">Truck Brand</label>
                  <input type="text" min="1" v-model="form.truck_brand" class="form-control" id="truckBrand" :class="{
                    'is-invalid': typeSubmit && $v.form.truck_brand.$error,
                  }">
                  <div
                    v-if="typeSubmit && $v.form.truck_brand.$error"
                    class="invalid-feedback"
                >
                  <span v-if="!$v.form.truck_brand.required"
                  >This value is required.</span
                  >
                </div>

                </div>
                <div class="form-group col-md-6">
                  <label for="truckModel">Truck Model</label>
                  <input type="text" min="1" v-model="form.truck_model" class="form-control" id="truckModel" :class="{
                    'is-invalid': typeSubmit && $v.form.truck_model.$error,
                  }">
                  <div
                      v-if="typeSubmit && $v.form.truck_model.$error"
                      class="invalid-feedback"
                  >
                  <span v-if="!$v.form.truck_model.required"
                  >This value is required.</span
                  >
                  </div>
                </div>
              </div>
              <br>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="truckPlateNumber">Truck Plate Number</label>
                  <input type="text" v-model="form.truck_plate_number" class="form-control" id="truckPlateNumber" :class="{
                    'is-invalid': typeSubmit && $v.form.truck_plate_number.$error,
                  }">
                  <div
                      v-if="typeSubmit && $v.form.truck_plate_number.$error"
                      class="invalid-feedback"
                  >
                  <span v-if="!$v.form.truck_plate_number.required"
                  >This value is required.</span
                  >
                  </div>
                </div>
                <div class="form-group col-md-6">
                  <label for="truckStartKm">Truck Start Km</label>
                  <input type="text" min="1" v-model="form.truck_start_km" class="form-control" id="truckStartKm" :class="{
                    'is-invalid': typeSubmit && $v.form.truck_start_km.$error,
                  }">
                  <div
                      v-if="typeSubmit && $v.form.truck_start_km.$error"
                      class="invalid-feedback"
                  >
                  <span v-if="!$v.form.truck_start_km.required"
                  >This value is required.</span
                  >
                  </div>
                </div>
              </div>
              <br>
              <div class="form-row">
                <label>Truck Image</label>
                <div class="col-md-8 input-group">
                  <input type="file" @change="onFileSelected" class="form-control" id="inputGroupFile02" :class="{
                    'is-invalid': typeSubmit && $v.form.truck_image.$error,
                  }">
                  <label class="input-group-text" for="inputGroupFile02">Upload</label>
                  <div
                      v-if="typeSubmit && $v.form.truck_image.$error"
                      class="invalid-feedback"
                  >
                  <span v-if="!$v.form.truck_image.required"
                  >This value is required.</span
                  >
                  </div>
                </div>
                <br><br>
                <div class="form-group col-md-2">
                  <img :src="form.truck_image" id="truck_photo">
                </div>
                <div class="form-group col-md-12 mt-3">
                  <lazy-component wrapper-tag="section" @intersected="optionalDispatch">
                    <img :src="'https://crmtr.balinsoft.com/public/'+form.truck_image" id="truck_photo">
                    <span slot="placeholder">Loading..</span> <!-- Optional -->
                  </lazy-component>
                </div>
              </div>
              <br>
              <button type="submit" class="btn btn-primary col-xl-12">Save</button>
            </form>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>

<script>

import Layout from "../../router/layouts/main.vue";
import LazyComponent from "v-lazy-component";
import {
  required
} from "vuelidate/lib/validators";

export default {
  components:{
    // eslint-disable-next-line vue/no-unused-components
    Layout,LazyComponent
  },
  name:'admin-create-truck',
  created(){
    // eslint-disable-next-line no-undef
    if(!User.loggedIn()){
      this.$router.push({name: 'admin-login'})
    }
    this.allSupplier();
    let id = this.$route.params.id
    this.$http.get('truck/'+id)
        .then(({data}) => (this.form = data))
        .catch()
  },
  validations(){
    return{
      form:{
        supplier_id: {required},
        truck_brand: {required},
        truck_model: {required},
        truck_plate_number: {required},
        truck_start_km: {required},
        truck_image: {required},
      },
    }
  },
  data(){
    return {
      form:{
        supplier_id: '',
        truck_brand: '',
        truck_model: '',
        truck_plate_number: '',
        truck_start_km: '',
        truck_image: '',
        new_truck_image: '',
      },
      errors:{},
      typeSubmit:false,
      trucks:{},
      suppliers:{},
    }
  },

  methods:{
    // All Supplier
    allSupplier(){
      this.$http.get('supplier')
          .then(({data}) => (this.suppliers = data))
          .catch()
    },
    onFileSelected(event){
      let file = event.target.files[0];
      if(file.size > 20971520) {
        this.$toasted.global.image_validation()
      } else {
        let reader = new FileReader();
        reader.onload = event => {
          this.form.new_truck_image = event.target.result
          // eslint-disable-next-line no-console
          // console.log(event.target.result);
        };
        reader.readAsDataURL(file);
      }
    },
    truckUpdate(){
      this.typeSubmit = true;
      this.$v.$touch()

      if(this.$v.$invalid){
        return false;
      }else{
        let id = this.$route.params.id
        this.$http.patch('truck/'+id,this.form)
            .then(() => {
              this.$router.push({ name: 'admin-truck-list'})
              this.$toasted.global.success()
            })
            .catch(error => this.errors = error.response.data.errors)
      }
    }
  }
}
</script>
<style scoped>
#truck_photo{
  height: 100px;
  width: 100px;
}
.v-lazy-component.v-lazy-component--loading {
  filter: blur(20px);
}

.v-lazy-component.v-lazy-component--loaded {
  filter: blur(0);
  transition: filter 1s;
}
</style>