<template>
  <Layout>
    <div>
      <body>
      <div class="container">
        <div class="leftSide">
          <!--            header-->
          <div class="header">
            <div class="userImg">
              <img src="/whatsapp/user.jpg" class="cover" alt="userImg">
            </div>
            <div class="nav_icons">
              <li><i class="ion ion-md-qr-scanner"></i></li>
              <li><i class="ion ion-md-chatboxes"></i></li>
              <li><i class="ion ion-md-more"></i></li>
            </div>
          </div>
          <!--            search-->
          <div class="search_chat">
            <div>
              <input type="text" placeholder="Search or start new chat">
              <i class="ion ion-ios-search"></i>
            </div>
          </div>
          <!--            chat list-->
          <div class="chatlist">
            <div class="block active">
              <div class="imgbx">
                <img src="/whatsapp/img1.jpg" class="cover" alt="img1">
              </div>
              <div class="details">
                <div class="listHead">
                  <h4>Hüseyin Balın</h4>
                  <p class="time">13:30</p>
                </div>
                <div class="message_p">
                  <p>How to make Whatsapp clone using HTML and CSS</p>
                </div>
              </div>
            </div>
            <div class="block unread">
              <div class="imgbx">
                <img src="/whatsapp/img2.jpg" class="cover" alt="img2">
              </div>
              <div class="details">
                <div class="listHead">
                  <h4>Durmuş Efe Akman</h4>
                  <p class="time">11:58</p>
                </div>
                <div class="message_p">
                  <p>Hi!</p>
                  <b>1</b>
                </div>
              </div>
            </div>
            <div class="block unread">
              <div class="imgbx">
                <img src="/whatsapp/img3.jpg" class="cover" alt="img3">
              </div>
              <div class="details">
                <div class="listHead">
                  <h4>Sofia</h4>
                  <p class="time">yesterday</p>
                </div>
                <div class="message_p">
                  <p>What are you doing?</p>
                  <b>5</b>
                </div>
              </div>
            </div>
            <div class="block unread">
              <div class="imgbx">
                <img src="/whatsapp/img4.jpg" class="cover" alt="img4">
              </div>
              <div class="details">
                <div class="listHead">
                  <h4>Olivia</h4>
                  <p class="time">yesterday</p>
                </div>
                <div class="message_p">
                  <p>Can you please help?</p>
                  <b>1</b>
                </div>
              </div>
            </div>
            <div class="block">
              <div class="imgbx">
                <img src="/whatsapp/img5.jpg" class="cover" alt="img5">
              </div>
              <div class="details">
                <div class="listHead">
                  <h4>Eric</h4>
                  <p class="time">yesterday</p>
                </div>
                <div class="message_p">
                  <p>I like the beautiful design.</p>
                </div>
              </div>
            </div>
            <div class="block">
              <div class="imgbx">
                <img src="/whatsapp/img6.jpg" class="cover" alt="img6">
              </div>
              <div class="details">
                <div class="listHead">
                  <h4>Jack</h4>
                  <p class="time">yesterday</p>
                </div>
                <div class="message_p">
                  <p>Thank you.</p>
                </div>
              </div>
            </div>
            <div class="block">
              <div class="imgbx">
                <img src="/whatsapp/img7.jpg" class="cover" alt="img7">
              </div>
              <div class="details">
                <div class="listHead">
                  <h4>Diana</h4>
                  <p class="time">04/10/2022</p>
                </div>
                <div class="message_p">
                  <p>Nice to meet you.</p>
                </div>
              </div>
            </div>
            <div class="block">
              <div class="imgbx">
                <img src="/whatsapp/img8.jpg" class="cover" alt="img8">
              </div>
              <div class="details">
                <div class="listHead">
                  <h4>John</h4>
                  <p class="time">27/09/2022</p>
                </div>
                <div class="message_p">
                  <p>Congratulations on the job.</p>
                </div>
              </div>
            </div>
            <div class="block">
              <div class="imgbx">
                <img src="/whatsapp/img9.jpg" class="cover" alt="img9">
              </div>
              <div class="details">
                <div class="listHead">
                  <h4>Mary</h4>
                  <p class="time">21/09/2022</p>
                </div>
                <div class="message_p">
                  <p>Congratulations on the job.</p>
                </div>
              </div>
            </div>
            <div class="block">
              <div class="imgbx">
                <img src="/whatsapp/img3.jpg" class="cover" alt="img3">
              </div>
              <div class="details">
                <div class="listHead">
                  <h4>Victoria</h4>
                  <p class="time">15/09/2022</p>
                </div>
                <div class="message_p">
                  <p>What are you doing?</p>
                </div>
              </div>
            </div>
            <div class="block">
              <div class="imgbx">
                <img src="/whatsapp/img4.jpg" class="cover" alt="img4">
              </div>
              <div class="details">
                <div class="listHead">
                  <h4>Valencia</h4>
                  <p class="time">11/09/2022</p>
                </div>
                <div class="message_p">
                  <p>Can you please help?</p>
                </div>
              </div>
            </div>
            <div class="block">
              <div class="imgbx">
                <img src="/whatsapp/img3.jpg" class="cover" alt="img3">
              </div>
              <div class="details">
                <div class="listHead">
                  <h4>Mary</h4>
                  <p class="time">09/09/2022</p>
                </div>
                <div class="message_p">
                  <p>What are you doing?</p>
                </div>
              </div>
            </div>
            <div class="block">
              <div class="imgbx">
                <img src="/whatsapp/img4.jpg" class="cover" alt="img4">
              </div>
              <div class="details">
                <div class="listHead">
                  <h4>Zoe</h4>
                  <p class="time">08/09/2022</p>
                </div>
                <div class="message_p">
                  <p>How are you?</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="rightSide">
          <!--            chat header-->
          <div class="header">
            <div class="imgText">
              <div class="userImg">
                <img src="/whatsapp/img1.jpg" class="cover" alt="user">
              </div>
              <h4>Hüseyin Balın<br><span>online</span></h4>
            </div>
            <div class="nav_icons">
              <li><i class="ion ion-ios-search"></i></li>
              <li><i class="ion ion-ios-videocam"></i></li>
              <li><i class="ion ion-md-more"></i></li>
            </div>
          </div>
          <!--            chat box-->
          <div class="chatBox">
            <div class="message my_message">
              <p>Hi<br><span>12:15</span></p>
            </div>
            <div class="message friend_message">
              <p>Hello<br><span>12:20</span></p>
            </div>
            <div class="message my_message">
              <p>How are you?<br><span>12:32</span></p>
            </div>
            <div class="message friend_message">
              <p>I'm good. How about you?<br><span>12:45</span></p>
            </div>
            <div class="message my_message">
              <p>I'm good too thanks<br><span>12:54</span></p>
            </div>
            <div class="message friend_message">
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc convallis, nisi eget accumsan semper, dui libero dapibus nisl, id lobortis eros est et magna. Ut in consequat velit, id consectetur neque. Suspendisse lacinia porta velit, et volutpat lectus lacinia eget. Proin feugiat elit ut odio pulvinar, sit amet dignissim felis auctor. Integer leo ligula, lacinia a neque a, pellentesque pharetra nisl. Proin blandit bibendum gravida. Aenean commodo lectus purus, non faucibus erat vestibulum eu. Ut elementum dui sed tellus posuere, vel tristique tellus ultrices. Cras sollicitudin blandit velit, et tempus velit euismod eget. Nunc sagittis vel nisi vel placerat.<br><span>12:59</span></p>
            </div>
            <div class="message my_message">
              <p>Sed mollis erat non odio egestas, id lobortis est scelerisque. Suspendisse vitae pulvinar nisl. Pellentesque enim nibh, congue sed tortor eget, pharetra lacinia nibh. Integer faucibus ligula id diam dictum condimentum. Cras non molestie urna, eu aliquam arcu. Mauris dictum tellus vitae felis commodo, auctor mollis tellus viverra. Ut consequat turpis a arcu aliquet, volutpat facilisis lorem consequat. Pellentesque non interdum orci.<br><span>13:01</span></p>
            </div>
            <div class="message friend_message">
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In hac habitasse platea dictumst. Morbi lobortis euismod felis, vitae fermentum libero posuere sed. Nulla facilisi. Fusce bibendum, enim vitae rutrum iaculis, nisi risus maximus lorem, a interdum ex lacus nec tellus. Praesent rhoncus risus ac luctus consectetur. Aenean eleifend consequat magna. Duis at purus mi.<br><span>13:10</span></p>
            </div>
            <div class="message my_message">
              <p>How can i help you?<br><span>13:20</span></p>
            </div>
            <div class="message friend_message">
              <p>How to make Whatsapp clone using HTML and CSS?<br><span>13:30</span></p>
            </div>
          </div>

          <!--        chat input-->
          <div class="chatbox_input">
            <i class="ion ion-md-happy"></i>
            <i class="ion ion-ios-attach"></i>
            <input type="text" id="messageBox" placeholder="Type a message" v-on:input="changeContent(form.message)" v-model="form.message">
            <i v-show="showMic" id="mic" class="ion ion-ios-mic"></i>
            <i v-show="showPlane" id="plane" class="ion ion-ios-paper-plane"></i>
          </div>
        </div>

      </div>
      </body>
    </div>
  </Layout>

</template>
<!--<script type="module" src="https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.esm.js"></script>-->
<!--<script nomodule src="https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.js"></script>-->
<!--<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.1/jquery.min.js" integrity="sha512-aVKKRRi/Q/YV+4mjoKBsE4x3H+BkegoM/em46NNlCqNTmUYADjBbeNefNxYV7giUp0VxICtqdrbqU7iVaeZNXA==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>-->
<!--<script>-->
<!--$('.chatbox_input #messageBox').keyup(function () {-->
<!--  var text = $(this).val()-->
<!--  if (text.length > 0) {-->
<!--    $('.chatbox_input #plane').css("display","");-->
<!--    $('.chatbox_input #mic').css("display","none");-->
<!--  } else {-->
<!--    $('.chatbox_input #mic').css("display","");-->
<!--    $('.chatbox_input #plane').css("display","none");-->
<!--  }-->
<!--});-->
<!--</script>-->
<script>
import Layout from "../../router/layouts/main.vue";

export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { Layout, },
  created(){
    // eslint-disable-next-line no-undef
    if(!User.loggedIn()){
      this.$router.push({name: 'admin-login'})
    }

  },
  data(){
    return{
      form:{
        message: null,
      },
      showMic: true,
      showPlane: false,
    }
  },
  computed:{

  },
  mounted() {

  },
  methods:{
    changeContent(event){
      if (event === ''){
        this.showMic = true
        this.showPlane = false
      }else{
        this.showMic = false
        this.showPlane = true
      }
    },
  },


}
</script>

<style scoped>
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
}
body{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(#009688 0%, #009688 130px, #d9dbd5 130px, #d9dbd5 100%);
}
.container{
  position: relative;
  width: 1396px;
  max-width: 100%;
  height: calc(100vh - 40px);
  background: #fff;
  box-shadow: 0 1px 1px 0 rgba(0,0,0,0.6), 0 2px 5px 0 rgba(0,0,0,0.6);
  display: flex;
}
.container .leftSide{
  position: relative;
  flex: 30%;
  background: #fff;
  border-right: 1px solid rgba(0,0,0,0.2);
}
.container .rightSide{
  position: relative;
  flex: 70%;
  background: #e5ddd5;
  border-right: 1px solid #fff;
}
.container .rightSide::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("/whatsapp/pattern.png");
  opacity: 0.06;
}
/*header*/
.header{
  position: relative;
  width: 100%;
  height: 60px;
  background-color: #ededed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
}
.userImg{
  position: relative;
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
  cursor: pointer;
}
.cover{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.nav_icons{
  display: flex;
}
.nav_icons li{
  display: flex;
  list-style: none;
  cursor: pointer;
  color: #51585c;
  font-size: 1.5em;
  margin-left: 22px;
}
/*search*/
.search_chat{
  position: relative;
  width: 100%;
  height: 50px;
  background: #f6f6f6;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
}
.search_chat div{
  width: 100%;
}
.search_chat div input{
  width: 100%;
  outline: none;
  border: none;
  background: #fff;
  padding: 6px;
  height: 38px;
  border-radius: 30px;
  font-size: 14px;
  padding-left: 40px;
}
.search_chat div input::placeholder{
  color: #bbb;
}
.search_chat div i{
  position: absolute;
  left: 30px;
  top: 14px;
  font-size: 1.2em;
}
/*chat list*/
.chatlist{
  position: relative;
  height: calc(100% - 110px);
  overflow-y: auto;
}
.chatlist .block{
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid rgba(0,0,0,0.06);
  cursor: pointer;
}
.chatlist .block.active{
  background: #ebebeb;
}
.chatlist .block:hover{
  background: #f5f5f5;
}
.chatlist .block .imgbx{
  position: relative;
  min-width: 45px;
  height: 45px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 10px;
}
.chatlist .block .details{
  position: relative;
  width: 100%;
}
.chatlist .block .details .listHead{
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
.chatlist .block .details .listHead h4{
  font-size: 1.1em;
  font-weight: 600;
  color: #111;
}
.chatlist .block .details .listHead .time{
  font-size: 0.75em;
  color: #aaa;
}
.chatlist .block .details .listHead .time{
  color: #111;
}
.chatlist .block.unread .details .listHead .time{
  color: #06d755;
}
.message_p{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.message_p p{
  color: #aaa;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  font-size: 0.9em;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.message_p b{
  background: #06d755;
  color: #fff;
  min-width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75em;
}
/*chat header*/
.imgText{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.imgText h4{
  font-weight: 500;
  line-height: 1.2em;
  margin-left: 15px;
}
.imgText h4 span{
  font-size: 0.8em;
  color: #555;
}
/*chat*/
.chatBox{
  position: relative;
  width: 100%;
  height: calc(100% - 120px);
  padding: 50px;
  overflow-y: auto;
}
.message{
  position: relative;
  display: flex;
  width: 100%;
  margin: 5px 0;
}
.message p{
  position: relative;
  right: 0;
  text-align: right;
  max-width: 65%;
  padding: 12px;
  background: #dcf8c6;
  border-radius: 10px;
  font-size: 0.9em;
}
.message p::before{
  content: '';
  position: absolute;
  top: 0;
  right: -12px;
  width: 20px;
  height: 20px;
  background: linear-gradient(135deg, #dcf8c6 0%, #dcf8c6 50%, transparent 50%, transparent);
}
.message p span{
  display: block;
  margin-top: 5px;
  font-size: 0.85em;
  opacity: 0.5;
}
.my_message{
  justify-content: flex-end;
}
.friend_message{
  justify-content: flex-start;
}
.friend_message p{
  background: #fff;
  text-align: left;
}
.message.friend_message p::before{
  content: '';
  position: absolute;
  top: 0;
  left: -12px;
  width: 20px;
  height: 20px;
  background: linear-gradient(225deg, #fff 0%, #fff 50%, transparent 50%, transparent);
}
.chatbox_input{
  position: relative;
  width: 100%;
  height: 60px;
  background: #f0f0f0;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.chatbox_input i{
  cursor: pointer;
  font-size: 1.8em;
  color: #51585c;
}
.chatbox_input input{
  position: relative;
  width: 85%;
  margin: 0 20px;
  padding: 10px 20px;
  border: none;
  outline: none;
  border-radius: 30px;
  font-size: 1em;
}
</style>
