import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import VueMask from 'v-mask'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueSweetalert2 from 'vue-sweetalert2'
import VueApexCharts from 'vue-apexcharts'
import router from './router/index'
import store from '@/state/store'
import vco from "v-click-outside"

import "@/design/index.scss";

import Sparkline from 'vue-sparklines'

// User Class Imported
import User from './helpers/authservice/User';
window.User = User
import Toasted from 'vue-toasted';

// Import Notification Class
import Notification from './helpers/authservice/Notification';
window.Notification = Notification

import money from 'v-money'
Vue.use(money, {precision: 4})

// import VueCoreVideoPlayer from 'vue-core-video-player'
// Vue.use(VueCoreVideoPlayer)

// import CanvasVideo from 'vue-canvasvideo'
// import 'vue-canvasvideo/dist/vuecanvasvideo.min.css'
// Vue.use(CanvasVideo)

// import VideoBackground from 'vue-responsive-video-background-player'
// Vue.component('video-background', VideoBackground);

import IdleVue from "idle-vue";
// import store from './store'

const eventsHub = new Vue();

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 1800000, // 30 minute 1800000
  startAtIdle: false
});

global.jQuery = require('jquery');
const $ = global.jQuery;
window.$ = $;
// Sweet Alert Start
import Swal from 'sweetalert2'
// import axios from "axios";
window.Swal = Swal;
import initAxios from './helpers/authservice/axios'
import axios from 'axios';

initAxios();

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
});
window.Toast = Toast;



// if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
//   initFirebaseBackend(firebaseConfig);
// } else {
//   configureFakeBackend();
// }

Vue.use(BootstrapVue);

Vue.config.productionTip = false
Vue.use(Toasted);
Vue.use(Vuelidate);
Vue.use(VueMask)
Vue.use(VueSweetalert2)
Vue.use(require('vue-chartist'))
Vue.use(vco)
Vue.use(Sparkline)
window.Reload = new Vue();

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDUFz_grdu36GXFm2dTi79oY9YHnqPr0Dk',
    libraries: 'places',
  },
  installComponents: true
})
Vue.component('apexchart', VueApexCharts)

import InputTag from 'vue-input-tag'
Vue.component('input-tag', InputTag)

// axios.defaults.headers.common = {'X-Requested-With': 'XMLHttpRequest'}
// axios.defaults.baseURL =  'http://127.0.0.1:8001/api/';
// Vue.prototype.$http = axios;

import LazyComponent from "v-lazy-component";
Vue.use(LazyComponent);

import VueTour from 'vue-tour'
require('vue-tour/dist/vue-tour.css')
Vue.use(VueTour)


// import VueEcho from 'vue-echo-laravel';
//
// Vue.use(VueEcho, {
//   broadcaster: 'socket.io',
//   host: process.env.CHANNEL_URL + ':6001',
// });


// import Echo from 'laravel-echo';
//
// window.Pusher = require('pusher-js');
//
// window.Echo = new Echo({
//   broadcaster: 'pusher',
//   key: process.env.VUE_APP_WEBSOCKETS_KEY,
//   wsHost: process.env.VUE_APP_WEBSOCKETS_SERVER,
//   wsPort: 6001,
//   forceTLS: false,
//   disableStats: true
// });


axios.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  // console.log( config.url + ' : ' + token);
  if (token) {
    config.headers.Authorization = "Bearer " + token
  }
  return config;
}, (err) => {
  return Promise.reject(err);
});


Vue.directive('role', {
  inserted: function (el, binding) {
    let _userRoles = ["ROLE_USER"]
    let _v = binding.value
    if (Object.keys(_v).length > 0) {
      _userRoles.push(window.localStorage.getItem('user_roles'));
      let diff = _v.filter((e) => _userRoles.indexOf(e) !== -1);
      if (Object.keys(diff).length <= 0 && !_v.includes('*')) {
        el.remove()
      }
    }
  },
});


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')


