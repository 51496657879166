<template>
  <Layout>
    <div>

      <div class="col-xl-12">
        <div class="card card-statistics">
          <div class="card-body">
            <form @submit.prevent="employeeUpdate" enctype="multipart/form-data">
              <div class="form-row">
                <div class="form-group col-md-4">
                  <label for="employeeCode">Employee Code</label>
                  <input type="text" v-model="form.employee_code" class="form-control" id="employeeCode" :class="{
                    'is-invalid': typeSubmit && $v.form.employee_code.$error,
                  }">
                  <div v-if="typeSubmit && $v.form.employee_code.$error" class="invalid-feedback">
                    <span v-if="!$v.form.employee_code.required">This value is required.</span>
                  </div>
                </div>
                <div class="form-group col-md-4">
                  <label for="employeeName">Employee Name</label>
                  <input type="text" v-model="form.employee_name" class="form-control" id="employeeName" :class="{
                    'is-invalid': typeSubmit && $v.form.employee_name.$error,
                  }">
                   <div v-if="typeSubmit && $v.form.employee_name.$error" class="invalid-feedback">
                    <span v-if="!$v.form.employee_name.required">This value is required.</span>
                  </div>
                </div>
                <div class="form-group col-md-4">
                  <label for="employeeSurname">Employee Surname</label>
                  <input type="text" v-model="form.employee_surname" class="form-control" id="employeeSurname" :class="{
                    'is-invalid': typeSubmit && $v.form.employee_surname.$error,
                  }">
                   <div v-if="typeSubmit && $v.form.employee_surname.$error" class="invalid-feedback">
                    <span v-if="!$v.form.employee_surname.required">This value is required.</span>
                  </div>
                </div>
              </div>
              <br>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="employeeCountry">Country</label>
                  <select @change="switchCountrySelect($event)" id="employeeCountry" class="form-control" v-model="form.country_id">
                    <option :key="ct.id" :value="ct.id" v-for="ct in country">{{ ct.country }}</option>
                  </select>
                  <div
                      v-if="typeSubmit && $v.form.country_id.$error"
                      class="invalid-feedback"
                  >
                      <span v-if="!$v.form.country_id.required"
                      >This value is required.</span
                      >
                  </div>
                </div>


                <div class="form-group col-md-6">
                  <label for="employeeCity">City</label>
                  <select id="employeeCity" class="form-control" v-model="form.city_id">
                    <option :key="c.id" :value="c.id" v-for="c in city">{{ c.city }}</option>
                  </select>
                  <div
                      v-if="typeSubmit && $v.form.city_id.$error"
                      class="invalid-feedback"
                  >
                      <span v-if="!$v.form.city_id.required"
                      >This value is required.</span
                      >
                  </div>
                </div>
              </div>
              <br>
              <div class="form-row">
                <div class="form-group col-md-4">
                  <label for="employeePhone">Employee Phone</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span v-if="form.employeePhoneDialCode != null" class="input-group-text" id="basic-addon1">{{ form.employeePhoneDialCode }}</span>
                      <span v-else class="input-group-text" id="basic-addon1">{{ form.employee_phone_dial_code }}</span>
                    </div>
                    <input :class="{
                    'is-invalid': typeSubmit && $v.form.employee_phone.$error,
                  }" type="text" v-mask="form.employeePhoneFormat" v-model="form.employee_phone" class="form-control" id="employeePhone">
                    <div v-if="typeSubmit && $v.form.employee_phone.$error" class="invalid-feedback">
                      <span v-if="!$v.form.employee_phone.required">This value is required.</span>
                    </div>
                  </div>


<!--                  <input type="text" v-model="form.employee_phone" class="form-control" id="employeePhone" :class="{-->
<!--                    'is-invalid': typeSubmit && $v.form.employee_phone.$error,-->
<!--                  }">-->
<!--                   <div v-if="typeSubmit && $v.form.employee_phone.$error" class="invalid-feedback">-->
<!--                    <span v-if="!$v.form.employee_phone.required">This value is required.</span>-->
<!--                  </div>-->
                </div>
                <div class="form-group col-md-4">
                  <label for="employeeEmail">Employee Email</label>
                  <input type="email" v-model="form.employee_email" class="form-control" id="employeeEmail" :class="{
                    'is-invalid': typeSubmit && $v.form.employee_email.$error,
                  }">
                   <div v-if="typeSubmit && $v.form.employee_email.$error" class="invalid-feedback">
                    <span v-if="!$v.form.employee_email.required">This value is required.</span>
                  </div>
                </div>
                <div class="form-group col-md-4">
                  <label for="employeeJob">Employee Job</label>
                  <input type="text" v-model="form.employee_job" class="form-control" id="employeeJob" :class="{
                    'is-invalid': typeSubmit && $v.form.employee_job.$error,
                  }">
                   <div v-if="typeSubmit && $v.form.employee_job.$error" class="invalid-feedback" >
                    <span v-if="!$v.form.employee_job.required">This value is required.</span>
                  </div>
                </div>
              </div>
              <br>
              <div class="form-row">
                <div class="form-group col-md-4">
                  <label for="employeePosition">Employee Position</label>
                  <input type="text" v-model="form.employee_position" class="form-control" id="employeePosition" :class="{
                    'is-invalid': typeSubmit && $v.form.employee_position.$error,
                  }">
                   <div v-if="typeSubmit && $v.form.employee_position.$error" class="invalid-feedback">
                    <span v-if="!$v.form.employee_position.required">This value is required.</span>
                  </div>
                </div>
                <div class="form-group col-md-4">
                  <label for="employeeExperience">Employee Experience</label>
                  <input type="text" v-model="form.employee_experience" class="form-control" id="employeeExperience" :class="{
                    'is-invalid': typeSubmit && $v.form.employee_experience.$error,
                  }">
                   <div v-if="typeSubmit && $v.form.employee_experience.$error" class="invalid-feedback">
                    <span v-if="!$v.form.employee_experience.required">This value is required.</span>
                  </div>
                </div>
                <div class="form-group col-md-4">
                  <label for="employeePracticeArea">Employee Practice Area</label>
                  <input type="text" v-model="form.employee_practice_area" class="form-control" id="employeePracticeArea" :class="{
                    'is-invalid': typeSubmit && $v.form.employee_practice_area.$error,
                  }">
                   <div v-if="typeSubmit && $v.form.employee_practice_area.$error" class="invalid-feedback">
                    <span v-if="!$v.form.employee_practice_area.required">This value is required.</span>
                  </div>
                </div>
              </div>
              <br>
              <div class="form-group">
                <label for="employeeLocation">Employee Location</label>
                <ckeditor :editor="editor" v-model="form.employee_location" class="form-control" id="employeeLocation" :class="{
                    'is-invalid': typeSubmit && $v.form.employee_location.$error,
                  }"></ckeditor>
                 <div v-if="typeSubmit && $v.form.employee_location.$error" class="invalid-feedback">
                    <span v-if="!$v.form.employee_location.required">This value is required.</span>
                  </div>
              </div>
              <br>
              <div class="form-group">
                <label for="employeeDescription">Employee Description</label>
                <ckeditor :editor="editor" v-model="form.employee_description" class="form-control" id="employeeDescription" :class="{
                    'is-invalid': typeSubmit && $v.form.employee_description.$error,
                  }"></ckeditor>
                 <div v-if="typeSubmit && $v.form.employee_description.$error" class="invalid-feedback">
                    <span v-if="!$v.form.employee_description.required">This value is required.</span>
                  </div>
              </div>
              <br>
              <div class="form-row">
                <label>Employee Image</label>
                <div class="col-md-8 input-group">
                  <input type="file" @change="onFileSelected" class="form-control" id="inputGroupFile02" :class="{
                    'is-invalid': typeSubmit && $v.form.employee_image.$error,
                  }">
                  <label class="input-group-text" for="inputGroupFile02">Upload</label>
                  <div v-if="typeSubmit && $v.form.employee_image.$error" class="invalid-feedback">
                    <span v-if="!$v.form.employee_image.required">This value is required.</span>
                  </div>
                </div>
                <br><br>
                <div class="form-group col-md-2">
                  <img :src="form.employee_image" id="employee_image">
                </div>
                <div class="form-group col-md-12 mt-3">
                  <lazy-component wrapper-tag="section" @intersected="optionalDispatch">
                    <img :src="'https://crmtr.balinsoft.com/public/'+form.employee_image" id="employee_image">
                    <span slot="placeholder">Loading..</span> <!-- Optional -->
                  </lazy-component>
                </div>
              </div>
              <br>
              <button type="submit" class="btn btn-primary col-xl-12">Save</button>
            </form>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>

<script>

import Layout from "../../router/layouts/main.vue";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import LazyComponent from "v-lazy-component";
import {
  required,email
} from "vuelidate/lib/validators";

export default {
  components:{
    // eslint-disable-next-line vue/no-unused-components
    ckeditor: CKEditor.component,
    Layout,LazyComponent
  },
  name:'admin-edit-employee',
  created(){
    // eslint-disable-next-line no-undef
    if(!User.loggedIn()){
      this.$router.push({name: 'admin-login'})
    }
    this.$http.get('country/')
        .then(({data}) => (this.country = data))
    let id = this.$route.params.id
    this.$http.get('employee/'+id)
        .then(({data}) => {this.form = data;
          this.phoneFormatNumber();
          this.$http.get('select-to-country/'+data.country_id)
              .then(({data}) => (this.city = data))
              .catch()})
        .catch()
  },
  validations(){
    return{
      form:{
        employee_code: {required},
        employee_name: {required},
        employee_surname: {required},
        employee_phone: {required},
        employee_email: {required,email},
        employee_position: {required},
        employee_experience: {required},
        employee_practice_area: {required},
        employee_job: {required},
        employee_location: {required},
        employee_description: {required},
        employee_image: {required},
        country_id: {required},
        city_id: {required},
      },
    }
  },
  data(){
    return {
      form:{
        employee_code: '',
        employee_name: '',
        employee_surname: '',
        employee_phone: '',
        employee_email: '',
        employee_position: '',
        employee_experience: '',
        employee_practice_area: '',
        employee_job: '',
        employee_location: '',
        employee_description: '',
        employee_image: '',
        new_employee_image: '',
        country_id: '',
        city_id: '',
        employeePhoneDialCode: '',
        employeePhoneFormat: '',
        employee_phone_dial_code: '',
      },
      errors:{},
      typeSubmit:false,
      editor: ClassicEditor,
      country:[],
      city:[],
    }
  },

  methods:{
    onFileSelected(event){
      let file = event.target.files[0];
      if(file.size > 20971520) {
        this.$toasted.global.image_validation()
      } else {
        let reader = new FileReader();
        reader.onload = event => {
          this.form.new_employee_image = event.target.result
          // eslint-disable-next-line no-console
          // console.log(event.target.result);
        };
        reader.readAsDataURL(file);
      }
    },
    employeeUpdate(){
      this.typeSubmit = true;
      this.$v.$touch()

      if(this.$v.$invalid){
        return false;
      }else{
        let id = this.$route.params.id
        this.$http.patch('employee/'+id,this.form)
            .then(() => {
              this.$router.push({ name: 'admin-employee-list'})
              this.$toasted.global.success()
            })
            .catch(error => this.errors = error.response.data.errors)
      }
    },
    switchCountrySelect(event){
      let id = event.target.value;
      if (id != null) {
        this.$http.get('select-to-country/'+id)
            .then(({data}) => (this.city = data))
            .catch()
        this.changePhoneFormatNumber();
      } else {
        this.$toasted.global.selectCountry()
      }
    },
    changePhoneFormatNumber(){
      // eslint-disable-next-line no-unused-vars
      let format;
      let dialCode;
      this.country.map(e=>{
        if(e.id === this.form.country_id){
          format = e.country_format;
          dialCode = e.country_dial_code;
          if (this.form.employee_phone != null){
            this.form.employee_phone = '';
          }
        }
      })
      this.form.employeePhoneDialCode = dialCode;
      this.form.employeePhoneFormat = format;

    },
    phoneFormatNumber(){
      let format;
      let dialCode;
      this.country.map(e=>{
        if(e.id === this.form.country_id){
          format = e.country_format;
          dialCode = e.country_dial_code;
        }
      })
      this.form.employeePhoneDialCode = dialCode;
      this.form.employeePhoneFormat = format;
    }
  }
}
</script>
<style scoped>
#employee_image{
  width: 50px;
  height: 50px;
}
.v-lazy-component.v-lazy-component--loading {
  filter: blur(20px);
}

.v-lazy-component.v-lazy-component--loaded {
  filter: blur(0);
  transition: filter 1s;
}
</style>
