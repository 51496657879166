<template>
<div>

</div>
</template>

<script>
import User from "@/helpers/authservice/User";

export default {
  created(){
    try {
      this.$http.get('admin/offline/'+User.userId())
          .then()
          .catch()
      localStorage.clear();
      // Toast.fire({
      //   icon: 'success',
      //   title: 'Logout successfully'
      // })
      this.$router.push({ name: 'admin-login' })
    }catch (err){
      console.log(err)
    }
  },
}
</script>

<style scoped>

</style>
