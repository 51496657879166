<template>
<!--  <Layout>-->
    <div class="contacts-list">
      <ul>
        <li v-for="(contact,index) in contacts" :key="contact.id" @click="selectContact(index,contact)" :class="{'selected': index === selected}">
          <div class="avatar">
            <img :src="'https://crmtr.balinsoft.com/public/'+contact.photo" :alt="contact.name + contact.surname">
          </div>
          <div class="contact">
            <p class="name">{{ contact.name }}</p>
            <p class="email">{{ contact.email }}</p>
          </div>
        </li>
      </ul>
    </div>
<!--  </Layout>-->

</template>

<script>
export default {
  name: "ContactsList",
  props: {
    contacts: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    }
  },
  data(){
    return {
      selected: 0,
    };
  },
  methods:{
    selectContact(index,contact){
      this.selected = index;
      this.$emit('selected',contact);
    }
  }
}
</script>

<style lang="scss" scoped>
.contacts-list {
  flex: 2;
  max-height: 600px;
  overflow: scroll;
  border-left: 1px solid lightgray;

  ul {
    list-style-type: none;
    padding-left: 0;

    li {
      display: flex;
      padding: 2px;
      border-bottom: 1px solid lightgray;
      height: 80px;
      position: relative;
      cursor: pointer;
      background: #ffffff;
      color: black;

      &.selected {
        background: lightgray ;
        color: black;
      }
    }

    .avatar {
      flex: 1;
      display: flex;
      align-items: center;

      img {
        width: 35px;
        border-radius: 50%;
        margin: 0 auto;
      }
    }

    .contact {
      flex: 3;
      font-size: 10px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;

      p {
        margin: 0;

        &.name {
          font-weight: bold;
        }
      }
    }

  }
}
</style>
