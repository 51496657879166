<template>
  <Layout>
    <div>

      <div class="col-xl-12">
        <div class="card card-statistics">
          <div class="card-body">
            <form @submit.prevent="warehouseInsert">
              <div class="form-row">
                <div class="form-group col-md-3">
                  <label for="warehouseName">Warehouse Name</label>
                  <input type="text" v-model="form.warehouse_name" class="form-control" id="warehouseName" :class="{
                    'is-invalid': typeSubmit && $v.form.warehouse_name.$error,
                  }">
                  <div
                      v-if="typeSubmit && $v.form.warehouse_name.$error"
                      class="invalid-feedback"
                  >
                  <span v-if="!$v.form.warehouse_name.required"
                  >This value is required.</span
                  >
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label for="warehouseEmail">Warehouse Email</label>
                  <input type="email" v-model="form.warehouse_email" class="form-control" id="warehouseEmail" :class="{
                    'is-invalid': typeSubmit && $v.form.warehouse_email.$error,
                  }">
                  <div
                      v-if="typeSubmit && $v.form.warehouse_email.$error"
                      class="invalid-feedback"
                  >
                  <span v-if="!$v.form.warehouse_email.required"
                  >This value is required.</span
                  >
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label for="warehouseCountry">Warehouse Country</label>
                  <select @change="switchCountrySelect($event)" id="warehouseCountry" class="form-control" v-model="form.country_id"
                          :class="{'is-invalid': typeSubmit && $v.form.country_id.$error}">
                    <option :key="ct.id" :value="ct.id" v-for="ct in country">{{ ct.country }}</option>
                  </select>
                  <div
                      v-if="typeSubmit && $v.form.country_id.$error"
                      class="invalid-feedback"
                  >
                  <span v-if="!$v.form.country_id.required"
                  >This value is required.</span
                  >
                  </div>
<!--                  <input type="text" v-model="form.warehouse_country" class="form-control" id="warehouseCountry" :class="{-->
<!--                    'is-invalid': typeSubmit && $v.form.warehouse_country.$error,-->
<!--                  }">-->
<!--                  <div-->
<!--                      v-if="typeSubmit && $v.form.warehouse_country.$error"-->
<!--                      class="invalid-feedback"-->
<!--                  >-->
<!--                  <span v-if="!$v.form.warehouse_country.required"-->
<!--                  >This value is required.</span-->
<!--                  >-->
<!--                  </div>-->
                </div>
                <div class="form-group col-md-3">
                  <label for="warehouseCity">Warehouse City</label>
                  <select id="warehouseCity" class="form-control" v-model="form.city_id"
                          :class="{'is-invalid': typeSubmit && $v.form.city_id.$error}">
                    <option :key="c.id" :value="c.id" v-for="c in city">{{ c.city }}</option>
                  </select>
                  <div
                      v-if="typeSubmit && $v.form.city_id.$error"
                      class="invalid-feedback"
                  >
                  <span v-if="!$v.form.city_id.required"
                  >This value is required.</span
                  >
                  </div>
<!--                  <input type="text" v-model="form.warehouse_city" class="form-control" id="warehouseCity" :class="{-->
<!--                    'is-invalid': typeSubmit && $v.form.warehouse_city.$error,-->
<!--                  }">-->
<!--                  <div-->
<!--                      v-if="typeSubmit && $v.form.warehouse_city.$error"-->
<!--                      class="invalid-feedback"-->
<!--                  >-->
<!--                  <span v-if="!$v.form.warehouse_city.required"-->
<!--                  >This value is required.</span-->
<!--                  >-->
<!--                  </div>-->
                </div>
              </div>
              <br>
              <div class="form-row">
                <div class="form-group col-md-3">
                  <label for="warehousePhone">Warehouse Phone</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span v-if="form.warehousePhoneDialCode != null" class="input-group-text" id="basic-addon1">{{ form.warehousePhoneDialCode }}</span>
                    </div>
                    <input type="text" v-mask="form.warehousePhoneFormat" v-model="form.warehouse_phone" class="form-control" id="warehousePhone"
                           :class="{'is-invalid': typeSubmit && $v.form.warehouse_phone.$error}">
                    <div
                        v-if="typeSubmit && $v.form.warehouse_phone.$error"
                        class="invalid-feedback"
                    >
                  <span v-if="!$v.form.warehouse_phone.required"
                  >This value is required.</span
                  >
                    </div>
                  </div>
<!--                  <input type="text" v-model="form.warehouse_phone" class="form-control" id="warehousePhone" :class="{-->
<!--                    'is-invalid': typeSubmit && $v.form.warehouse_phone.$error,-->
<!--                  }">-->
<!--                  <div-->
<!--                      v-if="typeSubmit && $v.form.warehouse_phone.$error"-->
<!--                      class="invalid-feedback"-->
<!--                  >-->
<!--                  <span v-if="!$v.form.warehouse_phone.required"-->
<!--                  >This value is required.</span-->
<!--                  >-->
<!--                  </div>-->
                </div>
                <div class="form-group col-md-3">
                  <label for="warehousePostCode">Warehouse Post Code</label>
                  <input type="text" v-model="form.warehouse_postcode" class="form-control" id="warehousePostCode" :class="{
                    'is-invalid': typeSubmit && $v.form.warehouse_postcode.$error,
                  }">
                  <div
                      v-if="typeSubmit && $v.form.warehouse_postcode.$error"
                      class="invalid-feedback"
                  >
                  <span v-if="!$v.form.warehouse_postcode.required"
                  >This value is required.</span
                  >
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label for="warehouseType">Warehouse Type</label>
                  <select id="warehouseType" class="form-control" v-model="form.warehouse_type" :class="{
                    'is-invalid': typeSubmit && $v.form.warehouse_type.$error,
                  }">
                    <option v-if="controlMainWarehouse.warehouse_type === '1'" disabled="">Main Warehouse</option>
                    <option v-else="" :value="1">Main Warehouse</option>
                    <option :value="0">Sub Warehouse</option>
                  </select>
                  <div
                      v-if="typeSubmit && $v.form.warehouse_type.$error"
                      class="invalid-feedback"
                  >
                  <span v-if="!$v.form.warehouse_type.required"
                  >This value is required.</span
                  >
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label for="warehouseStatus">Warehouse Status</label>
                  <select id="warehouseStatus" class="form-control" v-model="form.warehouse_status" :class="{
                    'is-invalid': typeSubmit && $v.form.warehouse_status.$error,
                  }">
                    <option :value="1">Active</option>
                    <option :value="0">Passive</option>
                  </select>
                  <div
                      v-if="typeSubmit && $v.form.warehouse_status.$error"
                      class="invalid-feedback"
                  >
                  <span v-if="!$v.form.warehouse_status.required"
                  >This value is required.</span
                  >
                  </div>
                </div>
              </div>
              <br>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="warehouseLatitude">Warehouse Latitude</label>
                  <input type="text" v-model="form.warehouse_latitude" class="form-control" id="warehouseLatitude" :class="{
                    'is-invalid': typeSubmit && $v.form.warehouse_latitude.$error,
                  }">
                  <div
                      v-if="typeSubmit && $v.form.warehouse_latitude.$error"
                      class="invalid-feedback"
                  >
                  <span v-if="!$v.form.warehouse_latitude.required"
                  >This value is required.</span
                  >
                  </div>
                </div>
                <div class="form-group col-md-6">
                  <label for="warehouseLongitude">Warehouse Longitude</label>
                  <input type="text" v-model="form.warehouse_longitude" class="form-control" id="warehouseLongitude" :class="{
                    'is-invalid': typeSubmit && $v.form.warehouse_longitude.$error,
                  }">
                  <div
                      v-if="typeSubmit && $v.form.warehouse_longitude.$error"
                      class="invalid-feedback"
                  >
                  <span v-if="!$v.form.warehouse_longitude.required"
                  >This value is required.</span
                  >
                  </div>
                </div>
              </div>
              <br>
              <div class="form-group">
                <label for="warehouseAddress">Warehouse Address</label>
                <ckeditor :editor="editor" v-model="form.warehouse_address" class="form-control" id="warehouseAddress" :class="{
                    'is-invalid': typeSubmit && $v.form.warehouse_address.$error,
                  }"></ckeditor>
                <div
                    v-if="typeSubmit && $v.form.warehouse_address.$error"
                    class="invalid-feedback"
                >
                  <span v-if="!$v.form.warehouse_address.required"
                  >This value is required.</span
                  >
                </div>
              </div>
              <br>
              <button type="submit" class="btn btn-primary col-xl-12">Save</button>
            </form>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>

<script>

import Layout from "../../router/layouts/main.vue";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  required,email
} from "vuelidate/lib/validators";

export default {
  components:{
    // eslint-disable-next-line vue/no-unused-components
    ckeditor: CKEditor.component,
    Layout,
  },
  name:'admin-create-warehouse',
  created(){
    // eslint-disable-next-line no-undef
    if(!User.loggedIn()){
      this.$router.push({name: 'admin-login'})
    }
    this.$http.get('warehouse/')
        .then(({data}) => (this.warehouses = data))
    this.$http.get('control-main-warehouse/')
        .then(({data}) => (this.controlMainWarehouse = data))
    this.$http.get('country/')
        .then(({data}) => (this.country = data))
  },
  validations(){
    return{
      form:{
        warehouse_name: {required},
        warehouse_phone: {required},
        warehouse_email: {required,email},
        // warehouse_country: {required},
        // warehouse_city: {required},
        country_id: {required},
        city_id: {required},
        warehouse_postcode: {required},
        warehouse_type: {required},
        warehouse_status: {required},
        warehouse_address: {required},
        warehouse_latitude: {required},
        warehouse_longitude: {required},
      },
    }
  },
  data(){
    return {
      form:{
        warehouse_name: null,
        warehouse_phone: null,
        warehouse_email: null,
        // warehouse_country: null,
        // warehouse_city: null,
        warehouse_postcode: null,
        warehouse_type: null,
        warehouse_status: null,
        warehouse_address: null,
        warehouse_latitude: null,
        warehouse_longitude: null,
        country_id: null,
        city_id: null,
        warehousePhoneDialCode: null,
        warehousePhoneFormat: null
      },
      errors:{},
      typeSubmit:false,
      warehouses:{},
      controlMainWarehouse:[],
      editor: ClassicEditor,
      country:[],
      city:[],
    }
  },

  methods:{
    warehouseInsert(){
      this.typeSubmit = true;
      this.$v.$touch()

      if(this.$v.$invalid){
        return false;
      }else{
        this.$http.post('warehouse',this.form)
            .then(() => {
              this.$router.push({ name: 'admin-warehouse-list'})
              this.$toasted.global.success()
            })
            .catch(error => this.errors = error.response.data.errors)
      }
    },
    switchCountrySelect(event){
      let id = event.target.value;
      if (id != null) {
        this.$http.get('select-to-country/'+id)
            .then(({data}) => (this.city = data))
            .catch()
        this.changePhoneFormatNumber();
      } else {
        this.$toasted.global.selectCountry()
      }

    },

    changePhoneFormatNumber(){
      // eslint-disable-next-line no-unused-vars
      let format;
      let dialCode;
      this.country.map(e=>{
        if(e.id === this.form.country_id){
          format = e.country_format;
          dialCode = e.country_dial_code;
          if (this.form.warehouse_phone != null){
            this.form.warehouse_phone = '';
          }
        }
      })
      this.form.warehousePhoneDialCode = dialCode;
      this.form.warehousePhoneFormat = format;

    },
  }
}
</script>
<style scoped>

</style>
