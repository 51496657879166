var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('PageHeader',{attrs:{"items":_vm.item,"title":_vm.title}}),_c('div',[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"col-sm-4",staticStyle:{"margin-left":"575px"}},[_c('div',{staticClass:"float-end d-none d-md-block"})]),_c('b-row',[_c('b-col',{attrs:{"xl":"6"}},[_c('b-input-group',{staticClass:"datatable-btn"},[_c('b-form-input',{attrs:{"placeholder":"Type to Search"},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"disabled":!_vm.filter},on:{"click":function($event){_vm.filter = ''}}},[_vm._v("Clear")])],1)],1)],1),_c('b-col',{attrs:{"xl":"6"}},[_c('b-form-group',{staticClass:"mb-4 datatable-select",attrs:{"label-cols":"2","label":"Per page"}},[_c('b-form-select',{attrs:{"options":_vm.pageOptions},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}})],1)],1)],1),_c('div',{staticClass:"table-responsive datatable-vue"},[_c('b-table',{attrs:{"show-empty":"","stacked":"md","items":_vm.items,"fields":_vm.tablefields,"filter":_vm.filter,"current-page":_vm.currentPage,"per-page":_vm.perPage},on:{"filtered":_vm.onFiltered},scopedSlots:_vm._u([{key:"cell(customer)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.customer.customer_name + ' '+ item.customer.customer_surname)+" ")]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [(['0', 0].includes(item.status))?_c('b-badge',{staticClass:"btn btn-primary"},[_vm._v(_vm._s(item.order_status[0].status_text))]):_vm._e(),(['1', 1].includes(item.status))?_c('b-badge',{staticClass:"btn btn-primary"},[_vm._v(_vm._s(item.order_status[0].status_text))]):_vm._e(),(['2', 2].includes(item.status))?_c('b-badge',{staticClass:"btn btn-primary"},[_vm._v(_vm._s(item.order_status[0].status_text))]):_vm._e(),(['3', 3].includes(item.status))?_c('b-badge',{staticClass:"btn btn-primary"},[_vm._v(_vm._s(item.order_status[0].status_text))]):_vm._e(),(['4', 4].includes(item.status))?_c('b-badge',{staticClass:"btn btn-primary"},[_vm._v(_vm._s(item.order_status[0].status_text))]):_vm._e()]}},{key:"cell(fromWarehouse_type)",fn:function(ref){
var item = ref.item;
return [(item.fromWarehouse_type === 1)?_c('div',[_c('b-badge',{staticClass:"btn btn-success"},[_vm._v("Main")])],1):_c('div',[_c('b-badge',{staticClass:"btn btn-warning"},[_vm._v("Sub")])],1)]}},{key:"cell(paymentStatus)",fn:function(ref){
var item = ref.item;
return [(item.payment_status === 1)?_c('div',[_c('b-badge',{staticClass:"btn btn-success"},[_vm._v("Paid")])],1):_c('div',[_c('b-badge',{staticClass:"btn btn-danger"},[_vm._v("Not Paid")])],1)]}},{key:"cell(advancePaymentStatus)",fn:function(ref){
var item = ref.item;
return [(item.advance_payment_status === 1)?_c('div',[_c('b-badge',{staticClass:"btn btn-success"},[_vm._v("Paid")])],1):_c('div',[_c('b-badge',{staticClass:"btn btn-danger"},[_vm._v("Not Paid")])],1)]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('b-dropdown',{scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_vm._v(" Dropdown button "),_c('i',{staticClass:"mdi mdi-chevron-down"})]},proxy:true}],null,true)},[(item.status === 3 && item.payment_status === 1)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.statusChange(item)}}},[_vm._v("Arrived")]):_vm._e(),_c('b-dropdown-item',[_c('router-link',{staticClass:"btn btn-sm btn-dark",attrs:{"to":{ name: 'admin-order-show',params:{id:item.id}}}},[_vm._v("Show")])],1),_c('b-dropdown-item',[_c('router-link',{staticClass:"btn btn-sm btn-warning",attrs:{"to":{ name: 'admin-order-process-show',params:{id:item.id}}}},[_vm._v("Process")])],1),_c('b-dropdown-item',[_c('router-link',{staticClass:"btn btn-sm btn-success",attrs:{"to":{ name: 'admin-order-invoice-show',params:{id:item.id}}}},[_vm._v("Invoice")])],1)],1)]}}])})],1),_c('b-col',{staticClass:"my-1 p-0",attrs:{"md":"6"}},[_c('b-pagination',{staticClass:"my-0",attrs:{"total-rows":_vm.rows,"per-page":_vm.perPage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }