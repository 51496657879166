<template>
  <Layout>
    <div>

      <div class="col-xl-12">
        <div class="card card-statistics">
          <div class="card-body">
            <form @submit.prevent="customerInsert">
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="customer_name">Customer Name</label>
                  <input type="text" v-model="form.customer_name"
                         :class="{
                    'is-invalid': typeSubmit && $v.form.customer_name.$error,
                  }" class="form-control" id="customer_name">
                  <div
                      v-if="typeSubmit && $v.form.customer_name.$error"
                      class="invalid-feedback"
                  >
                  <span v-if="!$v.form.customer_name.required"
                  >This value is required.</span
                  >
                  </div>
                </div>
                <div class="form-group col-md-6">
                  <label for="customer_surname">Customer Surname</label>
                  <input type="text" v-model="form.customer_surname"
                         :class="{
                    'is-invalid': typeSubmit && $v.form.customer_surname.$error,
                  }"  class="form-control" id="customer_surname">
                  <div
                      v-if="typeSubmit && $v.form.customer_surname.$error"
                      class="invalid-feedback"
                  >
                  <span v-if="!$v.form.customer_surname.required"
                  >This value is required.</span
                  >
                  </div>
                </div>
              </div>
              <br>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="customerCountry">Country</label>
                  <select @change="switchCountrySelect($event)" id="customerCountry" class="form-control" v-model="form.country_id"
                          :class="{'is-invalid': typeSubmit && $v.form.country_id.$error}">
                    <option :key="ct.id" :value="ct.id" v-for="ct in country">{{ ct.country }}</option>
                  </select>
                  <div
                      v-if="typeSubmit && $v.form.country_id.$error"
                      class="invalid-feedback"
                  >
                  <span v-if="!$v.form.country_id.required"
                  >This value is required.</span
                  >
                  </div>
                </div>
<!--                <div class="form-group col-md-3">-->
<!--                  <label for="customer_country">Customer Country</label>-->
<!--                  <input type="text" :class="{-->
<!--                    'is-invalid': typeSubmit && $v.form.customer_country.$error,-->
<!--                  }" v-model="form.customer_country" :readonly="true" class="form-control" id="customer_country">-->
<!--                  <div-->
<!--                      v-if="typeSubmit && $v.form.customer_country.$error"-->
<!--                      class="invalid-feedback"-->
<!--                  >-->
<!--                  <span v-if="!$v.form.customer_country.required"-->
<!--                  >This value is required.</span-->
<!--                  >-->
<!--                  </div>-->
<!--                </div>-->
                <div class="form-group col-md-6">
                  <label for="teamCity">City</label>
                  <select id="teamCity" class="form-control" v-model="form.city_id"
                          :class="{'is-invalid': typeSubmit && $v.form.city_id.$error,}">
                    <option :key="c.id" :value="c.id" v-for="c in city">{{ c.city }}</option>
                  </select>
                  <div
                      v-if="typeSubmit && $v.form.city_id.$error"
                      class="invalid-feedback"
                  >
                  <span v-if="!$v.form.city_id.required"
                  >This value is required.</span
                  >
                  </div>
                </div>
<!--                <div class="form-group col-md-3">-->
<!--                  <label for="customer_city">Customer City</label>-->
<!--                  <input type="text" :class="{-->
<!--                    'is-invalid': typeSubmit && $v.form.customer_city.$error,-->
<!--                  }" v-model="form.customer_city" :readonly="true" class="form-control" id="customer_city">-->
<!--                  <div-->
<!--                      v-if="typeSubmit && $v.form.customer_city.$error"-->
<!--                      class="invalid-feedback"-->
<!--                  >-->
<!--                  <span v-if="!$v.form.customer_city.required"-->
<!--                  >This value is required.</span-->
<!--                  >-->
<!--                  </div>-->
<!--                </div>-->
              </div>
              <br>
              <div class="form-row">
                <div class="form-group col-md-4">
                  <label for="customer_phone">Customer Phone</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span v-if="form.customerPhoneDialCode != null" class="input-group-text" id="basic-addon1">{{ form.customerPhoneDialCode }}</span>
                    </div>
                    <input type="text" v-mask="form.customerPhoneFormat" v-model="form.customer_phone" class="form-control" id="customer_phone"
                           :class="{'is-invalid': typeSubmit && $v.form.customer_phone.$error,}">
                    <div
                        v-if="typeSubmit && $v.form.customer_phone.$error"
                        class="invalid-feedback"
                    >
                  <span v-if="!$v.form.customer_phone.required"
                  >This value is required.</span
                  >
                    </div>
                  </div>
                </div>
                <div class="form-group col-md-4">
                  <label for="customer_email">Customer Email</label>
                  <input type="email" :class="{
                    'is-invalid': typeSubmit && $v.form.customer_email.$error,
                  }" v-model="form.customer_email" class="form-control" id="customer_email">
                  <div
                      v-if="typeSubmit && $v.form.customer_email.$error"
                      class="invalid-feedback"
                  >
                  <span v-if="!$v.form.customer_email.required"
                  >This value is required.</span
                  >
                  </div>
                  <div
                      v-if="typeSubmit && $v.form.customer_email.$error"
                      class="invalid-feedback"
                  >
                  <span v-if="!$v.form.customer_email.email"
                  >This must be an email.</span
                  >
                  </div>
                </div>
                <div class="form-group col-md-4">
                  <label for="customer_zipcode">Customer Zipcode</label>
                  <input type="text" :class="{
                    'is-invalid': typeSubmit && $v.form.customer_zipcode.$error,
                  }" v-model="form.customer_zipcode" class="form-control" id="customer_zipcode">
                  <div
                      v-if="typeSubmit && $v.form.customer_zipcode.$error"
                      class="invalid-feedback"
                  >
                  <span v-if="!$v.form.customer_zipcode.required"
                  >This value is required.</span
                  >
                  </div>
                </div>
              </div>
              <br>
              <div class="form-row">
                <div class="form-group col-md-4"  >
                  <label for="customer_address">Customer Address</label>
                  <ckeditor :editor="editor" :class="{
                    'is-invalid': typeSubmit && $v.form.customer_address.$error,
                  }" v-model="form.customer_address" class="form-control" id="customer_address"></ckeditor>
                  <div
                      v-if="typeSubmit && $v.form.customer_address.$error"
                      class="invalid-feedback"
                  >
                  <span v-if="!$v.form.customer_address.required"
                  >This value is required.</span
                  >
                  </div>
                </div>
                <div class="form-group col-md-4">
                  <label for="customer_shipping_address">Customer Shipping Address</label>
                  <ckeditor :editor="editor" :class="{
                    'is-invalid': typeSubmit && $v.form.customer_shipping_address.$error,
                  }" v-model="form.customer_shipping_address" class="form-control" id="customer_shipping_address"></ckeditor>
                  <div
                      v-if="typeSubmit && $v.form.customer_shipping_address.$error"
                      class="invalid-feedback"
                  >
                  <span v-if="!$v.form.customer_shipping_address.required"
                  >This value is required.</span
                  >
                  </div>
                </div>
                <div class="form-group col-md-4">
                  <label for="customer_billing_address">Customer Billing Address</label>
                  <ckeditor :editor="editor" v-model="form.customer_billing_address" class="form-control" id="customer_billing_address"></ckeditor>
                </div>
              </div>
              <br>
              <button type="submit" class="btn btn-primary col-xl-12">Save</button>
            </form>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>

<script>

import Layout from "../../router/layouts/main.vue";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  required,email
} from "vuelidate/lib/validators";

export default {
  components:{
    // eslint-disable-next-line vue/no-unused-components
    ckeditor: CKEditor.component,
    Layout,
  },
  name:'admin-create-customer',
  created(){
    // eslint-disable-next-line no-undef
    if(!User.loggedIn()){
      this.$router.push({name: 'admin-login'})
    }
    this.$http.get('customer/')
        .then(({data}) => (this.customers = data))
    this.$http.get('country/')
        .then(({data}) => (this.country = data))
  },
  validations(){
    return{
      form:{
        customer_name: {required},
        customer_surname: {required},
        customer_phone: {required},
        customer_email: {required,email},
        // customer_country: {required},
        // customer_city: {required},
        country_id: {required},
        city_id: {required},
        customer_zipcode: {required},
        customer_address: {required},
        customer_shipping_address: {required},
        customer_billing_address: {required},
      },
    }
  },

  data(){
    return {
      typeSubmit:false,
      form:{
        customer_name: null,
        customer_surname: null,
        customer_phone: null,
        customer_email: null,
        // customer_country: null,
        // customer_city: null,
        customer_zipcode: null,
        customer_address: null,
        customer_shipping_address: null,
        customer_billing_address: null,
        country_id: null,
        city_id: null,
        customerPhoneDialCode: null,
        customerPhoneFormat: null
      },
      errors:{},
      customers:{},
      country:[],
      city:[],
      editor: ClassicEditor,
    }
  },

  methods:{
    customerInsert(){
      this.typeSubmit = true;
      this.$v.$touch()

      if(this.$v.$invalid){
        return false;
      }else{
        this.$http.post('customer',this.form)
            .then(() => {
              this.$router.push({ name: 'admin-customer-list'})
              this.$toasted.global.success()
            })
            .catch(error => this.errors = error.response.data.errors)
      }

    },
    switchCountrySelect(event){
      let id = event.target.value;
      if (id != null) {
        this.$http.get('select-to-country/'+id)
            .then(({data}) => (this.city = data))
            .catch()
        this.changePhoneFormatNumber();
      } else {
        this.$toasted.global.selectCountry()
      }

    },

    changePhoneFormatNumber(){
      // eslint-disable-next-line no-unused-vars
      let format;
      let dialCode;
      this.country.map(e=>{
        if(e.id === this.form.country_id){
          format = e.country_format;
          dialCode = e.country_dial_code;
          if (this.form.customer_phone != null){
            this.form.customer_phone = '';
          }
        }
      })
      this.form.customerPhoneDialCode = dialCode;
      this.form.customerPhoneFormat = format;

    },
  }
}
</script>
<style scoped>

</style>
