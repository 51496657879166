<template>
<!--  <Layout>-->
    <div class="feed" ref="feed">
      <ul>
        <li v-for="message in messages" :class="`message${message.to === contact.id ? ' received' : ' sent'}`" :key="message.id">
          <div class="text">
            {{ message.text }}
          </div>
        </li>
      </ul>
    </div>
<!--  </Layout>-->
</template>

<script>
import Layout from "../../router/layouts/main.vue";
import PageHeader from "@/components/page-header";

export default {
  name: "MessagesFeed",
  props:{
    contact:{
      type: Object,
    },
    messages:{
      type: Array,
      required: true
    }
  },
  components:{
    // eslint-disable-next-line vue/no-unused-components
    Layout,PageHeader
  },
  methods: {
    scrollToBottom(){
      setTimeout(() => {
        this.$refs.feed.scrollTop = this.$refs.feed.scrollHeight - this.$refs.feed.clientHeight;
      },50);
    },
  },
  watch: {
    contact(){
      this.scrollToBottom();
    },
    messages(){
      this.scrollToBottom();
    }
  }
}
</script>

<style lang="scss" scoped>
.feed{
  background: #f0f0f0;
  height: 100%;
  max-height: 470px;
  overflow: scroll;

  ul {
    list-style-type: none;
    padding: 5px;

    li {
      &.message {
        margin: 10px 0;
        width: 100%;

        .text {
          max-width: 600px;
          border-radius: 5px;
          padding: 12px;
          display: inline-block;
        }

        &.received {
          text-align: right;

          .text {
            background: #dcf8c6;
            color: black;
          }
        }

        &.sent {
          text-align: left;

          .text {
            background: #ffffff;
            color: black;
          }
        }
      }
    }
  }
}
</style>