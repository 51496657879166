<template>
<!--  <Layout>-->
    <div class="conversation">
      <h1>{{ contact ? contact.name : 'Select a Contact' }}</h1>
      <MessagesFeed :contact="contact" :messages="messages" />
      <MessageComposer @send="sendMessage"/>
    </div>
<!--  </Layout>-->

</template>

<script>
import Layout from "../../router/layouts/main.vue";
import PageHeader from "@/components/page-header";
import MessageComposer from "./MessageComposer";
import MessagesFeed from "./MessagesFeed";

export default {
  name: "Conversation",
  props:{
    contact:{
      type: Object,
      default: null
    },
    messages:{
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: []
    }
  },
  components:{
    // eslint-disable-next-line vue/no-unused-components
    MessageComposer,MessagesFeed,Layout,PageHeader
  },
  methods:{
    sendMessage(text){
      if (!this.contact){
        return;
      }
      this.$http.post('conversation/send',{
        contact_id: this.contact.id,
        text: text
      }).then((response) => {
        this.$emit('new',response.data);
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.conversation{
  flex: 5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h1 {
    font-size: 20px;
    padding: 10px;
    margin: 0;
    border-bottom: 1px dashed lightgray;
  }
}
</style>