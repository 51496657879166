<template>
  <Layout>
    <PageHeader :items="item" :title="title"></PageHeader>
    <div>
      <div class="col-sm-6 col-md-3 mt-4">
        <div class="text-center">
          <b-modal id="edit-modal" v-model="displayModal" title="Edit Color" hide title-class="font-18">
<!--            <div class="form-group">-->
<!--              <label for="selectRouteList">Select Role</label>-->
<!--              <select id="inputFromWarehouse" class="form-control" v-model="editForm.role_id">-->
<!--                <option value="">Select Role</option>-->
<!--                <option :key="role.id" :value="role.id" v-for="role in roles">{{ role.role }}</option>-->
<!--              </select>-->
<!--            </div>-->
<!--            <br>-->
            <div class="form-group">
              <label>Title</label>
              <b-form-input id="edit-color-name" v-model="editForm.title" placeholder="Add Title"
                            :class="{'is-invalid': typeSubmit && $v.editForm.title.$error,}"></b-form-input>
              <div
                  v-if="typeSubmit && $v.editForm.title.$error"
                  class="invalid-feedback"
              >
                  <span v-if="!$v.editForm.title.required"
                  >This value is required.</span
                  >
              </div>
            </div>
            <br>
            <div class="form-group">
              <label for="description">Description</label>
              <ckeditor :editor="editor" v-model="editForm.description" class="form-control" id="description"
                        :class="{'is-invalid': typeSubmit && $v.editForm.description.$error,}"></ckeditor>
              <div
                  v-if="typeSubmit && $v.editForm.description.$error"
                  class="invalid-feedback"
              >
                  <span v-if="!$v.editForm.description.required"
                  >This value is required.</span
                  >
              </div>
            </div>
            <br>
            <div class="form-group">
              <label for="selectRouteList">Status</label>
              <select id="inputFromWarehouse" class="form-control" v-model="editForm.status"
                      :class="{'is-invalid': typeSubmit && $v.editForm.status.$error,}">
                <option value="1">Active</option>
                <option value="0">Passive</option>
              </select>
              <div
                  v-if="typeSubmit && $v.editForm.status.$error"
                  class="invalid-feedback"
              >
                  <span v-if="!$v.editForm.status.required"
                  >This value is required.</span
                  >
              </div>
            </div>
            <template #modal-footer>
              <button v-b-modal.modal-close_visit data-dismiss="modal" @click="closeModal" class="btn btn-danger btn-sm m-1">Close</button>
              <button v-b-modal.modal-close_visit class="btn btn-success btn-sm m-1" @click="editAnnouncements(id)" >Save</button>
            </template>
          </b-modal>
        </div>
        <div class="text-center">
          <b-modal id="modal-standard"  v-model="editModalDisplay" title="Add Color" hide title-class="font-18">
<!--            <div class="form-group">-->
<!--              <label for="selectRouteList">Select Role</label>-->
<!--              <select id="inputFromWarehouse" class="form-control" v-model="form.role_id">-->
<!--                <option value="">Select Role</option>-->
<!--                <option :key="role.id" :value="role.id" v-for="role in roles">{{ role.role }}</option>-->
<!--              </select>-->
<!--            </div>-->
<!--            <br>-->
            <div class="form-group">
              <label>Title</label>
              <b-form-input id="edit-color-name" v-model="form.title" placeholder="Add Title"
                            :class="{'is-invalid': typeSubmit && $v.form.title.$error,}"></b-form-input>
              <div
                  v-if="typeSubmit && $v.form.title.$error"
                  class="invalid-feedback"
              >
                  <span v-if="!$v.form.title.required"
                  >This value is required.</span
                  >
              </div>
            </div>
            <br>
            <div class="form-group">
              <label for="description">Description</label>
              <ckeditor :editor="editor" v-model="form.description" class="form-control" id="description"
                        :class="{'is-invalid': typeSubmit && $v.form.description.$error,}"></ckeditor>
              <div
                  v-if="typeSubmit && $v.form.description.$error"
                  class="invalid-feedback"
              >
                  <span v-if="!$v.form.description.required"
                  >This value is required.</span
                  >
              </div>
            </div>
            <br>
            <div class="form-group">
              <label for="selectRouteList">Status</label>
              <select id="inputFromWarehouse" class="form-control" v-model="form.status"
                      :class="{'is-invalid': typeSubmit && $v.form.description.$error,}">
                <option value="1">Active</option>
                <option value="0">Passive</option>
              </select>
              <div
                  v-if="typeSubmit && $v.form.description.$error"
                  class="invalid-feedback"
              >
                  <span v-if="!$v.form.description.required"
                  >This value is required.</span
                  >
              </div>
            </div>
            <template  #modal-footer>
              <button  data-dismiss="modal" @click="closeModal" class="btn btn-danger btn-sm m-1">Close</button>
              <b-button  for="edit-modal" class="btn btn-success btn-sm m-1" @click="addAnnouncements" >Save</b-button>
            </template>
          </b-modal>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body">
                <div style="margin-left: 575px" class="col-sm-4">
                  <div class="float-end d-none d-md-block">
                    <b-button style="float: right"  v-b-modal.modal-standard variant="primary">New Announcements</b-button>
                  </div>
                </div>
                <b-row>
                  <b-col xl="6">
                    <b-input-group class="datatable-btn">
                      <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                      <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-col>
                  <b-col xl="6">
                    <b-form-group  label-cols="2" label="Per page" class="mb-4 datatable-select">
                      <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                    </b-form-group>

                  </b-col>
                </b-row>

                <div class="table-responsive datatable-vue">
                  <b-table
                      show-empty
                      stacked="md"
                      :items="items"
                      :fields="tablefields"
                      :filter="filter"
                      :current-page="currentPage"
                      :per-page="perPage"
                      @filtered="onFiltered"

                  >
                    <template v-slot:cell(status)="{item}">
                      <div v-if="item.status === 1 || item.status === '1'">
                        <b-badge class="btn btn-success">Active</b-badge>
                      </div>
                      <div v-else>
                        <b-badge class="btn btn-danger">Passive</b-badge>
                      </div>
                    </template>
                    <template v-slot:cell(description)="{item}">
                      {{ item.description.substring(0,30) }}
                    </template>
                    <template v-slot:cell(userFullName)="{item}">
                      {{ item.user.name }} {{ item.user.surname }}
                    </template>
                    <template v-slot:cell(actions)="{item}">
                      <b-button  v-b-modal.edit-modal @click="editAnnouncementsModal(item)" class="btn btn-sm btn-dark">Edit</b-button>
                      <a  @click="deleteAnnouncements(item.id)" v-role="['ROLE_ROOT']" style="color: white;" class="btn btn-sm btn-danger">Delete</a>
                    </template>

                  </b-table>
                </div>

                <b-col md="6" class="my-1 p-0">
                  <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                      class="my-0"
                  ></b-pagination>
                </b-col>


              </div>

            </div>
          </div>

        </div>
      </div>
      <!-- end row -->
    </div>
  </Layout>
</template>
<script>
import Layout from "../../router/layouts/main.vue";
import PageHeader from "@/components/page-header";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {required} from "vuelidate/lib/validators";


export default {
  components:{
    // eslint-disable-next-line vue/no-unused-components
    Layout,PageHeader,ckeditor: CKEditor.component,
  },
  created(){
    this.getAnnouncements();
    // eslint-disable-next-line no-undef
    if(!User.loggedIn()){
      this.$router.push({name: '/admin/login'})
    }
    this.allRoleList();
  },
  data(){
    return{
      typeSubmit:false,
      editModalDisplay:false,
      editor: ClassicEditor,
      tablefields: [
        { key: 'title', label: 'Title', sortable: true, },
        { key: 'role.role', label: 'Role', sortable: true, },
        { key: 'userFullName', label: 'Name - Surname', sortable: true, },
        { key: 'Description', label: 'Description', sortable: true, },
        { key: 'Status', label: 'Status', sortable: true, },
        { key: 'actions', label: 'Actions', sortable: true, },
      ],
      items: [

      ],
      editForm:{
        title: null,
        description: null,
        // role_id: null,
        status: null,
      },
      displayModal:false,
      form:{
        title: null,
        description: null,
        // role_id: null,
        status: null,
      },
      id:null,
      title: "Form Elements",
      item: [
        {
          text: "Veltrix",
          href: "/"
        },
        {
          text: "Forms",
          href: "/"
        },
        {
          text: "Form Elements",
          active: true
        },


      ],
      categories:[],
      buttons: [
        {
          text: "New Announcements",
          href: "/",
          active: true
        },
      ],
      searchTerm:'',
      filter: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15],
      roles:{},
    }
  },
  validations(){
    return{
      form:{
        title: {required},
        description: {required} ,
        // role_id: null,
        status: {required},
      },
      editForm:{
        title: {required},
        description: {required} ,
        // role_id: null,
        status: {required},
      },
    }
  },
  mounted(){
    this.totalRows = this.items.length;

  },
  computed:{
    sortOptions() {
      // Create an options list from our fields
      return this.tablefields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key };
          });
    },
    rows(){
      return this.items.length;
    },

  },
  methods:{
    allRoleList(){
      this.$http.get('role')
          .then(({data}) => (this.roles = data))
          .catch()
    },
    clearModalInput(){
      this.form.title = null;
      this.form.role_id = null;
      this.form.description = null;
      this.form.status = null;

    },
    editAnnouncementsModal(item){
      this.id = item.id;
      this.editForm.title = item.title;
      this.editForm.role_id = item.role_id;
      this.editForm.description = item.description;
      this.editForm.status = item.status;

    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    closeModal(){
      this.displayModal = false;
      this.editModalDisplay =false;
    },
    addAnnouncements(){
      this.typeSubmit = true;
      this.$v.$touch();
      if(this.$v.form.$invalid){
        return;
      }else{
        this.$http.post('announcements',this.form)
            // eslint-disable-next-line no-console
            .then((data) => {console.log(data)
              this.editModalDisplay = false;
              this.typeSubmit = false;
              this.$toasted.global.success()
              this.getAnnouncements();
              this.clearModalInput();
            })
            .catch()
      }


    },
    getAnnouncements(){
      this.$http.get('announcements')
          .then((res) => {
            this.items = res.data;
            // eslint-disable-next-line no-console
            console.log(res);
          })
          .catch()
    },
    editAnnouncements(id){
      // eslint-disable-next-line no-console
      this.typeSubmit = true;
      this.$v.$touch();
      if(this.$v.editForm.$invalid){
        return;
      }else{
        this.$http.patch('announcements/'+id,this.editForm)
            .then(() => {
              // eslint-disable-next-line no-console
              this.getAnnouncements();
              this.displayModal = false;
              this.$toasted.global.success()
            })
            .catch()
      }


    },
    deleteAnnouncements(id){
      // eslint-disable-next-line no-undef
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          this.$http.delete('announcements/'+id)
              .then(() => {
                this.getAnnouncements();
                this.$toasted.global.success()
                // this.categories = this.categories.filter(role => {
                //   return role.id != id
                // })
              })
              .catch(() => {
              })
          // eslint-disable-next-line no-undef
          Swal.fire(
              'Deleted!',
              'Your data has been deleted.',
              'success'
          )
        }
      })

    }
  },

}
</script>

<style scoped>

</style>
