<template>
  <Layout>
    <br>
    <div>
      <!-- Container-fluid starts-->
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header d-flex justify-content-between">
                <div class="card-heading">
                  <h4 class="card-title .customCardTitle">Variants</h4>
                </div>
              </div>
              <div class="card-body">
                <b-tabs justified pills class="navtab-bg" content-class="p-3">
                  <b-tab active>
                    <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                  </span>
                      <span class="d-none d-sm-inline-block">Add</span>
                    </template>
                    <div>
                      <div class="card-body">
                        <div class="row">
                          <div class="form-row">
                            <div class="form-group col-md-3">
                              <div class="checkbox checbox-switch switch-success">
                                <button type="button" class="btn btn-block btn-round btn-warning" @click="addNewVariantRow">Add More</button>
                              </div>
                            </div>
                          </div>
                          <form class="row g-3" @submit.prevent="insertVariant">
                            <table class="display compact table table-striped table-bordered" width="100%" style="border-color: #ddd;">
                              <thead>
                              <th>Size</th>
                              <th>Color</th>
                              <th>Unit</th>
                              <th>Sku No</th>
                              <th>Quantity</th>
                              <th>Weight</th>
<!--                              <th>Capacity</th>-->
                              <th>Tax</th>
                              <th>Cost Price</th>
                              <th>Selling Price</th>
                              <th>Discount Price</th>
                              <th>Upload Image</th>
                              <th>Image</th>
                              <th>Action</th>
                              </thead>
                              <tbody id="addRow" class="addRow">

                              </tbody>
                              <tbody>
                              <tr v-for="(variant, k) in variants" :key="k">
                                <td>
                                  <select id="size" class="form-control" v-model="variant.size_id">
                                    <option :key="size.id" :value="size.id" v-for="size in sizes">{{ size.size_name }}</option>
                                  </select>
                                </td>
                                <td>
                                  <select id="color" class="form-control" v-model="variant.color_id">
                                    <option :key="color.id" :value="color.id" v-for="color in colors">{{ color.color_name }}</option>
                                  </select>
                                </td>
                                <td>
                                  <select id="unit" class="form-control" v-model="variant.unit_id">
                                    <option :key="unit.id" :value="unit.id" v-for="unit in units">{{ unit.unit_name }}</option>
                                  </select>
                                </td>
                                <td>
                                  <input class="form-control" style="width: 100%" type="text" v-model="variant.sku_no">
                                </td>
                                <td>
                                  <input v-on:keypress="NumbersOnly" class="form-control" style="width: 100%" type="text" v-model="variant.variant_quantity">
                                </td>
                                <td>
                                  <money style="width: 100%" v-model.lazy="variant.variant_weight" v-bind="weight" class="form-control"></money>
<!--                                  <input v-on:keypress="NumbersOnly" class="form-control" style="width: 100%" type="text" v-model="variant.variant_weight">-->
                                </td>
<!--                                <td>-->
<!--                                  <input class="form-control" style="width: 100%" type="text" v-model="variant.variant_capacity" :readonly="true">-->
<!--                                </td>-->
                                <td>
                                  <money style="width: 100%" v-model.lazy="variant.variant_tax" v-bind="money" class="form-control"></money>
<!--                                  <input class="form-control" style="width: 100%" type="text" v-model="variant.variant_tax">-->
                                </td>
                                <td>
                                  <money style="width: 100%" v-model.lazy="variant.variant_cost_price" v-bind="money" class="form-control"></money>
                                  <!--                                  <input class="form-control" style="width: 100%" type="text" v-model="variant.variant_selling_price">-->
                                </td>
                                <td>
                                  <money style="width: 100%" v-model.lazy="variant.variant_selling_price" v-bind="money" class="form-control"></money>
<!--                                  <input class="form-control" style="width: 100%" type="text" v-model="variant.variant_selling_price">-->
                                </td>
                                <td>
                                  <money style="width: 100%" v-model.lazy="variant.variant_discount_price" v-bind="money" class="form-control"></money>
<!--                                  <input class="form-control" style="width: 100%" type="text" v-model="variant.variant_discount_price">-->
                                </td>
                                <td>
                                  <div class="col-md-8 input-group">
                                    <input type="file" @change="onFileSelected(k)" class="form-control" id="inputGroupFile02">
                                    <label class="input-group-text" for="inputGroupFile02">Upload</label>
                                  </div>
                                </td>
                                <td>
                                  <div class="form-group col-md-2">
                                    <img :src="variant.variant_image" id="variant_image">
                                  </div>
                                </td>
                                <td>
                                  <button type="button" class="btn btn-block btn-round btn-danger" @click="deleteVariantRow(k,variant)">X</button>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                            <button type="submit" class="btn btn-primary col-xl-12">Save</button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </b-tab>

                  <b-tab>
                    <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-cog"></i>
                  </span>
                      <span class="d-none d-sm-inline-block">Show</span>
                    </template>
                    <div>
                      <div class="card-body">
                        <div class="row">
                          <form class="row g-3" @submit.prevent="updateVariant">
                            <table class="display compact table table-striped table-bordered" width="100%" style="border-color: #ddd;">
                              <thead>
                              <th>Size</th>
                              <th>Color</th>
                              <th>Unit</th>
                              <th>Sku No</th>
                              <th>Quantity</th>
                              <th>Weight</th>
                              <th>Capacity</th>
                              <th>Tax</th>
                              <th>Cost Price</th>
                              <th>Selling Price</th>
                              <th>Discount Price</th>
                              <th>Upload Image</th>
                              <th>New Image</th>
                              <th>Image</th>
                              <th>Action</th>
                              </thead>
                              <tbody id="addRow" class="addRow">

                              </tbody>
                              <tbody>
                              <tr v-for="(show_variant, r) in show_variants" :key="r">
                                <input type="hidden" v-model="show_variant.id">
                                <td>
                                  <select id="size" class="form-control" v-model="show_variant.size_id">
                                    <option :key="size.id" :value="size.id" v-for="size in sizes">{{ size.size_name }}</option>
                                  </select>
                                </td>
                                <td>
                                  <select id="color" class="form-control" v-model="show_variant.color_id">
                                    <option :key="color.id" :value="color.id" v-for="color in colors">{{ color.color_name }}</option>
                                  </select>
                                </td>
                                <td>
                                  <select id="unit" class="form-control" v-model="show_variant.unit_id">
                                    <option :key="unit.id" :value="unit.id" v-for="unit in units">{{ unit.unit_name }}</option>
                                  </select>
                                </td>
                                <td>
                                  <input class="form-control" style="width: 100%" :readonly="true" type="text" v-model="show_variant.sku_no">
                                </td>
                                <td>
                                  <input v-on:keypress="NumbersOnly" class="form-control" style="width: 100%" type="text" v-model="show_variant.variant_quantity">
                                </td>
                                <td>
                                  <money style="width: 100%" v-model.lazy="show_variant.variant_weight" v-bind="weight" class="form-control"></money>
<!--                                  <input v-on:keypress="NumbersOnly" class="form-control" style="width: 100%" type="text" v-model="show_variant.variant_weight">-->
                                </td>
                                <td>
                                  <input class="form-control" style="width: 100%" type="text" v-model="show_variant.variant_capacity" :readonly="true">
                                </td>
                                <td>
                                  <money style="width: 100%" v-model.lazy="show_variant.variant_tax" v-bind="money" class="form-control"></money>
<!--                                  <input class="form-control" style="width: 100%" type="text" v-model="show_variant.variant_tax">-->
                                </td>
                                <td>
                                  <money style="width: 100%" v-model.lazy="show_variant.variant_cost_price" v-bind="money" class="form-control"></money>
                                  <!--                                  <input class="form-control" style="width: 100%" type="text" v-model="show_variant.variant_selling_price">-->
                                </td>
                                <td>
                                  <money style="width: 100%" v-model.lazy="show_variant.variant_selling_price" v-bind="money" class="form-control"></money>
<!--                                  <input class="form-control" style="width: 100%" type="text" v-model="show_variant.variant_selling_price">-->
                                </td>
                                <td>
                                  <money style="width: 100%" v-model.lazy="show_variant.variant_discount_price" v-bind="money" class="form-control"></money>
<!--                                  <input class="form-control" style="width: 100%" type="text" v-model="show_variant.variant_discount_price">-->
                                </td>
                                <td>
                                  <div class="col-md-8 input-group">
                                    <input type="file" @change="onShowFileSelected(r)" class="form-control" id="inputGroupFile02">
                                    <label class="input-group-text" for="inputGroupFile02">Upload</label>
                                  </div>
                                </td>
                                <td>
                                  <div class="form-group col-md-2">
                                    <img :src="show_variant.new_variant_image" id="variant_photo">
                                  </div>
                                </td>
                                <td>
                                  <div class="form-group col-md-2">
                                    <lazy-component wrapper-tag="section" @intersected="optionalDispatch">
                                      <img :src="'https://crmtr.balinsoft.com/public/'+show_variant.variant_image" id="variant_photo">
                                      <span slot="placeholder">Loading..</span> <!-- Optional -->
                                    </lazy-component>
<!--                                    <img :src="'https://crmtr.balinsoft.com/public/'+show_variant.variant_image" id="variant_photo">-->
                                  </div>
                                </td>
                                <td>
                                  <a @click="deleteVariant(show_variant.id)" style="color: white;" class="btn btn-block btn-round btn-danger">X</a>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                            <button type="submit" class="btn btn-primary col-xl-12">Update</button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </b-tab>
                </b-tabs>

              </div>

            </div>
          </div>

        </div>
      </div>
      <!-- Container-fluid Ends-->
    </div>
  </Layout>

</template>

<script>
import Layout from "../../router/layouts/main.vue";
import axios from "axios";
import {Money} from "v-money";
import LazyComponent from "v-lazy-component";

export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { Layout,Money,LazyComponent },
  created(){
    // eslint-disable-next-line no-undef
    if(!User.loggedIn()){
      this.$router.push({name: 'admin-login'})
    }
    this.showVariant();
    this.getSize();
    this.getColor();
    this.getUnit();
  },
  data(){
    return{
      money: {
        decimal: '.',
        thousands: ',',
        // prefix: 'R$ ',
        // suffix: ' #',
        precision: 2,
        masked: true /* doesn't work with directive */
      },
      weight: {
        decimal: '.',
        thousands: ',',
        // prefix: 'R$ ',
        // suffix: ' #',
        precision: 3,
        masked: true /* doesn't work with directive */
      },
      variants: [{
        size_id: '',
        color_id: '',
        unit_id: '',
        variant_weight: '',
        // variant_capacity: '',
        variant_tax: '',
        variant_cost_price: '',
        variant_selling_price: '',
        variant_discount_price	: '',
        sku_no: '',
        variant_quantity: '',
        variant_image: '',
      }],
      show_variants: [{
        id: '',
        size_id: '',
        color_id: '',
        unit_id: '',
        variant_weight: '',
        variant_capacity: '',
        variant_tax: '',
        variant_cost_price: '',
        variant_selling_price: '',
        variant_discount_price	: '',
        sku_no: '',
        variant_quantity: '',
        variant_image: '',
        new_variant_image: '',
      }],
      searchTerm:'',
      sizes:{},
      colors:{},
      units:{},
    }
  },
  computed:{
    // filtersearch(){
    //   return this.productVariants.filter(productVariant => {
    //     return productVariant.product.product_name.match(this.searchTerm)
    //
    //   })
    // }
  },
  methods:{
    NumbersOnly(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    addNewVariantRow() {
      this.variants.push({
        size_id: '',
        color_id: '',
        sku_no: '',
        variant_quantity: '',
        variant_image: '',
      })
    },
    deleteVariantRow(index,variant) {
      var idx = this.variants.indexOf(variant);
      if(idx > -1) {
        this.variants.splice(idx, 1);
      }
    },
    getSize(){
      this.$http.get('size/')
          .then(({data}) => (this.sizes = data))
    },
    getColor(){
      this.$http.get('color/')
          .then(({data}) => (this.colors = data))
    },
    getUnit(){
      this.$http.get('unit/')
          .then(({data}) => (this.units = data))
    },
    onFileSelected(index){
      let file = event.target.files[0];
      console.log(index,file)
      if(file.size > 20971520) {
        Notification.image_validation()
      } else {
        let reader = new FileReader();
        reader.onload = event => {
          this.variants[index].variant_image = event.target.result
          // eslint-disable-next-line no-console
          // console.log(event.target.result);
        };
        reader.readAsDataURL(file);
      }
    },
    onShowFileSelected(index){
      let file = event.target.files[0];
      console.log(index,file)
      if(file.size > 20971520) {
        Notification.image_validation()
      } else {
        let reader = new FileReader();
        reader.onload = event => {
          this.show_variants[index].new_variant_image = event.target.result
          // eslint-disable-next-line no-console
          // console.log(event.target.result);
        };
        reader.readAsDataURL(file);
      }
    },
    showVariant(){
      let id = this.$route.params.id
      this.$http.get('show/variant/'+id)
          .then(({data}) => (this.show_variants = data))
          .catch()
    },
    insertVariant(){
      let id = this.$route.params.id
      this.$http.post('variant-store/'+id,this.variants)
          .then(() => {
            this.$router.push({name: 'admin-variant-product',params:{id:id}})
            this.$router.push({ name: 'admin-product-list'})
            Notification.success()
          })
          .catch(error => this.errors = error.response.data.errors)
    },
    updateVariant(){
      let id = this.$route.params.id
      this.$http.post('variant-update/'+id,this.show_variants)
          .then(() => {
            this.$router.push({ name: 'admin-product-list'})
            Notification.success()
          })
          .catch(error => this.errors = error.response.data.errors)
    },
    deleteVariant(id){
      // eslint-disable-next-line no-undef
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          axios.delete('variant-delete/'+id)
              .then(() => {
                this.show_variants = this.show_variants.filter(showVariant => {
                  return showVariant.id !== id
                })
                this.$router.push({ name: 'admin-product-list'})
              })
              .catch(() => {
                // this.$router.push({name: 'admin-variant-list'})
              })
          // eslint-disable-next-line no-undef
          Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
          )
        }
      })

    },
    // Variant Size Change
    // sizeChange(event,index){
    //   let id = event.target.value;
    //   this.sizes.map(e=>{
    //     if (e.id === parseInt(id)){
    //       let explode = e.size_name.split(' X ');
    //       this.variants[index].variant_capacity = explode[0]*explode[1]*explode[2];
    //     }
    //   })
    // },
    // Variant Show Size Change
    // showSizeChange(event,index){
    //   let id = event.target.value;
    //   this.sizes.map(e=>{
    //     if (e.id === parseInt(id)){
    //       let explode = e.size_name.split(' X ');
    //       this.show_variants[index].variant_capacity = explode[0]*explode[1]*explode[2];
    //     }
    //   })
    // },
  },


}
</script>

<style scoped>
#variant_photo{
  height: 40px;
  width: 40px;
}
#variant_image{
  width: 50px;
  height: 50px;
}
</style>
