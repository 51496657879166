<template>
  <Layout>
    <div>
      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-body">
              <b-tabs justified pills class="navtab-bg" content-class="p-3">
                <b-tab active>
                  <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                  </span>
                    <span class="d-none d-sm-inline-block">Add Permission</span>
                  </template>
                  <div>
                    <div class="card-body">
                      <div class="row">
                        <form @submit.prevent="storeRoutePermission">
                          <div class="form-group" id="roleShow">
                            <label for="selectRouteList">Select Role</label>
                            <select id="inputFromWarehouse" @change="roleChange($event)" class="form-control" v-model="form.role_id">
                              <option value="">Select Role</option>
                              <option :key="role.id" :value="role.id" v-for="role in roles">{{ role.role }}</option>
                            </select>
                          </div>
                          <br>
                          <div class="form-group" v-show="permissionShow">
                            <b-card no-body class="mb-1 faq" v-model="form.route_group_name" v-for="routeGroup in routeGroups" :key="routeGroup.id">
                              <b-card-header role="tab">
                                <h6 class="m-0 font-size-16">
                                  <a
                                      v-b-toggle="'accordion-'+routeGroup.id"
                                      class="text-dark"
                                      href="javascript: void(0);"
                                  >{{ routeGroup.route_group_name }}</a>
                                </h6>
                              </b-card-header>
                              <b-collapse :id="'accordion-'+routeGroup.id" visible accordion="my-accordion">
                                <b-card-body>
<!--                                  <label class="list-group-item"><input class="form-check-input me-1" type="checkbox" @click="selectAll($event)"> Select All</label>-->
                                  <div class="list-group" :key="group.id" v-for="group in routeGroup.route_list">
                                    <label class="list-group-item">
                                      <input class="form-check-input me-1" v-model="form.permission" type="checkbox" v-bind:value="group">{{ group }}
                                    </label>
                                  </div>
                                </b-card-body>
                              </b-collapse>
                            </b-card>
                          </div>
                          <br>
                          <button type="submit" class="btn btn-success col-xl-12" v-show="permissionButtonShow">Save</button>
                        </form>
                      </div>
                    </div>
                  </div>
                </b-tab>

                <b-tab>
                  <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-cog"></i>
                  </span>
                    <span class="d-none d-sm-inline-block">Permission</span>
                  </template>
                  <div>
                    <div class="card-body">
                      <div class="row">
                        <div class="form-group">
                          <b-card no-body class="mb-1 faq" v-for="permission in routePermission" :key="permission.id">
                            <b-card-header role="tab">
                              <h6 class="m-0 font-size-16">
                                <a v-b-toggle="'accordion-'+permission.id"
                                    class="text-dark"
                                    href="javascript: void(0);">{{ permission.role }}</a>
                                <a v-role="['ROLE_ROOT']" @click="deletePermission(permission.id)" style="color: white; float: right" class="btn btn-danger"><i class="fa fa-trash"></i></a>
                              </h6>
                            </b-card-header>
                            <b-collapse :id="'accordion-'+permission.id" visible accordion="my-accordion">
                              <b-card-body>
                                <div class="list-group" :key="perm.id" v-for="perm in permission.permission">
                                  <label class="list-group-item">
                                    {{ perm }}
                                  </label>
                                </div>
                              </b-card-body>
                            </b-collapse>
                          </b-card>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-tab>
              </b-tabs>
            </div>
            <!-- end card-body -->
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>

import Layout from "../../router/layouts/main.vue";
import PageHeader from "@/components/page-header";
import Multiselect from "vue-multiselect";
import Switches from "vue-switches";

export default {
  components:{
    // eslint-disable-next-line vue/no-unused-components
    Switches,Layout,PageHeader,Multiselect
  },
  created(){
    // eslint-disable-next-line no-undef
    if(!User.loggedIn()){
      this.$router.push({name: '/admin/login'})
    }
    this.allRouteGroupList();
    this.allRoleList();
    this.allRoutePermissionList();
    // eslint-disable-next-line no-undef
    Reload.$on('AfterAdd',() => {
    })
  },
  data(){
    return{
      routeGroups:{},
      routeGroupLists:{},
      routePermission:{},
      showRoutePermission:[],
      roles:{},
      form: {
        role_id: '',
        permission: []
      },
      permissionShow: false,
      permissionButtonShow: false,
      listPermissionShow: false,
      listPermissionButtonShow: false,
      selectRole: '',
    }
  },
  methods:{
    // selectAll(event){
    //   console.log(event)
    // },
    roleChange(event){
      if (event.target.value !== ""){
        this.form.role_id = event.target.value
        this.permissionShow = true
        this.permissionButtonShow = true
      }else{
        this.permissionShow = false
        this.permissionButtonShow = false
      }
    },
    allRoutePermissionList(){
      this.$http.get('route/permission')
          .then(({data}) => (this.routePermission = data))
          .catch()
    },
    allRouteGroupList(){
      this.$http.get('route/group')
          .then(({data}) => (this.routeGroups = data))
          .catch()
    },
    allRoleList(){
      this.$http.get('role')
          .then(({data}) => (this.roles = data))
          .catch()
    },
    storeRoutePermission(){
      this.$http.post('store/route/permission',this.form)
          .then(() => {
            this.$router.push({ name: 'admin-route-permission'})
            this.form.role_id = null
            this.form.permission = ''
            this.permissionShow = false
            this.$toasted.global.success()
            this.allRoutePermissionList();
          })
          .catch(error => this.errors = error.response.data.errors)
    },
    deletePermission(id){
      // eslint-disable-next-line no-undef
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          this.$http.delete('route/permission/'+id)
              .then(() => {
                this.$toasted.global.success()
                this.allRoutePermissionList()
              })
              .catch(() => {
              })
          // eslint-disable-next-line no-undef
          Swal.fire(
              'Deleted!',
              'Your data has been deleted.',
              'success'
          )

        }
      })
    }
  },


}
</script>

<style scoped>

</style>
